import { api } from 'lib/api/base'
import { BaseApiResult } from 'types'
import { ENDPOINTS } from 'lib/api/endpoints'

export type UpdateRecordPalletsParams = {
  comment: string
  job_id: string
  pallet: {
    asset_id: number
    dimensions: {
      height?: string
      length?: string
      width?: string
    }
    quantity?: string
    weight?: string
  }[]
}

export const updateRecordPallets = async (
  params: UpdateRecordPalletsParams,
): Promise<BaseApiResult> => {
  const { data } = await api.post<BaseApiResult>(ENDPOINTS.job, {
    action: 'update_step_record_pallets',
    comment: params.comment,
    job_id: params.job_id,
    pallet: params.pallet,
  })

  return data
}
