import classNames from 'classnames'
import { Field, FieldProps } from 'formik'

import { FormikDatePicker } from '../FormikDatePicker'
import styles from './DatePickerField.module.scss'

type Props = {
  className?: string
  hideIcon?: boolean
  hideValidation?: boolean
  label: string
  name: string
  onChange?: (date: Date, event: React.SyntheticEvent<Date>) => void
  placeholderText?: string
}

export const DatePickerField = ({
  className,
  hideValidation,
  hideIcon,
  label,
  name,
  onChange,
  placeholderText,
}: Props) => {
  return (
    <Field name={name}>
      {({ form, meta, field }: FieldProps) => {
        const invalid = meta.touched && meta.error && form.submitCount > 0

        return (
          <div className={className}>
            <div className={styles.TextFieldContainer}>
              <div
                className={classNames(styles.Input, {
                  [styles.Invalid]: invalid && !hideValidation,
                  [styles.HasValue]: !!field.value,
                })}
              >
                <FormikDatePicker
                  name={name}
                  popperPlacement="bottom"
                  onChange={onChange}
                  placeholderText={placeholderText}
                  hideIcon={hideIcon}
                />
              </div>

              <div className={`${styles.Notch} ${hideIcon ? styles.NotchFullWidth : ''}`}>
                <div className={styles.NotchBefore}></div>
                <div className={classNames(styles.NotchBetween)}>
                  <label htmlFor={name}>{label}</label>
                </div>
                <div className={styles.NotchAfter}></div>
              </div>
            </div>

            {invalid && !hideValidation && <div className={styles.ErrorText}>{meta.error}</div>}
          </div>
        )
      }}
    </Field>
  )
}
