import classNames from 'classnames'

import { CreateAccountForm, FormValues as CreateAccountFormValues } from 'forms/CreateAccountForm'

import styles from '../../RedirectPage.module.scss'
import { getInitialAccountFormValues } from '../../../../constants'
import * as helpers from 'pages/RedirectPage/helpers.ts'
import { useAppContext } from 'contexts/ContextProvider'
import { useRedirectInfo } from 'pages/RedirectPage/hooks/useRedirectInfo'
import { useParams } from 'react-router'

export const CreateAccount = () => {
  const { uniqueId } = useParams<{ uniqueId: string }>()
  const { setUser } = useAppContext()
  const { redirectInfo } = useRedirectInfo(uniqueId!)

  const handleCreateAccount = async (values: CreateAccountFormValues) => {
    await helpers.handleCreateRaAccount(
      values,
      setUser,
      redirectInfo?.details.job_id ?? '',
      redirectInfo?.details.quote_id ?? '',
    )
  }

  return (
    <div className={classNames(styles.FormWrapper)}>
      <CreateAccountForm
        initialValues={getInitialAccountFormValues()}
        onSubmit={handleCreateAccount}
        simpleForm
        stateOptions={[]}
      />
    </div>
  )
}
