import { PropsWithChildren } from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import classnames from 'classnames'

import { JobColor } from 'types'
import styles from './ColorCell.module.scss'
import './styles.scss'

interface ColorCellProps {
  ariaHidden: boolean
  className?: string
  colorCode: JobColor
  statusText?: string
}

const colorCodeToClassName = (colorCode: JobColor) => {
  switch (colorCode) {
    case JobColor.purple:
      return styles.ColorCellPurple
    case JobColor.black:
      return styles.ColorCellBlack
    case JobColor.red:
      return styles.ColorCellRed
    case JobColor.yellow:
      return styles.ColorCellYellow
    case JobColor.green:
      return styles.ColorCellGreen
    case JobColor.blue:
      return styles.ColorCellBlue
    case JobColor.gray:
      return styles.ColorCellGrey
  }
}

export const ColorCell = ({
  ariaHidden,
  className,
  children,
  colorCode,
  statusText,
}: PropsWithChildren<ColorCellProps>): JSX.Element => {
  let ariaLabel: string | undefined = undefined
  if (!ariaHidden) {
    ariaLabel = statusText
  }

  const base = (
    <div
      aria-hidden={ariaHidden}
      aria-label={ariaLabel}
      className={classnames(colorCodeToClassName(colorCode), className)}
      role={!ariaHidden ? 'img' : undefined}
    >
      {children}
    </div>
  )
  const overlay = (
    <OverlayTrigger
      placement="right-start"
      overlay={
        <Tooltip id="sla-tooltip-colorCode" className="sla-tooltip">
          {statusText}
        </Tooltip>
      }
    >
      {base}
    </OverlayTrigger>
  )

  return statusText ? overlay : base
}
