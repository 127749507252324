/* Based on code from https://headbutt.io/useSafeDispatch */

import * as React from 'react'

type DispatchType<ActionType> = (value: ActionType) => void

export const useSafeDispatch = <ActionType>(
  dispatch: DispatchType<ActionType>,
): DispatchType<ActionType> => {
  const mounted = React.useRef(false)

  React.useLayoutEffect(() => {
    mounted.current = true

    return () => {
      mounted.current = false
    }
  }, [])

  return React.useCallback(
    (value: ActionType) => (mounted.current ? dispatch(value) : void 0),
    [dispatch],
  )
}
