import { Formik } from 'formik'

import { Contact, Paradigm, TFormSubmit } from 'types'

import { Form } from 'components/Form'
import { FormContainer, TPreviousPageHandler } from 'components/FormContainer'
import { TextField } from 'components/TextField'
import { PhoneNumberField } from 'components/PhoneNumberField'
import { ContactSchema } from './schema'
import { isPalletPickupParadigm } from 'pages/OrderPages/utils/orderUtils'

export interface TOrderContactFormValues {
  email: string
  emailRequired: boolean
  first_name: string
  last_name: string
  phone: string
}

export type Props = {
  contactRequired?: boolean
  initialValues: TOrderContactFormValues
  onGoToPreviousPage: TPreviousPageHandler
  onSubmit: TFormSubmit<TOrderContactFormValues, void>
  title: string
}

export const ContactForm = ({
  contactRequired = true,
  initialValues,
  onSubmit,
  onGoToPreviousPage,
  title,
}: Props): JSX.Element => {
  const validationSchema = ContactSchema(contactRequired)
  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={values => onSubmit(values)}
      validationSchema={validationSchema}
    >
      {({ handleSubmit, isSubmitting, status, values }) => {
        return (
          <Form onSubmit={handleSubmit}>
            <FormContainer
              formError={status}
              isSubmitting={isSubmitting}
              onGoToPreviousPage={onGoToPreviousPage}
              title={`${title}${contactRequired ? '' : ' (Optional)'}`}
            >
              <TextField
                autoComplete="new_first_name_1"
                className="mb-3"
                label="First Name"
                name="first_name"
                // ref={autoFocusRef}
              />

              <TextField
                autoComplete="new_last_name"
                className="mb-3"
                label="Last Name"
                name="last_name"
              />
              <PhoneNumberField className="mb-3" country={'US'} label="Phone #" name="phone" />

              <TextField
                autoComplete="new_email"
                className="mb-3"
                label={`Email Address${values.emailRequired ? '' : ' (Optional)'}`}
                name="email"
                type="email"
              />
            </FormContainer>
          </Form>
        )
      }}
    </Formik>
  )
}

export const getDefaultContact = (): Contact => {
  return {
    email: '',
    first_name: '',
    last_name: '',
    phone: '',
  }
}

export const getOrderContactFormValues = (
  contact: Contact,
  paradigm?: Paradigm,
): TOrderContactFormValues => {
  return {
    ...contact,
    email: contact.email ?? '',
    emailRequired: isPalletPickupParadigm(paradigm),
  }
}
