import { useCurrentMenu } from 'hooks/useCurrentMenu'
import { useErrorMessageContext } from 'contexts/ErrorMessageContextProvider'

type UseCurrentGridConfigId = () => number
export const useCurrentGridConfigId: UseCurrentGridConfigId = () => {
  const { configId, path } = useCurrentMenu()
  const { setErrorMessage } = useErrorMessageContext()

  if (configId === undefined) {
    setErrorMessage('Page is missing config_id.', {
      identifier1: path,
      identifierType1: 'Path',
    })
    throw new Error('This page needs a config_id for its grid')
  }

  return configId
}
