import { Dispatch } from 'react'
import { Sorting } from '@devexpress/dx-react-grid'

import { Grid, AdvancedSearchFilter, DispatchRow, GridConfig } from 'types'
import { GridConfigData, GridCustomization } from 'lib/api/gridConfig'
import { NormalizedGridDataResponse } from 'lib/api/types'

type SavedViews = Grid[] | undefined
export type SavedViewsCache = { [key: number]: SavedViews }
export interface GridState {
  baseConfigId: number
  configId: number
  currentPage: number
  customization: GridCustomization
  filterColumnId: number
  filters: AdvancedSearchFilter[]
  fixedColumns: number
  forceHideAdvancedSearch: boolean
  gridConfigs: GridConfig[]
  gridDataRows: DispatchRow[]
  loading: number
  name: string
  pageSize: number
  pageSizeOptions: number[]
  plmDownload: boolean
  recordsCount: number
  refresh: {
    gridConfigLastUpdated: number
    lastUpdated: number
  }
  savedViewsCache: SavedViewsCache
  showAdvancedSearch: boolean
  sorting: Sorting[]
}

export enum ActionTypes {
  ClearSavedViews = 'clear_saved_views',
  DeleteSavedView = 'delete_saved_view',
  DigestGridConfig = 'digest_grid_config',
  DigestGridData = 'digest_grid_data',
  RefreshAdvancedSearch = 'refresh_advanced_search',
  RefreshGridConfig = 'refresh_grid_config',
  RefreshLastUpdated = 'refresh_last_updated',
  Reset = 'reset',
  SetConfigId = 'set_config_id',
  SetCurrentPage = 'set_current_page',
  SetFilters = 'set_filters',
  SetGridConfigs = 'set_grid_configs',
  SetGridDataRows = 'set_grid_data_rows',
  SetLoading = 'set_loading',
  SetPageSize = 'set_page_size',
  SetPageSizeOptions = 'set_page_size_options',
  SetRecordsCount = 'set_records_count',
  SetSorting = 'set_sorting',
  ToggleAdvancedSearch = 'toggle_advanced_search',
  UpdateSavedViewsCache = 'update_saved_views_cache',
}

export type Action =
  | { payload: number; type: ActionTypes.Reset }
  | { payload: number; type: ActionTypes.DeleteSavedView }
  | { payload: number; type: ActionTypes.SetRecordsCount }
  | { payload: number; type: ActionTypes.SetPageSize }
  | { payload: number[]; type: ActionTypes.SetPageSizeOptions }
  | { payload: number | undefined; type: ActionTypes.SetConfigId }
  | { payload: Grid[]; type: ActionTypes.UpdateSavedViewsCache }
  | { payload: number; type: ActionTypes.ClearSavedViews }
  | { payload: AdvancedSearchFilter[] | undefined; type: ActionTypes.SetFilters }
  | { type: ActionTypes.RefreshLastUpdated }
  | { type: ActionTypes.RefreshGridConfig }
  | { type: ActionTypes.ToggleAdvancedSearch }
  | { payload: NormalizedGridDataResponse; type: ActionTypes.DigestGridData }
  | { payload: number; type: ActionTypes.SetCurrentPage }
  | { payload: GridConfig[]; type: ActionTypes.SetGridConfigs }
  | { payload: DispatchRow[]; type: ActionTypes.SetGridDataRows }
  | { payload: Sorting[]; type: ActionTypes.SetSorting }
  | { payload: GridConfigData; type: ActionTypes.DigestGridConfig }
  | { payload: boolean; type: ActionTypes.SetLoading }

export type GridDispatch = Dispatch<Action>
