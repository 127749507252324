import partition from 'lodash/partition'

import {
  ColumnExtension,
  ColumnName,
  DispatchRow,
  DynamicObject,
  GridConfig,
  MetaColumn,
} from 'types'

const columnReducer = (
  columns: MetaColumn[],
  { name, dataOnly, highlightColumnId, heading, headingAlignment, tooltip }: GridConfig,
  _: number,
  configs: GridConfig[],
) => {
  if (dataOnly) return columns
  const highlightColumnName = configs.find(({ columnId }) => columnId === highlightColumnId)
    ?.name as ColumnName

  const newColumn = {
    headingAlignment,
    highlightColumnName,
    name,
    title: heading || '\u200b',
    tooltip,
  }
  columns.push(newColumn)
  return columns
}

const rowFormatterFactory = (gridConfigs: GridConfig[]) => (row: DispatchRow) =>
  gridConfigs.reduce<DynamicObject>(
    (rowObject, { dataOnly, name }, index) => ({
      ...rowObject,
      [dataOnly ? 'dataOnly/' + name : name]: row[index],
    }),
    {},
  )

const formatColumnExtensions = (config: GridConfig): ColumnExtension => {
  const width = Number(config.width)

  return {
    align: config.align,
    columnName: config.name,
    sortingEnabled: config.sortable,
    width,
  }
}

const isDisplayable = ({ dataOnly }: GridConfig) => !dataOnly
const isVisible = ({ hidden }: GridConfig) => !hidden

const sortColumns = (colA: GridConfig, colB: GridConfig) => colA.sequence - colB.sequence
const formatColumnWidths = ({ name, width }: GridConfig) => ({
  columnName: name,
  width: Number(width),
})
const getName = ({ name }: GridConfig) => name

export const formatGridViewData = ({
  columns,
  fixedColumns = 0,
  rows,
}: {
  columns: GridConfig[]
  fixedColumns?: number
  rows: DispatchRow[]
}): {
  columnExtensions: ColumnExtension[]
  columnOrder: string[]
  columnWidths: {
    columnName: string
    width: number
  }[]
  columns: MetaColumn[]
  hiddenColumnNames: string[]
  leftColumns: string[]
  rows: DynamicObject[]
} => {
  const displayColumns = columns.filter(isDisplayable)
  const [visible, notVisible] = partition(displayColumns, isVisible)

  return {
    columnExtensions: displayColumns.map(formatColumnExtensions),
    columnOrder: displayColumns.sort(sortColumns).map(getName),
    columnWidths: displayColumns.map(formatColumnWidths),
    columns: columns.reduce(columnReducer, []),
    hiddenColumnNames: notVisible.map(getName),
    leftColumns: visible.sort(sortColumns).slice(0, fixedColumns).map(getName),
    rows: rows.map(rowFormatterFactory(columns)),
  }
}
