import { createContext, ReactNode, useCallback, useContext, useState } from 'react'

import { SetErrorMessage } from 'types'
import { report as reportError } from 'lib/api/error'

export type ErrorContext = { errorMessage: string | null; setErrorMessage: SetErrorMessage }

export const ErrorMessageContext = createContext<ErrorContext | undefined>(undefined)

export const ErrorMessageContextProvider = ({
  children,
  reportErrors,
}: {
  children: ReactNode
  reportErrors: boolean
}) => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  const displayAndReportErrorMessage: SetErrorMessage = useCallback(
    (errorMessage, options) => {
      if (errorMessage && reportErrors) {
        void reportError({ errorMessage, ...options })
      }
      setErrorMessage(errorMessage)
    },
    [setErrorMessage, reportErrors],
  )

  return (
    <ErrorMessageContext.Provider
      value={{ errorMessage, setErrorMessage: displayAndReportErrorMessage }}
    >
      {children}
    </ErrorMessageContext.Provider>
  )
}

export const useErrorMessageContext = (): ErrorContext => {
  const errorContext = useContext(ErrorMessageContext)

  if (errorContext === undefined) {
    throw new Error('useErrorMessageContext must be used inside ErrorMessageContextProvider')
  }

  return errorContext
}
