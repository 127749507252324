import { useCallback } from 'react'

import { useLoggedInAppContext } from 'hooks/useLoggedInAppContext'
import { MenuConfigIdCache } from 'types'

export const useMenuConfigIdCache = (): [
  MenuConfigIdCache,
  (currentConfigId: number, newConfigId: number) => void,
] => {
  const { setUser, user } = useLoggedInAppContext()

  const setMenuConfigIdCache = useCallback(
    (currentConfigId: number, newConfigId: number) =>
      currentConfigId !== newConfigId &&
      setUser(
        user && {
          ...user,
          menuConfigIdCache: {
            ...user.menuConfigIdCache,
            [currentConfigId]: newConfigId,
          },
        },
      ),
    [setUser, user],
  )

  return [user.menuConfigIdCache, setMenuConfigIdCache]
}
