import { PropsWithChildren, useEffect } from 'react'
import { Row } from 'react-bootstrap'

import styles from './UnauthorizedPage.module.scss'
import { Page } from 'components/Page'
import { getIeVersion } from 'utils/getIeVersion'
import { BarAlert } from 'components/BarAlert'
import { useUnauthorizedErrorMessageContext } from 'contexts/UnauthorizedErrorMessageContextProvider'

export const IE_11_WARNING =
  'We see that you are using Internet Explorer 11. ' +
  'The pages may not display optimally. ' +
  'We recommend using Firefox, Chrome, or Edge.'

export type Props = PropsWithChildren<{
  hideFooter?: boolean
  hideHeader?: boolean
  inModal?: boolean
  maxWidth?: number
}>

export const UnauthorizedPage = ({
  children,
  hideFooter = true,
  hideHeader,
  maxWidth = 325,
}: Props): JSX.Element => {
  const { errorMessage, setErrorMessage } = useUnauthorizedErrorMessageContext()

  useEffect(() => {
    getIeVersion() === 11 && setErrorMessage(IE_11_WARNING)
  }, [setErrorMessage])

  return (
    <Page fitOnScreen={false} hideFooter={hideFooter} hideHeader={hideHeader}>
      {errorMessage && <BarAlert onClose={() => setErrorMessage(null)}>{errorMessage}</BarAlert>}

      <Row className={styles.FormRow}>
        <div className={styles.FormContainer} style={{ maxWidth }}>
          {children}
        </div>
      </Row>
    </Page>
  )
}
