import { useCallback, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router'

export const useLocationParam = <T>(key: string): [T | undefined, (value?: T) => void] => {
  const navigate = useNavigate()
  const location = useLocation()
  const [value, setValue] = useState<T>(location.state?.[key])

  const updateValue = useCallback(
    (value?: T) => {
      void navigate(location.pathname, {
        replace: true,
        state: {
          ...location.state,
          [key]: value,
        },
      })
    },
    [navigate, location, key],
  )

  useEffect(() => {
    setValue(location.state?.[key])
  }, [location.state, key])

  return [value, updateValue]
}
