import { api } from 'lib/api/base'
import { ENDPOINTS } from 'lib/api/endpoints'
import { BaseGridConfigResponse } from '..'

export type ColumnOrder = {
  columnId: number
  sequence: number
}

type Params = {
  columns: ColumnOrder[]
  configId: number
}

type UpdateColumnOrder = (params: Params) => Promise<number>

export const updateColumnOrder: UpdateColumnOrder = async ({ columns, configId }) => {
  const {
    data: { config_id },
  } = await api.post<BaseGridConfigResponse>(ENDPOINTS.gridConfigs, {
    action: 'update_column_order',
    columns: columns.map(({ columnId, sequence }) => ({ column_id: columnId, sequence })),
    config_id: configId,
  })

  return Number(config_id)
}
