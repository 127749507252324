import styles from './DetailValue.module.scss'

export type Props = {
  alignItems?: 'center' | 'flex-start' | 'flex-end'
  className?: string
  label: string
  labelWidth?: string // value + units, for example 120px or 5rem
  value?: string | JSX.Element
}

export const DetailValue = ({
  alignItems,
  label,
  labelWidth,
  value,
  className,
}: Props): JSX.Element => (
  <div className={`${styles.DetailValue} ${className || ''}`} style={{ alignItems }}>
    <div className={styles.Label} style={labelWidth ? { flexBasis: labelWidth } : undefined}>
      {label}
    </div>
    <div className={styles.Value}>{value}</div>
  </div>
)
