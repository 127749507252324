import { api } from 'lib/api/base'
import { ENDPOINTS } from 'lib/api/endpoints'
import { BaseGridConfigResponse, NormalizedGridConfigResponse } from '../types'

type Params = {
  configId: number
  name: string
}

type AddSavedView = (params: Params) => Promise<NormalizedGridConfigResponse>

export const addSavedView: AddSavedView = async ({ configId, name }) => {
  const {
    data: { config_id },
  } = await api.post<BaseGridConfigResponse>(ENDPOINTS.gridConfigs, {
    action: 'add',
    config_id: configId,
    name,
  })

  return { configId: Number(config_id) }
}
