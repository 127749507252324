import { PasswordField } from 'components/PasswordField/PasswordField'
import { TextField } from 'components/TextField'
import { FormikErrors } from 'formik'
import { PhoneNumberField } from 'components/PhoneNumberField'

export type CreateAccountFormValues = {
  companyName: string
  email: string
  firstName: string
  lastName: string
  password: string
  passwordConfirmation: string
  phone: string
}

interface CreateAccountFieldsProps {
  includePasswordSuggestions?: boolean
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean,
  ) => Promise<void | FormikErrors<CreateAccountFormValues>>
}

export const CreateAccountFields = ({
  includePasswordSuggestions,
  setFieldValue,
}: CreateAccountFieldsProps) => {
  return (
    <>
      <TextField
        autoComplete="new_first_name"
        className="mb-3"
        label="First Name"
        name="firstName"
      />
      <TextField autoComplete="new_last_name" className="mb-3" label="Last Name" name="lastName" />
      <TextField
        autoComplete="new_email"
        className="mb-3"
        label="Email"
        name="email"
        type="email"
      />
      <TextField
        autoComplete="new_company_name"
        className="mb-3"
        label="Company / Organization Name"
        name="companyName"
      />
      <PhoneNumberField className="mb-3" country="US" label="Mobile Number" name="phone" />
      <PasswordField
        autoComplete="new_password"
        className="mb-3"
        label="Password"
        name="password"
        withRating
        withSuggestion={includePasswordSuggestions}
        onSelectSuggestion={suggestion => void setFieldValue('password', suggestion)}
      />
      <PasswordField
        autoComplete="new_password"
        className="mb-3"
        label="Re-enter Password"
        name="passwordConfirmation"
      />
    </>
  )
}
