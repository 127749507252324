import { FC } from 'react'
import { DataTypeProvider } from '@devexpress/dx-react-grid'

import { ColumnName } from 'types'

export const MailToProvider: FC = () => {
  const MailToFormatter: FC<DataTypeProvider.ValueFormatterProps> = ({ row }) => {
    const email = String(row[ColumnName.email])
    return (
      <a
        target="_blank"
        rel="noopener noreferrer"
        title={`Send Email to ${email}`}
        href={`mailto:${email}`}
      >
        {email}
      </a>
    )
  }
  return <DataTypeProvider formatterComponent={MailToFormatter} for={[ColumnName.email]} />
}
