import { SearchGlassIcon } from 'components/SearchGlassIcon'

import styles from './MobileActions.module.scss'

export type Props = {
  toggleSearch: () => void
}

export const MobileActions = ({ toggleSearch }: Props): JSX.Element => (
  <div className={styles.MobileActions}>
    <button aria-label="Toggle search bar" className={styles.SearchButton} onClick={toggleSearch}>
      <SearchGlassIcon fill="gray" />
    </button>
  </div>
)
