import flatMap from 'lodash/flatMap'
import { useMemo } from 'react'
import { useLocation } from 'react-router'

import { MenuConfig } from 'types'
import { useErrorMessageContext } from 'contexts/ErrorMessageContextProvider'
import { useLoggedInAppContext } from '../useLoggedInAppContext'

type UseCurrentMenu = () => MenuConfig
export const useCurrentMenu: UseCurrentMenu = () => {
  const {
    user: { menu },
  } = useLoggedInAppContext()
  const { setErrorMessage } = useErrorMessageContext()

  const { pathname } = useLocation()

  const menuForPath = useMemo<MenuConfig | undefined>(() => {
    return flatMap(menu, item => item.menu || item).find(menuItem => menuItem.path === pathname)
  }, [pathname, menu])

  if (!menuForPath) {
    setErrorMessage('This Page has no associated menu.', {
      identifier1: pathname,
      identifierType1: 'Path',
    })
    throw new Error(`Missing Menu for Path: ${pathname}`)
  }

  return menuForPath
}
