import { api } from 'lib/api/base'
import { BaseApiResult } from 'types'
import { ENDPOINTS } from 'lib/api/endpoints'

type UpdateStepAssignPoResponse = {
  status_description: string
} & BaseApiResult

const updateStepAssignPoConstants = {
  action: 'update_step_assignpo',
}

type UpdateStepAssignPoParams = {
  comment: string
  jobId: string
  reference1: string
}

export const updateStepAssignPo = async (
  params: UpdateStepAssignPoParams,
): Promise<UpdateStepAssignPoResponse> => {
  const { comment, jobId, reference1 } = params

  const { data } = await api.post<UpdateStepAssignPoResponse>(ENDPOINTS.job, {
    ...updateStepAssignPoConstants,
    comment,
    job_id: jobId,
    reference1,
  })

  return data
}
