import { api } from 'lib/api/base'
import { BaseApiResult } from 'types'
import { ENDPOINTS } from 'lib/api/endpoints'

export type UpdateDeliverToEpParams = {
  actual_delivery_date: string
  actual_delivery_time: string
  comment: string
  job_id: string
  proof_of_delivery: File
}

export const updateDeliverToEp = async (
  params: UpdateDeliverToEpParams,
): Promise<BaseApiResult> => {
  const formData = new FormData()
  formData.append('action', 'update_step_deliver_to_ep')
  formData.append('actual_delivery_date', params.actual_delivery_date)
  formData.append('actual_delivery_time', params.actual_delivery_time)
  formData.append('comment', params.comment)
  formData.append('job_id', params.job_id)
  if (params.proof_of_delivery) {
    formData.append('proof_of_delivery', params.proof_of_delivery)
  }

  const { data } = await api.post<BaseApiResult>(ENDPOINTS.job, formData, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })

  return data
}
