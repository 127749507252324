import { cancelJob, updateStepApproval } from 'lib/api/job'
import { FormValues } from './ApproveOrderModal'

export const handleApproveJob = async (
  jobId: string,
  values: FormValues,
  closeModal: () => void,
  refreshData: () => void,
): Promise<void> => {
  await updateStepApproval({ jobId, ...values })
  closeModal()
  refreshData()
}

export const handleRejectJob = async (
  jobId: string,
  comments: string,
  closeModal: () => void,
  refreshData: () => void,
): Promise<void> => {
  await cancelJob({ comments, jobId })
  closeModal()
  refreshData()
}
