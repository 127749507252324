import React from 'react'
import { useFormikContext } from 'formik'

import { CheckboxField } from 'components/CheckboxField'
import { CompanyInfo } from 'types'
import { usePrevious } from 'hooks/usePrevious'

export const BillingSameAsCompanyCheckbox = (): JSX.Element => {
  const { setFieldValue, values } = useFormikContext<FormWithCompanyAndBillingValues>()

  // Keep company information and billing information in sync if the user
  // selects to use company info as billing info
  const { billingSameAsCompany } = values

  const previousBillingSameAsCompany = usePrevious(billingSameAsCompany)

  React.useEffect(() => {
    if (billingSameAsCompany === previousBillingSameAsCompany) return
    if (!billingSameAsCompany) return

    void setFieldValue('billingInfo.name', values.companyInfo.name)
    void setFieldValue('billingInfo.address1', values.companyInfo.address1)
    void setFieldValue('billingInfo.address2', values.companyInfo.address2)
    void setFieldValue('billingInfo.city', values.companyInfo.city)
    void setFieldValue('billingInfo.postalCode', values.companyInfo.postalCode)
    void setFieldValue('billingInfo.stateProvince', values.companyInfo.stateProvince)
    void setFieldValue('billingInfo.phone', values.companyInfo.phone)
    void setFieldValue('billingInfo.email', values.companyInfo.email)
  }, [billingSameAsCompany, previousBillingSameAsCompany, setFieldValue, values])

  return (
    <CheckboxField
      className="mb-4"
      label="Same as Company Information"
      name="billingSameAsCompany"
    />
  )
}

type FormWithCompanyAndBillingValues = {
  billingInfo: CompanyInfo
  billingSameAsCompany: boolean
  companyInfo: CompanyInfo
  firstName: string
  lastName: string
}
