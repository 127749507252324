import React from 'react'

import { GridView } from '../../components/GridView'
import { Page } from '../../components/Page'
import { useCurrentGridConfigId } from '../../hooks/useCurrentGridConfigId'
import { useIsDesktop } from '../../hooks/useIsDesktop'

import { MobileActions } from './components/MobileActions'
import { MobileView } from './components/MobileView'

export const DispatchesPageInner = () => {
  const baseConfigId = useCurrentGridConfigId()
  const isDesktop = useIsDesktop()

  const [showSearch, setShowSearch] = React.useState(false)

  return (
    <Page headerActions={<MobileActions toggleSearch={() => setShowSearch(!showSearch)} />}>
      {isDesktop ? (
        <GridView showDispatchesHeader baseConfigId={baseConfigId} hideName key={baseConfigId} />
      ) : (
        <MobileView showSearch={showSearch} />
      )}
    </Page>
  )
}
