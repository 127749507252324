import { api } from 'lib/api/base'
import { BaseApiResult } from 'types'
import { ENDPOINTS } from 'lib/api/endpoints'

export type UpdateRecordPickupParams = {
  actual_pickup_date: string
  actual_pickup_time: string
  comment: string
  job_id: string
}

export const updateRecordPickup = async (
  params: UpdateRecordPickupParams,
): Promise<BaseApiResult> => {
  const { data } = await api.post<BaseApiResult>(ENDPOINTS.job, {
    action: 'update_step_record_pickup',
    actual_pickup_date: params.actual_pickup_date,
    actual_pickup_time: params.actual_pickup_time,
    comment: params.comment,
    job_id: params.job_id,
  })

  return data
}
