import { DetailValue } from 'pages/CompanyProfile/components/DetailValue.tsx'
import { useAppContext } from 'contexts/ContextProvider'
import { useMemo } from 'react'
import { GetJobResponse } from 'lib/api/job/get'

interface IReferenceNumbersValuesProps {
  job: GetJobResponse | null
  labelWidth: string
}

const ReferenceNumbersValues = ({ job, labelWidth }: IReferenceNumbersValuesProps) => {
  const { user } = useAppContext()
  const refNumbers = useMemo(() => {
    return {
      ref1: {
        label: user?.referenceNumbers[0].label,
        value: job?.reference1,
      },
      ref2: {
        label: user?.referenceNumbers[1].label,
        value: job?.reference2,
      },
      ref3: {
        label: user?.referenceNumbers[2].label,
        value: job?.reference3,
      },
    }
  }, [job, user])

  return (
    <>
      {!!refNumbers.ref1.value && (
        <DetailValue
          label={refNumbers.ref1.label || 'Reference #1'}
          labelWidth={labelWidth}
          value={refNumbers.ref1.value}
        />
      )}
      {!!refNumbers.ref2.value && (
        <DetailValue
          label={refNumbers.ref2.label || 'Reference #2'}
          labelWidth={labelWidth}
          value={refNumbers.ref2.value}
        />
      )}
      {!!refNumbers.ref3.value && (
        <DetailValue
          label={refNumbers.ref3.label || 'Reference #3'}
          labelWidth={labelWidth}
          value={refNumbers.ref3.value}
        />
      )}
    </>
  )
}

export default ReferenceNumbersValues
