import { useMemo } from 'react'
import classNames from 'classnames'

import { SelectOption } from 'types'
import styles from './NativeSelect.module.scss'
import DownCaretIcon from 'images/CaretDownFilled.svg'
import { randomInt } from 'utils/commonUtils'

type TNativeSelectValueType = string | number

type TNativeSelectProps = {
  ariaLabel?: string
  className?: string
  disabled?: boolean
  error?: string
  id?: string
  isVersion2?: boolean
  label?: string
  name: string
  onBlur?: (e: React.FocusEvent<HTMLSelectElement>) => void
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void
  options: SelectOption[]
  value: TNativeSelectValueType
  whiteBG?: boolean
}

export const NativeSelect = ({
  ariaLabel,
  className,
  disabled,
  error,
  id,
  isVersion2,
  label,
  name,
  onBlur,
  onChange,
  options,
  value,
  whiteBG = false,
}: TNativeSelectProps): JSX.Element => {
  const elementId = id ?? 'select_' + String(randomInt(100000)).padStart(6, '0')
  const invalid = !!error

  // Prepend options with an empty option if value is empty and array of options
  // does not have an empty value yet. Using memoization because all form
  // elements under formik context are re-rendering a lot.
  const { emptyOption, optionsWithEmpty } = useMemo(() => {
    let optionsNew = options.map(v => ({ label: v.label, value: v.value }))
    let emptyOption = options.find(v => !v.value || value === '0')
    if (!emptyOption) {
      optionsNew = [{ label: '', value: '' as string | number }].concat(options)
      emptyOption = optionsNew.at(0)
    }

    return { emptyOption, optionsWithEmpty: optionsNew }
  }, [options, value])

  const isEmptyValue = !value || value === '0'
  const isEmptyLabel = emptyOption ? emptyOption.label.length === 0 : false

  return (
    <div className={className}>
      <div className={classNames(styles.NativeSelect, { [styles.WhiteBackground]: whiteBG })}>
        <select
          id={elementId}
          name={name}
          aria-label={ariaLabel}
          disabled={disabled}
          className={classNames({
            [styles.Empty]: isEmptyValue && isEmptyLabel,
            [styles.Invalid]: invalid,
            [styles.version2]: isVersion2,
          })}
          onBlur={onBlur}
          onChange={onChange}
          value={value}
        >
          {optionsWithEmpty.map(opt => {
            return (
              <option value={opt.value} key={opt.value}>
                {opt.label}
              </option>
            )
          })}
        </select>
        <div className={styles.Notch}>
          <div className={styles.NotchBefore}></div>
          <div className={styles.NotchBetween}>
            {label && <label htmlFor={elementId}>{label}</label>}
          </div>
          <div className={styles.NotchAfter}>
            <img alt="Down facing caret" src={DownCaretIcon} />
          </div>
        </div>
      </div>
      {error && <div className={styles.ErrorText}>{error}</div>}
    </div>
  )
}
