import * as React from 'react'

import { Status, useAsync } from 'hooks/useAsync'
import { getGridConfig, GridConfigData } from 'lib/api/gridConfig'

type ReturnType = {
  gridConfig: GridConfigData | null
  gridConfigStatus: Status
  refreshGridConfig: () => void
}

export const useGridConfig = (
  configId: number,
  externalLastUpdated?: number,
  externalGridConfig?: GridConfigData,
): ReturnType => {
  const { data: gridConfig, run, setData, status: gridConfigStatus } = useAsync<GridConfigData>()

  React.useEffect(() => {
    if (externalGridConfig) {
      setData(externalGridConfig)
    } else {
      run(getGridConfig({ configId }))
    }
  }, [configId, externalGridConfig, externalLastUpdated, run, setData])

  const refreshGridConfig = React.useCallback(() => {
    run(getGridConfig({ configId }))
  }, [configId, run])

  return { gridConfig, gridConfigStatus, refreshGridConfig }
}
