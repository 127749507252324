import { FC } from 'react'

export const RemoveRowIcon: FC = () => (
  <svg height={24} width={24}>
    <title>Remove Row Icon</title>
    <desc>A horizontal white line inside a red circle.</desc>
    <circle cx={12} cy={12} r={12} fill="#d0021b" />
    <line
      shapeRendering="crispEdges"
      stroke="#eaeaea"
      strokeWidth={1}
      x1={6}
      x2={18}
      y1={12}
      y2={12}
    />
  </svg>
)
