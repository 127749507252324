import { useEffect, useState, createContext, useCallback, useContext, useMemo, useRef } from 'react'
import { useNavigate, useLocation, useParams, useSearchParams } from 'react-router'

import { BrandParameter } from 'types'
import { getSettingsForPath } from 'lib/api/setting'
import { hexToCssHsl } from 'utils/cssHexToHSL'
import { MixpanelEvents, mixpanelTrack } from 'lib/api/mixpanel/track'
import { getURL } from 'utils/getURL'

import SignInIllustration from 'images/SignIn-Illustration.svg'
import Logo from 'images/ReturnCenterLogo.svg'
import BrandMark from 'images/ReturnCenterBrandMark.svg'
import { GuestPageExtraData, InventoryTypeResponse } from 'lib/api/types'
import { Loader } from 'components/Loader'
import { getGuestToken, removeGuestToken, setGuestToken } from 'utils/guestToken'
import { getLocalToken } from 'utils/localToken'
import { useBrandPathContext } from 'contexts/BrandPathContext'

type ContextValue = {
  appSettingsPath: string
  cleanup: () => void
  loginImage: string
  logo: string
  mobileLogo: string
  pageData?: GuestPageExtraData
  paradigmId: number | null
  promoCode: string | null
  promoCodeId: number | null
  setParadigmId: (val: number | null) => void
  setPromoCode: (val: string | null) => void
  setPromoCodeId: (val: number | null) => void
  theme?: BrandParameter
}

const AppSettingsContext = createContext<ContextValue | undefined>(undefined)

type StaticDataProviderProps = {
  brandingParameters?: BrandParameter
  children: React.ReactNode
}

type GuestIntakeWhoOption = 'business' | 'personal'
type GuestIntakeWhatOption = InventoryTypeResponse
type GuestIntakeWhereOption = 'USA' | 'Other'

export type GuestIntakeOptions = {
  path: string
  token: string | null
  what: GuestIntakeWhatOption | null
  where: GuestIntakeWhereOption | null
  who: GuestIntakeWhoOption | null
}

const AppSettingsProvider = ({ brandingParameters, children }: StaticDataProviderProps) => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const [searchParams] = useSearchParams()
  const { brand_name, flow_name } = useParams<{ brand_name?: string; flow_name?: string }>()
  const { setBrandPath } = useBrandPathContext()

  const prefixPath = brand_name ? `/${brand_name}${flow_name ? `/${flow_name}` : ''}` : ''
  const processedAppSettingsPath = useRef('')

  const [pageData, setPageData] = useState<GuestPageExtraData>()

  const [theme, setTheme] = useState<BrandParameter>()
  const [logo, setLogo] = useState(Logo)
  const [mobileLogo, setMobileLogo] = useState(BrandMark)
  const [loginImage, setLoginImage] = useState(SignInIllustration)
  const [promoCode, setPromoCode] = useState<string | null>(null)
  const [promoCodeId, setPromoCodeId] = useState<number | null>(null)
  const [paradigmId, setParadigmId] = useState<number | null>(null)
  const [loadingStyles, setLoadingStyles] = useState<boolean>(!!prefixPath)
  const [showLoader, setShowLoader] = useState<boolean>(false)

  const cleanup = useCallback(() => {
    sessionStorage.removeItem('isSingleKit')
    sessionStorage.removeItem('wasRedirectedToCreateAccount')

    setPromoCode(null)
    setPromoCodeId(null)
    setParadigmId(null)
  }, [])

  const setDefaultTheme = () => {
    setLogo(Logo)
    setMobileLogo(BrandMark)
    setLoginImage(SignInIllustration)

    /** Setting font and font color are currently unused */
    /** Login image is set on the login component */
    document.documentElement.style.setProperty('--color-navbar', '#fff')
    document.documentElement.style.setProperty('--color-primary-h', '191')
    document.documentElement.style.setProperty('--color-primary-s', '97%')
    document.documentElement.style.setProperty('--color-primary-l', '41%')
  }

  const updateThemeState = (theme: BrandParameter) => {
    setTheme(theme)
    if (theme.logo) {
      const logoURL = getURL(theme.logo)
      setLogo(logoURL)
      setMobileLogo(logoURL)
    }
    if (theme.loginImage) {
      setLoginImage(getURL(theme.loginImage))
    }
    if (theme.navigationBarColor) {
      document.documentElement.style.setProperty('--color-navbar', theme.navigationBarColor)
    }
    if (theme.primaryColor) {
      const hslValues = hexToCssHsl(theme.primaryColor)
      if (hslValues) {
        document.documentElement.style.setProperty('--color-primary-h', hslValues.h)
        document.documentElement.style.setProperty('--color-primary-s', hslValues.s)
        document.documentElement.style.setProperty('--color-primary-l', hslValues.l)
      }
    }
  }

  useEffect(() => {
    const localToken = getLocalToken()

    const getAppSettings = async (settingsParameters: GuestIntakeOptions) => {
      setLoadingStyles(true)

      try {
        const settings = await getSettingsForPath(settingsParameters)

        if (settings.type === 'theme') {
          updateThemeState(settings.branding_parameters)
          if (settings.navigation_parameters) {
            if (settings.navigation_parameters.page_data) {
              setPageData(settings.navigation_parameters.page_data)
            }
            if (settings.token && settings.token !== localToken) {
              setGuestToken(settings.token)
            } else {
              removeGuestToken()
            }

            if (settings.navigation_parameters.page_path) {
              void navigate(settings.navigation_parameters.page_path, { replace: true })
            }
          }

          setBrandPath(settingsParameters.path)
        } else if (settings.type === 'promo') {
          setPromoCode(settings.promoCode)
          setPromoCodeId(settings.promoCodeId)
          setParadigmId(settings.paradigmId)
          if (settings.singleKit) sessionStorage.setItem('isSingleKit', 'true')

          const newLocation = pathname.replace(settingsParameters.path, '')
          void navigate(newLocation, { replace: true })
        }
      } catch (e) {
        const newLocation = pathname.replace(settingsParameters.path, '')
        void navigate(newLocation, { replace: true })
      } finally {
        setLoadingStyles(false)
        setShowLoader(false)
      }
    }

    if (prefixPath) {
      if (processedAppSettingsPath.current === prefixPath) return

      const who = searchParams.get('rc-customer') as GuestIntakeWhoOption
      const what = searchParams.get('rc-product') as GuestIntakeWhatOption
      const where = searchParams.get('rc-country') as GuestIntakeWhereOption

      const settingsParams: GuestIntakeOptions = {
        path: prefixPath,
        token: localToken || getGuestToken(),
        what,
        where,
        who,
      }

      processedAppSettingsPath.current = prefixPath
      void getAppSettings(settingsParams)
    } else if (brandingParameters) {
      updateThemeState(brandingParameters)
    } else {
      setDefaultTheme()
    }
  }, [prefixPath, brandingParameters, navigate, pathname, searchParams, setBrandPath])

  useEffect(() => {
    if (promoCode) {
      const trackVisit = async () => {
        await mixpanelTrack(MixpanelEvents.PROMO_LINK_VISITED, {
          promoCode,
        })
      }

      void trackVisit()
    }
  }, [promoCode])

  // Delay showing loader indicator
  useEffect(() => {
    if (loadingStyles) {
      const timerId = setTimeout(() => {
        setShowLoader(true)
        clearTimeout(timerId)
      }, 500)
    }
  }, [loadingStyles])

  const value = useMemo<ContextValue>(
    () => ({
      appSettingsPath: prefixPath,
      cleanup,
      loginImage,
      logo,
      mobileLogo,
      pageData,
      paradigmId,
      promoCode,
      promoCodeId,
      setParadigmId,
      setPromoCode,
      setPromoCodeId,
      theme,
    }),
    [
      prefixPath,
      cleanup,
      loginImage,
      logo,
      mobileLogo,
      pageData,
      paradigmId,
      promoCode,
      promoCodeId,
      theme,
    ],
  )

  if (loadingStyles) {
    if (showLoader) {
      return (
        <div className="d-flex flex-column align-items-center mt-5">
          <Loader />
          <h2 className="mt-3">Loading application settings...</h2>
        </div>
      )
    } else {
      return <></>
    }
  } else {
    return <AppSettingsContext.Provider value={value}>{children}</AppSettingsContext.Provider>
  }
}

const useAppSettings = (): ContextValue => {
  const context = useContext(AppSettingsContext)

  if (context === undefined) {
    throw new Error('useAppSettings must be used within an AppSettingsProvider')
  }

  return context
}

export { AppSettingsProvider, useAppSettings }
