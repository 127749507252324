import { GridConfig } from 'types'
import { GridConfigSortable } from '../types'

export const mapConfigToSortable = (config: GridConfig): GridConfigSortable => ({
  id: config.columnId,
  name: (config.heading || config.name)
    .replace('/', '/\u200b')
    .replace('.', '.\u200b')
    .replace('_', '_\u200b'),
  original: config,
})
