import { FormValues as TAccountFormValues } from 'forms/CreateAccountForm'
import { CompanyInfo } from 'types'

export const getInitialAccountFormValues = (): TAccountFormValues => {
  return {
    billingInfo: getDefaultCompanyFormValues(),
    billingSameAsCompany: false,
    companyInfo: getDefaultCompanyFormValues(),
    companyName: '',
    email: '',
    firstName: '',
    lastName: '',
    password: '',
    passwordConfirmation: '',
    phone: '',
  }
}

export const getDefaultCompanyFormValues = (company?: Partial<CompanyInfo>): CompanyInfo => {
  return {
    address1: '',
    address2: '',
    city: '',
    email: '',
    firstName: '',
    lastName: '',
    name: '',
    phone: '',
    postalCode: '',
    stateProvince: '',
    ...company,
  }
}
