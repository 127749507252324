import { api } from 'lib/api/base'
import { BaseApiResult } from 'types'
import { ENDPOINTS } from 'lib/api/endpoints'

type UpdateStepApprovalResponse = {
  status_description: string
} & BaseApiResult

const updateStepApprovalConstants = {
  action: 'update_step_approval',
}

type UpdateStepApprovalParams = {
  assets: {
    id: string
    service_tag: string
  }[]
  billto_info: {
    address1: string
    address2: string
    city: string
    first_name: string
    last_name: string
    phone: string
    postal_code: string
    state_province: string
  }
  comment: string
  jobId: string
  reference1: string
  reference2: string
  reference3: string
}

export const updateStepApproval = async (
  params: UpdateStepApprovalParams,
): Promise<UpdateStepApprovalResponse> => {
  const { jobId, ...rest } = params

  const { data } = await api.post<UpdateStepApprovalResponse>(ENDPOINTS.job, {
    ...updateStepApprovalConstants,
    ...rest,
    job_id: jobId,
  })

  return data
}
