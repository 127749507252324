import { api } from 'lib/api/base'
import { BaseApiResult } from 'types'
import { ENDPOINTS } from 'lib/api/endpoints'

type AddJobParams = {
  flow_id: number
  price_id: number
  product_price_id?: number
  quote_id: number
}

type AddJobResponse = {
  job_id: string
} & BaseApiResult

export const addJob = async (params: AddJobParams): Promise<AddJobResponse> => {
  const { data } = await api.post<AddJobResponse>(ENDPOINTS.job, {
    action: 'add',
    ...params,
  })

  return data
}
