import classNames from 'classnames'
import { FormikErrors } from 'formik'

import { BootstrapFormikFeedback } from 'components/BootstrapFormikFeedback'
import { FormValues } from 'forms/PalletsForm'
import { FormikFieldAutoTrim } from 'components/FormikFieldAutoTrim'
import { SelectOption } from 'types'
import { QuantityInput } from 'components/QuantityInput'
import { SelectField } from 'components/SelectField'

import { RemoveRowButton } from './components/RemoveRowButton'
import styles from './PalletRow.module.scss'

export type Props = {
  errors?: FormikErrors<FormValues>
  index: number
  isWeightRequired?: boolean
  onRemove?: (index: number) => void
  palletTypeOptions: SelectOption[]
}

export const PalletRow = ({
  errors,
  index,
  onRemove,
  palletTypeOptions,
  isWeightRequired,
}: Props): JSX.Element => (
  <div className={styles.Container}>
    <div className={styles.PalletRow}>
      <div className={classNames(styles.FormGroup, styles.AssetType)}>
        <label htmlFor={`pallet-asset-${index}`}>Asset Type</label>
        <SelectField
          className={styles.AssetTypeSelect}
          id={`pallet-asset-${index}`}
          name={`pallets[${index}].asset_id`}
          options={palletTypeOptions}
        />
      </div>

      <div className={styles.PalletData}>
        <div className={styles.FormGroup}>
          <label htmlFor={`pallet-quantity-${index}`}># of Pallets</label>
          <QuantityInput id={`pallet-quantity-${index}`} name={`pallets[${index}].quantity`} />
        </div>

        <div className={styles.FormGroup}>
          <label htmlFor={`pallet-dimensions-${index}`}>Pallet Dimensions (Inches)</label>
          <div className={styles.PalletDimensions}>
            <FormikFieldAutoTrim
              aria-label="length"
              id={`pallet-dimensions-${index}`}
              min={1}
              name={`pallets[${index}].length`}
              placeholder="L"
              type="number"
            />
            <span>x</span>
            <FormikFieldAutoTrim
              aria-label="width"
              min={1}
              name={`pallets[${index}].width`}
              placeholder="W"
              type="number"
            />
            <span>x</span>
            <FormikFieldAutoTrim
              aria-label="height"
              min={1}
              name={`pallets[${index}].height`}
              placeholder="H"
              type="number"
            />
          </div>
        </div>

        <div className={classNames(styles.FormGroup, styles.PalletWeight)}>
          <label htmlFor={`pallet-weight-${index}`}>
            Pallet Weight {!isWeightRequired && '(Optional)'}
          </label>
          <div className={styles.PalletWeightInput}>
            <FormikFieldAutoTrim
              aria-label="weight"
              id={`pallet-weight-${index}`}
              inputMode="numeric"
              max={9999}
              min={0}
              name={`pallets[${index}].weight`}
              pattern={/^[1-9][0-9]{0,3}$/}
              placeholder="0"
            />
            <span>LBS</span>
          </div>
        </div>

        {onRemove && <RemoveRowButton index={index} onClick={() => onRemove(index)} />}
      </div>
    </div>

    {errors && (
      <div className={styles.Errors}>
        <BootstrapFormikFeedback name={`pallets[${index}].asset_id`} />
        {errors.pallets &&
          Array.isArray(errors.pallets) &&
          typeof errors.pallets[index]?.length === 'string' && (
            <BootstrapFormikFeedback name={`pallets[${index}].length`} />
          )}
        <BootstrapFormikFeedback name={`pallets[${index}].width`} />
        <BootstrapFormikFeedback name={`pallets[${index}].height`} />
        <BootstrapFormikFeedback name={`pallets[${index}].weight`} />
      </div>
    )}
  </div>
)
