import { FC, useState } from 'react'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import { Formik } from 'formik'
import * as Yup from 'yup'

import { addSavedView as addSavedViewApi } from 'lib/api/gridConfig'
import { BootstrapFormikFeedback } from 'components/BootstrapFormikFeedback'
import { FormikFieldAutoTrim } from 'components/FormikFieldAutoTrim'
import { Grid } from 'types'
import { Modal } from 'components/Modal'

import { GridDispatch, ActionTypes } from '../state'
import styles from './AddSavedViewModal.module.scss'

export type Props = {
  configId: number
  dispatch: GridDispatch
  hide: () => void
  savedViews: Grid[]
  show: boolean
}
const formId = 'AddSavedViewModal'
export const AddSavedViewModal: FC<Props> = ({ configId, savedViews, dispatch, ...modalProps }) => {
  const savedViewNames = savedViews.map(({ name }) => name)
  const [saving, setSaving] = useState(false)
  const AddSavedViewSchema = Yup.object().shape({
    name: Yup.string()
      .required('Name is required')
      .max(30, 'Name must be less than 30 characters')
      .notOneOf(savedViewNames, 'Name already in use. Please choose another name.'),
  })

  const addSavedView = async (name: string) => {
    const { configId: newConfigId } = await addSavedViewApi({ configId, name })
    dispatch({ payload: newConfigId, type: ActionTypes.ClearSavedViews })
  }

  return (
    <Modal
      dialogClassName={styles.Dialog}
      submitButtonText="Save"
      title="Add Saved View"
      formId={formId}
      saving={saving}
      {...modalProps}
    >
      <Col>
        <Formik
          initialValues={{
            name: '',
          }}
          validationSchema={AddSavedViewSchema}
          onSubmit={({ name }) => {
            setSaving(true)
            void addSavedView(name)
              .then(() => {
                modalProps.hide()
              })
              .finally(() => setSaving(false))
          }}
        >
          {({ handleSubmit }) => (
            <Form className={styles.Form} id={formId} onSubmit={handleSubmit}>
              <Form.Group controlId="name">
                <Form.Label>Name:</Form.Label>
                <FormikFieldAutoTrim className={styles.Input} name="name" />
                <BootstrapFormikFeedback name="name" />
              </Form.Group>
            </Form>
          )}
        </Formik>
      </Col>
    </Modal>
  )
}
