import React from 'react'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'
import Row from 'react-bootstrap/Row'
import { Formik, FormikErrors } from 'formik'

import { Button } from 'components/Button'
import { Loader } from 'components/Loader'
import { OrderReferenceNumber, OrderResponse } from 'types'
import { getReferenceNumber } from 'utils/getReferenceNumber'
import { TextareaField } from '../TextareaField'
import { TextField } from '../TextField'
import './ApprovePoModal.scss'

export interface FormValues {
  comment: string
  pickup_info: {
    address1: string
    address2: string
    city: string
    company: string
    first_name: string
    last_name: string
    phone: string
    postal_code: string
    state_province: string
  }
  reference1: string
  reference2: string
  reference3: string
}

export interface IApprovePoModalProps {
  closeModal: () => void
  initialValues: FormValues
  jobId: string
  loading: boolean
  onApprove: (values: FormValues) => Promise<void>
  order: OrderResponse | null
  referenceNumbers: OrderReferenceNumber[]
  show: boolean
}

export const ApprovePoModal = ({
  closeModal,
  initialValues,
  jobId,
  loading,
  onApprove,
  order,
  referenceNumbers,
  show,
}: IApprovePoModalProps) => {
  const actionRef = React.useRef<'approve' | 'reject' | undefined>()

  React.useEffect(() => {
    actionRef.current = undefined
  }, [show])

  return (
    <Modal className="ApprovePoModal__modal" onHide={closeModal} size="lg" show={show}>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={async (values, { setSubmitting }) => {
          if (actionRef.current === 'approve') {
            await onApprove(values)
          }

          setSubmitting(false)
        }}
        validate={values => {
          const errors: FormikErrors<FormValues> = {}

          if (actionRef.current === 'approve') {
            // Validate reference numbers
            referenceNumbers.forEach((referenceNumber, index) => {
              const name = `reference${index + 1}` as keyof typeof values

              if (referenceNumber.required && !values[name]) {
                errors[name] = `${referenceNumber.label} is required.`
              }
            })
          } else if (actionRef.current === 'reject') {
            if (!values.comment) {
              errors.comment = 'A comment is required when rejecting an order.'
            }
          }

          return errors
        }}
      >
        {({ handleSubmit, isSubmitting, submitForm }) => (
          <Form onSubmit={handleSubmit}>
            <Modal.Header closeButton>
              <div className="d-flex flex-wrap align-items-center justify-content-between w-100">
                <Modal.Title>Assign PO</Modal.Title>
                <div className="ApprovePoModal__jobId">
                  <strong>Job ID:</strong> {jobId}
                </div>
              </div>
            </Modal.Header>

            <Modal.Body>
              {loading ? (
                <Loader />
              ) : (
                <>
                  {/* Reference numbers section */}
                  <Row>
                    <Col className="ApprovePoModal__column--borderRight" lg={6} xs={12}>
                      {referenceNumbers.map((referenceNumber, index) => {
                        const fieldName = `reference${index + 1}`

                        return index ? (
                          referenceNumber.label && order && (
                            <Row key={fieldName}>
                              <Col>
                                <div className="mb-0 font-weight-bold">{referenceNumber.label}</div>
                                <div className="ms-2 mb-3">
                                  {getReferenceNumber(order, index) || (
                                    <span className="ApprovePoModal__empty">blank</span>
                                  )}
                                </div>
                              </Col>
                            </Row>
                          )
                        ) : (
                          <Row key={fieldName}>
                            <Col>
                              <TextField
                                className="mb-3"
                                label={referenceNumber.label}
                                name={fieldName}
                              />
                            </Col>
                          </Row>
                        )
                      })}
                    </Col>

                    {/* Pickup information */}
                    <Col xs={12} lg={6}>
                      {/* Contact name section */}
                      <Row>
                        <Col className="mb-0" xs={12}>
                          <Form.Label>Pickup Location</Form.Label>
                        </Col>
                        <Col className="ApprovePoModal__pickup mb-0" xs={12}>
                          <Form.Label className="m-0 small">Contact Name</Form.Label>
                          <div className="ms-4 mb-1">
                            {initialValues.pickup_info.first_name}{' '}
                            {initialValues.pickup_info.last_name}
                          </div>
                        </Col>
                      </Row>

                      {/* Company section */}
                      <Row>
                        <Col xs={12}>
                          <Form.Label className="m-0 small">Company</Form.Label>
                          <div className="ms-4 mb-1">{initialValues.pickup_info.company}</div>
                        </Col>
                      </Row>

                      {/* Address section */}
                      <Row>
                        <Col>
                          <Form.Label className="m-0 small">Address</Form.Label>
                          <div className="ms-4 mb-1">{initialValues.pickup_info.address1}</div>
                          {initialValues.pickup_info.address2 && (
                            <div className="ms-4 mb-1">{initialValues.pickup_info.address2}</div>
                          )}
                          <div className="ms-4 mb-1">
                            {initialValues.pickup_info.city},{' '}
                            {initialValues.pickup_info.state_province}{' '}
                            {initialValues.pickup_info.postal_code}
                          </div>
                        </Col>
                      </Row>

                      {/* Phone number section */}
                      <Row className="g-0">
                        <Col lg={6} xs={12}>
                          <Form.Label className="m-0 small">Phone</Form.Label>
                          <div className="ms-4 mb-1">{initialValues.pickup_info.phone}</div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                  {/* Comments section */}
                  <Row className="ApprovePoModal__row--borderTop">
                    <Col>
                      <TextareaField label="Comment" name="comment" />
                    </Col>
                  </Row>
                </>
              )}
            </Modal.Body>

            <Modal.Footer className="ApprovePoModal__footer">
              <Row className="justify-content-center w-100">
                <Col className="mb-3" lg={4} xs={8}>
                  <Button
                    onClick={closeModal}
                    type="button"
                    variant="outline-dark"
                    className="w-100"
                  >
                    Cancel
                  </Button>
                </Col>
                <Col lg={4} xs={12}>
                  <Button
                    disabled={isSubmitting}
                    onClick={() => {
                      actionRef.current = 'approve'
                      void submitForm()
                    }}
                    type="button"
                    className="w-100"
                  >
                    Approve
                  </Button>
                </Col>
              </Row>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  )
}
