import { Address } from 'types'

const streetNumberPath = (component: google.maps.GeocoderAddressComponent) =>
  component.types.includes('street_number')
const streetPath = (component: google.maps.GeocoderAddressComponent) =>
  component.types.includes('route')
const cityPath = (component: google.maps.GeocoderAddressComponent) =>
  component.types.includes('locality')
const statePath = (component: google.maps.GeocoderAddressComponent) =>
  component.types.includes('administrative_area_level_1')
const postalCodePath = (component: google.maps.GeocoderAddressComponent) =>
  component.types.includes('postal_code')

export const parseFromAddressComponents = (
  addressComponents: google.maps.GeocoderAddressComponent[],
): Address => {
  const streetNumberComponent = addressComponents.find(streetNumberPath)
  const streetComponent = addressComponents.find(streetPath)
  const cityComponent = addressComponents.find(cityPath)
  const stateComponent = addressComponents.find(statePath)
  const postalCodeComponent = addressComponents.find(postalCodePath)

  let address1 = ''
  if (streetNumberComponent && streetComponent) {
    address1 = `${streetNumberComponent.long_name} ${streetComponent.short_name}`
  }

  let city = ''
  if (cityComponent) {
    city = cityComponent.long_name
  }

  let state_province = ''
  if (stateComponent) {
    state_province = stateComponent.short_name
  }

  let postal_code = ''
  if (postalCodeComponent) {
    postal_code = postalCodeComponent.long_name
  }

  return {
    address1,
    address2: '',
    city,
    postal_code,
    state_province,
  }
}
