import { FC } from 'react'

import { DialogBox } from 'components/DialogBox'
import styles from './QueueNoticeDialog.module.scss'

type Props = {
  hide: () => void
  show: boolean
}
export const QueueNoticeDialog: FC<Props> = props => (
  <DialogBox
    buttonText="OK"
    title="Export into a Report"
    dialogClassName={styles.QueueNoticeDialog}
    {...props}
  >
    Your report has been added to the report queue.
  </DialogBox>
)
