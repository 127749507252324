import { parseISO } from 'date-fns'

export const pickFirstDateFromList = (dateCandidate: string): Date | null => {
  const dates = dateCandidate.split('||').map(dateStr => dateStr.trim())

  for (const dateStr of dates) {
    const parsedDate = parseISO(dateStr)

    if (!isNaN(parsedDate.getTime())) {
      return parsedDate
    }
  }

  return null
}
