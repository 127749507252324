import { format, parse } from 'date-fns'

export type TimeOption = {
  label: string
  value: string
}

export const useTimeOptions = (): TimeOption[] => {
  return Array.from({ length: 24 }, (_, i) => {
    const hour = i.toString().padStart(2, '0')
    const value = `${hour}:00`
    const label = format(parse(value, 'HH:mm', new Date()), 'hh:mm a')
    return { label, value }
  })
}
