import * as React from 'react'
import classNames from 'classnames'
import { Field, FieldProps } from 'formik'

import styles from './TextareaField.module.scss'

type Props = {
  className?: string
  label: string
  name: string
  rows?: number
}

export const TextareaField = React.forwardRef<HTMLTextAreaElement, Props>(
  ({ label, name, rows, className }, ref) => (
    <Field name={name}>
      {({ field, meta }: FieldProps) => {
        const invalid = meta.touched && meta.error

        return (
          <div className={className}>
            <div className={styles.Container}>
              <textarea
                {...field}
                className={classNames(styles.Textarea, { [styles.Invalid]: invalid })}
                id={name}
                placeholder={label}
                ref={ref}
                rows={rows}
              />
              <div className={styles.Notch}>
                <div className={styles.NotchBefore}></div>
                <div className={styles.NotchBetween}>
                  <label htmlFor={name}>{label}</label>
                </div>
                <div className={styles.NotchAfter}></div>
              </div>
            </div>

            {invalid && <div className={styles.ErrorText}>{meta.error}</div>}
          </div>
        )
      }}
    </Field>
  ),
)

TextareaField.displayName = 'TextareaField'
