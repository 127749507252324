import React, { FC, useCallback, useMemo, useEffect } from 'react'
import { Collapse, Row } from 'react-bootstrap'
import { Sorting } from '@devexpress/dx-react-grid'

import { ActionsProvider } from '../formatters/ActionsProvider'
import { AddSavedView } from '../AddSavedView'
import { ApproveOrderModalContainer as ApproveOrderModal } from 'components/ApproveOrderModal'
import { ApprovePoModalContainer as ApprovePoModal } from 'components/ApprovePoModal'
import { assignColumnSequences } from '../utils/assignColumnSequences'
import {
  AdvancedSearchFilter,
  BoolSortDirection,
  ColumnName,
  CommentType,
  DynamicObject,
  Grid,
} from 'types'
import { canAddViews } from '../utils/canAddViews'
import { CancelButtonProvider } from '../formatters/CancelButtonProvider'
import { canModifyColumns } from '../utils/canModifyColumns'
import { CustomizeView } from '../CustomizeView'
import { DevExpressGrid } from '../DevExpressGrid'
import { DispatchesAdvancedSearchWrapper as DispatchesAdvancedSearch } from 'components/DispatchesAdvancedSearch'
import { EditButtonProvider } from '../formatters/EditButtonProvider'
import { EditCancelModal } from 'components/EditCancelModal'
import { Export } from '../Export'
import { formatGridViewData } from '../formatGridViewData'
import { getDataOnlyValue } from 'utils/getDataOnlyValue'
import {
  getGridConfig,
  updateColumnOrder,
  updateColumnWidth,
  updateSortOrder,
  updateRecordsPerPage,
  updatePageNumber,
} from 'lib/api/gridConfig'
import { getGridData } from 'lib/api/grid'
import { GridState, GridDispatch, ActionTypes } from '../state'
import { GridViewProps } from '..'
import { JobCount } from '../JobCount'
import { Loader } from 'components/Loader'
import { MailToProvider } from '../formatters/MailToProvider'
import { MoreCommentsModal } from 'components/MoreCommentsModal'
import { MoreCommentModalState } from '../types'
import { moreCommentsFormatter } from '../formatters'
import { NormalizedGridDataResponse } from 'lib/api/types'
import { PDFPortalProvider } from '../formatters/PDFPortalProvider'
import { ResetView } from '../ResetView'
import { SavedViewList } from '../SavedViewList'
import { SearchAndFilter } from 'pages/DispatchesPage/components/SearchAndFilter'
import { SlaColorCellProvider } from '../formatters/SlaColorCellProvider'
import styles from './GridViewInner.module.scss'
import { TrackingNumberProvider } from '../formatters/TrackingNumberProvider'
import { useModalState } from 'hooks/useModalState'
import { updateFilters } from '../utils/updateFilters'
import { QuoteDetailLinkProvider } from '../formatters/QuoteDetailLinkProvider'
import { OrderDetailLinkProvider } from '../formatters/OrderDetailLinkProvider'
import { EditButtonLinkProvider } from '../formatters/EditButtonLinkProvider'
import { QuoteDeleteModal } from '../QuoteDeleteModal'
import { DownloadButtonProvider, DownloadServices } from '../formatters/DownloadButtonProvider'
import { UpdateJobInfoModal } from 'components/UpdateJobInfoModal'
import { JobStatusActionProvider } from 'components/GridView/formatters/JobStatusActionProvider'

interface DisplayColumnWidth {
  columnName: string
  width: number | string
}

const reduceNameToWidth = (
  map: { [key: string]: number },
  { columnName, width }: DisplayColumnWidth,
): { [key: string]: number } => {
  map[columnName] = typeof width === 'string' ? parseInt(width, 10) : width
  return map
}

export type GridViewInnerProps = GridViewProps &
  GridState & {
    dateFormat: string
    dispatch: GridDispatch
    isLoading: boolean
    savedViews: Grid[]
    setMenuConfigIdCache: (currentConfigId: number, newConfigId: number) => void
  }

export const GridViewInner: FC<GridViewInnerProps> = ({
  brandId,
  cellFormatters,
  configId,
  currentPage,
  customization,
  dispatch,
  externalLastUpdated,
  filterColumnId,
  filters,
  fixedColumns,
  forceHideAdvancedSearch,
  gridConfigs,
  gridDataRows,
  hideHeader,
  hideName,
  infoBarRecordsUnit,
  isLoading,
  name,
  pageSize,
  pageSizeOptions,
  recordsCount,
  refresh: { gridConfigLastUpdated, lastUpdated },
  renderHeaderContent,
  savedViews,
  setMenuConfigIdCache,
  showAdvancedSearch,
  showDispatchesHeader = false,
  slaRuleSetId,
  sorting,
  subtitle,
}) => {
  const digestGridData = useCallback(
    (payload: NormalizedGridDataResponse) => {
      setMenuConfigIdCache(configId, payload.configId)
      dispatch({ payload, type: ActionTypes.DigestGridData })
    },
    [setMenuConfigIdCache, dispatch, configId],
  )

  useEffect(() => {
    dispatch({ payload: 0, type: ActionTypes.SetCurrentPage })
  }, [dispatch, externalLastUpdated])

  // Load Grid Config
  useEffect(() => {
    dispatch({ payload: true, type: ActionTypes.SetLoading })

    void getGridConfig({ configId })
      .then(config => {
        dispatch({ payload: config.filters, type: ActionTypes.SetFilters })
        dispatch({ payload: config, type: ActionTypes.DigestGridConfig })
      })
      .finally(() => dispatch({ payload: false, type: ActionTypes.SetLoading }))
  }, [configId, dispatch, gridConfigLastUpdated])

  // Load grid data.
  useEffect(() => {
    dispatch({ payload: true, type: ActionTypes.SetLoading })

    void getGridData({
      brandId,
      configId,
      slaRuleSetId,
    })
      .then(digestGridData)
      .finally(() => dispatch({ payload: false, type: ActionTypes.SetLoading }))
  }, [
    brandId,
    configId,
    slaRuleSetId,
    digestGridData,
    externalLastUpdated,
    gridConfigLastUpdated,
    lastUpdated,
    dispatch,
  ])

  const setSortingAndSync = React.useCallback(
    (sorting: Sorting[]) => {
      dispatch({ payload: true, type: ActionTypes.SetLoading })

      const activeSort = sorting[0]
      if (activeSort) {
        const columnId = gridConfigs.find(({ name }) => activeSort.columnName === name)?.columnId
        if (columnId) {
          void updateSortOrder({
            brandId,
            columnId,
            configId,
            slaRuleSetId,
            sortDirection:
              activeSort.direction === 'asc' ? BoolSortDirection.asc : BoolSortDirection.desc,
          }).then(payload => {
            digestGridData(payload)
            dispatch({ payload: sorting, type: ActionTypes.SetSorting })
            dispatch({ payload: 0, type: ActionTypes.SetCurrentPage })
            dispatch({ payload: false, type: ActionTypes.SetLoading })
          })
        }
      }
    },
    [brandId, configId, digestGridData, dispatch, gridConfigs, slaRuleSetId],
  )

  const setPageSizeAndSync = React.useCallback(
    (pageSize: number) => {
      void updateRecordsPerPage({
        brandId,
        configId,
        perPage: pageSize,
        slaRuleSetId,
      }).then(payload => {
        digestGridData(payload)
        dispatch({ payload: pageSize, type: ActionTypes.SetPageSize })
        dispatch({ payload: 0, type: ActionTypes.SetCurrentPage })
        dispatch({ type: ActionTypes.RefreshLastUpdated })
      })
    },
    [brandId, configId, digestGridData, dispatch, slaRuleSetId],
  )

  const setCurrentPage = React.useCallback(
    (pageNumber: number) => {
      dispatch({ payload: true, type: ActionTypes.SetLoading })
      void updatePageNumber({
        brandId,
        configId,
        pageNumber,
        slaRuleSetId,
      })
        .then(digestGridData)
        .finally(() => dispatch({ payload: false, type: ActionTypes.SetLoading }))
    },
    [brandId, configId, digestGridData, dispatch, slaRuleSetId],
  )

  const setColumnOrder = React.useCallback(
    (displayColumnNamesInNewOrder: string[]) => {
      const nameToOrder = assignColumnSequences({
        displayColumnNamesInNewOrder,
        gridConfigs,
      })
      const columns = gridConfigs.map(config => ({
        ...config,
        sequence: nameToOrder[config.columnId],
      }))
      void updateColumnOrder({ columns, configId })
      dispatch({ payload: columns, type: ActionTypes.SetGridConfigs })
    },
    [configId, dispatch, gridConfigs],
  )

  const setColumnWidths = React.useCallback(
    (widthsOfDisplayColumns: DisplayColumnWidth[]) => {
      const nameToWidth = widthsOfDisplayColumns.reduce(reduceNameToWidth, {})
      const newConfigs = gridConfigs.map(config => {
        const nextWidth = Math.max(nameToWidth[config.name], Number(config.minimumWidth))

        if (config.dataOnly || Number(config.width) === nextWidth) {
          return config
        }

        void updateColumnWidth({ columnId: config.columnId, configId, width: nextWidth })

        return {
          ...config,
          width: String(nextWidth),
        }
      })
      dispatch({ payload: newConfigs, type: ActionTypes.SetGridConfigs })
    },
    [configId, dispatch, gridConfigs],
  )

  const {
    hide: closeApproveOrderModal,
    open: openApproveOrderModal,
    state: approveOrderModalState,
  } = useModalState({ jobId: '', reference1: '', reference2: '' })

  const {
    hide: closeApprovePoModal,
    open: openApprovePoModal,
    state: approvePoModalState,
  } = useModalState({ jobId: '', reference1: '', reference2: '' })

  const {
    hide: closeEditCancelModal,
    open: openEditCancelModal,
    state: editCancelModalState,
  } = useModalState<{ jobId: string; orderNumber: string; type: 'cancel' | 'edit' }>({
    jobId: '',
    orderNumber: '',
    type: 'edit',
  })

  const {
    hide: closeQuoteDeleteModal,
    open: openQuoteDeleteModal,
    state: quoteDeleteModalState,
  } = useModalState<{ quoteId: string; quoteNumber: string }>({
    quoteId: '',
    quoteNumber: '',
  })

  const handleCloseQuoteDeleteModal = (deleted: boolean) => {
    closeQuoteDeleteModal()
    if (deleted) {
      dispatch({ payload: true, type: ActionTypes.SetLoading })

      void getGridData({
        brandId,
        configId,
        slaRuleSetId,
      })
        .then(digestGridData)
        .finally(() => dispatch({ payload: false, type: ActionTypes.SetLoading }))
    }
  }

  const {
    hide: closeMoreCommentsModal,
    open: openMoreCommentsModal,
    state: moreCommentsModalState,
  } = useModalState({ commentType: CommentType.epNote, jobId: '' })

  const {
    hide: closeUpdateJobInfoModal,
    open: openUpdateJobInfoModal,
    state: updateJobInfoModalState,
  } = useModalState({ editAction: '', jobId: '' })

  const openMoreCommentsModalFactory = React.useCallback(
    ({ commentType, jobId }: MoreCommentModalState) =>
      () =>
        openMoreCommentsModal({ commentType, jobId }),
    [openMoreCommentsModal],
  )

  const MoreCommentsFormatter = React.useMemo(
    () =>
      moreCommentsFormatter({
        columnNames: [ColumnName.comments, ColumnName.commentText],
        openModalFactory: openMoreCommentsModalFactory,
      }),
    [openMoreCommentsModalFactory],
  )

  const formatGridViewDataProps = useMemo(
    () =>
      formatGridViewData({
        columns: gridConfigs,
        fixedColumns,
        rows: gridDataRows,
      }),
    [gridDataRows, fixedColumns, gridConfigs],
  )

  const handleEditButtonClick = React.useCallback(
    (row: DynamicObject) => {
      return () => {
        if (getDataOnlyValue(row, ColumnName.jobStatus) === 'Assign PO') {
          openApprovePoModal({
            jobId: String(getDataOnlyValue(row, ColumnName.jobId)),
            reference1: String(getDataOnlyValue(row, ColumnName.customerReferenceNum) || ''),
            reference2: String(getDataOnlyValue(row, ColumnName.customerReferenceNum2) || ''),
          })
        } else if (getDataOnlyValue(row, ColumnName.jobStatus) === 'Approval') {
          openApproveOrderModal({
            jobId: String(getDataOnlyValue(row, ColumnName.jobId)),
            reference1: String(getDataOnlyValue(row, ColumnName.customerReferenceNum) || ''),
            reference2: String(getDataOnlyValue(row, ColumnName.customerReferenceNum2) || ''),
          })
        } else {
          openEditCancelModal({
            jobId: String(getDataOnlyValue(row, ColumnName.jobId)),
            orderNumber: String(getDataOnlyValue(row, ColumnName.customerReferenceNum)),
            type: 'edit',
          })
        }
      }
    },
    [openApproveOrderModal, openApprovePoModal, openEditCancelModal],
  )

  // const handleCancelButtonClick = React.useCallback(
  //   (row: DynamicObject) => {
  //     return () => {
  //       openEditCancelModal({
  //         jobId: String(getDataOnlyValue(row, ColumnName.jobId)),
  //         orderNumber: String(getDataOnlyValue(row, ColumnName.customerReferenceNum)),
  //         type: 'cancel',
  //       })
  //     }
  //   },
  //   [openEditCancelModal],
  // )

  const handleDeleteQuoteClick = React.useCallback(
    (row: DynamicObject) => {
      return () => {
        openQuoteDeleteModal({
          quoteId: String(getDataOnlyValue(row, ColumnName.quoteId)),
          quoteNumber: String(getDataOnlyValue(row, ColumnName.quoteNumber)),
        })
      }
    },
    [openQuoteDeleteModal],
  )

  const handleUpdateFilters = React.useCallback(
    (newFilters: AdvancedSearchFilter[]) => {
      void updateFilters({ brandId, configId, filters: newFilters, slaRuleSetId }, dispatch)
    },
    [brandId, configId, slaRuleSetId, dispatch],
  )

  const cellFormattersWithCommentFormatters = React.useMemo(
    () => [
      ...React.Children.toArray(cellFormatters),
      <EditButtonProvider
        column={ColumnName.edpEditModal}
        key="edit button"
        onClickFormatter={handleEditButtonClick}
      />,
      <EditButtonLinkProvider
        editColumn={ColumnName.quoteEditModal}
        quoteIdColumn={ColumnName.quoteId}
        key="quote edit link"
      />,
      // Temporarily do not show Delete icon per card ONPK-11252
      // <CancelButtonProvider
      //   column={ColumnName.edpCancelModal}
      //   key="cancel button"
      //   onClickFormatter={handleCancelButtonClick}
      // />,
      <CancelButtonProvider
        column={ColumnName.quoteDeleteModal}
        key="delete button"
        onClickFormatter={handleDeleteQuoteClick}
      />,
      <ActionsProvider
        column={ColumnName.actions}
        key="action icons provider"
        handleDeleteQuoteClick={handleDeleteQuoteClick}
      />,
      <MoreCommentsFormatter key="more comments formatter" />,
      <DownloadButtonProvider
        key="download dispatch provider"
        column={ColumnName.downloadDispatch}
        downloadService={DownloadServices.DispatchDataFile}
        queryString={{ job_id: ColumnName.jobId }}
      />,
      <TrackingNumberProvider key="tracking number formatter" />,
      <SlaColorCellProvider key="sla color cell provider" />,
      <PDFPortalProvider key="pdf portal provider" />,
      <MailToProvider key="mail to link provider" />,
      <OrderDetailLinkProvider column={ColumnName.jobId} key="order id link provider" />,
      <OrderDetailLinkProvider column={ColumnName.quoteJobId} key="quote id link provider" />,
      <QuoteDetailLinkProvider
        valueColumn={ColumnName.quoteNumber}
        idColumn={ColumnName.quoteId}
        key="quote number provider"
      />,
      <JobStatusActionProvider
        column={ColumnName.jobStatusWithAction}
        key="job status action provider"
        onClickFormatter={openUpdateJobInfoModal}
      />,
      <JobStatusActionProvider
        column={ColumnName.jobStatusWithActionITAD}
        key="job status action provider for ITADs"
        onClickFormatter={openUpdateJobInfoModal}
      />,
      <JobStatusActionProvider
        column={ColumnName.jobStatusWithActionLP}
        key="job status action provider for LPs"
        onClickFormatter={openUpdateJobInfoModal}
      />,
    ],
    [
      cellFormatters,
      handleEditButtonClick,
      // handleCancelButtonClick,
      handleDeleteQuoteClick,
      MoreCommentsFormatter,
      openUpdateJobInfoModal,
    ],
  )

  const gridName = React.useMemo(
    () =>
      hideName || (
        <div className={styles.GridName}>
          {name}
          {subtitle ? ` (${subtitle})` : ''}
        </div>
      ),
    [hideName, name, subtitle],
  )

  const refreshData = () => {
    dispatch({ payload: 0, type: ActionTypes.SetCurrentPage })
    dispatch({ type: ActionTypes.RefreshLastUpdated })
  }

  const refreshGridConfig = () => dispatch({ type: ActionTypes.RefreshGridConfig })
  const basicQuery = filters.length > 0 ? String(filters[0].value) : ''

  return (
    <>
      {showDispatchesHeader && (
        <SearchAndFilter
          dispatch={dispatch}
          hideBasicSearch={showAdvancedSearch && !forceHideAdvancedSearch}
          query={basicQuery}
          clearFilters={() => handleUpdateFilters([])}
        />
      )}

      <Collapse in={showAdvancedSearch && !forceHideAdvancedSearch} mountOnEnter timeout={200}>
        <DispatchesAdvancedSearch
          filters={filters}
          filterColumnId={filterColumnId}
          filterableColumns={gridConfigs.filter(({ filterable }) => filterable)}
          loading={isLoading}
          onSearch={newFilters => handleUpdateFilters(newFilters)}
        />
      </Collapse>

      {isLoading ? (
        <Loader />
      ) : (
        <>
          {!hideHeader && (
            <Row className={styles.GridHeader}>
              {gridName}
              {renderHeaderContent}
            </Row>
          )}
          <div className={styles.TableInfoBar}>
            <JobCount
              currentPage={currentPage}
              recordsUnit={infoBarRecordsUnit || 'Orders'}
              recordsCount={recordsCount}
              pageSize={pageSize}
            />
            <Export configId={configId} gridName={name} />
            {canModifyColumns(customization) && (
              <>
                <CustomizeView configId={configId} refresh={refreshGridConfig} />
                {canAddViews(customization) && (
                  <AddSavedView configId={configId} dispatch={dispatch} savedViews={savedViews} />
                )}
                <ResetView
                  savedViews={savedViews}
                  configId={configId}
                  refresh={refreshGridConfig}
                />
                {canAddViews(customization) && (
                  <SavedViewList configId={configId} savedViews={savedViews} dispatch={dispatch} />
                )}
              </>
            )}
          </div>

          <DevExpressGrid
            {...formatGridViewDataProps}
            cellFormatters={cellFormattersWithCommentFormatters}
            setColumnOrder={canModifyColumns(customization) ? setColumnOrder : undefined}
            setColumnWidths={canModifyColumns(customization) ? setColumnWidths : undefined}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            pageSize={pageSize}
            setPageSize={setPageSizeAndSync}
            recordsCount={recordsCount}
            pageSizeOptions={pageSizeOptions}
            setSorting={setSortingAndSync}
            sorting={sorting}
          />

          <MoreCommentsModal closeModal={closeMoreCommentsModal} {...moreCommentsModalState} />

          <EditCancelModal closeModal={closeEditCancelModal} {...editCancelModalState} />

          <QuoteDeleteModal closeModal={handleCloseQuoteDeleteModal} {...quoteDeleteModalState} />

          <ApproveOrderModal
            closeModal={closeApproveOrderModal}
            refreshData={refreshData}
            {...approveOrderModalState}
          />

          <ApprovePoModal
            closeModal={closeApprovePoModal}
            refreshData={refreshData}
            {...approvePoModalState}
          />

          <UpdateJobInfoModal
            closeModal={closeUpdateJobInfoModal}
            refreshData={refreshData}
            {...updateJobInfoModalState}
          />
        </>
      )}
    </>
  )
}
