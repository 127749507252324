import { BaseApiResult } from 'types'
import { api } from 'lib/api/base'
import { ENDPOINTS } from 'lib/api/endpoints'
import { AxiosPromise } from 'axios'

export interface ReportQueueParams {
  configId: number
  emailBcc: string
  emailBody: string
  emailCc: string
  emailSubject: string
  emailTo: string
  filename: string
  title: string
}

type reportQueueAdd = (params: ReportQueueParams) => AxiosPromise<BaseApiResult>
export const reportQueueAdd: reportQueueAdd = ({
  configId,
  emailBcc,
  emailBody,
  emailCc,
  emailSubject,
  emailTo,
  filename,
  title,
}) => {
  return api.post<BaseApiResult>(ENDPOINTS.reportQueue, {
    action: 'add',
    config_id: configId,
    email_bcc: emailBcc,
    email_body: emailBody,
    email_cc: emailCc,
    email_subject: emailSubject,
    email_to: emailTo,
    filename,
    title,
  })
}
