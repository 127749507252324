import { PlusIcon } from 'components/PlusIcon'

import styles from './AddPalletButton.module.scss'

export type Props = {
  onClick: () => void
}

export const AddPalletButton = ({ onClick }: Props): JSX.Element => (
  <button aria-label="Add pallet" className={styles.Button} onClick={onClick} type="button">
    <PlusIcon />
    Add Pallet
  </button>
)
