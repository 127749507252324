import React from 'react'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'
import Row from 'react-bootstrap/Row'
import { capitalize } from 'lodash'
import { Formik } from 'formik'

import { addComment } from 'lib/api/comments'
import { Button } from 'components/Button'
import { CommentType } from 'types'
import styles from './EditCancelModal.module.scss'
import { TextareaField } from '../TextareaField'

export type Props = {
  closeModal: () => void
  jobId: string
  orderNumber: string
  show: boolean
  type: 'edit' | 'cancel'
}

export const EditCancelModal: React.FC<Props> = ({
  closeModal,
  jobId,
  orderNumber,
  show,
  type,
}) => (
  <Modal onHide={closeModal} size="lg" show={show}>
    <Modal.Header className={styles.ModalHeader} closeButton>
      <div className="d-flex flex-wrap align-items-center justify-content-between w-100">
        <Modal.Title className="font-weight-bold">{`${capitalize(type)} Order`}</Modal.Title>
        <div className={styles.ModalHeaderRightContent}>
          {jobId && (
            <>
              <strong>Job ID: </strong>
              <span>{jobId}</span>
            </>
          )}
          <strong>Order Number: </strong>
          <span>{orderNumber}</span>
        </div>
      </div>
    </Modal.Header>

    <Formik
      enableReinitialize
      initialValues={{
        comment:
          type === 'edit'
            ? 'Please make the following changes to this order: '
            : 'Please cancel this order for the following reason: ',
      }}
      onSubmit={async values => {
        await addComment({
          commentText: values.comment,
          commentType: CommentType.job,
          jobId,
        })
        closeModal()
      }}
    >
      {({ handleSubmit, isSubmitting, setFieldValue, values }) => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const commentsRef = React.useRef<HTMLTextAreaElement>(null)

        // eslint-disable-next-line react-hooks/rules-of-hooks
        React.useEffect(() => {
          setTimeout(() => {
            if (commentsRef.current) {
              commentsRef.current.focus()
              const commentValue = values.comment
              void setFieldValue('comment', '')
              void setFieldValue('comment', commentValue)
            }
          }, 1000)
          // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [setFieldValue])

        return (
          <Form onSubmit={handleSubmit}>
            <Modal.Body className={styles.ModalBody}>
              <Row>
                <Col>
                  <TextareaField label="Comments" name="comment" ref={commentsRef} />
                </Col>
              </Row>
            </Modal.Body>

            <Modal.Footer className={styles.ModalFooter}>
              <Row className="justify-content-end w-100">
                <Col lg={3} xs={6}>
                  <Button className="w-100" onClick={closeModal} variant="outline-dark">
                    Cancel
                  </Button>
                </Col>
                <Col lg={3} xs={6}>
                  <Button className="w-100" disabled={isSubmitting} type="submit">
                    Save
                  </Button>
                </Col>
              </Row>
            </Modal.Footer>
          </Form>
        )
      }}
    </Formik>
  </Modal>
)
