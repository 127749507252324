import { FC, useState, useEffect, FormEvent } from 'react'
import classnames from 'classnames'
import { Form } from 'react-bootstrap'
import partition from 'lodash/partition'
import { ReactSortable } from 'react-sortablejs'

import { FrozenMarker, HiddenMarker } from './constants'
import {
  getGridConfig,
  updateFixedColumns,
  updateColumnVisibility,
  updateColumnOrder,
} from 'lib/api/gridConfig'
import { GridConfig } from 'types'
import { GridConfigSortable } from './types'
import { Loader } from 'components/Loader'
import { mapConfigToSortable, isFrozenMarker, isHiddenMarker } from './utils'
import { Modal } from 'components/Modal'
import styles from './GridConfiguratorModal.module.scss'

const formId = 'grid-configurator'
export type GridConfiguratorModalProps = {
  configId: number
  hide: () => void
  refresh: () => void
  show: boolean
}
export const GridConfiguratorModal: FC<GridConfiguratorModalProps> = ({
  refresh,
  show,
  configId,
  hide,
}) => {
  const [displayableColumns, setDisplayableColumns] = useState<GridConfigSortable[]>([])
  const [dataOnlyColumns, setDataOnlyColumns] = useState<GridConfig[]>([])
  const [saving, setSaving] = useState(false)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(true)
    if (show) {
      void getGridConfig({ configId })
        .then(({ fixedColumns, configs }) => {
          const [displayableConfigs, dataOnlyConfigs] = partition(
            configs,
            ({ dataOnly }) => !dataOnly,
          )
          const [visibleConfigs, hiddenConfigs] = partition(
            displayableConfigs,
            ({ hidden }) => !hidden,
          )

          const rawDraggables = [...visibleConfigs, ...hiddenConfigs].map(mapConfigToSortable)

          setDisplayableColumns([
            ...rawDraggables.slice(0, fixedColumns),
            FrozenMarker,
            ...rawDraggables.slice(fixedColumns, visibleConfigs.length),
            HiddenMarker,
            ...rawDraggables.slice(visibleConfigs.length),
          ])
          setDataOnlyColumns(dataOnlyConfigs)
        })
        .finally(() => setLoading(false))
    }
  }, [configId, show])

  const frozenIndex = displayableColumns.findIndex(isFrozenMarker)
  const hiddenIndex = displayableColumns.findIndex(isHiddenMarker)

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    try {
      setSaving(true)
      const allColumns = [
        ...displayableColumns,
        ...dataOnlyColumns.map(mapConfigToSortable),
      ].filter(({ original }) => original)

      const hiddenColumns = allColumns.slice(hiddenIndex - 1).map(({ original }) => ({
        columnId: Number(original?.columnId),
        visible: false,
      }))
      const visibleColumns = allColumns.slice(0, hiddenIndex - 1).map(({ original }) => ({
        columnId: Number(original?.columnId),
        visible: true,
      }))

      await Promise.all([
        updateFixedColumns({ configId, fixedColumns: frozenIndex || 0 }),
        updateColumnVisibility({
          columns: [...visibleColumns, ...hiddenColumns],
          configId,
        }),
        updateColumnOrder({
          columns: allColumns.map(({ original }, index) => ({
            columnId: Number(original?.columnId),
            sequence: index,
          })),
          configId,
        }),
      ])
      refresh()
    } catch {
    } finally {
      setSaving(false)
    }
  }

  return (
    <Modal
      saving={saving}
      formId={formId}
      submitButtonText="Save"
      title="Customize View"
      show={show}
      hide={hide}
      size="xl"
    >
      {loading ? (
        <Loader />
      ) : (
        <Form className="mb-5" id={formId} onSubmit={e => void onSubmit(e)}>
          <ReactSortable
            animation={200}
            className={styles.Dragzone}
            list={displayableColumns}
            setList={params =>
              params.findIndex(isFrozenMarker) <= params.findIndex(isHiddenMarker) &&
              setDisplayableColumns(params)
            }
          >
            {displayableColumns.map(({ id, name, original }, index) => {
              return (
                <div className={styles.DraggableContainer} key={id}>
                  <div
                    className={classnames(styles.Draggable, {
                      [styles.Barrier]: !original,
                      [styles.Frozen]: index <= frozenIndex,
                      [styles.Hidden]: index >= hiddenIndex,
                    })}
                  >
                    <p>{name}</p>
                  </div>
                </div>
              )
            })}
          </ReactSortable>
        </Form>
      )}
    </Modal>
  )
}
