import { PhoneNumberField } from 'components/PhoneNumberField'
import { SelectField } from 'components/SelectField'
import { TextField } from 'components/TextField'
import { useFormikContext } from 'formik'
import { useAddressAutocomplete } from 'hooks/useAddressAutocomplete'
import { CountryCode } from 'libphonenumber-js'
import React from 'react'
import { Address, CompanyInfo, SelectOption } from 'types'
import { BillingSameAsCompanyCheckbox } from './BillingSameAsCompanyCheckbox'

type Props = {
  headerClassName?: string
  inclEmail?: boolean
  inclPhone?: boolean
  inclSameAsCompany?: boolean
  stateOptions: SelectOption[]
}

export const BillingInfoFields = ({
  headerClassName = 'mb-4 mt-4',
  inclSameAsCompany = true,
  stateOptions,
  inclEmail = false,
  inclPhone = false,
}: Props): JSX.Element => {
  const { setValues, values } = useFormikContext<FormWithBillingValues>()

  const onBillingAddressAutocomplete = React.useCallback(
    (newAddress: Address) => {
      void setValues({
        ...values,
        billingInfo: {
          ...values.billingInfo,
          address1: newAddress.address1,
          city: newAddress.city,
          postalCode: newAddress.postal_code,
          stateProvince: newAddress.state_province,
        } as CompanyInfo,
      })
    },
    [setValues, values],
  )

  const { address2Ref, setAddressInputElement } = useAddressAutocomplete(
    onBillingAddressAutocomplete,
  )

  return (
    <>
      <h4 className={headerClassName}>Billing Information</h4>
      {inclSameAsCompany && <BillingSameAsCompanyCheckbox />}
      <TextField
        autoComplete="new_billing_name"
        className="mb-3"
        label="Billing Company Name"
        name="billingInfo.name"
      />
      <TextField
        autoComplete="new_first_name"
        className="mb-3"
        label="Billing Contact First Name"
        name="billingInfo.firstName"
      />
      <TextField
        autoComplete="new_last_name"
        className="mb-3"
        label="Billing Contact Last Name"
        name="billingInfo.lastName"
      />
      {inclPhone && (
        <PhoneNumberField
          autocomplete="billing_new_phone"
          className="mb-3"
          country={(values.billingInfo.country || 'US') as CountryCode}
          label="Billing Phone"
          name="billingInfo.phone"
        />
      )}
      {inclEmail && (
        <TextField
          autoComplete="new_email"
          className="mb-3"
          label="Billing Email"
          name="billingInfo.email"
        />
      )}
      <TextField
        autoComplete="new_address1"
        className="mb-3"
        label="Billing Address 1"
        name="billingInfo.address1"
        ref={el => setAddressInputElement(el)}
      />
      <TextField
        autoComplete="new_address2"
        className="mb-3"
        label="Billing Address 2"
        name="billingInfo.address2"
        ref={address2Ref}
      />
      <TextField
        autoComplete="new_city"
        className="mb-3"
        label="Billing City"
        name="billingInfo.city"
      />
      <SelectField
        className="mb-3"
        label="Billing State"
        name="billingInfo.stateProvince"
        options={stateOptions}
      />
      <TextField
        autoComplete="new_postal_code"
        className="mb-3"
        label="Billing Zip Code"
        name="billingInfo.postalCode"
      />
    </>
  )
}

type FormWithBillingValues = {
  billingInfo: CompanyInfo
  billingSameAsCompany: boolean
}
