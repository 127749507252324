import * as React from 'react'
import Spinner from 'react-bootstrap/Spinner'
import { useNavigate } from 'react-router'

import { ApproveOrderModalContainer as ApproveOrderModal } from 'components/ApproveOrderModal'
import { ColumnName, JobColor } from 'types'
import { EditCancelModal } from 'components/EditCancelModal'
import { useLocationParam } from 'hooks/useLocationParam'
import { useModalState } from 'hooks/useModalState'

import { AdvancedSearchModal } from './components/AdvancedSearchModal'
import * as helpers from './helpers'
import { OrderActions } from './components/OrderActions'
import { SearchBar } from './components/SearchBar'
import styles from './MobileView.module.scss'
import { useAdvancedSearchSettings } from './hooks/useAdvancedSearchSettings'
import { MobileGridView, RowData } from 'components/MobileGridView'
import { useGridConfig } from 'components/MobileGridView/hooks/useGridConfig'
import { useMemo } from 'react'
import { useCurrentGridConfigId } from '../../../../hooks/useCurrentGridConfigId'
import { useCurrentMenu } from '../../../../hooks/useCurrentMenu'

type Columns = {
  columns: ColumnName[]
}
const COLUMNS_MAP: Record<string, Columns> = {
  'Completed Quotes': {
    columns: [ColumnName.quoteNumber, ColumnName.quoteCreateDate, ColumnName.quoteJobLocationCity],
  },
  Drafts: {
    columns: [ColumnName.quoteNumber, ColumnName.quoteCreateDate, ColumnName.jobLocationCompany],
  },
  'Open Quotes': {
    columns: [ColumnName.quoteNumber, ColumnName.quoteCreateDate, ColumnName.jobLocationCompany],
  },
  default: {
    columns: [ColumnName.createDate, ColumnName.jobId, ColumnName.jobLocationCompany],
  },
}

type Props = {
  showSearch: boolean
}

export const MobileView = ({ showSearch }: Props): JSX.Element => {
  const navigate = useNavigate()
  const [advanced] = useLocationParam<boolean>('advanced')

  const { description } = useCurrentMenu()
  const configId = useCurrentGridConfigId()
  const targetColumns = useMemo(() => {
    if (description in COLUMNS_MAP) return COLUMNS_MAP[description]
    else return COLUMNS_MAP.default
  }, [description])

  const {
    gridColumnTypeMap,
    gridFilterTypeMap,
    loading: loadingAdvancedSearchSettings,
  } = useAdvancedSearchSettings()

  const {
    hide: hideAdvancedSearch,
    open: openAdvancedSearch,
    state: advancedSearchState,
  } = useModalState({ show: advanced })
  const {
    hide: hideApproveOrderModal,
    open: openApproveOrderModal,
    state: approveOrderModalState,
  } = useModalState<{
    jobId: string
  }>({
    jobId: '',
  })
  const {
    hide: hideEditCancelModal,
    open: openEditCancelModal,
    state: editCancelModalState,
  } = useModalState<{
    jobId: string
    orderNumber: string
    type: 'cancel' | 'edit'
  }>({
    jobId: '',
    orderNumber: '',
    type: 'edit',
  })

  const getClassName = React.useCallback((row: RowData) => {
    const statusColor = row[ColumnName.sla]

    switch (statusColor) {
      case JobColor.black:
        return styles.Black

      case JobColor.green:
        return styles.Green

      case JobColor.purple:
        return styles.Purple

      case JobColor.red:
        return styles.Red

      case JobColor.yellow:
        return styles.Yellow
    }
  }, [])

  const [gridLastUpdated, setGridLastUpdated] = React.useState(() => Date.now())
  const refreshGrid = React.useCallback(() => {
    setGridLastUpdated(Date.now())
  }, [])

  const { gridConfig, gridConfigStatus } = useGridConfig(configId, gridLastUpdated)

  const loading = loadingAdvancedSearchSettings || gridConfigStatus === 'pending'

  return (
    <div className="d-flex flex-column flex-grow-1 w-100">
      <div className="sticky-top">
        <SearchBar
          onAdvancedSearchClick={openAdvancedSearch}
          onClear={() => void helpers.clearFilters(configId, refreshGrid)}
          onSearch={searchTerm => {
            helpers.search(searchTerm, navigate)
          }}
          show={advanced || showSearch}
        />
      </div>

      {loading ? (
        <div className="d-flex align-items-center justify-content-center flex-grow-1 w-100">
          <Spinner animation="border" role="status" />
        </div>
      ) : (
        <div className="d-flex flex-column flex-grow-1 p-3 w-100">
          <MobileGridView
            columnsToHighlight={targetColumns.columns}
            configId={configId}
            externalGridConfig={gridConfig ?? undefined}
            externalLastUpdated={gridLastUpdated}
            getClassName={getClassName}
            idColumn={ColumnName.jobId}
          >
            {row => {
              const jobId = row[ColumnName.jobId] as string
              const jobStatus = row[ColumnName.jobStatus] as string

              return (
                <OrderActions
                  jobId={jobId}
                  onCancelOrder={() => openEditCancelModal({ jobId, type: 'cancel' })}
                  onEditOrder={() =>
                    jobStatus === 'Approval'
                      ? openApproveOrderModal({ jobId })
                      : openEditCancelModal({ jobId, type: 'edit' })
                  }
                  show
                />
              )
            }}
          </MobileGridView>

          <AdvancedSearchModal
            gridColumnTypeMap={gridColumnTypeMap}
            gridConfigData={gridConfig}
            gridFilterTypeMap={gridFilterTypeMap}
            hide={hideAdvancedSearch}
            onSearch={filters => helpers.updateSearchFilters(configId, filters, refreshGrid)}
            show={advancedSearchState.show}
          />

          <ApproveOrderModal
            closeModal={hideApproveOrderModal}
            refreshData={refreshGrid}
            {...approveOrderModalState}
          />
          <EditCancelModal closeModal={hideEditCancelModal} {...editCancelModalState} />
        </div>
      )}
    </div>
  )
}
