import { Formik } from 'formik'
import { Col, Form, Row } from 'react-bootstrap'
import * as Yup from 'yup'

import { DatePickerField } from 'components/DatePickerField'
import { TextareaField } from 'components/TextareaField'
import { DetailValue } from 'pages/CompanyProfile/components/DetailValue.tsx'
import { FormikFileField } from 'components/FormikFileField'
import { updateEpReceive } from 'lib/api/job'
import { GetJobResponse } from 'lib/api/job/get'

import styles from './ConfirmReceiptForm.module.scss'
import { REQUIRED_FIELD_ERROR_MESSAGE } from 'utils/commonUtils.ts'
import ReferenceNumbersValues from 'components/ReferenceNumbersValues'

const LABEL_WIDTH = '150px'

const ConfirmReceiptSchema = Yup.object().shape({
  receipt_date: Yup.string().required(REQUIRED_FIELD_ERROR_MESSAGE),
})

export type ConfirmReceiptFormProps = {
  formId: string
  job: GetJobResponse | null
  jobId: string
  onSubmitSuccess: () => void
  setSaving: (saving: boolean) => void
}

export const ConfirmReceiptForm = ({
  formId,
  job,
  jobId,
  onSubmitSuccess,
  setSaving,
}: ConfirmReceiptFormProps) => {
  const handleSubmit = async (values: any) => {
    setSaving(true)

    try {
      await updateEpReceive({
        bill_of_lading: values.bill_of_lading,
        comment: values.comment,
        job_id: jobId,
        receipt_date: values.receipt_date,
      })
      onSubmitSuccess()
    } catch {
      setSaving(false)
    }
  }

  return (
    <Formik
      initialValues={{
        bill_of_lading: null,
        comment: '',
        receipt_date: '',
      }}
      onSubmit={handleSubmit}
      validationSchema={ConfirmReceiptSchema}
      enableReinitialize
    >
      {({ handleSubmit }) => {
        return (
          <Form className="w-100" noValidate id={formId} onSubmit={handleSubmit}>
            <DetailValue label="Order Number:" labelWidth={LABEL_WIDTH} value={jobId} />
            <ReferenceNumbersValues job={job} labelWidth={LABEL_WIDTH} />

            <Row className="mb-3 mt-4">
              <Col>
                <DatePickerField
                  className={styles.DateInput}
                  name="receipt_date"
                  label="Receipt Date"
                  placeholderText="mm/dd/yyyy"
                  hideIcon
                />
              </Col>
            </Row>

            <Row className="mb-3">
              <Col>
                <FormikFileField
                  label="Signed Bill of Lading"
                  name="bill_of_lading"
                  accept="application/pdf"
                />
              </Col>
            </Row>

            <Row className="mb-3">
              <Col>
                <TextareaField label="Comment" name="comment" />
              </Col>
            </Row>
          </Form>
        )
      }}
    </Formik>
  )
}
