import { useCallback, useMemo, useState, useEffect } from 'react'
import partition from 'lodash/partition'

import { AdvancedSearchFilter, Relation, SearchType } from 'types'
import { DispatchesAdvancedSearch, FilterFormParams } from './DispatchesAdvancedSearch'
import {
  FilterType,
  getFilterTypes,
  getGridColumnTypes,
  NormalizedGridColumnType,
} from 'lib/api/setting'
import { isObjectEmpty } from 'utils/commonUtils'

export interface DispatchesAdvancedSearchFilterProps {
  className?: string
  filterColumnId?: number
  filterableColumns: {
    columnId: number
    heading: string
    typeId: number
  }[]
  filters: AdvancedSearchFilter[]
  loading?: boolean
  onSearch: (filters: AdvancedSearchFilter[]) => void
}

export const DispatchesAdvancedSearchWrapper = ({
  className,
  filterColumnId,
  filterableColumns,
  filters: propFilters,
  loading,
  onSearch,
}: DispatchesAdvancedSearchFilterProps): JSX.Element => {
  const [filterTypes, setFilterTypes] = useState<FilterType[]>([])
  const [gridColumnTypes, setGridColumnTypes] = useState<NormalizedGridColumnType[]>([])

  const blankFilter: AdvancedSearchFilter = useMemo(() => {
    return {
      columnId: filterColumnId || filterableColumns[0]?.columnId,
      relationId: Relation.and,
      typeId: SearchType.contains,
      value: '',
    }
  }, [filterColumnId, filterableColumns])

  const [formFilters, urlOnlyFilters] = useMemo(() => {
    const filterableColumnMap: { [key: number]: boolean } = {}
    filterableColumns.forEach(col => {
      filterableColumnMap[col.columnId] = true
    })
    // If grid_config API is in progress, filterableColumnMap will be empty.
    // In this case we assume that all propFilters columns can be added to the
    // form (are filterable)
    if (isObjectEmpty(filterableColumnMap)) {
      return [propFilters, [] as AdvancedSearchFilter[]]
    } else {
      return partition(propFilters, filter => filterableColumnMap[filter.columnId])
    }
  }, [filterableColumns, propFilters])

  const defaultFilters = formFilters.length ? formFilters : [blankFilter]

  const onSubmit = useCallback(
    ({ filters }: FilterFormParams) => {
      const populatedFilters = filters.filter(({ value }) => Boolean(value))
      onSearch([...populatedFilters, ...urlOnlyFilters])
    },
    [onSearch, urlOnlyFilters],
  )

  const onClear = useCallback(() => {
    onSearch([])
  }, [onSearch])

  useEffect(() => {
    void getFilterTypes().then(filterTypes => {
      setFilterTypes(filterTypes)
    })
    void getGridColumnTypes().then(columnTypes => {
      setGridColumnTypes(columnTypes)
    })
  }, [])

  return (
    <DispatchesAdvancedSearch
      blankFilter={blankFilter}
      className={className}
      defaultFilters={defaultFilters}
      filterableColumns={filterableColumns}
      filterTypes={filterTypes}
      gridColumnTypes={gridColumnTypes}
      loading={loading}
      onClear={onClear}
      onSubmit={onSubmit}
    />
  )
}
