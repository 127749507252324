import axios, { AxiosError, AxiosResponse, isAxiosError } from 'axios'
import { Contact, PaymentType } from 'types'
import { getLocalToken } from './localToken'
import { getGuestToken } from './guestToken'

export const REQUIRED_FIELD_ERROR_MESSAGE = 'This field is required.'

type TClientError = {
  message: string
}

export type TApiError = AxiosError<{
  error?: string | null
}>

type TNormalizedError = {
  message: string
}

// Idea to handle errors is borrowed from here:
// https://stackoverflow.com/questions/68240884/error-object-inside-catch-is-of-type-unknown
export function normalizeError(error: unknown): TNormalizedError {
  const result: TNormalizedError = { message: '' }

  if (!error) {
    result.message = ''
  } else if (isAxiosError(error)) {
    result.message = (error as TApiError).response?.data?.error || ''
  } else if (error instanceof Error) {
    result.message = error.message
  } else if (typeof error === 'string') {
    result.message = error
  } else if (typeof (error as TClientError)?.message === 'string') {
    result.message = (error as TClientError).message
  } else {
    result.message = String(`[${typeof error}]: ${String(error)}`)
  }

  return result
}

export function isApiError(error: unknown): boolean {
  if (isAxiosError(error)) {
    const err = error as TApiError
    return Object.prototype.hasOwnProperty.call(err.response?.data, 'error')
  }
  return false
}

export const mapPaymentType = (apiPaymentName: string): PaymentType => {
  if (apiPaymentName === 'Credit Card') {
    return PaymentType.CreditCard
  } else if (apiPaymentName === 'Invoice') {
    return PaymentType.Invoice
  } else if (apiPaymentName === 'Terms') {
    return PaymentType.Terms
  }
  return PaymentType.None
}

export const isObjectEmpty = (obj: object): boolean => {
  return !obj || Object.keys(obj).length === 0
}

export const randomInt = (max: number = Number.MAX_SAFE_INTEGER): number => {
  return Math.floor(Math.random() * max)
}

// GET IP address of user's browser. Results are cached until user refreshes page.
let MyIpAddressMemoized = ''

export const getAppIpAddress = async (): Promise<string> => {
  if (!MyIpAddressMemoized) {
    const response = await axios.get<string, AxiosResponse<string>>(
      'https://api.ipify.org/?format=text',
    )
    MyIpAddressMemoized = response.data
  }

  return MyIpAddressMemoized
}

type TUserAgentMetadata = {
  srcIP: string
  srcURL: string
  userAgent: string
}

export const getMixpanelContext = async (srcURL?: string): Promise<TUserAgentMetadata> => {
  const ipResult = await getAppIpAddress()

  return {
    srcIP: ipResult,
    srcURL: srcURL ?? window.location.href,
    userAgent: window.navigator.userAgent,
  }
}

export const isEmptyContact = (contact: Contact | null | undefined): boolean => {
  return contact
    ? (contact.email || contact.first_name || contact.last_name || contact.phone).length === 0
    : true
}

/**
 * Get current session token. This could be either token associated with user session or guest session
 * @returns string Current
 */
export const getCurrentSessionToken = (): string => {
  return getLocalToken() || getGuestToken() || ''
}
