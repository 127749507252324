import { FC, useEffect, useMemo } from 'react'
import Button from 'react-bootstrap/Button'
import classNames from 'classnames'
import Col from 'react-bootstrap/Col'
import { FormikHelpers } from 'formik'
import Row from 'react-bootstrap/Row'
import sortBy from 'lodash/sortBy'

import { AdvancedSearchFilter, Relation, SearchType } from 'types'
import { DynamicSearchInput } from './DynamicSearchInput'
import { FilterableColumnSelect } from './FilterableColumnSelect'
import { FilterInputType, FilterType, NormalizedGridColumnType } from 'lib/api/setting'
import { IsOneOf } from '../IsOneOf'
import { RemoveRowIcon } from '../RemoveRowIcon'
import { SelectField } from 'components/SelectField'
import styles from './FilterRow.module.scss'

const nullColumn: NormalizedGridColumnType = {
  description: FilterInputType.string,
  filterTypes: [],
  typeId: 0,
}

const relationOptions = [
  {
    label: 'AND',
    value: Relation.and,
  },
  {
    label: 'OR',
    value: Relation.or,
  },
]

export type Props = {
  filter: AdvancedSearchFilter
  filterLength: number
  filterTypes: FilterType[]
  filterableColumns: {
    columnId: number
    heading: string
    typeId: number
  }[]
  gridColumnTypes: NormalizedGridColumnType[]
  index: number
  remove: () => void
  setFieldValue: FormikHelpers<AdvancedSearchFilter>['setFieldValue']
}
export const FilterRow: FC<Props> = ({
  filterTypes,
  filterableColumns,
  filter: { columnId, typeId, value },
  gridColumnTypes,
  index,
  remove,
  filterLength,
  setFieldValue,
}) => {
  const sortedFilterableColumns = useMemo(() => {
    return sortBy(filterableColumns, 'heading')
  }, [filterableColumns])

  const columnType = useMemo(() => {
    const filterColumnTypeId = filterableColumns.find(
      ({ columnId: columnIdFromList }) => columnIdFromList === Number(columnId),
    )?.typeId

    return gridColumnTypes.find(({ typeId }) => typeId === filterColumnTypeId) || nullColumn
  }, [columnId, filterableColumns, gridColumnTypes])

  const filtersForColumnType = useMemo(
    () => filterTypes.filter(({ typeId }) => columnType.filterTypes.includes(typeId)),
    [columnType, filterTypes],
  )
  const typeIdFieldName = `filters[${index}].typeId`

  const isValidFilterType = useMemo(
    () =>
      filtersForColumnType.some(({ typeId: typeIdFromList }) => typeIdFromList === Number(typeId)),
    [filtersForColumnType, typeId],
  )

  const defaultFilterTypeId = filtersForColumnType[0]?.typeId
  useEffect(() => {
    if (!isValidFilterType && defaultFilterTypeId) {
      void setFieldValue(typeIdFieldName, defaultFilterTypeId)
    }
  }, [defaultFilterTypeId, isValidFilterType, setFieldValue, typeIdFieldName])

  return (
    <Row data-testid={`filter-row-${index}`} key={index} className={styles.AdvancedFilterRow}>
      <Col className={styles.SearchFieldSelectorColumn}>
        {filterLength > 1 && (
          <div className={styles.RemoveRowButtonWrap}>
            <Button onClick={remove} variant="link">
              <RemoveRowIcon />
            </Button>
          </div>
        )}

        <SelectField
          className={classNames('me-2', { 'd-none': index === 0 })}
          ariaLabel="Relation"
          name={`filters[${index}].relationId`}
          options={relationOptions}
          whiteBG
        />

        <FilterableColumnSelect
          className={styles.ColumnSelectorField}
          filterableColumns={sortedFilterableColumns}
          setFieldValue={setFieldValue}
          index={index}
        />
      </Col>

      <Col className={styles.SearchOperatorColumn}>
        <SelectField
          className="w-100 h-100"
          ariaLabel="Search operator"
          name={typeIdFieldName}
          options={filtersForColumnType.map(({ description, typeId }) => ({
            label: description,
            value: typeId,
          }))}
          whiteBG
        />
      </Col>

      <Col className={styles.SearchInputColumn}>
        {Number(typeId) === SearchType.isOneOf && (
          <IsOneOf
            applyFilters={(newValue: string) =>
              void setFieldValue(`filters[${index}].value`, newValue)
            }
            existingFilters={String(value)}
          />
        )}
        {Number(typeId) === SearchType.isOneOf && (
          <span>{String(value).replaceAll('||', ', ')}</span>
        )}
        {Number(typeId) !== SearchType.isOneOf && (
          <DynamicSearchInput filterInputType={columnType.description} index={index} />
        )}
      </Col>
    </Row>
  )
}
