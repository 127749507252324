import { api } from 'lib/api/base'
import { ENDPOINTS } from 'lib/api/endpoints'
import { BaseApiResult, Grid } from 'types'
import { camelCaseKeys } from 'utils/camelCaseKeys'

type Params = {
  configId: number
}

export type ListSavedViewsResponse = BaseApiResult & {
  view: {
    config_id: number
    name: string
  }[]
}

type ListSavedViews = (params: Params) => Promise<Grid[]>
export const listSavedViews: ListSavedViews = async ({ configId }) => {
  const {
    data: { view },
  } = await api.get<ListSavedViewsResponse>(ENDPOINTS.gridConfigs, {
    params: {
      action: 'list',
      config_id: configId,
    },
  })

  return camelCaseKeys(view)
}
