import { FC } from 'react'
import { Nav } from 'react-bootstrap'

import style from './HeaderLink.module.scss'
import { Link } from 'react-router'

export interface HeaderLinkProps {
  label: string
  to: string
}

export const HeaderLink: FC<HeaderLinkProps> = ({ to, label }) => (
  <Link to={to} className={style.HeaderLink}>
    <Nav.Item className={style.HeaderLinkText}>
      <span className={style.HeaderLinkText}>{label}</span>
    </Nav.Item>
  </Link>
)
