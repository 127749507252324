import { useMemo } from 'react'
import { DataTypeProvider } from '@devexpress/dx-react-grid'

import { ColumnName, DynamicObject } from 'types'
import { useLoggedInAppContext } from '../../../../hooks/useLoggedInAppContext'
import { getDataOnlyValue } from '../../../../utils/getDataOnlyValue'
import { PortalLink } from '../../../PortalLink'
import { DownloadIcon } from '../../../DownloadIcon'

export enum DownloadServices {
  DispatchDataFile = 'view_otto_file.php',
  ReportQueueFile = 'view_report.php',
  UploadQueueFile = 'view_upload_file.php',
}

export interface Props {
  column: ColumnName
  downloadService: DownloadServices
  queryString?: Record<string, ColumnName> // e.g. { queue_id: ColumnName.uploadQueueId }
  visibleIf?: (row: DynamicObject) => boolean
}

export const DownloadButtonProvider = ({
  column,
  downloadService,
  queryString,
  visibleIf,
}: Props): JSX.Element => {
  const DownloadButtonFormatter = ({ row }: DataTypeProvider.ValueFormatterProps) => {
    const {
      user: { token },
    } = useLoggedInAppContext()
    const hasValue = String(getDataOnlyValue(row as DynamicObject, column))
    const isVisible = visibleIf ? visibleIf(row as DynamicObject) : hasValue

    const queries = useMemo(() => {
      const queries: string[] = []
      Object.entries(queryString || {}).forEach(([key, column]) => {
        queries.push(
          `${key}=${encodeURIComponent(getDataOnlyValue(row as DynamicObject, column) as string)}`,
        )
      })
      queries.push(`token=${encodeURIComponent(token)}`)

      return queries
    }, [row, token])

    if (!isVisible) return null

    const downloadLink = `${downloadService}?${queries.join('&')}`

    return (
      <div className="text-center">
        <PortalLink relativeHref={downloadLink}>
          <DownloadIcon />
        </PortalLink>
      </div>
    )
  }

  return <DataTypeProvider formatterComponent={DownloadButtonFormatter} for={[column]} />
}
