import TagManager from '@sooro-io/react-gtm-module'

import {
  Order,
  TOrderPage,
  Paradigm,
  PaymentType,
  Quote,
  SelectOption,
  AssetCategory,
  OrderReferenceNumber,
  Email,
  EmailType,
  TEmailResponse,
  GuestAssetCategory,
} from 'types'
import { ORDER_ROUTES, ROUTES } from 'Routes'
import { OrderState } from '../contexts/OrderContext'
import { generatePath } from 'react-router'
import { addJob } from 'lib/api/job'
import { MixpanelEvents, mixpanelTrack } from 'lib/api/mixpanel/track'

export const calculateOrderTotal = (
  order: Order,
  quote: Quote | undefined,
): { amount: number; discountAmount?: number; originalAmount?: number } => {
  if (!quote) {
    return { amount: 0 }
  }

  const inboundPrices = quote.prices ?? []
  const outboundPrices = quote.product_prices ?? []

  if (inboundPrices.length > 0) {
    const { inboundPriceId, outboundPriceId } = order

    // Shipping kits & labels orders will have inbound and/or outbound price IDs
    if (inboundPriceId) {
      const inbound = inboundPrices.find(price => price.price_id === inboundPriceId)
      const outbound = outboundPrices?.find(price => price.price_id === outboundPriceId)

      return {
        amount: (Number(inbound?.amount) || 0) + (Number(outbound?.amount) || 0),
        discountAmount:
          (Number(inbound?.discount_amount) || 0) + (Number(outbound?.discount_amount) || 0),
        originalAmount:
          (Number(inbound?.original_amount) || 0) + (Number(outbound?.original_amount) || 0),
      }
    }

    // For all other paradigms we assume there is 1 price.
    // return Number(inboundPrices[0].amount).valueOf() || 0
  }

  return { amount: 0 }
}

export const isPalletPickupParadigm = (paradigm?: Paradigm | null): boolean => {
  return paradigm?.name.toLowerCase() === 'pallet pickup'
}

export const isKitsAndLabelsParadigm = (paradigm?: Paradigm | null): boolean => {
  return paradigm?.name.toLowerCase() === 'kits & labels'
}

export const isLTLOrFTLParadigm = (paradigm?: Paradigm | null): boolean => {
  return (
    paradigm?.name.toLowerCase() === 'less-than-truckload' ||
    paradigm?.name.toLowerCase() === 'full-truckload'
  )
}

export const allowAssets = (paradigm?: Paradigm): boolean => {
  return paradigm?.asset_entry.allowed ?? false
}

export const allowBoxes = (paradigm?: Paradigm): boolean => {
  return paradigm?.box_entry.allowed ?? false
}

export const allowPallets = (paradigm?: Paradigm): boolean => {
  return paradigm?.pallet_entry.allowed ?? false
}

export const allowProducts = (paradigm?: Paradigm): boolean => {
  return paradigm?.product_entry.allowed ?? false
}

export const getAssetTypeOptions = (
  assetCategories?: AssetCategory[] | GuestAssetCategory[],
): SelectOption[] => {
  if (assetCategories) {
    return assetCategories
      .map(category =>
        category.assets.map(asset => ({
          label: asset.name,
          value: asset.asset_id,
        })),
      )
      .flat()
  }

  return []
}

type TPageFlowContext = {
  addAssetDetails: boolean
  allPages: TOrderPage[]
  assetsCount: number
  currentFlowId: number
  customQuoteRequired: boolean
  hasExtraLabels: boolean
  isLocalPickup: boolean
  isQuoteEdit: boolean
  isRa: boolean
  isSingleKitCase: boolean
  jobId: string
  orderReferenceNumbers: string[] | undefined
  paradigm?: Paradigm
  payment: PaymentType
  quoteId: string
  referenceNumbers: OrderReferenceNumber[]
  totalPrice: number | undefined
}

type TPageRuleHandler = (context: TPageFlowContext) => boolean

type TPageRuleHandlersMap = {
  [key: string]: TPageRuleHandler
}

type TOrderWizardNavData = {
  flowId: number
  path: string
  slug: string
}

enum PageFlow {
  Next,
  Prev,
}

const getPageFlowId = (pageFlowType: PageFlow, context: TPageFlowContext): number => {
  const pageConfig = context.allPages?.find(page => page.flow_id === context.currentFlowId)
  if (!pageConfig) {
    return 0
  }

  let flowId = 0
  const flows = pageConfig[pageFlowType === PageFlow.Next ? 'next_flow' : 'prev_flow']
  const rulesStr = pageConfig[pageFlowType === PageFlow.Next ? 'next_rules' : 'prev_rules']
  const rules = rulesStr ? rulesStr.split(',') : []

  // Return first flow id if there are no rules
  if (!rules.length) {
    return flows[0]
  }

  // Go through each rule to find out which flow id to use
  for (let i = 0; i < rules.length; ++i) {
    const ruleName = rules[i].trim()

    if (typeof TOrderRulesHandlers[ruleName] === 'function') {
      const handlerFunc: TPageRuleHandler = TOrderRulesHandlers[ruleName]
      if (handlerFunc(context)) {
        flowId = flows[i]
        break
      }
    }
  }

  return flowId
}

export const getOrderPaymentType = (state: OrderState): PaymentType => {
  const order = state.order
  // If this is an existent order and we already have payment type - return it.
  // NOTE that if order exists but does not have payment set up yet, the payment
  // value is None. So it is important to check if value is not None.
  // Otherwise, identify if order will have Terms payment based on the paradigm.
  if (order.payment && order.payment !== PaymentType.None) {
    return order.payment
  } else if (order.paradigm?.payment_terms) {
    return PaymentType.Terms
  }
  return PaymentType.None
}

export const getNavContext = (state: OrderState, jobId?: string): TPageFlowContext => {
  const allPages = state.order.paradigm?.pages ?? []
  const products = state.order.products ?? []
  const assetsCount =
    state.order.assets?.reduce((amount, asset) => amount + (asset.quantity ?? 0), 0) ?? 0

  return {
    addAssetDetails: state.order.addAssetDetails ?? false,
    allPages,
    assetsCount,
    currentFlowId: state.quote?.flow_id || (allPages?.[0]?.flow_id ?? 0),
    customQuoteRequired: state.customQuoteRequired,
    hasExtraLabels: products.findIndex(product => product.type === 'waybill') >= 0,
    isLocalPickup: state.quote.is_local_pickup ?? false,
    isQuoteEdit: state.isQuoteEdit,
    isRa: state.order.is_ra ?? false,
    isSingleKitCase: state.isSingleKitCase ?? false,
    jobId: jobId ?? state.order.job_id ?? '',
    orderReferenceNumbers: state.order.reference_numbers,
    paradigm: state.order.paradigm,
    payment: getOrderPaymentType(state),
    quoteId: state.quote?.quote_id ?? '',
    referenceNumbers: state.referenceNumbers,
    totalPrice: calculateOrderTotal(state.order, state.quote).amount,
  }
}

export const getPageSlugByFlowId = (flowId: number, context: TPageFlowContext): string => {
  const slug = context.allPages?.find(page => page.flow_id === flowId)?.path || ''
  return slug || context.allPages[0]?.path || ORDER_ROUTES.selectProgram
}

const buildOrderWizardRoute = (newSlug: string, context: TPageFlowContext) => {
  return generatePath(`${ROUTES.editOrder}${newSlug}`, {
    orderId: context.quoteId ?? undefined,
  })
}

export const getNextPageNavData = (state: OrderState, jobId?: string): TOrderWizardNavData => {
  const context = getNavContext(state, jobId)
  const flowId = getPageFlowId(PageFlow.Next, context)
  const slug = getPageSlugByFlowId(flowId, context)
  const path = buildOrderWizardRoute(slug, context)
  return { flowId, path, slug }
}

export const getPrevPageNavData = (state: OrderState, jobId?: string): TOrderWizardNavData => {
  const context = getNavContext(state, jobId)
  const flowId = getPageFlowId(PageFlow.Prev, context)
  const slug = getPageSlugByFlowId(flowId, context)
  const path = buildOrderWizardRoute(slug, context)
  return { flowId, path, slug }
}

export const getPageNavDataByFlowId = (
  flowId: number,
  state: OrderState,
  jobId?: string,
): TOrderWizardNavData => {
  const context = getNavContext(state, jobId)
  const slug = getPageSlugByFlowId(flowId, context)
  const path = buildOrderWizardRoute(slug, context)
  return { flowId, path, slug }
}

/**
 * Return flow id given page slug.
 * WARN: Use this method only if you need to navigate directly to a specific
 * page in the flow, without going through normal Next/Prev steps.
 */
export const getPageNavDataByFlowSlug = (slug: string, state: OrderState): TOrderWizardNavData => {
  let flowId = 0
  let path = ''

  const page = state.order.paradigm?.pages?.find(page => page.path === slug)
  if (page) {
    flowId = page.flow_id
    const context = getNavContext(state)
    path = buildOrderWizardRoute(slug, context)
  }

  return { flowId, path, slug }
}

export const getInitialFlowId = (paradigm: Paradigm): number => {
  return paradigm.pages[0]?.flow_id ?? 0
}

export const isOrderReferencePageAtStart = (state: OrderState, jobId?: string): boolean => {
  // We expect Order Reference page to be listed twice: at the start and at the
  // end of the pages flow. Given currentFlowId we can determine if this is the
  // start or the end case.
  const context = getNavContext(state, jobId)
  const refPages = context.allPages.filter(page => page.path === ORDER_ROUTES.orderReference)
  return refPages.length === 0 ? false : context.currentFlowId === refPages[0].flow_id
}

const isLocalPickup: TPageRuleHandler = context => {
  return context.isLocalPickup || false
}

const isRAOrder: TPageRuleHandler = context => {
  return context.isRa || false
}

const isShipMyself: TPageRuleHandler = context => {
  // Ship myself is only applicable to RA orders
  return context.isRa && context.payment === PaymentType.ShipMyself
}

const isTermPayment: TPageRuleHandler = context => {
  return context.payment === PaymentType.Terms
}

const isZeroPrice: TPageRuleHandler = context => {
  return context.totalPrice === 0
}

const isCreditCardPayment: TPageRuleHandler = context => {
  return context.payment === PaymentType.CreditCard
}

const isInvoicePayment: TPageRuleHandler = context => {
  return context.payment === PaymentType.Invoice
}

const isProductParadigmRuleHandler: TPageRuleHandler = context => {
  return allowProducts(context.paradigm)
}

const showReferenceNumbersAtStart: TPageRuleHandler = context => {
  const hasPromptAtStart = context.referenceNumbers.some(refConfig => refConfig.prompt === 1)
  const hasNoPromptValues = context.referenceNumbers.some((refConfig, index) => {
    const value = context.orderReferenceNumbers ? context.orderReferenceNumbers[index] ?? '' : ''
    return refConfig.prompt === 0 && !!value
  })

  return hasPromptAtStart || hasNoPromptValues
}

const showReferenceNumbersAtEnd: TPageRuleHandler = context => {
  return context.referenceNumbers.some(refConfig => refConfig.prompt === 2)
}

const hasExtraLabels: TPageRuleHandler = context => {
  return context.hasExtraLabels
}

const addAssetDetails: TPageRuleHandler = context => {
  return context.addAssetDetails
}

const defaultHandler: TPageRuleHandler = () => {
  return true
}

const isSingleKit: TPageRuleHandler = context => {
  return !!sessionStorage.getItem('isSingleKit') || context.isSingleKitCase
}

const hasLessThan10Assets: TPageRuleHandler = context => {
  return context.assetsCount < 10
}

const showCommentsPage: TPageRuleHandler = context => {
  return !isSingleKit(context)
}

const customQuoteRequired: TPageRuleHandler = context => {
  return context.customQuoteRequired
}

const TOrderRulesHandlers: TPageRuleHandlersMap = {
  DEFAULT: defaultHandler,
  addAssetDetails,
  customQuoteRequired,
  hasExtraLabels,
  hasLessThan10Assets,
  isCreditCardPayment,
  isInvoicePayment,
  isLocalPickup,
  isProductParadigm: isProductParadigmRuleHandler,
  isRAOrder,
  isShipMyself,
  isSingleKit,
  isTermPayment,
  isZeroPrice,
  showCommentsPage,
  showReferenceNumbersAtEnd,
  showReferenceNumbersAtStart,
}

export const getPageInfo = (orderState: OrderState, page: string): TOrderPage | undefined => {
  return orderState.order.paradigm?.pages?.find(p => p.path === page)
}

export const getPageDescription = (
  orderState: OrderState,
  page: string,
  defaultDescription: string,
): string => {
  return getPageInfo(orderState, page)?.description || defaultDescription
}

export const getSelectedInboundPriceId = (quote: Quote): string | undefined => {
  // Expect that at least one of the prices will be pre-selected. In case it is
  // not pre-selected - pick first available.
  return (
    quote.prices?.find(price => price.selected === 1)?.price_id ||
    (quote.prices && quote.prices?.length > 0 ? quote.prices[0].price_id : undefined)
  )
}

export const getSelectedOutboundPriceId = (quote: Quote): string | undefined => {
  // Expect that at least one of the prices will be pre-selected. In case it is
  // not pre-selected - pick first available.
  return (
    quote.product_prices?.find(price => price.selected === 2)?.price_id ||
    (quote.product_prices && quote.product_prices.length > 0
      ? quote.product_prices[0].price_id
      : undefined)
  )
}

export const getOrderEmailAddressesByType = (emails: Email[], type: EmailType): string[] => {
  return (
    emails
      ?.find(e => e.type === type)
      ?.email_addresses.filter(e => e.is_checked && e.email.length > 0)
      ?.map(e => e.email) ?? []
  )
}

export const mapApiToUIEmails = (emails: TEmailResponse[]): Email[] => {
  return emails.map(item => ({
    email_addresses:
      item.email_addresses.map(el => ({
        email: el,
        is_checked: true,
      })) ?? [],
    label: item.label,
    type: item.type,
  }))
}

export const onOrderComplete = async (
  orderState: OrderState,
  flowId: number,
  sessionUserEmail: string,
): Promise<string> => {
  const { order, quote } = orderState
  const jobResult = await addJob({
    flow_id: flowId,
    price_id: Number(order.inboundPriceId ?? 0),
    product_price_id: Number(order.outboundPriceId ?? 0),
    quote_id: Number(quote.quote_id),
  })

  if (quote.promo_code) {
    await mixpanelTrack(MixpanelEvents.ORDER_CREATED_WITH_PROMO, {
      jobId: jobResult.job_id,
      promoCode: quote.promo_code,
      userEmail: sessionUserEmail,
    })

    TagManager.dataLayer({
      dataLayer: {
        event: 'OrderWithPromoCreated',
        promo_code: quote.promo_code,
        user_name: sessionUserEmail,
      },
    })
  }

  return jobResult.job_id
}
