import { ColumnName } from 'types'

export enum GridType {
  DSP = 0,
  EDP = 1,
  EDP_LABEL = 2,
  LP = 3,
  RM = 4,
  None = 5,
}
type MinimalRowType = {
  [ColumnName.dspEditModal]?: unknown
  [ColumnName.edpEditModal]?: unknown
  [ColumnName.edpLabelEditModal]?: unknown
  [ColumnName.lpEditModal]?: unknown
  [ColumnName.rmEditModal]?: unknown
  [key: string]: unknown
}
export const gridType = (row: MinimalRowType): number => {
  if (row[ColumnName.dspEditModal] !== undefined) {
    return GridType.DSP
  } else if (row[ColumnName.edpEditModal] !== undefined) {
    return GridType.EDP
  } else if (row[ColumnName.edpLabelEditModal] !== undefined) {
    return GridType.EDP_LABEL
  } else if (row[ColumnName.lpEditModal] !== undefined) {
    return GridType.LP
  } else if (row[ColumnName.rmEditModal] !== undefined) {
    return GridType.RM
  }
  return GridType.None
}
