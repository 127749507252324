import * as React from 'react'
import classNames from 'classnames'
import ReactSelect, { createFilter } from 'react-select'

import type { TSelectProps } from '.'
import styles from './Select.module.scss'

const customFilter = createFilter({
  ignoreAccents: false,
  ignoreCase: true,
  matchFrom: 'start',
  trim: true,
})

export const SearchableSelect = <ValueType,>({
  ariaLabel,
  disabled,
  error,
  floatingLabel = true,
  id,
  label,
  onChange,
  openAbove,
  options,
  value,
}: TSelectProps<ValueType>) => {
  const selected = React.useMemo(
    () => options.find(option => option.value === value),
    [options, value],
  )
  const [isFocused, setIsFocused] = React.useState(false)
  const shouldOutline = React.useMemo(() => !!selected || isFocused, [selected, isFocused])

  return (
    <div className={styles.Container}>
      <ReactSelect
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        onMenuOpen={() => setIsFocused(true)}
        styles={{
          container: baseStyles => ({ ...baseStyles, zIndex: 2 }),
          control: baseStyles => ({
            ...baseStyles,
            ':hover': { cursor: 'text' },
            background: 'transparent',
            border: 'none',
            boxShadow: 'none',
            minHeight: 0,
          }),
          indicatorSeparator: () => ({ display: 'none' }),
          input: baseStyles => ({ ...baseStyles, border: 'none' }),
          menu: baseStyles => ({ ...baseStyles }),
        }}
        id={id}
        options={options}
        placeholder={null}
        isDisabled={disabled}
        value={selected}
        onChange={v => onChange?.((v?.value ?? '') as ValueType)}
        inputId="test"
        aria-label={ariaLabel ?? label}
        filterOption={customFilter}
      />
      <div
        aria-label={ariaLabel ?? label}
        className={classNames(styles.SelectTrigger, {
          [styles.FloatingLabel]: floatingLabel,
          [styles.Invalid]: error,
          [styles.Open]: open,
          [styles.OpenAbove]: openAbove,
        })}
        id={id}
        style={{ left: 0, position: 'absolute', top: 0 }}
      >
        <div className={classNames(styles.Notch, { [styles.HasValue]: shouldOutline })}>
          <div className={styles.NotchBefore}></div>
          <div className={styles.NotchBetween}>
            <label>{label}</label>
          </div>
          <div className={styles.NotchAfter}></div>
        </div>
      </div>

      {error && (
        <div className={styles.ErrorText} style={{ marginTop: '0.5rem' }}>
          {error}
        </div>
      )}
    </div>
  )
}
