import classNames from 'classnames'

import { SearchForm } from './components/SearchForm'
import styles from './SearchBar.module.scss'

export type Props = {
  onAdvancedSearchClick: () => void
  onClear: () => void
  onSearch: (searchTerm: string) => void
  show: boolean
}

export const SearchBar = ({
  onAdvancedSearchClick,
  onClear,
  onSearch,
  show,
}: Props): JSX.Element => (
  <div
    aria-hidden={!show}
    className={classNames(styles.SearchBarContainer, { [styles.Hidden]: !show })}
  >
    <div className={styles.SearchBar}>
      <SearchForm onSubmit={values => onSearch(values.searchTerm)} />

      <button onClick={onAdvancedSearchClick}>Advanced Search</button>
      <button onClick={onClear}>Clear</button>
    </div>
  </div>
)
