import { isValid, parse } from 'date-fns'
import { useField } from 'formik'
import { ReactDatePickerProps } from 'react-datepicker'

import { LocalizedDatePicker } from 'components/LocalizedDatePicker'
import { ISO_DATE_FORMAT, maybeDateToString } from 'utils/date'
import { pickFirstDateFromList } from 'utils/date/pickFirstDateFromList'
import { isValidDateObject } from 'components/OrderReviewCard/helpers'

type Props = Partial<ReactDatePickerProps> & {
  hideIcon?: boolean
  name: string
}

export const FormikDatePicker = ({ id, name, onChange, ...restProps }: Props): JSX.Element => {
  const [{ value, onBlur }, , { setValue }] = useField(name)
  const dateValue = new Date(value)
  const formatedDate = isValidDateObject(dateValue)
    ? parse(value as string, ISO_DATE_FORMAT, new Date())
    : pickFirstDateFromList(value)

  return (
    <LocalizedDatePicker
      className="form-control"
      id={id || name}
      name={name}
      onChange={(maybeDate, event) => {
        // maybeDate can represent a date range [from, to]
        // Current UI does not support date range, so if range is received, we
        // will pick and process the first date in the range
        const date = Array.isArray(maybeDate) ? (maybeDate[0] as Date) : maybeDate
        const newDateStr = maybeDateToString(date, ISO_DATE_FORMAT)
        if (!newDateStr) {
          void setValue(newDateStr)
        } else {
          const newDate = parse(newDateStr, ISO_DATE_FORMAT, new Date())
          isValid(newDate) && void setValue(newDateStr)
        }
        onChange?.(date, event)
      }}
      onBlur={onBlur}
      selected={formatedDate}
      {...restProps}
    />
  )
}
