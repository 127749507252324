import { CountryCode, parsePhoneNumberFromString } from 'libphonenumber-js'

import { TextField } from 'components/TextField'

type Props = {
  autocomplete?: string
  className?: string
  country: CountryCode
  disabled?: boolean
  label: string
  name: string
}

export const PhoneNumberField = ({
  autocomplete = 'new_phone',
  className,
  country,
  disabled,
  label,
  name,
}: Props): JSX.Element => (
  <TextField
    autoComplete={autocomplete}
    className={className}
    disabled={disabled}
    label={label}
    name={name}
    onBlur={({ field, form }) => {
      const parsedNumber = parsePhoneNumberFromString(field.value as string, country)

      if (parsedNumber && parsedNumber.isValid()) {
        void form.setFieldValue(name, parsedNumber.formatNational())
      }
    }}
    type="tel"
  />
)
