import { DataTypeProvider } from '@devexpress/dx-react-grid'
import { generatePath, Link } from 'react-router'

import { ColumnName, DynamicObject } from 'types'
import { getDataOnlyValue } from 'utils/getDataOnlyValue'
import { ROUTES } from 'Routes'

export interface Props {
  column: ColumnName
}

export const OrderDetailLinkProvider = ({ column }: Props): JSX.Element => {
  const LinkFormatter = ({ row }: DataTypeProvider.ValueFormatterProps): JSX.Element => {
    const orderId = String(getDataOnlyValue(row as DynamicObject, column))
    return <Link to={generatePath(ROUTES.orderDetails, { jobId: orderId })}>{orderId}</Link>
  }

  return <DataTypeProvider formatterComponent={LinkFormatter} for={[column]} />
}
