import React, { PropsWithChildren } from 'react'
import { Alert } from 'react-bootstrap'
import Col from 'react-bootstrap/Col'
import Modal from 'react-bootstrap/Modal'
import Row from 'react-bootstrap/Row'

import { Button } from 'components/Button'
import styles from '../../components/EditCancelModal/EditCancelModal.module.scss'
import { deleteQuote } from 'lib/api/quote'
import { Loader } from 'components/Loader'

export type QuoteDeleteProps = {
  closeModal: (deleted: boolean) => void
  quoteId: string
  quoteNumber: string
  show: boolean
}

export const QuoteDeleteModal = ({
  closeModal,
  quoteId,
  quoteNumber,
  show,
}: PropsWithChildren<QuoteDeleteProps>): JSX.Element => {
  const [isSubmitting, setIsSubmitting] = React.useState(false)
  const deleted = React.useRef(false)
  const [error, setError] = React.useState('')

  const localClose = () => {
    if (!isSubmitting) {
      setError('')
      closeModal(deleted.current)
    }
  }

  const onSubmit = async () => {
    setIsSubmitting(true)
    const response = await deleteQuote(Number(quoteId))
    setIsSubmitting(false)

    if (response.result === 'Success') {
      deleted.current = true
      localClose()
    } else {
      setError(response.error || '')
    }
  }

  return (
    <Modal onHide={localClose} size="lg" show={show}>
      <Modal.Header closeButton>
        <Modal.Title className="font-weight-bold">Delete Quote</Modal.Title>
      </Modal.Header>

      <Modal.Body className={styles.ModalBody}>
        <Row>
          <Col>
            Are you sure that you want to delete <strong>{quoteNumber}</strong>?
            {isSubmitting && <Loader />}
            {error && <Alert variant="danger">{error}</Alert>}
          </Col>
        </Row>
      </Modal.Body>

      <Modal.Footer className={styles.ModalFooter}>
        <Row className="justify-content-end w-100">
          <Col lg={3} xs={6}>
            <Button className="w-100" onClick={localClose} variant="outline-dark">
              Cancel
            </Button>
          </Col>
          <Col lg={3} xs={6}>
            <Button className="w-100" disabled={isSubmitting} onClick={() => void onSubmit()}>
              Delete
            </Button>
          </Col>
        </Row>
      </Modal.Footer>
    </Modal>
  )
}
