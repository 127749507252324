import { FC } from 'react'

import styles from './DynamicSearchInput.module.scss'
import { FilterInputType } from 'lib/api/setting'
import { NumberInput } from '../NumberInput'
import { DateInput } from '../DateInput'
import { StringInput } from '../StringInput'

export type Props = { filterInputType: FilterInputType; index: number }

export const DynamicInputType: FC<Props> = ({ filterInputType, index }) => {
  switch (filterInputType) {
    case FilterInputType.integer:
      return <NumberInput index={index} />
    case FilterInputType.date:
      return <DateInput index={index} />
    default:
      return <StringInput index={index} />
  }
}

export const DynamicSearchInput: FC<Props> = props => (
  <div className={styles.DynamicSearchInput} data-testid="dynamic-search-input">
    <DynamicInputType {...props} />
  </div>
)
