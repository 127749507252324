import { FC } from 'react'

import { TransparentButton } from 'components/TransparentButton'
import { resetView } from 'lib/api/gridConfig'
import { ConfirmationDialog } from 'components/ConfirmationDialog'
import { useModalState } from 'hooks/useModalState'
import { Grid } from 'types'

export type Props = {
  configId: number
  refresh: () => void
  savedViews: Grid[]
}

export const ResetView: FC<Props> = ({ configId, refresh, savedViews }) => {
  const { hide, open, state } = useModalState()
  const currentSavedViewName = savedViews.find(({ configId: viewId }) => viewId === configId)?.name
  return (
    <>
      <TransparentButton onClick={open}>Reset View</TransparentButton>
      <ConfirmationDialog
        disabled={false}
        onCancel={hide}
        onAccept={() =>
          void resetView({ configId })
            .catch(() => undefined)
            .finally(refresh)
        }
        title="Reset View"
        {...state}
      >
        {currentSavedViewName
          ? `Reset view "${currentSavedViewName}" to the default configuration?`
          : `Reset the current view to the default configuration?`}
      </ConfirmationDialog>
    </>
  )
}
