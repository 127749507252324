import { createContext, JSX, ReactNode, useCallback, useContext, useState } from 'react'
import { GuestPageResponse, GuestPageExtraData } from 'lib/api/types'
import { GuestQuote } from 'types'

type Props = {
  children: ReactNode
}

export type GuestOrderContextType = {
  backActionLoading: boolean
  guestPageExtraData: GuestPageExtraData | null
  guestPagePath: string | null
  guestQuote: GuestQuote | null
  setBackActionLoading: (loading: boolean) => void
  setGuestOrder: (assetsResponse: GuestPageResponse) => void
  setGuestPagePath: (path: string) => void
}

export const GuestOrderContext = createContext<GuestOrderContextType | undefined>(undefined)

export const GuestOrderProvider = ({ children }: Props): JSX.Element => {
  const [guestQuote, setGuestQuote] = useState<GuestQuote | null>(null)
  const [guestPageExtraData, setGuestPageExtraData] = useState<GuestPageExtraData | null>(null)
  const [guestPagePath, setGuestPagePath] = useState<string | null>(null)
  const [backActionLoading, setBackActionLoading] = useState(false)

  const setGuestOrder = useCallback((res: GuestPageResponse) => {
    setGuestQuote(res.quote)
    setGuestPageExtraData(res.page_extra_data || null)
    setGuestPagePath(res.page_path)
  }, [])

  return (
    <GuestOrderContext.Provider
      value={{
        backActionLoading,
        guestPageExtraData,
        guestPagePath,
        guestQuote,
        setBackActionLoading,
        setGuestOrder,
        setGuestPagePath,
      }}
    >
      {children}
    </GuestOrderContext.Provider>
  )
}

export const useGuestOrderContext = (): GuestOrderContextType => {
  const context = useContext(GuestOrderContext)

  if (context === undefined) {
    throw new Error('useGuestOrderContext must be used inside GuestOrderContext.Provider')
  }

  return context
}
