import * as React from 'react'

import { FilterType, NormalizedGridColumnType } from 'lib/api/setting'
import { useGridColumnTypes } from '../useGridColumnTypes'
import { useGridFilterTypes } from '../useGridFilterTypes'

export type GridColumnTypeMap = {
  [typeId: number]: NormalizedGridColumnType
}

export type GridFilterTypeMap = {
  [typeId: number]: FilterType
}

type ReturnType = {
  gridColumnTypeMap: GridColumnTypeMap
  gridFilterTypeMap: GridFilterTypeMap
  loading: boolean
}

export const useAdvancedSearchSettings = (): ReturnType => {
  const { gridColumnTypes, status: gridColumnTypesStatus } = useGridColumnTypes()
  const { gridFilterTypes, status: gridFilterTypesStatus } = useGridFilterTypes()

  const gridColumnTypeMap = React.useMemo(() => {
    if (gridColumnTypes) {
      return gridColumnTypes.reduce<GridColumnTypeMap>((map, columnType) => {
        map[columnType.typeId] = columnType
        return map
      }, {})
    }

    return {}
  }, [gridColumnTypes])

  const gridFilterTypeMap = React.useMemo(() => {
    if (gridFilterTypes) {
      return gridFilterTypes.reduce<GridFilterTypeMap>((map, filterType) => {
        map[filterType.typeId] = filterType
        return map
      }, {})
    }

    return {}
  }, [gridFilterTypes])

  return {
    gridColumnTypeMap,
    gridFilterTypeMap,
    loading: gridColumnTypesStatus === 'pending' || gridFilterTypesStatus === 'pending',
  }
}
