import React from 'react'
import classNames from 'classnames'

import styles from './PageOptions.module.scss'

interface Props {
  currentPage: number
  onCurrentPageChange: (page: number) => void
  pageSize: number
  totalCount: number
  totalPages: number
}

export const PageOptions: React.FC<Props> = ({
  currentPage,
  onCurrentPageChange,
  pageSize,
  totalCount,
  totalPages,
}) => {
  const pageOptions = []

  const startingIndex =
    currentPage > 2 ? (currentPage < totalPages - 3 ? currentPage - 1 : totalPages - 3) : 0
  const endingIndex =
    currentPage === totalPages - 1 ? totalPages - 1 : Math.min(startingIndex + 2, totalPages)

  for (let i = startingIndex; i <= endingIndex; i++) {
    const startingPageNumber = pageSize * i + 1
    const endingPageNumber = Math.min(pageSize * (i + 1), totalCount)
    const activePage = i === currentPage

    pageOptions.push(
      <button
        className={classNames(styles.PageOption, activePage ? styles.ActivePageOption : '')}
        key={startingPageNumber}
        onClick={() => {
          if (i !== currentPage) {
            onCurrentPageChange(i)
          }
        }}
        role="link"
      >
        [{startingPageNumber} - {endingPageNumber}]
      </button>,
    )
  }

  return (
    <div className={styles.PageOptions}>
      {currentPage > 0 && (
        <button
          className={classNames(styles.PageOption)}
          onClick={() => {
            onCurrentPageChange(currentPage - 1)
          }}
          role="link"
        >
          [Prev]
        </button>
      )}
      {currentPage > 2 && (
        <>
          <button
            className={classNames(styles.PageOption)}
            onClick={() => {
              onCurrentPageChange(0)
            }}
            role="link"
          >
            1
          </button>
          <span>...</span>
        </>
      )}
      {pageOptions}
      {currentPage < totalPages - 3 && (
        <>
          <span>...</span>
          <button
            className={classNames(styles.PageOption)}
            onClick={() => {
              onCurrentPageChange(totalPages - 1)
            }}
            role="link"
          >
            {totalPages}
          </button>
        </>
      )}
      {currentPage !== totalPages - 1 && (
        <button
          className={classNames(styles.PageOption)}
          onClick={() => {
            onCurrentPageChange(currentPage + 1)
          }}
          role="link"
        >
          [Next]
        </button>
      )}
    </div>
  )
}
