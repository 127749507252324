import * as React from 'react'

import { getRedirection, TRedirectInfo } from 'lib/api/user'
import { Status, useAsync } from 'hooks/useAsync'

type ReturnType = {
  redirectInfo: TRedirectInfo | null
  status: Status
}

export const useRedirectInfo = (uniqueId: string): ReturnType => {
  const { data: redirectInfo, run, status } = useAsync<TRedirectInfo>()

  React.useEffect(() => {
    run(getRedirection(uniqueId))
  }, [run, uniqueId])

  return {
    redirectInfo,
    status,
  }
}
