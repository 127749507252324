import * as React from 'react'
import classNames from 'classnames'
import { Listbox } from '@headlessui/react'

import { TSelectOption, TSelectProps } from '.'
import styles from './Select.module.scss'
import DownCaretIcon from 'images/CaretDownFilled.svg'

export const NonSearchableSelect = <ValueType,>({
  ariaLabel,
  disabled,
  error,
  floatingLabel = true,
  id,
  label,
  multiple,
  onBlur,
  onChange,
  openAbove,
  options,
  value,
}: TSelectProps<ValueType>) => {
  const selected = React.useMemo(() => {
    return multiple
      ? (value as TSelectOption<ValueType>[])
      : options.find(option => option.value === value)
  }, [multiple, options, value])

  return (
    <React.Fragment>
      <Listbox disabled={disabled} onChange={onChange} value={value} multiple={multiple}>
        {({ open }) => (
          <div className={styles.SelectContainer}>
            <Listbox.Button
              aria-label={ariaLabel ?? label}
              className={classNames(styles.SelectTrigger, {
                [styles.FloatingLabel]: floatingLabel,
                [styles.Invalid]: error,
                [styles.Open]: open,
                [styles.OpenAbove]: openAbove,
                [styles.HasValue]: Array.isArray(selected)
                  ? !!selected.length
                  : selected !== undefined,
              })}
              id={id}
              onBlur={onBlur}
              role="combobox"
            >
              <div
                className={classNames(styles.Notch, {
                  [styles.HasValue]: selected !== undefined,
                })}
              >
                <div className={styles.NotchBefore}></div>
                <div className={styles.NotchBetween}>
                  <label>{label}</label>
                </div>
                <div className={styles.NotchAfter}></div>
              </div>
              <div
                className={classNames(
                  styles.SelectedValue,
                  'd-flex align-items-center justify-content-between',
                )}
              >
                <span>{multiple ? label : (selected as TSelectOption<ValueType>)?.label}</span>
                <img alt="Down facing caret" src={DownCaretIcon} />
              </div>
            </Listbox.Button>

            <Listbox.Options
              className={classNames(styles.SelectOptions, { [styles.OpenAbove]: openAbove })}
            >
              {options.map((option, index) => (
                <Listbox.Option key={String(option.value) + String(index)} value={option.value}>
                  {({ active, selected: isSelected }) => (
                    <div
                      className={classNames(styles.SelectOption, {
                        [styles.Active]: active,
                        [styles.Selected]: isSelected,
                      })}
                    >
                      {option.label}
                    </div>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </div>
        )}
      </Listbox>
      {error && <div className={styles.ErrorText}>{error}</div>}
    </React.Fragment>
  )
}
