import { FC, useEffect, useState } from 'react'

import { ApproveOrderModal } from './ApproveOrderModal'
import { getJob } from 'lib/api/job/get'
import { OrderResponse } from 'types'
import { useLoggedInAppContext } from 'hooks/useLoggedInAppContext'
import { useStaticData } from 'contexts/StaticDataContext'

import * as helpers from './helpers'

interface Props {
  closeModal: () => void
  jobId: string
  refreshData: () => void
  show: boolean
}

export const ApproveOrderModalContainer: FC<Props> = ({ closeModal, jobId, refreshData, show }) => {
  const {
    user: { referenceNumbers, token },
  } = useLoggedInAppContext()
  const { stateOptions } = useStaticData()

  const [loadingOrder, setLoadingOrder] = useState(false)
  const [order, setOrder] = useState<OrderResponse | null>(null)

  // Fetch the order information
  useEffect(() => {
    if (!show) {
      return
    }

    let mounted = true
    const getOrder = async () => {
      setLoadingOrder(true)

      try {
        const data = await getJob(jobId, token)
        if (mounted) {
          setOrder(data)
        }
      } finally {
        if (mounted) {
          setLoadingOrder(false)
        }
      }
    }

    void getOrder()

    return () => {
      mounted = false
    }
  }, [jobId, show, token])

  return (
    <ApproveOrderModal
      closeModal={closeModal}
      initialValues={{
        assets:
          order?.assets_expected.map(asset => ({
            id: asset.asset_id,
            service_tag: asset.serial_number,
          })) || [],
        billto_info: {
          address1: order?.billto_info?.address1 || '',
          address2: order?.billto_info?.address2 || '',
          city: order?.billto_info?.city || '',
          company: order?.billto_info?.company || '',
          first_name: order?.billto_info?.first_name || '',
          last_name: order?.billto_info?.last_name || '',
          phone: order?.billto_info?.phone || '',
          postal_code: order?.billto_info?.postal_code || '',
          state_province: order?.billto_info?.state_province || '',
        },
        comment: '',
        reference1: order?.reference1 || '',
        reference2: order?.reference2 || '',
        reference3: order?.reference3 || '',
      }}
      jobId={jobId}
      loading={loadingOrder}
      onApprove={async values => {
        await helpers.handleApproveJob(jobId, values, closeModal, refreshData)
      }}
      onReject={async comment => {
        await helpers.handleRejectJob(jobId, comment, closeModal, refreshData)
      }}
      order={order}
      referenceNumbers={referenceNumbers}
      show={show}
      states={stateOptions}
    />
  )
}
