import React from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

import { Button } from 'components/Button'
import styles from './FormContainer.module.scss'
import classNames from 'classnames'

export type TPreviousPageHandler = () => void
export interface IFormContainerProps {
  backButtonText?: string
  buttons?: React.ReactNode
  children: React.ReactNode
  formError?: string
  fullWidth?: boolean
  isSubmitting: boolean
  isVersion2?: boolean
  onGoToPreviousPage?: TPreviousPageHandler
  restrictBackAction?: boolean
  submitButtonDisabled?: boolean
  submitButtonIcon?: {
    alt: string
    src: string
  }
  submitButtonText?: string
  title?: string
}

export const FormContainer = ({
  backButtonText = 'Back',
  buttons,
  children,
  formError,
  fullWidth,
  isSubmitting,
  isVersion2 = false,
  onGoToPreviousPage,
  restrictBackAction,
  submitButtonDisabled = false,
  submitButtonIcon,
  submitButtonText = 'Next',
  title,
}: IFormContainerProps): JSX.Element => {
  return (
    <div className={styles.FormContainer}>
      <div className={styles.Main}>
        <h2 className={classNames(styles.Title, { [styles.Version2]: isVersion2 })}>{title}</h2>

        <Row className={classNames('justify-content-center mb-4', styles.FormContainerContent)}>
          <Col sm={fullWidth ? 12 : 8}>{children}</Col>
        </Row>
      </div>

      {formError && (
        <Row className="mt-5">
          <Col className="text-center text-error">{formError}</Col>
        </Row>
      )}

      <Row className={styles.ButtonRow}>
        {buttons ? (
          buttons
        ) : (
          <>
            {onGoToPreviousPage && !restrictBackAction && (
              <Col xs={6} md={4} className={styles.ButtonCol}>
                <Button
                  ariaLabel="Back"
                  className={classNames(styles.Button, styles.BackButton)}
                  variant="outline-dark"
                  onClick={onGoToPreviousPage}
                  isVersion2={isVersion2}
                >
                  {backButtonText}
                </Button>
              </Col>
            )}
            <Col xs={6} md={4} className={styles.ButtonCol}>
              <Button
                ariaLabel={submitButtonText}
                className={classNames(styles.Button, styles.SubmitButton)}
                disabled={submitButtonDisabled}
                isVersion2={isVersion2}
                loading={isSubmitting}
                type="submit"
              >
                {submitButtonIcon ? (
                  <img
                    alt={submitButtonIcon.alt}
                    className={styles.SubmitButtonIcon}
                    src={submitButtonIcon.src}
                  />
                ) : null}
                {submitButtonText}
              </Button>
            </Col>
          </>
        )}
      </Row>
    </div>
  )
}
