import { FC } from 'react'

import { useModalState } from 'hooks/useModalState'
import { TransparentButton } from 'components/TransparentButton'
import { AddSavedViewModal } from 'components/GridView/AddSavedViewModal'
import { GridDispatch } from '../state'
import { Grid } from 'types'

export type Props = {
  configId: number
  dispatch: GridDispatch
  savedViews: Grid[]
}
export const AddSavedView: FC<Props> = props => {
  const { hide, open, state } = useModalState()
  return (
    <>
      <TransparentButton onClick={open}>Save as New View</TransparentButton>
      <AddSavedViewModal hide={hide} {...props} {...state} />
    </>
  )
}
