import React from 'react'
import FormCheck, { FormCheckProps } from 'react-bootstrap/FormCheck'
import styles from './RadioGroup.module.scss'

export const Radio: React.FC<FormCheckProps> = props => (
  <FormCheck
    {...props}
    id={props.id && props.value ? `${props.id}-${String(props.value)}` : props.id}
    type="radio"
    role="radio"
    className={`${styles.SelectionOption} ${props.className ?? ''}`}
  />
)
