import { api } from 'lib/api/base'
import { ENDPOINTS } from 'lib/api/endpoints'
import { BaseGridConfigResponse } from '../'

export type ColumnVisibility = {
  columnId: number
  visible: boolean
}

type Params = {
  columns: ColumnVisibility[]
  configId: number
}

type UpdateColumnVisibility = (params: Params) => Promise<number>

export const updateColumnVisibility: UpdateColumnVisibility = async ({ columns, configId }) => {
  const {
    data: { config_id },
  } = await api.post<BaseGridConfigResponse>(ENDPOINTS.gridConfigs, {
    action: 'update_column_visibility',
    columns: columns.map(({ columnId, visible }) => ({
      column_id: columnId,
      visible,
    })),
    config_id: configId,
  })

  return Number(config_id)
}
