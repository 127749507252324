import classNames from 'classnames'
import { generatePath, Link } from 'react-router'

import { EditIcon } from 'components/EditIcon'
import EyeIcon from 'images/Eye.svg'
import { TrashIcon } from 'components/TrashIcon'
import { ROUTES } from 'Routes'

import styles from './OrderActions.module.scss'

export type Props = {
  jobId: string
  onCancelOrder: () => void
  onEditOrder: () => void
  show: boolean
}

export const OrderActions = ({ jobId, onCancelOrder, onEditOrder, show }: Props): JSX.Element => (
  <div className={classNames(styles.OrderActions, { [styles.Active]: show })}>
    <button
      aria-label="Edit order"
      onClick={e => {
        e.stopPropagation()
        onEditOrder()
      }}
    >
      <EditIcon />
    </button>

    <button
      aria-label="Cancel order"
      onClick={e => {
        e.stopPropagation()
        onCancelOrder()
      }}
    >
      <TrashIcon />
    </button>

    <Link aria-label="View order details" to={generatePath(ROUTES.orderDetails, { jobId })}>
      <img alt="Eye" src={EyeIcon} />
    </Link>
  </div>
)
