import sortBy from 'lodash/sortBy'

import { init } from '../init'
import { GridState, Action, ActionTypes } from '../types'
import { ColumnName } from 'types'
import { emptyFilters } from '../constants'

type Reducer = (state: GridState, action: Action) => GridState
export const reducer: Reducer = (state, action) => {
  switch (action.type) {
    case ActionTypes.SetConfigId:
      return {
        ...state,
        configId: action.payload || state.baseConfigId,
      }

    case ActionTypes.DeleteSavedView:
      return {
        ...state,
        configId: state.configId === action.payload ? state.baseConfigId : state.configId,
        savedViewsCache: {
          ...state.savedViewsCache,
          [state.baseConfigId]: state.savedViewsCache[state.baseConfigId]?.filter(
            ({ configId }) => configId !== action.payload,
          ),
        },
      }
    case ActionTypes.ClearSavedViews:
      return {
        ...state,
        configId: action.payload,
        savedViewsCache: {
          ...state.savedViewsCache,
          [state.baseConfigId]: undefined,
        },
      }
    case ActionTypes.UpdateSavedViewsCache:
      return {
        ...state,
        savedViewsCache: {
          ...state.savedViewsCache,
          [state.baseConfigId]: action.payload,
        },
      }

    case ActionTypes.SetCurrentPage:
      return {
        ...state,
        currentPage: action.payload,
      }

    case ActionTypes.SetLoading:
      return {
        ...state,
        loading: action.payload ? state.loading + 1 : state.loading - 1,
      }

    case ActionTypes.SetRecordsCount:
      return {
        ...state,
        recordsCount: action.payload,
      }

    case ActionTypes.SetPageSize:
      return {
        ...state,
        pageSize: action.payload,
      }

    case ActionTypes.SetPageSizeOptions:
      return {
        ...state,
        pageSizeOptions: action.payload,
      }

    case ActionTypes.SetGridConfigs:
      return {
        ...state,
        gridConfigs: action.payload,
      }

    case ActionTypes.SetGridDataRows:
      return {
        ...state,
        gridConfigs: sortBy(state.gridConfigs, 'sequence'),
        gridDataRows: action.payload,
      }

    case ActionTypes.SetSorting:
      return {
        ...state,
        sorting: action.payload,
      }

    case ActionTypes.SetFilters:
      return {
        ...state,
        filters: action.payload || emptyFilters,
      }

    case ActionTypes.DigestGridData:
      return {
        ...state,
        configId: action.payload.configId,
        currentPage: action.payload.page,
        gridConfigs: sortBy(state.gridConfigs, 'sequence'),
        gridDataRows: action.payload.rows,
        recordsCount: action.payload.records,
      }

    case ActionTypes.DigestGridConfig:
      return {
        ...state,
        currentPage: 0,
        customization: action.payload.customization,
        filterColumnId: action.payload.filterColumnId,
        filters: action.payload.filters,
        fixedColumns: action.payload.fixedColumns,
        gridConfigs: action.payload.configs,
        name: action.payload.name,
        pageSize: action.payload.perPage,
        pageSizeOptions: action.payload.perPageOptions,
        plmDownload: action.payload.configs.some(
          ({ name }) => name === ColumnName.downloadDispatch,
        ),
        showAdvancedSearch: action.payload.filters.length > 0 ? true : state.showAdvancedSearch,
        sorting: [
          {
            columnName: action.payload.sortField,
            direction: action.payload.sortDir,
          },
        ],
      }

    case ActionTypes.RefreshLastUpdated:
      return {
        ...state,
        refresh: {
          ...state.refresh,
          lastUpdated: Date.now(),
        },
      }

    case ActionTypes.RefreshGridConfig:
      return {
        ...state,
        refresh: {
          ...state.refresh,
          gridConfigLastUpdated: Date.now(),
        },
      }

    case ActionTypes.ToggleAdvancedSearch:
      return {
        ...state,
        forceHideAdvancedSearch: false,
        showAdvancedSearch: state.forceHideAdvancedSearch ? true : !state.showAdvancedSearch,
      }

    case ActionTypes.Reset:
      return init({ baseConfigId: action.payload, savedViewsCache: state.savedViewsCache })
  }
}
