import * as React from 'react'

import { Address } from 'types'

import * as helpers from './helpers'

const AUTOCOMPLETE_EVENT = 'place_changed'
const ENTER_KEY = 'Enter'

type ReturnType = {
  address2Ref: React.RefObject<HTMLInputElement>
  setAddressInputElement: (el: HTMLInputElement | null) => void
}

export const useAddressAutocomplete = (callback: (address: Address) => void): ReturnType => {
  const [addressInputElement, setAddressInputElement] = React.useState<HTMLInputElement | null>(
    null,
  )
  const address2Ref = React.useRef<HTMLInputElement>(null)
  const autoCompleteRef = React.useRef<google.maps.places.Autocomplete>()

  const setAddressInputElementWithCheck = React.useCallback(
    (el: HTMLInputElement | null) => {
      if (addressInputElement !== el) setAddressInputElement(el)
    },
    [addressInputElement, setAddressInputElement],
  )

  React.useEffect(() => {
    if (addressInputElement && window.google) {
      autoCompleteRef.current = new window.google.maps.places.Autocomplete(addressInputElement)

      google.maps.event.addDomListener(addressInputElement, 'keydown', (event: any) => {
        if ((event as KeyboardEvent).key === ENTER_KEY) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-call
          event.preventDefault()
        }
      })
    }
  }, [addressInputElement])

  React.useEffect(() => {
    const autoComplete = autoCompleteRef.current

    if (autoComplete !== undefined) {
      google.maps.event.clearListeners(autoComplete, AUTOCOMPLETE_EVENT)
      autoComplete.addListener(AUTOCOMPLETE_EVENT, () => {
        const place = autoComplete.getPlace()
        const addressComponents = place.address_components || []
        const updatedAddress = helpers.parseFromAddressComponents(addressComponents)

        callback(updatedAddress)

        if (address2Ref.current) {
          address2Ref.current.focus()
        }
      })
    }
  }, [callback])

  React.useEffect(() => {
    setTimeout(() => {
      if (addressInputElement) {
        addressInputElement.setAttribute('autocomplete', 'no')
      }
    }, 100)
  }, [addressInputElement])

  return {
    address2Ref,
    setAddressInputElement: setAddressInputElementWithCheck,
  }
}
