import * as yup from 'yup'
import { validatePhone } from 'utils/validatePhone'
import { REQUIRED_FIELD_ERROR_MESSAGE } from 'utils/commonUtils'
import { validateEmail } from 'utils/validateEmail'

export const ContactSchema = (contactRequired: boolean) => {
  let schema = yup.object().shape({
    email: yup.string().test('email', 'Invalid email address', validateEmail).nullable(),
    first_name: yup.string().nullable(),
    last_name: yup.string().nullable(),
    phone: yup
      .string()
      .test('phone', 'Invalid phone number', value => {
        return validatePhone({ phone: value ?? '' })
      })
      .nullable(),
  })

  if (contactRequired) {
    schema = schema.shape({
      email: yup
        .string()
        .test('email', 'Invalid email address', validateEmail)
        .when('emailRequired', {
          is: true,
          then: (schema: yup.StringSchema) => schema.required(REQUIRED_FIELD_ERROR_MESSAGE),
        }),
      emailRequired: yup.boolean(),
      first_name: yup.string().required(REQUIRED_FIELD_ERROR_MESSAGE),
      last_name: yup.string().required(REQUIRED_FIELD_ERROR_MESSAGE),
      phone: yup
        .string()
        .test('phone', 'Invalid phone number', value => {
          return validatePhone({ phone: value ?? '' })
        })
        .required(REQUIRED_FIELD_ERROR_MESSAGE),
    })
  }

  return schema
}
