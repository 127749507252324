import * as yup from 'yup'
import { validateEmail } from 'utils/validateEmail'

export const ExportRecordsSchema = yup.object().shape({
  bccEmail: yup.string().when('emailReport', {
    is: true,
    otherwise: schema => schema.notRequired(),
    then: schema => schema.test('email', 'Not a valid email address', validateEmail),
  }),
  ccEmail: yup.string().when('emailReport', {
    is: true,
    otherwise: schema => schema.notRequired(),
    then: schema => schema.test('email', 'Not a valid email address', validateEmail),
  }),
  emailReport: yup.boolean(),
  fileName: yup.string().required('Report filename is required'),
  subject: yup.string().when('emailReport', {
    is: true,
    otherwise: schema => schema.notRequired(),
    then: schema => schema.required('Subject is required'),
  }),
  title: yup.string().required('Report title is required'),
  toEmail: yup.string().when('emailReport', {
    is: true,
    otherwise: schema => schema.notRequired(),
    then: schema =>
      schema
        .required('To Email is required')
        .test('email', 'Not a valid email address', validateEmail),
  }),
})
