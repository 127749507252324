import { ChangeEvent, ComponentPropsWithoutRef, FocusEvent, forwardRef } from 'react'
import { FieldValidator, useField } from 'formik'
import { FormControl } from 'react-bootstrap'

type FormControlElement = HTMLInputElement | HTMLTextAreaElement

type Props = ComponentPropsWithoutRef<typeof FormControl> & {
  name: string
  onBlur?: (value: string) => void
  pattern?: RegExp
  validate: FieldValidator
}

export const FormikFieldAutoTrim = forwardRef<FormControlElement, Props>(
  ({ as, name, pattern, validate, ...restProps }, forwardedRef) => {
    const [field, , { setValue }] = useField({ name, validate })

    if (as && as !== 'textarea' && as !== 'input') {
      throw new Error(
        'FormikFieldAutoTrim component is only for use with text inputs and textareas',
      )
    }

    return (
      <FormControl
        {...field}
        {...restProps}
        as={as}
        ref={forwardedRef}
        onBlur={(event: FocusEvent<FormControlElement>) => {
          const trimmed =
            typeof event.target.value === 'string' ? event.target.value.trim() : event.target.value
          void setValue(trimmed)

          if (restProps.onBlur) {
            restProps.onBlur(trimmed)
          }

          field.onBlur(event)
        }}
        onChange={(event: ChangeEvent<FormControlElement>) => {
          const value = event.target.value
          if (!pattern || pattern.exec(value) !== null || value.length === 0) {
            field.onChange(event)
          }
        }}
      />
    )
  },
)

FormikFieldAutoTrim.displayName = 'FormikFieldAutoTrim'
