import { FC } from 'react'
import { TableHeaderRow } from '@devexpress/dx-react-grid-bootstrap4'

import styles from './SortLabel.module.scss'
import { TransparentButton } from 'components/TransparentButton'

const downTriangle = '\u25BC'
const upTriangle = '\u25B2'
export const SortLabel: FC<TableHeaderRow.SortLabelProps> = ({
  children,
  direction,
  disabled,
  onSort,
}) =>
  disabled ? (
    <>{children}</>
  ) : (
    <TransparentButton className={styles.TableHeaderBold} onClick={onSort as unknown as () => void}>
      {direction && <span>{direction === 'asc' ? upTriangle : downTriangle}</span>}
      {children}
    </TransparentButton>
  )
