import { FC, ReactNode, ReactText, useCallback, useMemo, useState } from 'react'
import {
  DragDropProvider,
  Grid,
  PagingPanel,
  Table,
  TableColumnReordering,
  TableColumnResizing,
  TableColumnVisibility,
  TableFixedColumns,
  TableHeaderRow,
  TableSelection,
} from '@devexpress/dx-react-grid-bootstrap4'
import {
  CustomPaging,
  DataTypeProvider,
  DataTypeProviderProps,
  PagingState,
  SelectionState,
  Sorting,
  SortingState,
} from '@devexpress/dx-react-grid'
import classnames from 'classnames'

import styles from './DevExpressGrid.module.scss'
import './DevExpressGrid.scss'
import { TooltipFormatter } from './TooltipFormatter'
import { MetaColumn, ColumnExtension } from 'types'
import { SortLabel } from './SortLabel'
import { PagingPanelContainer } from './PagingPanel'

const RootComponent: FC<Grid.RootProps> = props => <Grid.Root {...props} style={{ minHeight: 0 }} />

const CellComponent: FC<Table.DataCellProps & { style: Record<string, unknown> }> = ({
  ...restProps
}) => {
  return (
    <Table.Cell
      {...restProps}
      style={{
        ...restProps.style,
        backgroundColor: 'inherit',
        boxSizing: 'content-box',
        height: '1rem',
      }}
    />
  )
}

const CellTooltip: FC<Pick<DataTypeProviderProps, 'for'>> = props => (
  <DataTypeProvider {...props} formatterComponent={TooltipFormatter} />
)

interface MetaContentProps extends TableHeaderRow.ContentProps {
  column: MetaColumn
}

const HeaderContent: FC<MetaContentProps> = props => {
  const headingAlignment = props.column.headingAlignment
  return (
    <TableHeaderRow.Content
      {...props}
      align={headingAlignment}
      className={classnames({
        [styles.HeaderContentJustifyLeft]: headingAlignment === 'left',
      })}
      key={props.column.name}
    />
  )
}

interface Props {
  cellFormatters?: ReactNode
  columnExtensions: ColumnExtension[]
  columnOrder: string[]
  columnWidths: { columnName: string; width: number }[]
  columns: readonly MetaColumn[]
  currentPage: number
  hiddenColumnNames: string[]
  leftColumns: string[]
  pageSize: number
  pageSizeOptions: number[]
  recordsCount: number
  rows: readonly Record<string, unknown>[]
  setColumnOrder?: (keys: string[]) => void
  setColumnWidths?: (columnWidths: { columnName: string; width: number | string }[]) => void
  setCurrentPage: (page: number) => void
  setPageSize: (pageSize: number) => void
  setSorting: (sorting: Sorting[]) => void
  sorting: Sorting[]
}

export const DevExpressGrid: FC<Props> = ({
  cellFormatters,
  rows,
  columnWidths,
  setColumnWidths,
  columns,
  columnExtensions,
  columnOrder,
  setColumnOrder,
  hiddenColumnNames,
  leftColumns,
  currentPage,
  setCurrentPage,
  pageSize,
  setPageSize,
  recordsCount,
  pageSizeOptions,
  setSorting,
  sorting,
}) => {
  const [selection, setSelection] = useState<ReactText[]>([])
  const useSingleSelection = useCallback((selection: ReactText[]) => {
    const newSelections = selection.slice(-1)
    setSelection(newSelections)
  }, [])
  const setSingleSorting = useCallback(
    (sorting: Sorting[]) => {
      setSorting(sorting.slice(-1))
    },
    [setSorting],
  )

  const toolTipCols = useMemo(() => {
    return columns.filter(({ tooltip }) => tooltip).map(({ name }) => name)
  }, [columns])

  return (
    <div className={styles.Container}>
      <Grid rows={rows} columns={columns} rootComponent={RootComponent}>
        <SelectionState selection={selection} onSelectionChange={useSingleSelection} />
        <PagingState
          currentPage={currentPage}
          onCurrentPageChange={setCurrentPage}
          pageSize={pageSize}
          onPageSizeChange={setPageSize}
        />
        <CustomPaging totalCount={recordsCount} />
        <SortingState
          columnExtensions={columnExtensions}
          onSortingChange={setSingleSorting}
          sorting={sorting}
        />
        <CellTooltip for={toolTipCols} />
        {cellFormatters}
        <DragDropProvider />
        <Table
          columnExtensions={columnExtensions}
          cellComponent={CellComponent as FC<Table.DataCellProps>}
        />
        {setColumnWidths && (
          <TableColumnResizing columnWidths={columnWidths} onColumnWidthsChange={setColumnWidths} />
        )}
        <TableHeaderRow
          contentComponent={HeaderContent as FC<TableHeaderRow.ContentProps>}
          showSortingControls
          sortLabelComponent={SortLabel}
        />
        <TableSelection selectByRowClick highlightRow showSelectionColumn={false} />
        {setColumnOrder && (
          <TableColumnReordering order={columnOrder} onOrderChange={setColumnOrder} />
        )}
        <TableColumnVisibility hiddenColumnNames={hiddenColumnNames} />
        <TableFixedColumns leftColumns={leftColumns} />
        <PagingPanel containerComponent={PagingPanelContainer} pageSizes={pageSizeOptions} />
      </Grid>
    </div>
  )
}
