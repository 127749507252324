import { FC } from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { DataTypeProvider, DataTypeProviderProps } from '@devexpress/dx-react-grid'

import { GridCommentTextDisplay } from 'components/GridCommentTextDisplay'
import { MoreCommentModalState } from 'components/GridView/types'
import { ColumnName, CommentType, DynamicObject } from 'types'
import { getDataOnlyValue } from 'utils/getDataOnlyValue'
import { gridType, GridType } from 'utils/grid'

type MoreCommentModalFactory = (value: MoreCommentModalState) => () => void
export type Params = {
  columnNames: ColumnName[]
  commentCountColumn?: ColumnName
  commentType?: CommentType
  openModalFactory: MoreCommentModalFactory
}

export const moreCommentsFormatter = ({
  columnNames,
  commentCountColumn = ColumnName.commentCount,
  commentType,
  openModalFactory,
}: Params): ((props: Partial<DataTypeProviderProps>) => JSX.Element) => {
  const MoreCommentsFormatter: FC<DataTypeProvider.ValueFormatterProps> = ({
    column,
    row,
    value,
  }) => {
    const tableRow = row as DynamicObject
    const jobId = String(getDataOnlyValue(tableRow, ColumnName.jobId))

    const typeOfGrid = gridType(tableRow)
    if (typeOfGrid === GridType.EDP_LABEL) {
      commentCountColumn = ColumnName.epCommentCount
    }

    const commentCount = Number(getDataOnlyValue(tableRow, commentCountColumn))
    if (!commentType) {
      switch (typeOfGrid) {
        case GridType.DSP:
          commentType = CommentType.dspNote
          break
        case GridType.EDP:
        case GridType.EDP_LABEL:
          commentType = CommentType.epNote
          break
        case GridType.LP:
          commentType = CommentType.lpNote
          break
        default:
          return null
      }
    }

    return (
      <OverlayTrigger
        overlay={
          <Tooltip id={`${column.name}-${jobId}`} style={{ fontSize: '14px' }}>
            {value}
          </Tooltip>
        }
      >
        <div>
          <GridCommentTextDisplay
            count={commentCount}
            openMoreCommentsModal={openModalFactory({
              commentType,
              jobId,
            })}
          >
            {value}
          </GridCommentTextDisplay>
        </div>
      </OverlayTrigger>
    )
  }
  const MoreCommentsDataTypeProvider = (props: Partial<DataTypeProviderProps>) => (
    <DataTypeProvider {...props} formatterComponent={MoreCommentsFormatter} for={columnNames} />
  )
  return MoreCommentsDataTypeProvider
}
