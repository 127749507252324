import React, { createContext, useContext, useLayoutEffect, useRef } from 'react'
import { Action, SuspendedActions } from 'components/App/suspendedActions.ts'
import { api } from '../../lib/api/base'
import { attachToken } from 'components/App/attachToken'
import { CurrentUser, SetUser } from 'types'
import { isAddressVerificationError } from 'components/App/utils'
import { Loader } from 'components/Loader'
import { useErrorMessageContext } from 'contexts/ErrorMessageContextProvider'
import axios from 'axios'
import { TApiError } from 'utils/commonUtils'

export type PollingState = {
  alertCount: number
  version: string
}

type ContextProviderProps = {
  alertCount: number
  children: React.ReactNode
  initializing: boolean
  refreshAlertCount: () => void
  setUser: SetUser
  user?: CurrentUser
}

export interface ContextInterface {
  alertCount: number
  initializing: boolean
  refreshAlertCount: () => unknown
  setUser: SetUser
  suspendedRetriesRef: React.MutableRefObject<SuspendedActions>
  user?: CurrentUser
}

export const AppContext = createContext<ContextInterface | undefined>(undefined)

export const ContextProvider = ({
  alertCount = 0,
  children,
  initializing,
  refreshAlertCount,
  setUser,
  user,
}: ContextProviderProps): JSX.Element => {
  const { setErrorMessage } = useErrorMessageContext()

  const suspendedRetriesRef = useRef(new SuspendedActions())

  useLayoutEffect(() => {
    if (user && !user.tokenExpired) {
      const { token } = user
      const reqInterceptorId = api.interceptors.request.use(config => {
        return attachToken(config, token)
      })
      const resInterceptorId = api.interceptors.response.use(
        response => response,
        (error: TApiError) => {
          if (error.response?.status === 401) {
            setUser({ ...user, tokenExpired: true })
            return new Promise(resolve => {
              suspendedRetriesRef.current.add(resolve as Action)
            }).then(() => {
              return api.request(error.config!)
            })
          } else if (axios.isAxiosError(error) && Number(error.response?.status) < 500) {
            if (!isAddressVerificationError(error)) {
              setErrorMessage(error.response?.data?.error || '')
            }
          } else {
            const errorData = {
              data: error.config?.data || error.config?.params,
              endpoint: error.config?.url,
              message: error.message,
              pathname: window.location.pathname,
              stackTrace: error.stack,
              userAgent: navigator.userAgent,
            }

            setErrorMessage('There was a problem fulfilling your request.', {
              identifier1: JSON.stringify(errorData),
              identifierType1: 'API error',
            })
          }

          return Promise.reject(error)
        },
      )
      return () => {
        api.interceptors.request.eject(reqInterceptorId)
        api.interceptors.response.eject(resInterceptorId)
      }
    }
  }, [setErrorMessage, setUser, user])

  return (
    <AppContext.Provider
      value={{
        alertCount,
        initializing,
        refreshAlertCount,
        setUser,
        suspendedRetriesRef,
        user,
      }}
    >
      {initializing ? (
        <div className="d-flex h-100">
          <Loader />
        </div>
      ) : (
        children
      )}
    </AppContext.Provider>
  )
}

export const useAppContext = () => {
  const appContext = useContext(AppContext)

  if (appContext === undefined) {
    throw new Error('useAppContext must be used inside ContextProvider')
  }

  return appContext
}
