import { FC } from 'react'
import { Col } from 'react-bootstrap'
import { Field, Formik } from 'formik'
import Form from 'react-bootstrap/Form'
import * as Yup from 'yup'
import { parse, isValid } from 'date-fns'

import { BootstrapFormikFeedback } from 'components/BootstrapFormikFeedback'
import { Modal } from 'components/Modal'
import styles from './IsOneOfModal.module.scss'

export type Props = {
  applyFilters: (newFilters: string) => void
  existingFilters: string
  hide: () => void
  show: boolean
}

const IsOneOfSchema = Yup.object().shape({
  filterCriteria: Yup.string().required('Please add at least one filter'),
})

const formId = 'IsOneOfModal'

export const IsOneOfModal: FC<Props> = ({
  existingFilters,
  applyFilters,
  hide: hideModal,
  show: isModalShown,
}) => {
  const handleSave = (updatedFilters: string) => {
    const notEmpty = (s: string) => /\S/.test(s)
    const formatDate = (s: string) => {
      const parsedDate = parse(s, 'MM/dd/yyyy', new Date())
      return isValid(parsedDate) ? parsedDate.toISOString().split('T')[0] : s
    }
    const commaSeparatedFilters = updatedFilters
      .split(/\n/)
      .filter(notEmpty)
      .map(formatDate)
      .join('||')
    applyFilters(commaSeparatedFilters)
  }

  const newLineSeparatedFilters = existingFilters.replace(/\|\|/g, '\n')

  return (
    <Modal
      dialogClassName={styles.Dialog}
      submitButtonText="Save"
      title='"Is One Of" Items'
      formId={formId}
      hide={hideModal}
      show={isModalShown}
    >
      <p>
        Please enter one item per line, then click the <b>SAVE</b> button.
      </p>
      <Formik
        initialValues={{
          filterCriteria: newLineSeparatedFilters,
        }}
        validationSchema={IsOneOfSchema}
        onSubmit={({ filterCriteria }) => {
          handleSave(filterCriteria)
          hideModal()
        }}
      >
        {({ handleSubmit }) => (
          <Col>
            <Form className={styles.Form} id={formId} onSubmit={handleSubmit}>
              <Form.Group controlId="filterCriteria">
                <Field
                  aria-label="filters"
                  as="textarea"
                  className={styles.TextArea}
                  name="filterCriteria"
                  rows="15"
                />
                <BootstrapFormikFeedback name="filterCriteria" />
              </Form.Group>
            </Form>
          </Col>
        )}
      </Formik>
    </Modal>
  )
}
