/* eslint sort-keys-fix/sort-keys-fix: 0 */
import { lazy as reactLazy, ReactNode } from 'react'
import { Route, Routes, Navigate } from 'react-router'

import { GUEST_ROUTES, ORDER_ROUTES, ROUTES, UNAUTHORIZED_ROUTES } from 'Routes'
import { SignOut } from 'components/SignOut'
import { Page } from 'components/Page'
import { ExpiredPasswordForm } from '../ExpiredPasswordForm'
import { ForgotPasswordPage } from '../ForgotPasswordPage'
import { ForgotPasswordSuccessPage } from '../ForgotPasswordSuccessPage'
import { LogInPage } from '../LogInPage'
import { RequireAuth } from '../RequireAuth'
import { ResetPasswordPage } from '../ResetPasswordPage'
import { UnauthorizedPage } from '../UnauthorizedPage'
import { UnAuthorizedResetPWSuccess } from '../UnauthorizedResetPWSuccess'
import { NewStyleFlowRouter } from 'pages/OrderPages/pages/NewStyleFlowRouter'
import { Providers } from 'components/Providers'
import { DispatchesPageInner } from 'pages/DispatchesPage/DispatchesPageInner'
import { ForceAccountAction } from 'components/Router/ForceAccountAction.tsx'
import { Illustration } from 'pages/RedirectPage/components/Illustration'
import { LogIn } from 'pages/RedirectPage/components/LogIn'
import { CreateAccount } from 'pages/RedirectPage/components/CreateAccount'

const lazy: typeof reactLazy = fn => {
  return reactLazy(() =>
    fn().catch(error => {
      if (error.name === 'ChunkLoadError') window.location.reload()
      throw error
    }),
  )
}

const OrderPages = lazy(() => import('pages/OrderPages'))
const SelectProgramPage = lazy(() => import('pages/OrderPages/pages/SelectProgramPage'))
const WhatKindOfServicePage = lazy(() => import('pages/OrderPages/pages/WhatKindOfServicePage'))
const OrderCommentsPage = lazy(() => import('pages/OrderPages/pages/OrderCommentsPage'))
const OrderReferencePage = lazy(() => import('pages/OrderPages/pages/OrderReferencePage'))
const PickupLocationPage = lazy(() => import('pages/OrderPages/pages/PickupLocationPage'))
const PrimaryContactPage = lazy(() => import('pages/OrderPages/pages/PrimaryContactPage'))
const SecondaryContactPage = lazy(() => import('pages/OrderPages/pages/SecondaryContactPage'))
const SiteInformationPage = lazy(() => import('pages/OrderPages/pages/SiteInformationPage'))
const DeliveryLocationPage = lazy(() => import('pages/OrderPages/pages/DeliveryLocationPage'))
const AssetsPage = lazy(() => import('pages/OrderPages/pages/AssetsPage'))
const PalletsPage = lazy(() => import('pages/OrderPages/pages/PalletsPage'))
const BoxesPage = lazy(() => import('pages/OrderPages/pages/BoxesPage/BoxesPage'))
const ShippingKitPage = lazy(() => import('pages/OrderPages/pages/ShippingKitPage'))
const ShippingKitsPage = lazy(() => import('pages/OrderPages/pages/ShippingKitsPage'))
const AddAssetDetailsPage = lazy(() => import('pages/OrderPages/pages/AddAssetDetails'))
const PrepaidLabelsPage = lazy(() => import('pages/OrderPages/pages/PrepaidLabelsPage'))
const CarrierDetailsPage = lazy(() => import('pages/OrderPages/pages/CarrierDetailsPage'))
const EmailOptionsPage = lazy(() => import('pages/OrderPages/pages/EmailOptionsPage'))
const CustomQuoteNoticePage = lazy(
  () => import('pages/OrderPages/pages/CustomQuoteNoticePage/CustomQuotePage'),
)
const CustomQuoteConfirmationPage = lazy(
  () => import('pages/OrderPages/pages/CustomQuoteConfirmationPage/CustomQuoteConfirmationPage'),
)
const PickupDatePage = lazy(() => import('pages/OrderPages/pages/PickupDatePage'))
const OrderReviewPage = lazy(() => import('pages/OrderPages/pages/OrderReviewPage'))
const PaymentOptionsPage = lazy(() => import('pages/OrderPages/pages/PaymentOptionsPage'))
const InvoicePage = lazy(() => import('pages/OrderPages/pages/InvoicePage'))
const InvoiceConfirmationPage = lazy(
  () => import('pages/OrderPages/pages/paymentConfirmation/InvoiceConfirmationPage'),
)
const LowAssetAlertPage = lazy(() => import('pages/OrderPages/pages/LowAssetAlertPage'))
const PaymentConfirmationPage = lazy(
  () => import('pages/OrderPages/pages/paymentConfirmation/PaymentConfirmationPage'),
)
const CreditCardPaymentPage = lazy(() => import('pages/OrderPages/pages/CreditCardPaymentPage'))
const OrderConfirmationPage = lazy(() => import('pages/OrderPages/pages/OrderConfirmationPage'))
const RANoticePage = lazy(() => import('pages/OrderPages/pages/RANoticePage/RANoticePage'))
const RALabelPage = lazy(() => import('pages/OrderPages/pages/RALabelPage/RALabelPage'))
const CongratulationsPage = lazy(() => import('pages/OrderPages/pages/CongratulationsPage'))

const LPCOIInfoPage = lazy(() => import('pages/LogisticsPartners/LPCOIInfoPage/LPCOIInfoPage'))
const LPContactInfoPage = lazy(
  () => import('pages/LogisticsPartners/LPContactInfoPage/LPContactInfoPage'),
)
const LPCreateAccountPage = lazy(
  () => import('pages/LogisticsPartners/LPCreateAccountPage/LPCreateAccountPage'),
)
const LPDOTInfoPage = lazy(() => import('pages/LogisticsPartners/LPDOTInfoPage/LPDOTInfoPage'))
const LPTaxInfoPage = lazy(() => import('pages/LogisticsPartners/LPTaxInfoPage/LPTaxInfoPage'))
const CompanyProfilePage = lazy(() => import('pages/CompanyProfile'))
const CreateAccountPage = lazy(() => import('pages/CreateAccountPage'))
const CreateOrderPage = lazy(() => import('pages/OrderPages/pages/CreateOrderPage/CreateOrderPage'))
const DashboardPage = lazy(() => import('pages/DashboardPage'))
const DestinationRoutingsPage = lazy(() => import('pages/DestinationRoutingsPage'))
const DestinationsPage = lazy(() => import('pages/DestinationsPage'))
const DispatchSearchResultsPage = lazy(() => import('pages/DispatchSearchResultsPage'))
const DispatchesPage = lazy(() => import('pages/DispatchesPage'))
const ImpersonatePage = lazy(() => import('pages/ImpersonatePage'))
const MyAccountPage = lazy(() => import('pages/MyAccountPage'))
const OrderDetailsPage = lazy(() => import('pages/OrderDetailsPage'))
const ProgramPage = lazy(() => import('pages/ProgramPage'))
const ProgramsPage = lazy(() => import('pages/ProgramsPage'))
const QuoteDetailsPage = lazy(() => import('pages/QuoteDetailsPage'))
const RedirectPage = lazy(() => import('pages/RedirectPage'))
const ReportGridPage = lazy(() => import('pages/ReportGridPage'))
const ReportQueuePage = lazy(() => import('pages/ReportQueuePage'))
const ReportSchedulePage = lazy(() => import('pages/ReportSchedulePage'))
const ReportSubmissionPage = lazy(() => import('pages/ReportSubmissionPage'))
const ReportsPage = lazy(() => import('pages/ReportsPage'))
const UploadsPage = lazy(() => import('pages/UploadsPage'))
const UserAdminPage = lazy(() => import('pages/UserAdminPage'))
const GuestAssetsPage = lazy(() => import('pages/OrderPages/pages/GuestAssetsPage/GuestAssetsPage'))
const GuestBoxesPage = lazy(() => import('pages/OrderPages/pages/GuestBoxesPage/GuestBoxesPage'))
const GuestCheckoutSummaryPage = lazy(
  () => import('pages/OrderPages/pages/GuestCheckoutSummaryPage/GuestCheckoutSummaryPage'),
)
const GuestCreateAccountPage = lazy(
  () => import('pages/OrderPages/pages/GuestCreateAccountPage/GuestCreateAccountPage'),
)
const GuestNotificationOptionsPage = lazy(
  () => import('pages/OrderPages/pages/GuestNotificationOptionsPage/GuestNotificationOptionsPage'),
)
const GuestFeedbackPage = lazy(
  () => import('pages/OrderPages/pages/GuestFeedbackPage/GuestFeedbackPage'),
)
const GuestForgotPasswordPage = lazy(
  () => import('pages/OrderPages/pages/GuestForgotPasswordPage/GuestForgotPasswordPage'),
)
const GuestKitsPage = lazy(() => import('pages/OrderPages/pages/GuestKitsPage/GuestKitsPage'))
const GuestOrderCompletedPage = lazy(
  () => import('pages/OrderPages/pages/GuestOrderCompletedPage/GuestOrderCompletedPage'),
)
const GuestOutOfServiceAreaConfirmationPage = lazy(
  () =>
    import(
      'pages/OrderPages/pages/GuestOutOfServiceAreaConfirmationPage/GuestOutOfServiceAreaConfirmationPage'
    ),
)
const GuestOutOfServiceAreaPage = lazy(
  () => import('pages/OrderPages/pages/GuestOutOfServiceAreaPage/GuestOutOfServiceAreaPage'),
)
const GuestPalletsPage = lazy(
  () => import('pages/OrderPages/pages/GuestPalletsPage/GuestPalletsPage'),
)
const GuestPaymentPage = lazy(
  () => import('pages/OrderPages/pages/GuestPaymentPage/GuestPaymentPage'),
)
const GuestPickupDetailsPage = lazy(
  () => import('pages/OrderPages/pages/GuestPickupDetailsPage/GuestPickupDetailsPage'),
)
const GuestPricePage = lazy(() => import('pages/OrderPages/pages/GuestPricePage/GuestPricePage'))
const QuoteRequiredPage = lazy(
  () => import('pages/OrderPages/pages/QuoteRequiredPage/QuoteRequiredPage'),
)
const GuestRequestedPickupPage = lazy(
  () => import('pages/OrderPages/pages/GuestRequestedPickupPage/GuestRequestedPickupPage'),
)
const GuestSignInPage = lazy(() => import('pages/OrderPages/pages/GuestSignInPage/GuestSignInPage'))

//////////////////////
// ROUTES GOES HERE //
//////////////////////

const unauthorizedRoutes: RouteType[] = [
  { path: UNAUTHORIZED_ROUTES.createAccount, element: <CreateAccountPage /> },
  {
    path: UNAUTHORIZED_ROUTES.alternativeLogin,
    element: <Navigate to={UNAUTHORIZED_ROUTES.login} />,
  },
  { path: UNAUTHORIZED_ROUTES.login, element: <LogInPage /> },
  {
    path: UNAUTHORIZED_ROUTES.passwordExpired,
    element: <ExpiredPasswordForm />,
    unauthorizedOnly: true,
  },
  {
    path: UNAUTHORIZED_ROUTES.forgotPassword,
    element: <ForgotPasswordPage />,
    unauthorizedOnly: true,
  },
  {
    path: UNAUTHORIZED_ROUTES.forgotPasswordSuccess,
    element: <ForgotPasswordSuccessPage />,
    unauthorizedOnly: true,
  },
  {
    path: UNAUTHORIZED_ROUTES.impersonate,
    element: <ImpersonatePage />,
    unauthorizedOnly: true,
  },
  {
    path: `${UNAUTHORIZED_ROUTES.resetPassword}/:uid`,
    element: <ResetPasswordPage />,
    unauthorizedOnly: true,
  },
  {
    path: UNAUTHORIZED_ROUTES.resetPasswordSuccess,
    element: <UnAuthorizedResetPWSuccess />,
    unauthorizedOnly: true,
  },
  {
    path: UNAUTHORIZED_ROUTES.redirect,
    element: <RedirectPage />,
    children: [
      { path: '', element: <Illustration /> },
      { path: 'sign-in', element: <LogIn /> },
      { path: 'create-account', element: <CreateAccount /> },
    ],
  },
]

const newStyleOrderRoutes: RouteType[] = [
  {
    path: ROUTES.order,
    element: <NewStyleFlowRouter />,
    children: [
      { path: GUEST_ROUTES.assets, element: <GuestAssetsPage /> },
      { path: GUEST_ROUTES.boxes, element: <GuestBoxesPage /> },
      { path: GUEST_ROUTES.checkoutSummary, element: <GuestCheckoutSummaryPage /> },
      { path: GUEST_ROUTES.createAccount, element: <GuestCreateAccountPage /> },
      { path: GUEST_ROUTES.feedback, element: <GuestFeedbackPage /> },
      { path: GUEST_ROUTES.forgotPassword, element: <GuestForgotPasswordPage /> },
      { path: GUEST_ROUTES.kits, element: <GuestKitsPage /> },
      { path: GUEST_ROUTES.notificationOptions, element: <GuestNotificationOptionsPage /> },
      { path: GUEST_ROUTES.orderCompleted, element: <GuestOrderCompletedPage /> },
      { path: GUEST_ROUTES.outOfServiceArea, element: <GuestOutOfServiceAreaPage /> },
      {
        path: GUEST_ROUTES.outOfServiceAreaConfirmation,
        element: <GuestOutOfServiceAreaConfirmationPage />,
      },
      { path: GUEST_ROUTES.pallets, element: <GuestPalletsPage /> },
      { path: GUEST_ROUTES.payment, element: <GuestPaymentPage /> },
      { path: GUEST_ROUTES.pickupDetails, element: <GuestPickupDetailsPage /> },
      { path: GUEST_ROUTES.price, element: <GuestPricePage /> },
      { path: GUEST_ROUTES.quoteRequired, element: <QuoteRequiredPage /> },
      { path: GUEST_ROUTES.requestedPickup, element: <GuestRequestedPickupPage /> },
      { path: GUEST_ROUTES.signIn, element: <GuestSignInPage /> },
    ],
  },
]

const oldStyleOrderRoutes: RouteType[] = [
  {
    path: `${ROUTES.editOrder}`,
    element: <OrderPages />,
    authorizedOnly: true,
    children: [
      // Only applicable to Create New Order
      { path: '', element: <Navigate to={ORDER_ROUTES.selectProgram.substring(1)} /> },
      { path: ORDER_ROUTES.selectProgram, element: <SelectProgramPage /> },
      { path: ORDER_ROUTES.whatKindOfService, element: <WhatKindOfServicePage /> },

      // Applicable to both New and Edit orders
      { path: ORDER_ROUTES.orderComments, element: <OrderCommentsPage /> },
      { path: ORDER_ROUTES.orderReference, element: <OrderReferencePage /> },
      { path: ORDER_ROUTES.pickupLocation, element: <PickupLocationPage includeContactInfo /> },
      { path: ORDER_ROUTES.pickupAddress, element: <PickupLocationPage /> },
      { path: ORDER_ROUTES.primaryContact, element: <PrimaryContactPage /> },
      { path: ORDER_ROUTES.secondaryContact, element: <SecondaryContactPage /> },
      { path: ORDER_ROUTES.siteInformation, element: <SiteInformationPage /> },
      { path: ORDER_ROUTES.deliveryLocation, element: <DeliveryLocationPage /> },
      { path: ORDER_ROUTES.assetDetails, element: <AssetsPage /> },
      { path: ORDER_ROUTES.palletDetails, element: <PalletsPage /> },
      { path: ORDER_ROUTES.boxDetails, element: <BoxesPage /> },
      { path: ORDER_ROUTES.shippingKit, element: <ShippingKitPage /> },
      { path: ORDER_ROUTES.shippingKits, element: <ShippingKitsPage /> },
      { path: ORDER_ROUTES.addAssetDetails, element: <AddAssetDetailsPage /> },
      { path: ORDER_ROUTES.prepaidLabelDetails, element: <PrepaidLabelsPage /> },
      { path: ORDER_ROUTES.carrierDetails, element: <CarrierDetailsPage /> },
      { path: ORDER_ROUTES.emailOptions, element: <EmailOptionsPage /> },
      { path: ORDER_ROUTES.customQuoteNotice, element: <CustomQuoteNoticePage /> },
      { path: ORDER_ROUTES.customQuoteConfirmation, element: <CustomQuoteConfirmationPage /> },
      { path: ORDER_ROUTES.pickupDate, element: <PickupDatePage /> },
      { path: ORDER_ROUTES.orderReview, element: <OrderReviewPage /> },
      { path: ORDER_ROUTES.paymentOptions, element: <PaymentOptionsPage /> },
      { path: ORDER_ROUTES.invoicePayment, element: <InvoicePage /> },
      { path: ORDER_ROUTES.invoiceConfirmation, element: <InvoiceConfirmationPage /> },
      { path: ORDER_ROUTES.lowAssetAlert, element: <LowAssetAlertPage /> },
      { path: ORDER_ROUTES.paymentConfirmation, element: <PaymentConfirmationPage /> },
      { path: ORDER_ROUTES.creditCardPayment, element: <CreditCardPaymentPage /> },
      { path: ORDER_ROUTES.orderConfirmation, element: <OrderConfirmationPage /> },
      { path: ORDER_ROUTES.raNotice, element: <RANoticePage /> },
      { path: ORDER_ROUTES.raLabel, element: <RALabelPage /> },
      { path: ORDER_ROUTES.congratulations, element: <CongratulationsPage /> },
    ],
  },
]

const routes: RouteType[] = [
  { path: ROUTES.LPCreateAccount, element: <LPCreateAccountPage />, authorizedOnly: true },
  { path: ROUTES.LPContactInfo, element: <LPContactInfoPage />, authorizedOnly: true },
  { path: ROUTES.LPTaxInfo, element: <LPTaxInfoPage />, authorizedOnly: true },
  { path: ROUTES.LPDOTInfo, element: <LPDOTInfoPage />, authorizedOnly: true },
  { path: ROUTES.LPCOIInfo, element: <LPCOIInfoPage />, authorizedOnly: true },
  { path: ROUTES.createOrder, element: <CreateOrderPage />, authorizedOnly: true },
  { path: ROUTES.dashboard, element: <DashboardPage />, authorizedOnly: true },
  { path: ROUTES.destinationRoutings, element: <DestinationRoutingsPage />, authorizedOnly: true },
  { path: ROUTES.destinations, element: <DestinationsPage />, authorizedOnly: true },
  { path: ROUTES.dispatchSearch, element: <DispatchSearchResultsPage />, authorizedOnly: true },
  { path: ROUTES.myAccount, element: <MyAccountPage />, authorizedOnly: true },
  { path: ROUTES.orderDetails, element: <OrderDetailsPage />, authorizedOnly: true },
  { path: ROUTES.quoteDetails, element: <QuoteDetailsPage />, authorizedOnly: true },
  { path: ROUTES.reportQueue, element: <ReportQueuePage />, authorizedOnly: true },
  { path: ROUTES.userAdmin, element: <UserAdminPage />, authorizedOnly: true },
  { path: ROUTES.views, element: <DispatchesPage />, authorizedOnly: true },
  { path: `${ROUTES.views}/:type`, element: <DispatchesPageInner />, authorizedOnly: true },
  { path: ROUTES.reportSchedule, element: <ReportSchedulePage />, authorizedOnly: true },
  { path: ROUTES.reportSubmission, element: <ReportSubmissionPage /> },
  { path: ROUTES.reports, element: <ReportsPage />, authorizedOnly: true },
  { path: ROUTES.reportGrid, element: <ReportGridPage />, authorizedOnly: true },
  { path: ROUTES.uploads, element: <UploadsPage />, authorizedOnly: true },
  { path: ROUTES.programs, element: <ProgramsPage />, authorizedOnly: true },
  { path: ROUTES.program, element: <ProgramPage />, authorizedOnly: true },
  { path: ROUTES.signOut, element: <SignOut />, authorizedOnly: true },
  { path: ROUTES.companyProfile, element: <CompanyProfilePage />, authorizedOnly: true },
  { path: '', element: <Navigate to={ROUTES.views} /> },
  {
    path: '*',
    element: (
      <Page>
        <h1>Not Found</h1>
      </Page>
    ),
  },
]

// Router component
export const Router = () => {
  return (
    <Routes>
      <Route path=":brand_name?/:flow_name?" element={<Providers />}>
        <Route element={<ForceAccountAction />}>
          {processRoutes(unauthorizedRoutes)}
          {processRoutes(newStyleOrderRoutes)}
          {processRoutes(oldStyleOrderRoutes)}
          {processRoutes(routes)}
        </Route>
      </Route>
    </Routes>
  )
}

type RouteType = {
  authorizedOnly?: boolean
  children?: RouteType[]
  element: ReactNode
  path: string
  unauthorizedOnly?: boolean
}
function processRoutes(routes: RouteType[]) {
  return routes.map(route => {
    const { authorizedOnly, children, element, path, unauthorizedOnly } = route
    return (
      <Route
        key={path}
        path={path.startsWith('/') ? path.substring(1) : path}
        element={
          authorizedOnly ? (
            <RequireAuth>{element}</RequireAuth>
          ) : unauthorizedOnly ? (
            <UnauthorizedPage>{element}</UnauthorizedPage>
          ) : (
            element
          )
        }
      >
        {children && processRoutes(children)}
      </Route>
    )
  })
}
