import { api } from 'lib/api/base'
import { ENDPOINTS } from 'lib/api/endpoints'
import { AdvancedSearchFilter } from 'types'
import { snakeCaseKeys } from 'utils/snakeCaseKeys'
import { GridDataResponse, NormalizedGridDataResponse } from 'lib/api/types'
import { formatGridData } from 'lib/api/utils/formatGridData'

export type UpdateFilterParams = {
  brandId?: number
  configId: number
  filters: AdvancedSearchFilter[]
  slaRuleSetId?: number
}

type UpdateFilters = (params: UpdateFilterParams) => Promise<NormalizedGridDataResponse>

export const updateFilters: UpdateFilters = async ({
  brandId,
  configId,
  filters,
  slaRuleSetId,
}) => {
  const { data } = await api.post<GridDataResponse>(ENDPOINTS.gridConfigs, {
    action: 'update_filters',
    brand_id: brandId,
    config_id: configId,
    filters: snakeCaseKeys(filters),
    set_id: slaRuleSetId,
  })

  return formatGridData(data)
}
