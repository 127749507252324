import { api } from 'lib/api/base'
import { BaseApiResult } from 'types'
import { ENDPOINTS } from 'lib/api/endpoints'

export type UpdateSchedulePickupParams = {
  comment: string
  job_id: string
  scheduled_pickup_date: string
  scheduled_pickup_time: {
    end_time: string
    start_time: string
  }
  tracking_number: string
}

export const updateSchedulePickup = async (
  params: UpdateSchedulePickupParams,
): Promise<BaseApiResult> => {
  const { data } = await api.post<BaseApiResult>(ENDPOINTS.job, {
    action: 'update_step_schedule_pickup',
    comment: params.comment,
    job_id: params.job_id,
    scheduled_pickup_date: params.scheduled_pickup_date,
    scheduled_pickup_time: params.scheduled_pickup_time,
    tracking_number: params.tracking_number,
  })

  return data
}
