import { DataTypeProvider } from '@devexpress/dx-react-grid'

import { ColumnName, DynamicObject } from 'types'
import { getDataOnlyValue } from 'utils/getDataOnlyValue'

export const TrackingNumberProvider = (): JSX.Element => {
  const TrackingNumberFormatter = ({ row }: DataTypeProvider.ValueFormatterProps): JSX.Element => {
    const trackingNumber = String(row[ColumnName.trackingNumber])
    const trackingNumberUrl = getDataOnlyValue(row as DynamicObject, ColumnName.trackingNumberUrl)

    return trackingNumberUrl ? (
      <a
        href={String(trackingNumberUrl)}
        title={`Tracking Link - ${trackingNumber}`}
        rel="noreferrer noopener"
        target="_blank"
      >
        {trackingNumber}
      </a>
    ) : (
      <>{trackingNumber}</>
    )
  }
  return (
    <DataTypeProvider
      formatterComponent={TrackingNumberFormatter}
      for={[ColumnName.trackingNumber]}
    />
  )
}
