import { useCallback } from 'react'
import { FieldArray, Formik } from 'formik'
import { Col, Form, Row } from 'react-bootstrap'

import { TextareaField } from 'components/TextareaField'
import { PalletRow } from 'forms/PalletsForm/components/PalletRow'
import { AddPalletButton } from 'forms/PalletsForm/components/AddPalletButton'
import { ConfirmationDialog } from 'components/ConfirmationDialog'
import { emptyPallet } from 'forms/PalletsForm'
import { useModalState } from 'hooks/useModalState'
import { DetailValue } from 'pages/CompanyProfile/components/DetailValue.tsx'
import { updateRecordPallets } from 'lib/api/job'
import { Pallet, SelectOption } from 'types'
import { GetJobResponse } from 'lib/api/job/get'
import { RecordPalletsSchema } from 'forms/RecordPalletsForm/schema.ts'
import ReferenceNumbersValues from 'components/ReferenceNumbersValues'
import * as React from 'react'
import { useAppContext } from 'contexts/ContextProvider'

const LABEL_WIDTH = '150px'

export type RecordPalletsFormProps = {
  formId: string
  job: GetJobResponse | null
  jobId: string
  onSubmitSuccess: () => void
  setSaving: (saving: boolean) => void
}

export const RecordPalletsForm = ({
  formId,
  job,
  jobId,
  onSubmitSuccess,
  setSaving,
}: RecordPalletsFormProps) => {
  const { user } = useAppContext()
  const {
    hide: hideConfirmDeleteModal,
    open: openConfirmDeleteModal,
    state: confirmDeleteModalState,
  } = useModalState<{ indexToDelete: number }>({ indexToDelete: 0 })

  const palletTypeOptions: SelectOption[] = React.useMemo(() => {
    if (!job) return []
    const paradigm = user?.paradigms?.find(p => +p.paradigm_id === +job.paradigm_id)
    const assetCategories = paradigm?.asset_categories

    if (!assetCategories) return []

    const flattenedAssets = assetCategories
      .map(category =>
        category.assets.map(asset => ({
          label: asset.name,
          value: asset.asset_id,
        })),
      )
      .flat()
    flattenedAssets.unshift({ label: 'Mixed Assets', value: 0 })
    return flattenedAssets
  }, [job, user])

  const onRemovePalletRow = useCallback(
    (index: number) => {
      openConfirmDeleteModal({ indexToDelete: index })
    },
    [openConfirmDeleteModal],
  )

  const handleSubmit = async (values: { comment: string; pallets: Pallet[] }) => {
    setSaving(true)

    try {
      await updateRecordPallets({
        comment: values.comment,
        job_id: jobId,
        pallet: values.pallets.map(pallet => ({
          asset_id: pallet.asset_id,
          dimensions: {
            height: pallet.height,
            length: pallet.length,
            width: pallet.width,
          },
          quantity: pallet.quantity,
          weight: pallet.weight,
        })),
      })
      onSubmitSuccess()
    } catch {
      setSaving(false)
    }
  }

  return (
    <Formik
      initialValues={{
        comment: '',
        pallets: [{ ...emptyPallet }],
      }}
      onSubmit={handleSubmit}
      validationSchema={RecordPalletsSchema}
      enableReinitialize
    >
      {({ handleSubmit, values, submitCount, errors }) => {
        return (
          <Form className="w-100" noValidate id={formId} onSubmit={handleSubmit}>
            <DetailValue label="Order Number:" labelWidth={LABEL_WIDTH} value={jobId} />
            <ReferenceNumbersValues job={job} labelWidth={LABEL_WIDTH} />

            <Row className="mb-3 mt-4 pt-4">
              <Col>
                <FieldArray name="pallets">
                  {({ push, remove }) => (
                    <>
                      {values.pallets.map((pallet, index) => (
                        <PalletRow
                          errors={submitCount > 0 ? errors : undefined}
                          index={index}
                          key={`${pallet.asset_id}_${index}`}
                          onRemove={values.pallets.length > 1 ? onRemovePalletRow : undefined}
                          palletTypeOptions={palletTypeOptions}
                          isWeightRequired
                        />
                      ))}

                      <Row className="justify-content-end">
                        <Col className="text-end" md={3} xs={12}>
                          <AddPalletButton onClick={() => push({ ...emptyPallet })} />
                        </Col>
                      </Row>

                      <ConfirmationDialog
                        acceptText="Yes"
                        cancelText="No"
                        disabled={false}
                        onAccept={() => {
                          remove(confirmDeleteModalState.indexToDelete)
                          hideConfirmDeleteModal()
                        }}
                        onCancel={hideConfirmDeleteModal}
                        show={confirmDeleteModalState.show}
                        title="Remove Pallet"
                      >
                        Are you sure you want to remove the pallet?
                      </ConfirmationDialog>
                    </>
                  )}
                </FieldArray>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col>
                <TextareaField label="Comment" name="comment" />
              </Col>
            </Row>
          </Form>
        )
      }}
    </Formik>
  )
}
