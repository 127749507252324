export const GUEST_ENDPOINTS = {
  guestAssets: '/page/assets',
  guestBoxes: '/page/boxes',
  guestCheckoutSummary: '/page/checkout_summary',
  guestCreateAccount: '/page/create_account',
  guestFeedback: '/page/feedback',
  guestForgotPassword: '/page/forgot_password',
  guestNotificationOptions: '/page/notification_options',
  guestOrderCompleted: '/page/order_completed',
  guestOutOfServiceArea: '/page/out_of_service_area',
  guestPallets: '/page/pallets',
  guestPayment: '/page/payment',
  guestPickupDetails: '/page/pickup_details',
  guestPrice: '/page/price',
  guestRequestedPickup: '/page/requested_pickup',
  guestSignIn: '/page/sign_in',
  pageForToken: '/page',
}

export const ENDPOINTS = {
  account: '/account',
  address: '/address',
  alert: '/alert',
  assets: '/asset',
  comments: '/comment',
  company: '/company',
  dashboard: '/dashboard',
  destination: '/destination',
  error: '/error',
  file: '/file_upload',
  grid: '/grid',
  gridConfigs: '/grid_config',
  job: '/job',
  mixpanel: '/mixpanel',
  page: '/page',
  program: '/program',
  quote: '/quote',
  report: '/report',
  reportQueue: '/report_queue',
  reportSchedule: '/report_schedule',
  setting: '/setting',
  user: '/user',
}
