import * as React from 'react'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'

import { Button } from 'components/Button'
import { Select } from 'components/Select'

export type Props = {
  currentPage: number
  currentPageSize: number
  onPageChange: (newPageNumber: number) => void
  onPageSizeChange: (newPageSize: number) => void
  pageSizes: number[]
  totalRecords: number
}

export const PagingPanel = ({
  currentPage,
  currentPageSize,
  onPageChange,
  onPageSizeChange,
  pageSizes,
  totalRecords,
}: Props): JSX.Element => {
  const pageOptions = React.useMemo(() => {
    const pageOptions: React.ReactNode[] = []

    if (currentPage > 1) {
      pageOptions.push(
        <Button onClick={() => onPageChange(0)} variant="link">
          1
        </Button>,
      )

      if (currentPage > 2) {
        pageOptions.push(<div>...</div>)
      }
    }

    const totalPages = Math.ceil(totalRecords / currentPageSize)

    const startingPage = Math.max(0, currentPage - 1)
    const endingPage = Math.min(totalPages - 1, currentPage + 1)

    for (let i = startingPage; i <= endingPage; i++) {
      const startingRecord = i * currentPageSize + 1
      const endingRecord = Math.min((i + 1) * currentPageSize, totalRecords)

      if (i !== currentPage) {
        pageOptions.push(
          <Button className="px-2 py-1" onClick={() => onPageChange(i)} variant="link">
            [{startingRecord}-{endingRecord}]
          </Button>,
        )
      } else {
        pageOptions.push(
          <div className="font-weight-bold px-2 py-1">
            [{startingRecord}-{endingRecord}]
          </div>,
        )
      }
    }

    if (currentPage <= totalPages - 3) {
      if (currentPage < totalPages - 3) {
        pageOptions.push(<div>...</div>)
      }

      pageOptions.push(
        <Button onClick={() => onPageChange(totalPages - 1)} variant="link">
          {totalPages}
        </Button>,
      )
    }

    return pageOptions
  }, [currentPage, currentPageSize, onPageChange, totalRecords])

  return (
    <Container className="p-0">
      <Row className="align-items-center justify-content-center">
        {pageOptions.map((pageOption, index) => (
          <div key={index}>{pageOption}</div>
        ))}
      </Row>

      <Row className="p-3 justify-content-center">
        <Col xs={6}>
          <Select
            floatingLabel={false}
            label="Page size"
            onChange={(newPageSize: number) => onPageSizeChange(newPageSize)}
            openAbove
            options={pageSizes.map(size => ({
              label: String(size),
              value: size,
            }))}
            value={currentPageSize}
          />
        </Col>
      </Row>
    </Container>
  )
}
