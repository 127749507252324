import { FC, ReactNode, useEffect } from 'react'
import { Button } from 'react-bootstrap'
import { useNavigate } from 'react-router'

import styles from './UnAuthorizedResetPWSuccess.module.scss'
import { GreenDotCheckMarkIcon } from 'components/GreenDotCheckMarkIcon'
import { UNAUTHORIZED_ROUTES } from 'Routes'
import { useAppContext } from 'contexts/ContextProvider'

export type Props = {
  clearExpiredFlag?: () => void
  defaultPath?: string
  setError?: (text: ReactNode) => void
}

export const UnAuthorizedResetPWSuccess: FC<Props> = ({
  clearExpiredFlag,
  defaultPath,
  setError,
}) => {
  const navigate = useNavigate()
  const { setUser } = useAppContext()

  const nextStepCallback = () => {
    clearExpiredFlag && clearExpiredFlag()
    setUser(user => user && { ...user, passwordExpired: false })
    defaultPath ? void navigate(defaultPath) : void navigate(UNAUTHORIZED_ROUTES.login)
  }

  useEffect(() => {
    setError && setError('')
  }, [setError])

  return (
    <div className={styles.CenterAlignedRow}>
      <GreenDotCheckMarkIcon />
      <h2 className={styles.PromptHeading}>Success!</h2>
      <p className={styles.PromptText}>Your password has been updated.</p>
      <Button onClick={nextStepCallback}>Continue</Button>
    </div>
  )
}
