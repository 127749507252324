import * as React from 'react'
import Spinner from 'react-bootstrap/Spinner'

import { ColumnName } from 'types'
import { ExpandableTable } from 'components/ExpandableTable'

import * as helpers from './helpers'
import { PagingPanel } from './components/PagingPanel'
import { useGridConfig } from './hooks/useGridConfig'
import { useGridData } from './hooks/useGridData'
import { GridConfigData } from 'lib/api/gridConfig'

type ColumnHeadingMap = {
  [columnName: string]: string
}

export type RowData = {
  [columnName: string]: number | string | null
}

type Props = {
  children?: (row: RowData) => React.ReactNode
  columnsToHighlight: ColumnName[]
  configId: number
  externalGridConfig?: GridConfigData
  externalLastUpdated?: number
  getClassName?: (row: RowData) => string | undefined
  idColumn: ColumnName
}

export const MobileGridView = ({
  children,
  columnsToHighlight,
  configId,
  externalGridConfig,
  externalLastUpdated,
  getClassName,
  idColumn,
}: Props): JSX.Element => {
  const { gridConfig, gridConfigStatus, refreshGridConfig } = useGridConfig(
    configId,
    externalLastUpdated,
    externalGridConfig,
  )
  const { gridData, gridDataStatus, refreshGridData } = useGridData(configId, externalLastUpdated)

  const refresh = React.useCallback(() => {
    refreshGridConfig()
    refreshGridData()
  }, [refreshGridConfig, refreshGridData])

  const data = React.useMemo(() => {
    if (!gridConfig || !gridData) {
      return []
    }

    return gridData.rows.map(row => {
      return gridConfig.configs.reduce<RowData>((map, config, index) => {
        map[config.name] = row[index]
        return map
      }, {})
    })
  }, [gridConfig, gridData])

  const columnHeadings = React.useMemo(() => {
    return (
      gridConfig?.configs.reduce<ColumnHeadingMap>((map, config) => {
        map[config.name] = config.heading
        return map
      }, {}) ?? {}
    )
  }, [gridConfig])

  const generateKey = React.useCallback(
    (row: RowData, index: number) => {
      return `${row[idColumn] ?? ''}_${index}`
    },
    [idColumn],
  )

  const getHighlightedValues = React.useCallback(
    (row: RowData) => {
      return columnsToHighlight.map(column => ({
        label: columnHeadings[column],
        value: row[column],
      }))
    },
    [columnHeadings, columnsToHighlight],
  )

  const loading = gridDataStatus === 'pending' || gridConfigStatus === 'pending'
  if (loading) {
    return (
      <div className="d-flex flex-grow-1 align-items-center justify-content-center">
        <Spinner animation="border" />
      </div>
    )
  }

  return (
    <>
      <ExpandableTable
        data={data}
        generateKey={generateKey}
        getClassName={getClassName}
        getHighlightedValues={getHighlightedValues}
      >
        {children}
      </ExpandableTable>

      {data.length > 0 && (
        <PagingPanel
          currentPage={gridData?.page ?? 0}
          currentPageSize={gridConfig?.perPage ?? 0}
          onPageChange={newPage => void helpers.handlePageChange(configId, newPage, refresh)}
          onPageSizeChange={newPageSize =>
            void helpers.handlePageSizeChange(configId, newPageSize, refresh)
          }
          pageSizes={gridConfig?.perPageOptions ?? []}
          totalRecords={gridData?.records ?? 0}
        />
      )}
    </>
  )
}
