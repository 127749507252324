import * as React from 'react'
import Modal from 'react-bootstrap/Modal'

import { Button } from 'components/Button'
import { DashboardAdvancedSearchForm } from 'pages/DispatchesPage/components/MobileView/components/AdvancedSearchModal/components/DashboardAdvancedSearchForm'
import { GridConfigData } from 'lib/api/gridConfig/getGridConfig'
import { AdvancedSearchFilter, Relation } from 'types'

import { GridColumnTypeMap, GridFilterTypeMap } from '../../hooks/useAdvancedSearchSettings'

const ADVANCED_SEARCH_FORM_ID = 'advanced-search-form'

export type Props = {
  gridColumnTypeMap: GridColumnTypeMap
  gridConfigData: GridConfigData | null
  gridFilterTypeMap: GridFilterTypeMap
  hide: () => void
  onSearch: (filters: AdvancedSearchFilter[]) => Promise<void>
  show: boolean
}

export const AdvancedSearchModal = ({
  gridColumnTypeMap,
  gridConfigData,
  gridFilterTypeMap,
  hide,
  onSearch,
  show,
}: Props): JSX.Element => {
  const filterableColumns = React.useMemo(() => {
    if (gridConfigData) {
      return gridConfigData.configs.filter(column => column.filterable)
    }

    return []
  }, [gridConfigData])

  const initialValues = React.useMemo(() => {
    if (gridConfigData && gridConfigData.filters.length > 0) {
      return {
        filters: gridConfigData.filters,
      }
    }

    const defaultColumn =
      filterableColumns.find(column => column.columnId === gridConfigData?.filterColumnId) ??
      filterableColumns[0]

    return {
      filters: defaultColumn
        ? [
            {
              columnId: defaultColumn.columnId,
              relationId: Relation.and,
              typeId: gridColumnTypeMap[defaultColumn.typeId]?.filterTypes[0] ?? -1,
              value: '',
            },
          ]
        : [],
    }
  }, [filterableColumns, gridColumnTypeMap, gridConfigData])

  return (
    <Modal onHide={hide} show={show}>
      <Modal.Header closeButton>
        <Modal.Title>Advanced Search</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <DashboardAdvancedSearchForm
          filterableColumns={filterableColumns}
          formId={ADVANCED_SEARCH_FORM_ID}
          gridColumnTypeMap={gridColumnTypeMap}
          gridFilterTypeMap={gridFilterTypeMap}
          initialValues={initialValues}
          onSubmit={({ filters }) => onSearch(filters).then(hide)}
        />
      </Modal.Body>

      <Modal.Footer className="border-0">
        <Button
          onClick={() => {
            const form = document.getElementById(ADVANCED_SEARCH_FORM_ID)
            const event = new CustomEvent('submit', { cancelable: true })
            form?.dispatchEvent(event)
          }}
        >
          Search
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
