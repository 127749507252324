import { NavigateFunction } from 'react-router'
import { AxiosError } from 'axios'

import { BaseApiResult } from 'types'
import { changePassword } from 'lib/api/user'
import { FormValues } from 'components/ChangePasswordForm'
import { UNAUTHORIZED_ROUTES } from 'Routes'

export const handleSubmit = async (
  { password, oldPassword }: FormValues,
  navigate: NavigateFunction,
  setError: (error: string) => void,
): Promise<void> => {
  try {
    await changePassword({
      newPassword: password,
      oldPassword,
    })

    void navigate(UNAUTHORIZED_ROUTES.resetPasswordSuccess)
  } catch (e) {
    const axiosError = e as AxiosError<BaseApiResult>
    if (axiosError.response?.data.error) {
      setError(axiosError.response.data.error)
    }
  }
}
