import classNames from 'classnames'

import { FormValues as LogInFormValues, LogInForm } from 'forms/LogInForm'

import styles from '../../RedirectPage.module.scss'
import * as helpers from 'pages/RedirectPage/helpers.ts'
import { useAppContext } from 'contexts/ContextProvider'

export const LogIn = (): JSX.Element => {
  const { setUser } = useAppContext()

  const handleLogIn = async (values: LogInFormValues) => {
    await helpers.handleLogIn(values, setUser)
  }

  return (
    <div className={classNames(styles.FormWrapper, styles.LoginWrapper)}>
      <LogInForm onSubmit={handleLogIn} showForgotPassword showHeader={false} />
    </div>
  )
}
