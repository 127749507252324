import { addRaAccount, authorizationCheck, logIn } from 'lib/api/user'
import { FormValues as CreateAccountFormValues } from 'forms/CreateAccountForm'
import { FormValues as LogInFormValues } from 'forms/LogInForm'
import { BaseApiResult, SetUser } from 'types'
import { AxiosError } from 'axios'
import { normalizeError } from 'utils/commonUtils'

export const handleCreateRaAccount = async (
  values: CreateAccountFormValues,
  setUser: SetUser,
  jobId: string,
  quoteId: string,
): Promise<void> => {
  try {
    const token = await addRaAccount(values, jobId, quoteId)

    const user = await authorizationCheck({ token })
    setUser(user)
  } catch (error) {
    const axiosError = error as AxiosError<BaseApiResult>
    if (axiosError.response) {
      throw new Error(axiosError.response.data.error)
    }

    throw new Error(
      'A problem occurred. Please try again or contact OnePak support if the issue persists.',
    )
  }
}

export const handleLogIn = async (values: LogInFormValues, setUser: SetUser): Promise<void> => {
  try {
    const user = await logIn(values)
    if (user) {
      setUser(user)
    }
  } catch (error) {
    const axiosError = normalizeError(error).message
    if (axiosError) {
      throw new Error(axiosError)
    }

    throw new Error(
      'A problem occurred. Please try again or contact OnePak support if the issue persists.',
    )
  }
}
