import { FC } from 'react'

import { useModalState } from 'hooks/useModalState'
import { TransparentButton } from 'components/TransparentButton'
import { GridConfiguratorModal } from 'components/GridView/GridConfiguratorModal'

export type Props = {
  configId: number
  refresh: () => void
}
export const CustomizeView: FC<Props> = props => {
  const { hide, open, state } = useModalState()
  return (
    <>
      <TransparentButton onClick={open}>Customize View</TransparentButton>
      <GridConfiguratorModal hide={hide} {...props} {...state} />
    </>
  )
}
