/* eslint sort-keys-fix/sort-keys-fix: 0 */

/** If any of these routes are modified, the unbrandedRoutes array must also be
 * changed. This is for comparing the url and checking
 * if the url parameter is a brand or path
 */

export const GUEST_ROUTES = {
  assets: '/:id/assets',
  boxes: '/:id/boxes',
  checkoutSummary: '/:id/checkout_summary',
  createAccount: '/:id/create_account',
  feedback: '/:id/feedback',
  forgotPassword: '/:id/forgot_password',
  kits: '/:id/kits',
  notificationOptions: '/:id/notification_options',
  orderCompleted: '/:id/order_completed',
  outOfServiceArea: '/out_of_service_area',
  outOfServiceAreaConfirmation: '/out_of_service_area_confirmation',
  pallets: '/:id/pallets',
  payment: '/:id/payment',
  pickupDetails: '/:id/pickup_details',
  price: '/:id/price',
  quoteRequired: '/:id/rfq_confirmation',
  requestedPickup: '/:id/requested_pickup',
  signIn: '/:id/sign_in',
}

export const UNAUTHORIZED_ROUTES = {
  alternativeLogin: '/login',
  createAccount: '/create_account',
  forgotPassword: '/email_password_reset',
  forgotPasswordSuccess: '/email_password_reset_success',
  guest: '/guest',
  impersonate: '/impersonate/:token',
  login: '/sign_in',
  passwordExpired: '/password_expired',
  redirect: '/r/:uniqueId',
  resetPassword: '/forgot_password',
  resetPasswordSuccess: '/reset_password_success',
}

export const ROUTES = {
  createOrder: '/create_order',
  dashboard: '/dashboard',
  destinationRoutings: '/destination_routings',
  destinations: '/destinations',
  dispatchSearch: '/dispatch_search',
  order: '/order',
  editOrder: '/order/:orderId?',
  editOrderParam: '/:orderId',
  LPCOIInfo: '/lp_coi_info',
  LPContactInfo: '/lp_contact_info',
  LPCreateAccount: '/lp_create_account',
  LPTaxInfo: '/lp_tax_info',
  LPDOTInfo: '/lp_dot_info',
  myAccount: '/account',
  orderDetails: '/order_details/:jobId',
  quoteDetails: '/draft_details/:quoteId',
  reportQueue: '/report_queue',
  resetPasswordSuccess: '/reset_password_success',
  root: '/',
  signOut: '/sign_out',
  userAdmin: '/user_admin',
  tools: '/tools',
  views: '/views',
  reportSchedule: '/report_schedule',
  reportSubmission: '/report_submission/:configId',
  reports: '/reports',
  allOrdersReport: '/reports/all_orders_report',
  reportGrid: '/reports/:configId',
  uploads: '/uploads',
  programs: '/programs',
  program: '/program/:id',
  companyProfile: '/company',
}

export const ORDER_ROUTES = {
  addAssetDetails: '/add_asset_details',
  assetDetails: '/asset_details',
  boxDetails: '/box_details',
  carrierDetails: '/carrier_details',
  congratulations: '/congratulations',
  creditCardPayment: '/credit_card',
  customQuoteConfirmation: '/custom_quote_confirmation',
  customQuoteNotice: '/custom_quote_notice',
  deliveryLocation: '/delivery_location',
  emailOptions: '/email_options',
  invoiceConfirmation: '/invoice_confirmation',
  invoicePayment: '/invoice',
  lowAssetAlert: '/assets_tip',
  orderComments: '/comments',
  orderConfirmation: '/order_confirmation',
  orderReference: '/order_reference',
  orderReview: '/order_review',
  palletDetails: '/pallet_details',
  paymentConfirmation: '/payment_confirmation',
  paymentOptions: '/payment_options',
  pickupAddress: '/pickup_address',
  pickupDate: '/pickup_date',
  pickupLocation: '/pickup_location',
  prepaidLabelDetails: '/prepaid_label_details',
  primaryContact: '/primary_contact',
  raLabel: '/ra_label',
  raNotice: '/ra_notice',
  secondaryContact: '/secondary_contact',
  selectProgram: '/select_program',
  shippingKit: '/one_kit',
  shippingKits: '/shipping_kits',
  siteInformation: '/site_information',
  whatKindOfService: '/what_kind_of_service',
}
