import { FC } from 'react'
import { Button, Col, Form, Row, Spinner } from 'react-bootstrap'
import classNames from 'classnames'
import { Formik, FieldArray, FormikHelpers } from 'formik'

import { AdvancedSearchFilter } from 'types'
import { FilterRow } from './FilterRow'
import { FilterType, NormalizedGridColumnType } from 'lib/api/setting'
import styles from './DispatchesAdvancedSearch.module.scss'

export type DispatchesAdvancedSearchFilterProps = {
  blankFilter: AdvancedSearchFilter
  className?: string
  defaultFilters: AdvancedSearchFilter[]
  filterTypes: FilterType[]
  filterableColumns: {
    columnId: number
    heading: string
    typeId: number
  }[]
  gridColumnTypes: NormalizedGridColumnType[]
  loading?: boolean
  onClear: () => void
  onSubmit: (values: FilterFormParams) => void
}

export type FilterFormParams = {
  filters: AdvancedSearchFilter[]
}

export const DispatchesAdvancedSearch: FC<DispatchesAdvancedSearchFilterProps> = ({
  blankFilter,
  className,
  defaultFilters,
  filterableColumns,
  filterTypes,
  gridColumnTypes,
  loading,
  onClear,
  onSubmit,
}) => {
  return (
    <Row className={classNames(styles.AdvancedSearch, className)}>
      <Formik enableReinitialize initialValues={{ filters: defaultFilters }} onSubmit={onSubmit}>
        {({ handleSubmit, resetForm, values: { filters }, setFieldValue }) => {
          return (
            <Form onSubmit={handleSubmit} className={styles.FormRow}>
              <Col lg="10">
                <FieldArray
                  name="filters"
                  render={arrayHelpers => (
                    <>
                      <Row className={styles.ButtonRow}>
                        <Col>
                          <span className={styles.AdvancedSearchLabel}>SEARCH IN VIEW</span>
                        </Col>
                        <Col className={styles.RightButtonColumn}>
                          <Row className={styles.RightButtonRow}>
                            <Button
                              className="me-3 w-auto"
                              onClick={() => {
                                resetForm()
                                onClear()
                              }}
                              size="sm"
                            >
                              Clear
                            </Button>
                            {filters.length < 5 && (
                              <Button
                                onClick={() => arrayHelpers.push(blankFilter)}
                                size="sm"
                                className="w-auto"
                              >
                                Add Filter
                              </Button>
                            )}
                          </Row>
                        </Col>
                      </Row>

                      {filters.map((filter, index) => (
                        <FilterRow
                          filterableColumns={filterableColumns}
                          filterTypes={filterTypes}
                          gridColumnTypes={gridColumnTypes}
                          key={`${index}-${filter.columnId}`}
                          index={index}
                          remove={() => arrayHelpers.remove(index)}
                          filter={filter}
                          filterLength={filters.length}
                          setFieldValue={
                            setFieldValue as FormikHelpers<AdvancedSearchFilter>['setFieldValue']
                          }
                        />
                      ))}
                    </>
                  )}
                />
              </Col>

              <Col className="d-flex align-items-end pb-2 px-4">
                <Button
                  className={styles.SubmitButton}
                  disabled={loading || filters.every(({ value }) => value === '')}
                  type="submit"
                >
                  {loading ? (
                    <Spinner animation="border" role="status" size="sm" variant="light" />
                  ) : (
                    'Search'
                  )}
                </Button>
              </Col>
            </Form>
          )
        }}
      </Formik>
    </Row>
  )
}
