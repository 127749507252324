import { api } from 'lib/api/base'
import { ENDPOINTS } from 'lib/api/endpoints'
import { GridDataResponse, NormalizedGridDataResponse } from 'lib/api/types'
import { formatGridData } from 'lib/api/utils/formatGridData'

interface GetGridDataParams {
  brandId?: number
  configId: number
  slaRuleSetId?: number
}

export type GridDataRequestData = {
  action: 'list'
  brand_id: number | undefined
  config_id: number
  set_id: number | undefined
}

type GetGridData = (params: GetGridDataParams) => Promise<NormalizedGridDataResponse>
export const getGridData: GetGridData = async ({ brandId, configId, slaRuleSetId }) => {
  const params: GridDataRequestData = {
    action: 'list',
    brand_id: brandId,
    config_id: configId,
    set_id: slaRuleSetId,
  }

  const { data } = await api.get<GridDataResponse>(ENDPOINTS.grid, { params })

  return formatGridData(data)
}
