import React, { FC } from 'react'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'
import Row from 'react-bootstrap/Row'
import { Formik, FormikErrors } from 'formik'
import _ from 'lodash'

import { Button } from 'components/Button'
import { Loader } from 'components/Loader'
import { OrderReferenceNumber, OrderResponse, SelectOption } from 'types'
import { PhoneNumberField } from '../PhoneNumberField'
import { SelectField } from '../SelectField'
import { TextareaField } from '../TextareaField'
import { TextField } from '../TextField'
import './ApproveOrderModal.scss'

export interface FormValues {
  assets: {
    id: string
    service_tag: string
  }[]
  billto_info: {
    address1: string
    address2: string
    city: string
    company: string
    first_name: string
    last_name: string
    phone: string
    postal_code: string
    state_province: string
  }
  comment: string
  reference1: string
  reference2: string
  reference3: string
}

interface Props {
  closeModal: () => void
  initialValues: FormValues
  jobId: string
  loading: boolean
  onApprove: (values: FormValues) => Promise<void>
  onReject: (comment: string) => Promise<void>
  order: OrderResponse | null
  referenceNumbers: OrderReferenceNumber[]
  show: boolean
  states: SelectOption[]
}

export const ApproveOrderModal: FC<Props> = ({
  closeModal,
  initialValues,
  jobId,
  loading,
  onApprove,
  onReject,
  order,
  referenceNumbers,
  show,
  states,
}) => {
  const actionRef = React.useRef<'approve' | 'reject' | undefined>()

  React.useEffect(() => {
    actionRef.current = undefined
  }, [show])

  return (
    <Modal className="ApproveOrderModal__modal" onHide={closeModal} size="lg" show={show}>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={async (values, { setSubmitting }) => {
          if (actionRef.current === 'approve') {
            await onApprove(values)
          } else {
            await onReject(values.comment)
          }

          setSubmitting(false)
        }}
        validate={values => {
          const errors: FormikErrors<FormValues> = {}

          if (actionRef.current === 'approve') {
            // Validate reference numbers
            referenceNumbers.forEach((referenceNumber, index) => {
              const name = `reference${index + 1}` as keyof typeof values

              if (referenceNumber.required && !values[name]) {
                errors[name] = `${referenceNumber.label} is required.`
              }
            })

            // Validate Bill To information
            if (values.billto_info && !values.billto_info.first_name) {
              _.set(errors, 'billto_info.first_name', 'First name is required.')
            }
            if (values.billto_info && !values.billto_info.last_name) {
              _.set(errors, 'billto_info.last_name', 'Last name is required.')
            }
            if (values.billto_info && !values.billto_info.company) {
              _.set(errors, 'billto_info.company', 'Company is required.')
            }
            if (values.billto_info && !values.billto_info.address1) {
              _.set(errors, 'billto_info.address1', 'Address is required.')
            }
            if (values.billto_info && !values.billto_info.city) {
              _.set(errors, 'billto_info.city', 'City is required.')
            }
            if (values.billto_info && !values.billto_info.state_province) {
              _.set(errors, 'billto_info.state_province', 'State is required.')
            }
            if (values.billto_info && !values.billto_info.postal_code) {
              _.set(errors, 'billto_info.postal_code', 'Zip code is required.')
            }
            if (values.billto_info && !values.billto_info.phone) {
              _.set(errors, 'billto_info.phone', 'Phone number is required.')
            }

            // Validate assets
            values.assets.forEach((asset, index) => {
              if (!asset.service_tag) {
                _.set(errors, `assets[${index}].service_tag`, 'Service tag is required.')
              }
            })
          } else if (actionRef.current === 'reject') {
            if (!values.comment) {
              errors.comment = 'A comment is required when rejecting an order.'
            }
          }

          return errors
        }}
      >
        {({ handleSubmit, isSubmitting, submitForm }) => (
          <Form onSubmit={handleSubmit}>
            <Modal.Header closeButton>
              <div className="d-flex flex-wrap align-items-center justify-content-between w-100">
                <Modal.Title>Approve Order</Modal.Title>
                <div className="ApproveOrderModal__jobId">
                  <strong>Job ID:</strong> {jobId}
                </div>
              </div>
            </Modal.Header>

            <Modal.Body>
              {loading ? (
                <Loader />
              ) : (
                <>
                  {/* Reference numbers section */}
                  <Row>
                    <Col className="ApproveOrderModal__column--borderRight" lg={6} xs={12}>
                      {referenceNumbers.map((referenceNumber, index) => {
                        if (!referenceNumber.prompt) return null
                        const fieldName = `reference${index + 1}`

                        return (
                          <Row key={fieldName}>
                            <Col>
                              <TextField
                                className="mb-3"
                                label={referenceNumber.label}
                                name={fieldName}
                              />
                            </Col>
                          </Row>
                        )
                      })}
                      {order?.references.map(reference => {
                        return (
                          <Row key={reference.name}>
                            <Col>
                              <div className="mb-0 font-weight-bold">{reference.label}</div>
                              <div className="ms-2 mb-3">
                                {reference.value.length > 0 ? (
                                  reference.value
                                ) : (
                                  <span className="ApproveOrderModal__empty">blank</span>
                                )}
                              </div>
                            </Col>
                          </Row>
                        )
                      })}
                    </Col>

                    {/* Bill To information */}
                    <Col xs={12} lg={6}>
                      {/* Contact name section */}
                      <Row>
                        <Col className="mb-2" xs={12}>
                          <Form.Label>Bill To</Form.Label>
                        </Col>
                        <Col className="ApproveOrderModal__billTo mb-3" xs={12}>
                          <TextField label="First Name" name="billto_info.first_name" />
                          <TextField label="Last Name" name="billto_info.last_name" />
                        </Col>
                      </Row>

                      {/* Company section */}
                      <Row>
                        <Col xs={12}>
                          <TextField className="mb-3" label="Company" name="billto_info.company" />
                        </Col>
                      </Row>

                      {/* Address section */}
                      <Row>
                        <Col>
                          <Row>
                            <Col>
                              <TextField
                                className="mb-3"
                                label="Address Line 1"
                                name="billto_info.address1"
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <TextField
                                className="mb-3"
                                label="Address Line 2"
                                name="billto_info.address2"
                              />
                            </Col>
                          </Row>
                          <Row className="ApproveOrderModal__address g-0">
                            <Col xs={6}>
                              <TextField className="mb-3" label="City" name="billto_info.city" />
                            </Col>
                            <Col lg={3} xs={6}>
                              <SelectField
                                label="State"
                                name="billto_info.state_province"
                                options={states}
                              />
                            </Col>
                            <Col lg={3} xs={6}>
                              <TextField
                                className="mb-3"
                                label="Zip"
                                name="billto_info.postal_code"
                              />
                            </Col>
                          </Row>
                        </Col>
                      </Row>

                      {/* Phone number section */}
                      <Row className="g-0">
                        <Col lg={6} xs={12}>
                          <PhoneNumberField country="US" label="Phone" name="billto_info.phone" />
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                  {/* Assets section */}
                  {order?.assets_expected && order.assets_expected.length > 0 && (
                    <Row className="ApproveOrderModal__row--borderTop">
                      <Col xs={12}>
                        <Row className="mb-2">
                          <Col className="font-weight-bold" lg={4} xs={6}>
                            Asset Type
                          </Col>
                          <Col className="font-weight-bold" lg={8} xs={6}>
                            Service Tag
                          </Col>
                        </Row>
                        {order.assets_expected.map((asset, index) => (
                          <React.Fragment key={asset.id}>
                            <Row className="mt-2">
                              <Col className="d-flex align-items-center" lg={4} xs={6}>
                                {asset.description}
                              </Col>
                              <Col lg={8} xs={6}>
                                <TextField
                                  hideLabel
                                  label={`Asset ${index + 1}`}
                                  name={`assets[${index}].service_tag`}
                                />
                              </Col>
                            </Row>
                          </React.Fragment>
                        ))}
                      </Col>
                    </Row>
                  )}

                  {/* Comments section */}
                  <Row className="ApproveOrderModal__row--borderTop">
                    <Col>
                      <TextareaField label="Comment" name="comment" />
                    </Col>
                  </Row>
                </>
              )}
            </Modal.Body>

            <Modal.Footer className="ApproveOrderModal__footer">
              <Row className="justify-content-center w-100">
                <Col className="mb-3" lg={4} xs={8}>
                  <Button onClick={closeModal} type="button" variant="outline-dark">
                    Cancel
                  </Button>
                </Col>
                <Col className="mb-4" lg={4} xs={8}>
                  <Button
                    disabled={isSubmitting}
                    onClick={() => {
                      actionRef.current = 'reject'
                      void submitForm()
                    }}
                    type="button"
                    variant="dark"
                  >
                    Reject
                  </Button>
                </Col>
                <Col lg={4} xs={12}>
                  <Button
                    disabled={isSubmitting}
                    onClick={() => {
                      actionRef.current = 'approve'
                      void submitForm()
                    }}
                    type="button"
                  >
                    Approve
                  </Button>
                </Col>
              </Row>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  )
}
