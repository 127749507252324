import { BrowserRouter } from 'react-router'
import { Router } from 'components/Router'
import { useState } from 'react'
import { BrandPathContext } from 'contexts/BrandPathContext'

export const App = () => {
  const [brandPath, setBrandPath] = useState('')

  return (
    <BrandPathContext.Provider value={{ brandPath, setBrandPath }}>
      <BrowserRouter basename={`${import.meta.env.BASE_URL}${brandPath}`} key={brandPath}>
        <Router />
      </BrowserRouter>
    </BrandPathContext.Provider>
  )
}
