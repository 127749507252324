import { format } from 'date-fns/format'

const DATE_FORMAT = 'MM/dd/yyyy'

export const formatDateRange = (dateStart: Date | string, dateEnd?: Date | string): string => {
  const startDT = new Date(dateStart)
  const startStr = isValidDateObject(startDT) ? format(startDT, DATE_FORMAT) : ''

  const endDT = new Date(dateEnd ?? '')
  const endStr = isValidDateObject(endDT) ? format(endDT, DATE_FORMAT) : ''

  if (startStr && endStr) {
    /** FTL and LTL orders can have same day shipping. Date start and
     * end will have the same time. If they do, we do not
     * need to display a date range.
     */
    if (startDT.getTime() === endDT.getTime()) {
      return startStr
    } else {
      return `${startStr} - ${endStr}`
    }
  } else {
    return startStr || endStr
  }
}

export const isValidDateObject = (date: Date): boolean => {
  return date instanceof Date && !isNaN(date.getTime())
}
