import classNames from 'classnames'
import styles from './RadioV2.module.scss'
import { JSX } from 'react'

type Props = {
  checked?: boolean
  id: string
  label: string
  name: string
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  onClick?: (e: React.MouseEvent<HTMLInputElement, MouseEvent>) => void
  value?: string | string[] | number
}

const RadioV2 = (props: Props): JSX.Element => {
  const { checked, id, label, name, onChange, onClick, value } = props

  return (
    <label
      aria-label={label}
      htmlFor={id}
      className={classNames(styles.Label, { [styles.Checked]: checked })}
    >
      {label}
      <input
        className={styles.Input}
        checked={checked}
        onChange={onChange}
        onClick={onClick}
        value={value}
        id={id}
        type="radio"
        name={name}
      />
    </label>
  )
}

export default RadioV2
