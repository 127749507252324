import { NavigateFunction } from 'react-router'

import { AdvancedSearchFilter } from 'types'
import { updateFilters } from 'lib/api/gridConfig'
import { ROUTES } from 'Routes'

export const clearFilters = async (configId: number, onSuccess: () => void): Promise<void> => {
  await updateFilters({ configId, filters: [] })
  onSuccess()
}

export const search = (searchTerm: string, navigate: NavigateFunction): void => {
  if (searchTerm.trim().length > 0) {
    void navigate(`${ROUTES.dispatchSearch}?query=${encodeURIComponent(searchTerm)}`)
  }
}

export const updateSearchFilters = async (
  configId: number,
  filters: AdvancedSearchFilter[],
  onSuccess: () => void,
): Promise<void> => {
  await updateFilters({ configId, filters })
  onSuccess()
}
