import { BaseApiResult } from 'types'
import { api } from 'lib/api/base'
import { ENDPOINTS } from 'lib/api/endpoints'
import { normalizeError } from 'utils/commonUtils'

type DeleteQuoteResponse = BaseApiResult

export const deleteQuote = async (quote_id: number): Promise<DeleteQuoteResponse> => {
  const response: DeleteQuoteResponse = { result: 'Success' }

  try {
    const { data } = await api.post<DeleteQuoteResponse>(ENDPOINTS.quote, {
      action: 'delete',
      quote_id,
    })

    response.result = data.result
  } catch (e) {
    response.result = 'Failure'
    response.error = normalizeError(e).message
  }

  return response
}
