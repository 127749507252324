import classNames from 'classnames'
import { ErrorMessage, Field, FieldProps } from 'formik'
import React, { ChangeEventHandler } from 'react'
import { Radio } from './Radio'
import styles from './RadioGroup.module.scss'
import RadioV2 from './RadioV2/RadioV2'

interface RadioGroupProps {
  className?: string
  id?: string
  isVersion2?: boolean
  label?: string | JSX.Element
  name: string
  onChange?: ChangeEventHandler<HTMLInputElement>
  onRadioClick?: (e: React.MouseEvent<HTMLInputElement, MouseEvent>) => void
  options: { label: string; value: string }[]
  verticalLayout?: boolean
}

export const RadioGroup = ({
  className,
  id,
  isVersion2 = false,
  label,
  name,
  onChange,
  onRadioClick,
  options,
  verticalLayout,
}: RadioGroupProps): JSX.Element => {
  const localId = id ?? name

  return (
    <div className={classNames(styles.WrapperContainer, className)}>
      {label && (
        <div className="mb-3">
          <p className={classNames({ [styles.Version2LabelText]: isVersion2 })}>{label}</p>
        </div>
      )}

      <div className={verticalLayout ? styles.OptionWrapperVertical : styles.OptionWrapper}>
        <Field name={name}>
          {({ field }: FieldProps) => {
            return options.map((option, index) => {
              if (isVersion2) {
                return (
                  <RadioV2
                    checked={field.value === option.value}
                    id={`${localId}_${option.value}`}
                    key={index}
                    label={option.label}
                    name={field.name}
                    onChange={field.onChange}
                    onClick={onRadioClick}
                    value={option.value}
                  />
                )
              } else {
                return (
                  <Radio
                    {...field}
                    checked={field.value === option.value}
                    id={localId}
                    key={index}
                    label={option.label}
                    onChange={(...args) => {
                      if (typeof onChange === 'function') onChange(...args)
                      field.onChange(...args)
                    }}
                    onClick={onRadioClick}
                    value={option.value}
                  />
                )
              }
            })
          }}
        </Field>
      </div>

      <ErrorMessage name={name}>
        {msg => {
          return <div className={styles.ErrorMessage}>{msg}</div>
        }}
      </ErrorMessage>
    </div>
  )
}
