import classNames from 'classnames'

import styles from './ExpandCollapseIndicator.module.scss'

type Props = {
  expanded: boolean
}

export const ExpandCollapseIndicator = ({ expanded }: Props): JSX.Element => (
  <div
    className={classNames(styles.ExpandCollapseIndicator, { [styles.Expanded]: expanded })}
    data-testid="expand-collapse-indicator"
  >
    <div />
    <div />
    <div />
  </div>
)
