import { FC } from 'react'

import styles from './IsOneOf.module.scss'
import { TransparentButton } from 'components/TransparentButton'
import { IsOneOfModal } from '../IsOneOfModal'
import { useModalState } from 'hooks/useModalState'
import { GearIcon } from 'components/GearIcon'

export type Props = {
  applyFilters: (filters: string) => void
  existingFilters: string
}

export const IsOneOf: FC<Props> = ({ applyFilters, existingFilters }) => {
  const { hide, open, state } = useModalState()
  return (
    <>
      <TransparentButton
        aria-label="open search filters edit modal"
        className={styles.TransparentButton}
        onClick={open}
      >
        <GearIcon aria-hidden />
      </TransparentButton>
      <IsOneOfModal
        applyFilters={applyFilters}
        existingFilters={existingFilters}
        hide={hide}
        {...state}
      />
    </>
  )
}
