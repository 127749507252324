import { AnchorHTMLAttributes, DetailedHTMLProps, FC } from 'react'

type Props = DetailedHTMLProps<AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement> & {
  relativeHref: string
}
export const PortalLink: FC<Props> = ({ children, relativeHref, ...restProps }) => {
  const path = relativeHref.startsWith('/') ? relativeHref : `/${relativeHref}`

  return (
    <a {...restProps} href={`${String(import.meta.env.VITE_PORTAL_BASE_URL)}${path}`}>
      {children}
    </a>
  )
}
