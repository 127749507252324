import * as React from 'react'

import { GetQuoteResponse, QuoteProduct } from 'lib/api/quote'
import sortBy from 'lodash/sortBy'
import {
  AddressWithCompany,
  Contact,
  ContactType,
  Destination,
  DestinationRouting,
  Order,
  OrderReferenceNumber,
  Paradigm,
  Product,
  ProductWithQuantity,
  Program,
  ProgramParadigm,
  Quote,
} from 'types'
import { getDefaultContact } from 'forms/ContactForm'
import { mapPaymentType } from 'utils/commonUtils'
import {
  calculateOrderTotal,
  getSelectedInboundPriceId,
  getSelectedOutboundPriceId,
  mapApiToUIEmails,
} from 'pages/OrderPages/utils/orderUtils'

export type TQuoteAddNew = Required<Pick<Quote, 'flow_id' | 'quote_id' | 'quote_number'>> &
  Pick<Quote, 'promo_code' | 'emails' | 'is_local_pickup'>
export type TQuoteUpdate = Required<Pick<Quote, 'flow_id' | 'quote_id'>> &
  Pick<Quote, 'prices' | 'product_prices'> & { pickup_location_contacts?: Contact[] }
export type TQuoteUpdateFlow = Required<Pick<Quote, 'flow_id'>>

// Update types with 'Local' prefix will only update local state (no API call)
export enum OrderUpdateType {
  Assets = 'update_assets',
  CancelInvoice = 'cancel_invoice',
  Comments = 'update_comments',
  CustQuoteNoticeAck = 'update_custom_quote_notice_ack',
  CustomerRFQ = 'update_request_custom_quote',
  DeliveryLocation = 'update_delivery_location',
  Emails = 'update_email',
  FlowOnly = 'update_flow_id', // update remote and local flow
  KitsAndLabelsPrices = 'update_kits_and_label_prices',
  LocalAssetDetails = 'local_update_asset_details',
  LocalFlow = 'local_update_flow', // update local flow only
  LocalPayment = 'local_update_payment',
  LocalProducts = 'local_update_products',
  LocalProgram = 'local_update_program',
  LocalRANoticeAck = 'local_ra_notice_acknowledge',
  Paradigm = 'update_paradigm',
  PickupAddress = 'update_pickup_address',
  PickupContact = 'update_pickup_contact',
  PickupDate = 'update_requested_pickup',
  PickupLocation = 'update_pickup_location',
  Program = 'update_program',
  ReferenceNumbers = 'update_reference_numbers',
  SiteInformation = 'update_site_information',
}

export type TOrderParadigm = Required<Pick<Order, 'paradigm'>> & Partial<Pick<Order, 'emails'>>
export type TOrderReferenceNumbers = Required<Pick<Order, 'reference_numbers'>>
export type TOrderComments = Required<Pick<Order, 'comment'>>
export type TOrderPickupAddress = AddressWithCompany & {
  override_validation?: boolean
}
export type TOrderPickupContact = { contact: Contact; contact_type: ContactType }
export type TOrderPickupLocation = Required<Pick<Order, 'pickup_location'>> & {
  override_validation?: boolean
}
export type TOrderDeliveryLocation = Required<Pick<Order, 'delivery_location'>>
export type TOrderSiteInformation = Required<Pick<Order, 'site_information'>>
export type TOrderAssets = Required<Pick<Order, 'assets'>>
export type TOrderBoxes = Required<Pick<Order, 'boxes'>>
export type TOrderPallets = Required<Pick<Order, 'pallets'>>
export type TOrderProducts = Required<Pick<Order, 'products'>>
export type TOrderPickupDate = Required<Pick<Order, 'pickup_date_end' | 'pickup_date_start'>>
export type TOrderEmails = Required<Pick<Order, 'emails'>>
export type TOrderPayment = Required<Pick<Order, 'payment'>> &
  Pick<Order, 'return_pallet_label' | 'job_id'>
export type TOrderPrices = Required<Pick<Order, 'inboundPriceId' | 'outboundPriceId'>>
export type TOrderRANotice = Required<Pick<Order, 'is_ra_notice_ack'>>
export type TOrderAddAssetDetails = Required<Pick<Order, 'addAssetDetails' | 'assets'>>
export type TOrderCustQuoteNotice = Required<Pick<Order, 'addAssetDetails' | 'assets'>>

export type TOrderNew = { order: TOrderParadigm; quote: TQuoteAddNew }
export type TOrderUpdate<T> = { order: T; quote: TQuoteUpdate }
export type TOrderUpdateLocal<T> = { order: T; quote: TQuoteUpdateFlow }

type Action =
  | { type: 'clear' }
  | { payload: OrderState; type: 'initialize' }
  | { payload: DestinationRouting[]; type: 'set_destination_routes' }
  | { payload: Destination[]; type: 'set_destinations' }
  | { payload: TOrderNew; type: 'new_order' }
  | { payload: { isSingleKitCase: boolean }; type: 'set_single_kit_case' }
  | {
      payload: { quoteData: GetQuoteResponse; userParadigms: Paradigm[]; userPrograms: Program[] }
      type: 'restore_from_quote'
    }
  | { payload: TOrderUpdate<TOrderParadigm>; type: OrderUpdateType.Paradigm }
  | { payload: TOrderUpdate<TOrderReferenceNumbers>; type: OrderUpdateType.ReferenceNumbers }
  | { payload: TOrderUpdate<TOrderComments>; type: OrderUpdateType.Comments }
  | { payload: TOrderUpdate<TOrderPickupAddress>; type: OrderUpdateType.PickupAddress }
  | { payload: TOrderUpdate<TOrderPickupContact>; type: OrderUpdateType.PickupContact }
  | { payload: TOrderUpdate<TOrderPickupLocation>; type: OrderUpdateType.PickupLocation }
  | { payload: TOrderUpdate<TOrderDeliveryLocation>; type: OrderUpdateType.DeliveryLocation }
  | { payload: TOrderUpdate<TOrderSiteInformation>; type: OrderUpdateType.SiteInformation }
  | { payload: TOrderUpdate<TOrderAssets>; type: OrderUpdateType.Assets }
  | { payload: TOrderUpdate<TOrderBoxes>; type: OrderUpdateType.Assets }
  | { payload: TOrderUpdate<TOrderPallets>; type: OrderUpdateType.Assets }
  | { payload: TOrderUpdate<TOrderProducts>; type: OrderUpdateType.Assets }
  | { payload: TOrderUpdate<TOrderPickupDate>; type: OrderUpdateType.PickupDate }
  | { payload: TOrderUpdate<TOrderEmails>; type: OrderUpdateType.Emails }
  | { payload: { quote: TQuoteUpdate }; type: OrderUpdateType.CancelInvoice }
  // Local state updates (no API calls)
  | { payload: TOrderUpdateLocal<TOrderPayment>; type: OrderUpdateType.LocalPayment }
  | { payload: TOrderUpdateLocal<TOrderPrices>; type: OrderUpdateType.KitsAndLabelsPrices }
  | { payload: TOrderUpdateLocal<TOrderRANotice>; type: OrderUpdateType.LocalRANoticeAck }
  | { payload: { quote: TQuoteUpdateFlow }; type: OrderUpdateType.LocalFlow }
  | { payload: { quote: TQuoteUpdateFlow }; type: OrderUpdateType.CustomerRFQ }
  | { payload: { quote: TQuoteUpdateFlow }; type: OrderUpdateType.FlowOnly }
  | {
      payload: { program: Program; resetParadigm?: boolean; userParadigms: Paradigm[] }
      type: OrderUpdateType.LocalProgram
    }
  | { payload: TOrderUpdateLocal<TOrderProducts>; type: OrderUpdateType.LocalProducts }
  | { payload: TOrderUpdateLocal<TOrderAddAssetDetails>; type: OrderUpdateType.LocalAssetDetails }

export type Dispatch = (action: Action) => void
export type OrderState = {
  availableProgramParadigms: Paradigm[]
  customQuoteRequired: boolean
  destinationRoutes: DestinationRouting[]
  destinations: Destination[]
  destinationsLoaded: boolean
  isQuoteEdit: boolean
  isSingleKitCase: boolean
  onlyOneProgramAvailable: boolean
  order: Order
  previousProgram: number | null
  program: Program | null
  quote: Quote
  referenceNumbers: OrderReferenceNumber[]
  restrictBackActionFor?: number
  totalPrice?: number
  userDefaultPath: string
}

export type TOrderKeys = keyof Order
export type TOrderFieldsInitMap = Partial<Record<TOrderKeys, boolean>>

type ContextValue = {
  dispatch: Dispatch
  state: OrderState
}

const OrderContext = React.createContext<ContextValue | undefined>(undefined)

const DEFAULT_CUSTOM_QUOTE_REQUIRED = true

export const getInitialOrderState = (userConfig?: TLoggeinUserConfig): OrderState => {
  return {
    availableProgramParadigms: userConfig?.availableProgramParadigms ?? [],
    customQuoteRequired: DEFAULT_CUSTOM_QUOTE_REQUIRED,
    destinationRoutes: [],
    destinations: [],
    destinationsLoaded: false,
    isQuoteEdit: false,
    isSingleKitCase: false,
    onlyOneProgramAvailable: userConfig?.onlyOneProgramAvailable || false,
    order: {},
    previousProgram: null,
    program: userConfig?.program ? userConfig.program : null,
    quote: {
      flow_id: 0,
      is_local_pickup: false,
      quote_id: '',
      quote_number: '',
    },
    referenceNumbers: userConfig?.referenceNumbers ?? [],
    restrictBackActionFor: 0,
    userDefaultPath: userConfig?.userDefaultPath || '',
  }
}

export const orderReducer = (state: OrderState, action: Action): OrderState => {
  switch (action.type) {
    case 'clear':
      return getInitialOrderState({
        onlyOneProgramAvailable: state.onlyOneProgramAvailable,
        referenceNumbers: state.referenceNumbers,
        userDefaultPath: state.userDefaultPath,
      })

    case 'initialize':
      return action.payload

    case 'set_destination_routes':
      return {
        ...state,
        destinationRoutes: action.payload,
        destinationsLoaded: true,
      }

    case 'set_destinations':
      return {
        ...state,
        destinations: action.payload,
        destinationsLoaded: true,
      }

    case 'set_single_kit_case':
      return {
        ...state,
        isSingleKitCase: action.payload.isSingleKitCase,
      }

    case 'new_order':
    case OrderUpdateType.Paradigm:
    case OrderUpdateType.LocalPayment:
    case OrderUpdateType.LocalProducts:
    case OrderUpdateType.KitsAndLabelsPrices:
    case OrderUpdateType.LocalRANoticeAck:
    case OrderUpdateType.ReferenceNumbers:
    case OrderUpdateType.Comments:
    case OrderUpdateType.PickupLocation:
    case OrderUpdateType.DeliveryLocation:
    case OrderUpdateType.Assets:
    case OrderUpdateType.LocalAssetDetails:
    case OrderUpdateType.PickupDate:
    case OrderUpdateType.SiteInformation:
    case OrderUpdateType.Emails: {
      const newState: OrderState = {
        ...state,
        order: {
          ...state.order,
          ...action.payload.order,
        },
        quote: {
          ...state.quote,
          ...action.payload.quote,
        },
      }

      if (action.type === 'new_order') {
        newState.customQuoteRequired = getCustomQuoteRequiredParam(
          state.program,
          action.payload.order.paradigm.paradigm_id,
        )
      } else if (action.type === OrderUpdateType.Paradigm) {
        // Clear things that are not shared among paradigms
        newState.order.assets = []
        newState.order.boxes = []
        newState.order.pallets = []
        newState.order.products = []
        newState.order.inboundPriceId = undefined
        newState.order.outboundPriceId = undefined
      } else if (
        action.type === OrderUpdateType.Assets ||
        action.type === OrderUpdateType.PickupLocation ||
        action.type === OrderUpdateType.DeliveryLocation
      ) {
        // Prices are recalculated for each of these actions. New prices might
        // come pre-selected. Old priceID becomes invalid any way.
        newState.order.inboundPriceId = getSelectedInboundPriceId(newState.quote)
        newState.order.outboundPriceId = getSelectedOutboundPriceId(newState.quote)
        // recalculate order Total
        newState.totalPrice = calculateOrderTotal(newState.order, newState.quote).amount
      }

      return newState
    }

    case OrderUpdateType.PickupAddress: {
      const newState = {
        ...state,
        order: {
          ...state.order,
          pickup_location: {
            ...state.order.pickup_location,
            address: action.payload.order.address,
            company: action.payload.order.company,
            contacts: action.payload.quote.pickup_location_contacts ?? [],
          },
        },
        quote: {
          ...state.quote,
          ...action.payload.quote,
        },
      }
      // Prices are recalculated when pickup address changes. New prices might
      // come pre-selected. Old priceID becomes invalid any way.
      newState.order.inboundPriceId = getSelectedInboundPriceId(newState.quote)
      newState.order.outboundPriceId = getSelectedOutboundPriceId(newState.quote)
      // recalculate order Total
      newState.totalPrice = calculateOrderTotal(newState.order, newState.quote).amount

      return newState
    }

    case OrderUpdateType.PickupContact: {
      const newState: OrderState = {
        ...state,
        quote: {
          ...state.quote,
          ...action.payload.quote,
        },
      }

      if (newState.order.pickup_location) {
        const contacts = newState.order.pickup_location.contacts ?? []
        if (action.payload.order.contact_type === ContactType.Primary) {
          contacts[0] = action.payload.order.contact
        } else {
          if (!contacts[0]) {
            contacts[0] = getDefaultContact()
          }
          contacts[1] = action.payload.order.contact
        }

        newState.order.pickup_location.contacts = contacts
      }

      return newState
    }

    case OrderUpdateType.CustomerRFQ:
    case OrderUpdateType.FlowOnly: // for remote flow update
    case OrderUpdateType.LocalFlow: // for local flow update
      return {
        ...state,
        quote: {
          ...state.quote,
          flow_id: action.payload.quote.flow_id,
        },
      }

    case OrderUpdateType.CancelInvoice:
      return {
        ...state,
        quote: {
          ...state.quote,
          ...action.payload.quote,
        },
      }

    case OrderUpdateType.LocalProgram: {
      const program = action.payload.program
      const newState: OrderState = {
        ...state,
        availableProgramParadigms: getAvailableProgramParadigms(
          program.paradigms,
          action.payload.userParadigms,
        ),
        program,
      }
      if (action.payload.resetParadigm) {
        newState.customQuoteRequired = DEFAULT_CUSTOM_QUOTE_REQUIRED
        newState.order.paradigm = undefined
      }

      return newState
    }

    case 'restore_from_quote': {
      const programId = action.payload.quoteData.program_id
      const program = action.payload.userPrograms.find(p => p.program_id === programId) ?? null
      const availableProgramParadigms = program
        ? getAvailableProgramParadigms(program.paradigms, action.payload.userParadigms)
        : []

      const newState: OrderState = {
        ...getInitialOrderState({
          availableProgramParadigms,
          onlyOneProgramAvailable: state.onlyOneProgramAvailable, // safe to copy from the existent state
          referenceNumbers:
            action.payload.quoteData.return_authorization?.lessor_reference_numbers ??
            state.referenceNumbers,
          userDefaultPath: state.userDefaultPath,
        }),
        ...mapQuoteResponseToUiState(action.payload),
        isQuoteEdit: true,
        isSingleKitCase: false, // this might need to change based on API response
        program,
        restrictBackActionFor: action.payload.quoteData.restrict_back_action_for,
      }
      newState.totalPrice = calculateOrderTotal(newState.order, newState.quote).amount
      newState.customQuoteRequired = getCustomQuoteRequiredParam(
        newState.program,
        action.payload.quoteData.paradigm_id,
      )

      // On BE, we do not reset paradigm when changing program. Because of this,
      // it is possible to receive quote data where paradigm_id is not in the
      // list of available paradigms for current program. Thus we might need to
      // reset paradigm here. In the future, BE will reset paradigm properly and
      // this code will not be needed.
      const paradigmId = action.payload.quoteData.paradigm_id
      const resetParadigm =
        availableProgramParadigms.findIndex(p => p.paradigm_id === paradigmId) < 0
      if (resetParadigm) {
        newState.customQuoteRequired = DEFAULT_CUSTOM_QUOTE_REQUIRED
        newState.order.paradigm = undefined
      }

      return newState
    }

    default:
      throw new Error('Unhandled action type')
  }
}

type TLoggeinUserConfig = {
  availableProgramParadigms?: Paradigm[]
  onlyOneProgramAvailable?: boolean
  program?: Program | null
  referenceNumbers: OrderReferenceNumber[]
  userDefaultPath?: string
}
type OrderProviderProps = {
  children: React.ReactNode
  program?: Program
  userConfig: TLoggeinUserConfig
}

const OrderProvider = ({ children, userConfig }: OrderProviderProps): JSX.Element => {
  const [state, dispatch] = React.useReducer(orderReducer, getInitialOrderState(userConfig))

  const value = { dispatch, state }

  return <OrderContext.Provider value={value}>{children}</OrderContext.Provider>
}

const useOrder = (): ContextValue => {
  const context = React.useContext(OrderContext)

  if (context === undefined) {
    throw new Error('useOrder must be used within an OrderProvider')
  }

  return context
}

export { OrderProvider, useOrder }

type RestoredOrderState = {
  order: Order
  quote?: Quote
}

const mapQuoteResponseToUiState = ({
  quoteData: quote,
  userPrograms,
}: {
  quoteData: GetQuoteResponse
  userPrograms: Program[]
}): RestoredOrderState => {
  // Paradigm from quote.paradigm don't have correct payment_terms (payment_terms is fetched from brand)
  // Correct payment_terms can be found in user.programs.paradigms
  const targetParadigmFromProgram = userPrograms
    .find(program => program.program_id === quote.program_id)
    ?.paradigms.find(paradigm => paradigm.paradigm_id === quote.paradigm_id)
  const paradigm = { ...quote.paradigm }
  if (targetParadigmFromProgram) {
    paradigm.payment_terms = targetParadigmFromProgram.payment_terms
  }

  const orderMapped: Order = {
    addAssetDetails: !!quote.asset?.length,
    assets: quote.asset.map(asset => ({ id: asset.asset_id, quantity: asset.quantity })),
    boxes:
      quote.box?.map(box => ({
        asset_id: Number(box.asset_id) || 0,
        height: Number(box.height) ? box.height : '',
        length: Number(box.length) ? box.length : '',
        quantity: Number(box.quantity) || 0,
        weight: Number(box.weight) ? box.weight : '',
        width: Number(box.width) ? box.width : '',
      })) || [],
    comment: quote.comments,
    delivery_location: quote.delivery_location
      ? {
          address: quote.delivery_location.address,
          company: quote.delivery_location.company,
          contacts: [quote.delivery_location.contact],
        }
      : undefined,
    emails: mapApiToUIEmails(quote.emails),
    inboundPriceId: getSelectedInboundPriceId(quote),
    invoice_id: quote.invoice_id,
    is_ra: quote.is_ra,
    job_id: quote.job_id,
    outboundPriceId: getSelectedOutboundPriceId(quote),
    pallets: quote.pallet.map(pallet => ({
      asset_id: Number(pallet.asset_id),
      height: pallet.height,
      icon: '', // TODO
      length: pallet.length,
      name: pallet.type,
      quantity: pallet.quantity,
      weight: pallet.weight,
      width: pallet.width,
    })),
    paradigm,
    payment: mapPaymentType(quote.payment_method_description),
    pickup_date_end: makeDate(quote.requested_pickup.end_date),
    pickup_date_start: makeDate(quote.requested_pickup.start_date),
    pickup_location: quote.pickup_location
      ? {
          address: quote.pickup_location.address,
          company: quote.pickup_location.company,
          contacts: quote.pickup_location.contact,
        }
      : undefined,
    products: mapSelectedProducts(paradigm.products, quote.product),
    ra_information: quote.return_authorization,
    reference_numbers: [quote.reference1, quote.reference2, quote.reference3],
    site_information: quote.site_information,
  }

  return { order: orderMapped, quote }
}

const makeDate = (datetime: string): Date | undefined => {
  const dt = new Date(datetime)
  return isNaN(dt.getTime()) ? undefined : dt
}

const mapSelectedProducts = (
  allProducts: Product[],
  selectedProducts: QuoteProduct[],
): ProductWithQuantity[] => {
  // INFO: There can be more entries in selectedProducts than in allProducts,
  // because it is possible to have multiple entries for "waybill" products.
  // We need to group products by ID and calculate quantity for each product
  const quantityMap: Map<number, number> = new Map() // product_id -> quantity
  selectedProducts.forEach(product => {
    const prod_id = Number(product.product_id)
    quantityMap.set(prod_id, (quantityMap.get(prod_id) ?? 0) + Number(product.quantity))
  })

  // Process non waybill products: add quantity
  let products = allProducts
    .filter(p => quantityMap.get(p.product_id) !== undefined && p.type !== 'waybill')
    .map(p => ({ ...p, quantity: quantityMap.get(p.product_id) || 0 }))

  // Process waybill products: add quantity, dimensions and weight
  const waybillProduct = allProducts.find(p => p.type === 'waybill')
  if (waybillProduct) {
    // In reality, we assume that paradigm has at least one waybill, so this
    // code will be executed
    products = products.concat(
      selectedProducts
        .filter(p => quantityMap.get(p.product_id) !== undefined && p.type === 'waybill')
        .map(p => ({
          ...waybillProduct,
          dimensions: { height: p.height, length: p.length, width: p.width },
          quantity: p.quantity,
          weight: p.weight,
        })),
    )
  }

  return products
}

const getCustomQuoteRequiredParam = (
  program: Program | undefined | null,
  paradigmId: number | undefined,
): boolean => {
  if (!program || !paradigmId) {
    return DEFAULT_CUSTOM_QUOTE_REQUIRED
  } else {
    return !!program.paradigms.find(p => p.paradigm_id === paradigmId)?.custom_quote
  }
}

const getAvailableProgramParadigms = (
  programParadigms: ProgramParadigm[],
  userParadigms: Paradigm[],
): Paradigm[] => {
  /** There are paradigms for the user and for the program. This loop will find paradigms that exist
   * in both the user paradigms and program paradigms. This will determine which paradigms
   * are available to see.
   */
  const paradigms =
    programParadigms?.reduce((result, current) => {
      const unionParadigm = userParadigms.find(
        paradigm => paradigm.paradigm_id === current.paradigm_id,
      )
      if (unionParadigm) result.push(unionParadigm)
      return result
    }, [] as Paradigm[]) || []

  return sortBy(paradigms, ['name'])
}
