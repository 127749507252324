import { FC } from 'react'

import { ActionTypes, GridDispatch } from 'components/GridView/state'
import { SearchAndFilterView } from './SearchAndFilter'

interface Props {
  clearFilters: () => void
  dispatch: GridDispatch
  hideBasicSearch?: boolean
  query: string
}

export const SearchAndFilter: FC<Props> = ({ dispatch, hideBasicSearch, query, clearFilters }) => {
  const toggleSearch = () => dispatch({ type: ActionTypes.ToggleAdvancedSearch })

  return (
    <SearchAndFilterView
      hideBasicSearch={hideBasicSearch}
      onClear={clearFilters}
      onToggleSearch={toggleSearch}
      query={query}
    />
  )
}
