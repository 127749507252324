import { api } from 'lib/api/base'
import { ENDPOINTS } from 'lib/api/endpoints'
import { GridDataResponse, NormalizedGridDataResponse } from 'lib/api/types'
import { formatGridData } from 'lib/api/utils/formatGridData'

export type RecordsPerPageParams = {
  brandId?: number
  configId: number
  perPage: number
  slaRuleSetId?: number
}

type UpdateRecordsPerPage = (params: RecordsPerPageParams) => Promise<NormalizedGridDataResponse>

export const updateRecordsPerPage: UpdateRecordsPerPage = async ({
  brandId,
  slaRuleSetId,
  configId,
  perPage,
}) => {
  const { data } = await api.post<GridDataResponse>(ENDPOINTS.gridConfigs, {
    action: 'update_per_page',
    brand_id: brandId,
    config_id: configId,
    per_page: perPage,
    set_id: slaRuleSetId,
  })

  return formatGridData(data)
}
