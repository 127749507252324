export const DateFormats: { [key: string]: string } = {
  af: 'yyyy-MM-dd',
  ar: 'd\u200f/M\u200f/yyyy',
  'ar-DZ': 'd\u200f/M\u200f/yyyy',
  az: 'yyyy-MM-dd',
  be: 'd.M.y',
  bg: 'dd.MM.y',
  bs: 'M/d/y',
  ca: 'dd/MM/y',
  cs: 'd.M.y',
  'cy-GB': 'yyyy-MM-dd',
  da: 'dd/MM/y',
  de: 'dd.MM.y',
  el: 'dd/MM/yyyy',
  'en-AU': 'd/MM/y',
  'en-GB': 'dd/MM/yyyy',
  'en-NZ': 'd/MM/y',
  'en-US': 'MM/dd/yyyy',
  eo: 'y-MM-dd',
  es: 'dd/MM/y',
  et: 'dd.MM.y',
  eu: 'yyyy-MM-dd',
  fa: 'y/M/d',
  fi: 'd.M.yyyy',
  fo: 'dd-MM-y',
  fr: 'dd/MM/y',
  'fr-CA': 'y-MM-dd',
  'fr-CH': 'dd.MM.y',
  gl: 'dd/MM/y',
  he: 'dd/MM/y',
  hi: 'd-M-y',
  hr: 'dd.MM.yyyy.',
  hu: 'yyyy.MM.dd.',
  hy: 'MM/dd/y',
  id: 'dd/MM/y',
  is: 'd.M.yyyy',
  it: 'dd/MM/y',
  'it-CH': 'dd.MM.y',
  ja: 'y/MM/dd',
  ka: 'yyyy-MM-dd',
  kk: 'dd.MM.y',
  km: 'd/M/yyyy',
  ko: 'y. M. d.',
  ky: 'M/d/y',
  lb: 'M/d/y',
  lt: 'yyyy-MM-dd',
  lv: 'dd.MM.y',
  mk: 'dd.M.y',
  ml: 'dd/MM/y',
  ms: 'yyyy-MM-dd',
  nb: 'dd.MM.y',
  nl: 'dd-MM-y',
  'nl-BE': 'd/MM/y',
  nn: 'dd.MM.y',
  no: 'dd.MM.y',
  pl: 'dd-MM-y',
  pt: 'dd/MM/y',
  'pt-BR': 'dd/MM/y',
  rm: 'M/d/y',
  ro: 'dd.MM.yyyy',
  ru: 'dd.MM.y',
  sk: 'd.M.yyyy',
  sl: 'd. MM. y',
  sq: 'y-MM-dd',
  sr: 'd.M.y.',
  'sr-SR': 'd.M.y.',
  sv: 'yyyy-MM-dd',
  ta: 'd-M-y',
  th: 'd/M/yyyy',
  tj: 'M/d/y',
  tr: 'dd.MM.yyyy',
  uk: 'dd.MM.y',
  vi: 'dd/MM/yyyy',
  'zh-CN': 'y-M-d',
  'zh-HK': 'y\u5e74M\u6708d\u65e5',
  'zh-TW': 'y/M/d',
}

export const DefaultDateFormat = 'MM/dd/yyyy'
