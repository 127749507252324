import React from 'react'
import Col from 'react-bootstrap/Col'
import { FieldArray, Formik } from 'formik'
import Row from 'react-bootstrap/Row'

import { Form } from 'components/Form'
import { FormContainer, TPreviousPageHandler } from 'components/FormContainer'
import { Pallet, SelectOption, TFormSubmit } from 'types'

import { AddPalletButton } from './components/AddPalletButton'
import { PalletRow } from './components/PalletRow'
import { PalletsSchema } from './schema'
import { useModalState } from 'hooks/useModalState'
import { ConfirmationDialog } from 'components/ConfirmationDialog'
export interface FormValues {
  pallets: Pallet[]
}

export type Props = {
  initialValues: FormValues
  onGoToPreviousPage: TPreviousPageHandler
  onSubmit: TFormSubmit<FormValues, void>
  palletTypeOptions: SelectOption[]
  title: string
}

export const emptyPallet: Pallet = {
  asset_id: 0,
  height: '',
  icon: '',
  length: '',
  name: '',
  quantity: '',
  weight: '',
  width: '',
}

export const PalletsForm = ({
  initialValues,
  onGoToPreviousPage,
  onSubmit,
  palletTypeOptions,
  title,
}: Props): JSX.Element => {
  const {
    hide: hideConfirmDeleteModal,
    open: openConfirmDeleteModal,
    state: confirmDeleteModalState,
  } = useModalState<{ indexToDelete: number }>({ indexToDelete: 0 })

  const onRemovePalletRow = React.useCallback(
    (index: number) => {
      openConfirmDeleteModal({ indexToDelete: index })
    },
    [openConfirmDeleteModal],
  )

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={values => onSubmit(values)}
      validationSchema={PalletsSchema}
    >
      {({ errors, handleSubmit, isSubmitting, submitCount, values }) => (
        <Form onSubmit={handleSubmit}>
          <FormContainer
            formError={
              submitCount > 0 && errors.pallets && typeof errors.pallets === 'string'
                ? errors.pallets
                : ''
            }
            fullWidth
            onGoToPreviousPage={onGoToPreviousPage}
            isSubmitting={isSubmitting}
            title={title}
          >
            <FieldArray name="pallets">
              {({ push, remove }) => (
                <>
                  {values.pallets.map((pallet, index) => (
                    <PalletRow
                      errors={submitCount > 0 ? errors : undefined}
                      index={index}
                      key={`${pallet.asset_id}_${index}`}
                      onRemove={values.pallets.length > 1 ? onRemovePalletRow : undefined}
                      palletTypeOptions={palletTypeOptions}
                    />
                  ))}

                  <Row className="justify-content-end">
                    <Col className="text-end" md={3} xs={12}>
                      <AddPalletButton onClick={() => push({ ...emptyPallet })} />
                    </Col>
                  </Row>

                  <ConfirmationDialog
                    acceptText="Yes"
                    cancelText="No"
                    disabled={false}
                    onAccept={() => {
                      remove(confirmDeleteModalState.indexToDelete)
                      hideConfirmDeleteModal()
                    }}
                    onCancel={hideConfirmDeleteModal}
                    show={confirmDeleteModalState.show}
                    title="Remove Pallet"
                  >
                    Are you sure you want to remove the pallet?
                  </ConfirmationDialog>
                </>
              )}
            </FieldArray>
          </FormContainer>
        </Form>
      )}
    </Formik>
  )
}
