import * as React from 'react'
import { useLocation } from 'react-router'

import { getDefaultPath } from '../../utils/getDefaultPath'
import { MenuConfig } from 'types'
import { useScrollPosition } from 'components/Header/hooks/useScrollPosition'

import { HeaderView } from './Header'
import { useAppContext } from 'contexts/ContextProvider'
import { UNAUTHORIZED_ROUTES } from 'Routes'

type Props = {
  additionalActions?: React.ReactNode
  className?: string
}

export const Header = ({ additionalActions, className }: Props): JSX.Element => {
  const { alertCount, user } = useAppContext()
  const { pathname } = useLocation()

  const defaultPath = getDefaultPath(user)
  const onLoginPage = pathname === UNAUTHORIZED_ROUTES.login
  const allMenuItems = ([] as MenuConfig[]).concat(
    ...(user?.menu.map(item => item.menu || item) ?? []),
  )

  const pageTitle = allMenuItems.find(({ path }) => path === pathname)?.description
  const scrollPosition = useScrollPosition()

  const [collapsedMenuOpen, setCollapsedMenuOpen] = React.useState(false)

  return (
    <HeaderView
      additionalActions={additionalActions}
      alertCount={alertCount}
      className={className}
      currentMenuItem={pathname}
      defaultPath={defaultPath}
      isAuthorized={!!user}
      menuItems={user?.menu ?? []}
      mobileMenuOpen={collapsedMenuOpen}
      onLoginPage={onLoginPage}
      pageTitle={pageTitle}
      scrollPosition={scrollPosition}
      toggleMobileMenu={() => setCollapsedMenuOpen(!collapsedMenuOpen)}
    />
  )
}
