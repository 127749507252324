import React, { FC, useCallback, useEffect, useState } from 'react'
import { Form, FormControl } from 'react-bootstrap'
import { useNavigate } from 'react-router'

import { SearchGlassIcon } from 'components/SearchGlassIcon'
import styles from './SearchBar.module.scss'
import classNames from 'classnames'
import { ROUTES } from 'Routes'

type SearchBarProps = {
  callback?: () => void
  collapsed?: boolean
  onCollapsedClick?: () => void
  query?: string
}
export const SearchBar: FC<SearchBarProps> = ({ callback, collapsed, onCollapsedClick, query }) => {
  const navigate = useNavigate()

  const [searchTerm, setSearchTerm] = useState(query || '')
  useEffect(() => {
    setSearchTerm(query || '')
  }, [query])

  const handleSubmit = useCallback(
    (formSubmitEvent: React.FormEvent) => {
      formSubmitEvent.preventDefault()
      if (!collapsed && searchTerm) {
        void navigate(`${ROUTES.dispatchSearch}?query=${encodeURIComponent(searchTerm)}`)
        callback && callback()
      }
    },
    [callback, collapsed, navigate, searchTerm],
  )

  const handleSearchChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => setSearchTerm(e.currentTarget.value),
    [setSearchTerm],
  )

  return (
    <Form
      className={classNames(styles.SearchBarForm, { [styles.Collapsed]: collapsed })}
      onSubmit={handleSubmit}
    >
      <FormControl
        className={styles.SearchBarInput}
        onChange={handleSearchChange}
        placeholder="Global Search"
        type="search"
        value={searchTerm}
      />
      {collapsed ? (
        <button
          className={styles.SearchBarButton}
          onClick={e => {
            e.preventDefault()
            e.stopPropagation()

            if (onCollapsedClick) {
              onCollapsedClick()
            }
          }}
          type="button"
        >
          <SearchGlassIcon fill="blue" />
        </button>
      ) : (
        <button className={styles.SearchBarButton} type="submit">
          <SearchGlassIcon fill="blue" />
        </button>
      )}
    </Form>
  )
}
