import { DataTypeProvider } from '@devexpress/dx-react-grid'

import { ColumnName, DynamicObject } from 'types'
import { TransparentButton } from 'components/TransparentButton'
import { TrashIcon } from 'components/TrashIcon'

export interface Props {
  column: ColumnName
  onClickFormatter: (row: DynamicObject) => () => void
}

export const CancelButtonProvider = ({ onClickFormatter, column }: Props): JSX.Element | null => {
  const CancelButtonFormatter = ({
    row,
  }: DataTypeProvider.ValueFormatterProps): JSX.Element | null => {
    const jobStatus = row[ColumnName.jobStatus]
    return jobStatus !== 'Delivered' ? (
      <TransparentButton
        aria-label="cancel button"
        className="w-100"
        onClick={onClickFormatter(row as DynamicObject)}
      >
        <TrashIcon />
      </TransparentButton>
    ) : null
  }
  return <DataTypeProvider formatterComponent={CancelButtonFormatter} for={[column]} />
}
