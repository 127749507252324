import { FC } from 'react'

import { useModalState } from 'hooks/useModalState'
import { TransparentButton } from 'components/TransparentButton'
import { ExportRecordsDialogBox } from 'components/ExportRecordsDialogBox'
import { QueueNoticeDialog } from './QueueNoticeDialog'

export type Props = {
  configId: number
  gridName: string
}
export const Export: FC<Props> = modalProps => {
  const { hide: exportHide, state: exportState, open: exportOpen } = useModalState()
  const { hide: dialogHide, state: dialogState, open: dialogOpen } = useModalState()
  return (
    <>
      <TransparentButton onClick={exportOpen}>Export</TransparentButton>
      <ExportRecordsDialogBox
        hideWithNotice={() => {
          exportHide()
          dialogOpen()
        }}
        hide={exportHide}
        {...modalProps}
        {...exportState}
      />
      <QueueNoticeDialog hide={dialogHide} {...dialogState} />
    </>
  )
}
