import { FC } from 'react'
import { useField, FormikHelpers } from 'formik'

import { AdvancedSearchFilter } from 'types'
import { Select } from 'components/Select'

export type Props = {
  className?: string
  filterableColumns: {
    columnId: number
    heading: string
    typeId: number
  }[]
  index: number
  setFieldValue: FormikHelpers<AdvancedSearchFilter>['setFieldValue']
}

export const FilterableColumnSelect: FC<Props> = ({
  className,
  filterableColumns,
  index,
  setFieldValue,
}) => {
  const columnSelectName = `filters[${index}].columnId`
  const searchValueName = `filters[${index}].value`
  const [field] = useField(columnSelectName)

  const filterableColumnOptions = filterableColumns.map(({ heading, columnId }) => ({
    label: heading,
    value: columnId,
  }))

  return (
    <Select
      {...field}
      className={className}
      floatingLabel={false}
      label="Filterable column"
      onChange={value => {
        void setFieldValue(searchValueName, '')
        void setFieldValue(columnSelectName, value)
      }}
      options={filterableColumnOptions}
    />
  )
}
