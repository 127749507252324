import { FC, useState } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import { Formik } from 'formik'

import { reportQueueAdd, ReportQueueParams } from 'lib/api/reportQueue/add'
import { Modal } from 'components/Modal'
import { useLoggedInAppContext } from 'hooks/useLoggedInAppContext'

import { CheckboxField } from 'components/CheckboxField'
import { TextField } from 'components/TextField'
import { TextareaField } from 'components/TextareaField'

import { ExportRecordsSchema } from './schema'

const formId = 'export-records-form'

export type FormValues = {
  bccEmail: string
  body: string
  ccEmail: string
  emailReport: boolean
  fileName: string
  subject: string
  title: string
  toEmail: string
}

export type Props = {
  configId: number
  gridName: string
  hide: () => void
  hideWithNotice: () => void
  show: boolean
}

const maybeZeroPadLeft = (n: number): string => {
  if (n < 10) return `0${n}`
  return String(n)
}

const getDateTimeFileName = (gridName: string): string => {
  const currentDateTime = new Date(Date.now())
  const suffix =
    String(currentDateTime.getFullYear()) +
    `_${maybeZeroPadLeft(currentDateTime.getMonth() + 1)}` +
    `_${maybeZeroPadLeft(currentDateTime.getDate())}` +
    `_${maybeZeroPadLeft(currentDateTime.getHours())}` +
    `${maybeZeroPadLeft(currentDateTime.getMinutes())}`

  gridName = gridName.toLowerCase().replace(/\s+|['.,+()]+/g, '_')
  return `${gridName}_${suffix}`
}

export const ExportRecordsDialogBox: FC<Props> = ({
  configId,
  gridName,
  hide,
  hideWithNotice,
  show,
}) => {
  const {
    user: { email: userEmail },
  } = useLoggedInAppContext()

  const [saving, setSaving] = useState(false)

  const filename = getDateTimeFileName(gridName)

  const initialValues: FormValues = {
    bccEmail: '',
    body: 'Your report is attached',
    ccEmail: '',
    emailReport: false,
    fileName: `${filename}.xlsx`,
    subject: `${gridName} Report From ReturnCenter`,
    title: `${gridName} Report`,
    toEmail: userEmail,
  }

  const handleSubmit = async (values: FormValues) => {
    try {
      setSaving(true)
      const params: ReportQueueParams = {
        configId,
        emailBcc: values.bccEmail,
        emailBody: values.body,
        emailCc: values.ccEmail,
        emailSubject: values.subject,
        emailTo: values.toEmail,
        filename: values.fileName,
        title: values.title,
      }
      if (!values.emailReport) {
        params.emailBcc = ''
        params.emailBody = ''
        params.emailCc = ''
        params.emailSubject = ''
        params.emailTo = ''
      }
      await reportQueueAdd(params)
        .then(() => {
          hideWithNotice()
        })
        .catch(() => {
          hide()
        })
        .finally(() => null)
    } catch {
    } finally {
      setSaving(false)
    }
  }

  return (
    <Modal
      formId={formId}
      hide={hide}
      show={show}
      saving={saving}
      size="lg"
      submitButtonText="Export"
      title="Export into a Report"
    >
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={ExportRecordsSchema}
        enableReinitialize
      >
        {({ handleSubmit, values }) => {
          return (
            <Form className="w-100" noValidate id={formId} onSubmit={handleSubmit}>
              <Row className="mb-2">
                <Col md="10">
                  <TextField label="Report Title" name="title" className="mb-3" />
                </Col>
              </Row>
              <Row className="mb-2">
                <Col md="10">
                  <TextField label="Report Filename" name="fileName" className="mb-3" />
                </Col>
              </Row>
              <Row className="mb-2">
                <Col md="10">
                  <CheckboxField label="Email the Report" name="emailReport" />
                </Col>
              </Row>
              {values.emailReport && (
                <Col md="12" className="ms-4">
                  <Row className="mt-4 mb-2">
                    <Col md="10">
                      <TextField label="Subject" name="subject" className="mb-3" />
                    </Col>
                  </Row>
                  <Row className="mb-4">
                    <Col md="10">
                      <TextareaField label="Body" name="body" />
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col md="10">
                      <TextField label="To" name="toEmail" type="email" className="mb-3" />
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col md="10">
                      <TextField label="CC" name="ccEmail" type="email" className="mb-3" />
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col md="10">
                      <TextField label="BCC" name="bccEmail" type="email" className="mb-3" />
                    </Col>
                  </Row>
                </Col>
              )}
            </Form>
          )
        }}
      </Formik>
    </Modal>
  )
}
