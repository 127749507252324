import { api } from 'lib/api/base'
import { ENDPOINTS } from 'lib/api/endpoints'
import { BaseGridConfigResponse } from '..'

type Params = {
  columnId: number
  configId: number
  width: number
}

type UpdateColumnWidth = (params: Params) => Promise<number>

export const updateColumnWidth: UpdateColumnWidth = async ({ columnId, configId, width }) => {
  const {
    data: { config_id },
  } = await api.post<BaseGridConfigResponse>(ENDPOINTS.gridConfigs, {
    action: 'update_column_width',
    column_id: columnId,
    config_id: configId,
    width,
  })

  return Number(config_id)
}
