import { useEffect } from 'react'
import { useNavigate, useLocation, Outlet } from 'react-router'

import { GuestOrderProvider, useGuestOrderContext } from 'contexts/GuestOrderContextProvider'
import { useBrandPathContext } from 'contexts/BrandPathContext'
import { api } from 'lib/api/base'
import { ENDPOINTS } from 'lib/api/endpoints'
import { GuestPageResponse } from 'lib/api/types'
import { getGuestToken } from 'utils/guestToken'
import { getLocalToken } from 'utils/localToken'

export const NewStyleFlowRouter = (): JSX.Element => {
  return (
    <GuestOrderProvider>
      <NewStyleFlowRouterInner />
    </GuestOrderProvider>
  )
}

const NewStyleFlowRouterInner = (): JSX.Element => {
  const navigate = useNavigate()
  const location = useLocation()
  const { brandPath } = useBrandPathContext()
  const { setBackActionLoading, setGuestOrder } = useGuestOrderContext()
  const currentPath = location.pathname

  // This useEffect() adds an additional entry of current path to the history
  // stack. Since the top 2 entries represent the same page in the stack we can
  // use history.goBack() call on any page to process back action. This works
  // the same as browser's back button.
  // When history.goBack() is executed, this does the following:
  // 1. Browser removes 1 entry from the history stack and navigates page to the
  //    new URL on the top of the stack. In our case this is the same URL and
  //    thus no page real navigation occurs. UX is that no page redirection
  //    happened.
  // 2. Browser triggers a "popstate" event. We catch this event and execute
  //    API call to get a path to the "prev" page. Then user is navigated to the
  //    received path.
  useEffect(() => {
    // eslint-disable-next-line no-console
    // console.log('push state', `${brandPath}${currentPath}`)
    window.history.pushState('', '', `${brandPath}${currentPath}`)
  }, [brandPath, currentPath])

  // This useEffect() handles "popstate" event described above
  useEffect(() => {
    let popstateHandlerTimeoutId: NodeJS.Timeout

    const popstateHandler = () => {
      // Regarding usage of setTimeout() call,
      // see note in https://developer.mozilla.org/en-US/docs/Web/API/Window/popstate_event
      popstateHandlerTimeoutId = setTimeout(() => {
        setBackActionLoading(true)

        if (popstateHandlerTimeoutId) {
          clearTimeout(popstateHandlerTimeoutId)
        }

        const token = getLocalToken() || getGuestToken() || ''

        const submitUserAction = async () => {
          const res = await submitBackUserAction(token, currentPath)
          setBackActionLoading(false)
          setGuestOrder(res)
          // Using replace() instead of push(), enables browser "Forward" arrow.
          // This creates another challenge sa it needs be be consistentl with
          // Next button. Its something we want to take a look at later.
          if (location.pathname !== res.page_path) {
            void navigate(res.page_path, { replace: true })
          }

          // // Simulate API call delay to test loading UI
          // const timeoutId = setTimeout(() => {
          //   // remove loading progress
          //   setBackActionLoading(false)

          //   setGuestOrder(res)
          //   // Using replace() instead of push(), enables browser "Forward" arrow.
          //   // This creates another challenge sa it needs be be consistentl with
          //   // Next button. Its something we want to take a look at later.
          //   if (location.pathname !== res.page_path) {
          //     navigate(res.page_path, { replace: true })
          //   }
          //   clearTimeout(timeoutId)
          // }, 1000)
        }

        void submitUserAction()
      }, 0)
    }

    // Cleanup any old event handlers before adding a new one
    window.removeEventListener('popstate', popstateHandler)
    window.addEventListener('popstate', popstateHandler)

    return () => {
      window.removeEventListener('popstate', popstateHandler)
    }
  }, [currentPath, navigate, location, setBackActionLoading, setGuestOrder])

  return <Outlet />
}

const submitBackUserAction = async (
  token: string,
  currentPath: string,
): Promise<GuestPageResponse> => {
  const { data } = await api.post<GuestPageResponse>(ENDPOINTS.page, {
    action: 'goto_prev_page',
    current_page_path: currentPath,
    token,
  })

  return data
}
