import * as React from 'react'
import classNames from 'classnames'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

import { FormikDatePicker } from 'components/FormikDatePicker'
import { SelectField } from 'components/SelectField'
import { Relation, SelectOption } from 'types'
import { TextField } from 'components/TextField'

import styles from './FilterRow.module.scss'
import { Button } from 'components/Button'

enum ValueType {
  string = 1,
  integer = 2,
  date = 3,
  sla = 4,
  currency = 5,
  flag = 6,
}

const relationOptions = [
  {
    label: 'AND',
    value: Relation.and,
  },
  {
    label: 'OR',
    value: Relation.or,
  },
]

export type Props = {
  columnOptions: SelectOption[]
  filterTypeOptions: SelectOption[]
  index: number
  onColumnIdChange: (value: SelectOption['value']) => void
  onRemoveFilter: () => void
  valueTypeId: number
}

export const FilterRow = ({
  columnOptions,
  filterTypeOptions,
  index,
  onColumnIdChange,
  onRemoveFilter,
  valueTypeId,
}: Props): JSX.Element => {
  const valueInput = React.useMemo(() => {
    switch (valueTypeId) {
      case ValueType.date:
        return (
          <FormikDatePicker
            ariaLabelledBy="Filter value"
            autoComplete="off"
            className={styles.DatePickerInput}
            name={`filters[${index}].value`}
            wrapperClassName={styles.DatePickerWrapper}
          />
        )

      case ValueType.integer:
      case ValueType.string:
        return <TextField hideLabel label="Filter value" name={`filters[${index}].value`} />

      default:
        return null
    }
  }, [index, valueTypeId])

  return (
    <Row className={classNames(styles.FilterRow, 'pb-3 mb-3 border-bottom')}>
      {index > 0 && (
        <Col className="mb-3" xs={12}>
          <SelectField
            ariaLabel="Relation"
            label=""
            name={`filters[${index}].relationId`}
            options={relationOptions}
          />
        </Col>
      )}

      <Col className="mb-3" xs={12}>
        <SelectField
          ariaLabel="Column to filter"
          label=""
          name={`filters[${index}].columnId`}
          onChange={onColumnIdChange}
          options={columnOptions}
        />
      </Col>

      <Col className="mb-3" xs={12}>
        <SelectField
          ariaLabel="Filter type"
          label=""
          name={`filters[${index}].typeId`}
          options={filterTypeOptions}
        />
      </Col>

      <Col className="mb-3" xs={12}>
        {valueInput}
      </Col>

      {index > 0 && (
        <Col>
          <Button onClick={onRemoveFilter} size="sm" variant="danger">
            Remove Filter
          </Button>
        </Col>
      )}
    </Row>
  )
}
