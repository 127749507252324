import * as React from 'react'

import { ExpandableRow, HighlightedValue } from './components/ExpandableRow'

type Props<DataType> = {
  children?: (row: DataType) => React.ReactNode
  data: DataType[]
  generateKey: (row: DataType, index: number) => string
  getClassName?: (row: DataType) => string | undefined
  getHighlightedValues: (row: DataType) => HighlightedValue[]
}

export const ExpandableTable = <DataType,>({
  children,
  data,
  generateKey,
  getClassName,
  getHighlightedValues,
}: Props<DataType>): JSX.Element => {
  if (data.length === 0) {
    return <div className="p-3 text-center">No data</div>
  }

  return (
    <>
      {data.map((row, index) => (
        <ExpandableRow
          className={getClassName && getClassName(row)}
          highlightedValues={getHighlightedValues(row)}
          index={index}
          key={generateKey(row, index)}
        >
          {children && children(row)}
        </ExpandableRow>
      ))}
    </>
  )
}
