import React from 'react'
import { PagingPanel } from '@devexpress/dx-react-grid'

import { PageOptions } from './PageOptions'
import { Select } from 'components/Select'
import styles from './PagingPanel.module.scss'

export const PagingPanelContainer: React.FC<PagingPanel.ContainerProps> = ({
  currentPage,
  onCurrentPageChange,
  onPageSizeChange,
  pageSize,
  pageSizes,
  totalCount,
  totalPages,
}) => {
  return (
    <div className={styles.PagingPanelContainer}>
      {totalCount > 0 && (
        <>
          <Select
            className={styles.PageSizeSelect}
            floatingLabel={false}
            label="Page size"
            onChange={(newPageSize: number) => onPageSizeChange(newPageSize)}
            openAbove={totalCount > 6}
            options={pageSizes.map(size => ({
              label: String(size),
              value: size,
            }))}
            value={pageSize}
          />

          <PageOptions
            currentPage={currentPage}
            onCurrentPageChange={onCurrentPageChange}
            pageSize={pageSize}
            totalCount={totalCount}
            totalPages={totalPages}
          />
        </>
      )}
    </div>
  )
}
