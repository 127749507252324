import { useEffect, useRef } from 'react'
import { useAppContext } from 'contexts/ContextProvider'

export const useCallbackWhenAlertsIncrease = (callback: () => any): void => {
  const { alertCount } = useAppContext()
  const localCount = useRef(alertCount)

  useEffect(() => {
    if (alertCount > localCount.current) callback()

    localCount.current = alertCount
  }, [alertCount, callback])
}
