import { useAppContext, ContextInterface } from 'contexts/ContextProvider'

export const useLoggedInAppContext = (): Required<ContextInterface> => {
  const appContext = useAppContext()

  if (!appContext.user) {
    throw new Error('You must be logged in to view this.')
  }

  return {
    ...appContext,
    user: appContext.user,
  }
}
