import { api } from 'lib/api/base'
import { BaseApiResult } from 'types'
import { ENDPOINTS } from 'lib/api/endpoints'

export type UpdateEpReceiveParams = {
  bill_of_lading: File
  comment: string
  job_id: string
  receipt_date: string
}

export const updateEpReceive = async (params: UpdateEpReceiveParams): Promise<BaseApiResult> => {
  const formData = new FormData()
  formData.append('action', 'update_step_ep_receive')
  if (params.bill_of_lading) {
    formData.append('bill_of_lading', params.bill_of_lading)
  }
  formData.append('comment', params.comment)
  formData.append('job_id', params.job_id)
  formData.append('receipt_date', params.receipt_date)

  const { data } = await api.post<BaseApiResult>(ENDPOINTS.job, formData, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })

  return data
}
