import { createContext, useContext } from 'react'

export type BrandPathContextValue = {
  brandPath: string
  setBrandPath: (v: string) => void
}

export const BrandPathContext = createContext<BrandPathContextValue | undefined>(undefined)

export const useBrandPathContext = (): BrandPathContextValue => {
  const errorContext = useContext(BrandPathContext)

  if (errorContext === undefined) {
    throw new Error('useBrandPathContext must be used inside BrandPathContext')
  }

  return errorContext
}
