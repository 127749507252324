import { useLocation } from 'react-router'
import { Helmet } from 'react-helmet'
import { useMemo } from 'react'

export const HtmlTitle = () => {
  const location = useLocation()
  const title = useMemo(() => {
    return location.pathname
      .split('/')
      .pop()
      ?.split('_')
      .filter(Boolean)
      .map(el => el.charAt(0).toUpperCase() + el.slice(1))
      .join(' ')
  }, [location.pathname])

  return (
    <Helmet>
      <title>{title ? `${title} | Return Center` : 'ReturnCenter Community'}</title>
    </Helmet>
  )
}
