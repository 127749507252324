import * as React from 'react'
import classNames from 'classnames'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

import { ExpandCollapseIndicator } from './components/ExpandCollapseIndicator'

import styles from './ExpandableRow.module.scss'

export type HighlightedValue = {
  label: string
  value: number | string | null
}

export type Props = {
  children?: React.ReactNode
  className?: string
  highlightedValues: HighlightedValue[]
  index: number
}

export const ExpandableRow = ({
  children,
  className,
  highlightedValues,
  index,
}: Props): JSX.Element => {
  const [expanded, setExpanded] = React.useState(false)

  return (
    <Row
      className={classNames(
        styles.ExpandableRow,
        {
          [styles.Expanded]: expanded,
          [styles.Even]: index % 2 === 0,
          [styles.Odd]: index % 2 === 1,
        },
        className,
      )}
      onClick={() => children && setExpanded(!expanded)}
    >
      {highlightedValues.map(({ label, value }) => (
        <Col className={styles.HighlightedValue} key={`${label}_${value ?? ''}`}>
          <div className={styles.Label}>{label}</div>
          <div>{value || '--'}</div>
        </Col>
      ))}

      {children && (
        <Col className="d-flex justify-content-end" xs={1}>
          <ExpandCollapseIndicator expanded={expanded} />
        </Col>
      )}

      <Col xs={12}>{children}</Col>
    </Row>
  )
}
