import * as React from 'react'
import classNames from 'classnames'
import { Field, FieldProps } from 'formik'

import styles from './FormikFileField.module.scss'
import Button from 'react-bootstrap/Button'
import { useRef } from 'react'
import { FormikFileFieldV2 } from './FormikFileFIeldV2'

type Props = {
  accept?: string
  className?: string
  description?: string
  disabled?: boolean
  hideLabel?: boolean
  hideValidation?: boolean
  isVersion2?: boolean
  label: string
  name: string
}

export const FormikFileField = React.forwardRef<HTMLInputElement, Props>((props, ref) => {
  const {
    accept,
    className,
    description,
    disabled,
    hideLabel,
    hideValidation,
    isVersion2,
    label,
    name,
  } = props

  const labelRef = useRef<HTMLLabelElement>(null)

  const handleButtonClick = () => {
    if (labelRef && labelRef.current) labelRef.current.click()
  }

  if (isVersion2) {
    return <FormikFileFieldV2 {...props} />
  } else {
    return (
      <Field name={name}>
        {({ field, form, meta }: FieldProps) => {
          const onFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            if (!event.target.files?.length) return

            void form.setFieldValue(name, event.target.files?.[0])
          }
          const invalid = meta.touched && meta.error && form.submitCount > 0

          return (
            <div className={className}>
              <div className={styles.TextFieldContainer}>
                <input
                  className={classNames(styles.Input, {
                    [styles.Invalid]: invalid && !hideValidation,
                  })}
                  disabled={disabled}
                  placeholder={label}
                  value={field.value?.name || ''}
                  readOnly
                />
                <div className={styles.Notch}>
                  <div className={styles.NotchBefore}></div>
                  <div
                    className={classNames(styles.NotchBetween, {
                      [styles.LabelHidden]: hideLabel,
                    })}
                  >
                    <label htmlFor={name}>{label}</label>
                  </div>
                  <div className={styles.NotchAfter}></div>
                </div>

                <Button className={styles.UploadButton} size="sm" onClick={handleButtonClick}>
                  Choose File
                </Button>
              </div>

              {description && <div className={styles.FieldDescription}>{description}</div>}
              {invalid && !hideValidation && <div className={styles.ErrorText}>{meta.error}</div>}

              <label htmlFor={name} style={{ display: 'none' }} ref={labelRef}>
                <input
                  type="file"
                  style={{ display: 'none' }}
                  id={name}
                  ref={ref}
                  accept={accept}
                  {...field}
                  value={undefined}
                  onChange={onFileChange}
                  disabled={disabled}
                />
              </label>
            </div>
          )
        }}
      </Field>
    )
  }
})

FormikFileField.displayName = 'FormikFileField'
