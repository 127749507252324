import { updatePageNumber, updateRecordsPerPage } from 'lib/api/gridConfig'

export const handlePageChange = async (
  configId: number,
  newPage: number,
  onSuccess: () => void,
): Promise<void> => {
  await updatePageNumber({ configId, pageNumber: newPage })
  onSuccess()
}

export const handlePageSizeChange = async (
  configId: number,
  newPageSize: number,
  onSuccess: () => void,
): Promise<void> => {
  await updateRecordsPerPage({ configId, perPage: newPageSize })
  onSuccess()
}
