import { Formik } from 'formik'
import { Col, Form, Row } from 'react-bootstrap'
import * as Yup from 'yup'

import { DatePickerField } from 'components/DatePickerField'
import { SelectField } from 'components/SelectField'
import { TextareaField } from 'components/TextareaField'
import { useTimeOptions } from 'hooks/useTimeOptions'
import { DetailValue } from 'pages/CompanyProfile/components/DetailValue.tsx'
import { FormikFileField } from 'components/FormikFileField'
import { updateDeliverToEp } from 'lib/api/job'
import { GetJobResponse } from 'lib/api/job/get'

import styles from './RecordDeliveryForm.module.scss'
import { REQUIRED_FIELD_ERROR_MESSAGE } from 'utils/commonUtils.ts'
import ReferenceNumbersValues from 'components/ReferenceNumbersValues'

const LABEL_WIDTH = '150px'

const RecordDeliverySchema = Yup.object().shape({
  actual_delivery_date: Yup.string().required(REQUIRED_FIELD_ERROR_MESSAGE),
  actual_delivery_time: Yup.string().required(REQUIRED_FIELD_ERROR_MESSAGE),
})

export type RecordDeliveryFormProps = {
  formId: string
  job: GetJobResponse | null
  jobId: string
  onSubmitSuccess: () => void
  setSaving: (saving: boolean) => void
}

export const RecordDeliveryForm = ({
  formId,
  job,
  jobId,
  onSubmitSuccess,
  setSaving,
}: RecordDeliveryFormProps) => {
  const timeOptions = useTimeOptions()

  const handleSubmit = async (values: any) => {
    setSaving(true)

    try {
      await updateDeliverToEp({
        actual_delivery_date: values.actual_delivery_date,
        actual_delivery_time: values.actual_delivery_time,
        comment: values.comment,
        job_id: jobId,
        proof_of_delivery: values.proof_of_delivery,
      })
      onSubmitSuccess()
    } catch {
      setSaving(false)
    }
  }

  return (
    <Formik
      initialValues={{
        actual_delivery_date: '',
        actual_delivery_time: '',
        comment: '',
        proof_of_delivery: null,
      }}
      onSubmit={handleSubmit}
      validationSchema={RecordDeliverySchema}
      enableReinitialize
    >
      {({ handleSubmit }) => {
        return (
          <Form className="w-100" noValidate id={formId} onSubmit={handleSubmit}>
            <DetailValue label="Order Number:" labelWidth={LABEL_WIDTH} value={jobId} />
            <ReferenceNumbersValues job={job} labelWidth={LABEL_WIDTH} />

            <Row className="mb-3 mt-4">
              <Col>
                <DatePickerField
                  className={styles.DateInput}
                  name="actual_delivery_date"
                  label="Actual Delivery Date"
                  placeholderText="mm/dd/yyyy"
                  hideIcon
                />
              </Col>
            </Row>

            <Row className="mb-3">
              <Col>
                <SelectField
                  className={styles.TimeInput}
                  name="actual_delivery_time"
                  label="Actual Delivery Time"
                  options={timeOptions}
                />
              </Col>
            </Row>

            <Row className="mb-3">
              <Col>
                <FormikFileField
                  label="Proof of Delivery"
                  name="proof_of_delivery"
                  accept="application/pdf"
                />
              </Col>
            </Row>

            <Row className="mb-3">
              <Col>
                <TextareaField label="Comment" name="comment" />
              </Col>
            </Row>
          </Form>
        )
      }}
    </Formik>
  )
}
