import { AxiosPromise } from 'axios'

import { api } from 'lib/api/base'
import { ENDPOINTS } from 'lib/api/endpoints'
import { BaseApiResult } from 'types'

type Params = {
  configId: number
}

type ResetView = (params: Params) => AxiosPromise<BaseApiResult>
export const resetView: ResetView = ({ configId }) =>
  api.post(ENDPOINTS.gridConfigs, {
    action: 'reset',
    config_id: configId,
  })
