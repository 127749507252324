type DataType = {
  reference1: string
  reference2: string
  reference3: string
}

export const getReferenceNumber = (data: DataType, index: number): string => {
  switch (index) {
    case 0:
      return data.reference1
    case 1:
      return data.reference2
    case 2:
      return data.reference3
    default:
      return ''
  }
}
