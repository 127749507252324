/* Based on code from https://headbutt.io/picking-apart-kents-useasync/ */

import * as React from 'react'

import { useSafeDispatch } from 'hooks/useSafeDispatch'

export type Status = 'idle' | 'pending' | 'rejected' | 'resolved'

type Action<DataType> =
  | { type: 'pending' }
  | { error: string; type: 'rejected' }
  | { data: DataType; type: 'resolved' }

type State<DataType> = {
  data: DataType | null
  error: string | null
  status: Status
}

const asyncReducer = <DataType>(
  _state: State<DataType>,
  action: Action<DataType>,
): State<DataType> => {
  switch (action.type) {
    case 'pending': {
      return { data: null, error: null, status: 'pending' }
    }

    case 'rejected': {
      return { data: null, error: action.error, status: 'rejected' }
    }

    case 'resolved': {
      return { data: action.data, error: null, status: 'resolved' }
    }

    default:
      throw new Error('Unhandled action type')
  }
}

type ReturnType<DataType> = {
  data: DataType | null
  error: string | null
  run: (promise: Promise<DataType>) => void
  setData: (data: DataType) => void
  setError: (error: string) => void
  status: Status
}

export const useAsync = <DataType>(
  initialState?: Partial<State<DataType>>,
): ReturnType<DataType> => {
  const [state, unsafeDispatch] = React.useReducer(asyncReducer, {
    data: null,
    error: null,
    status: 'idle',
    ...initialState,
  }) as [State<DataType>, React.Dispatch<Action<DataType>>]

  const dispatch = useSafeDispatch(unsafeDispatch)

  const { data, error, status } = state

  const run = React.useCallback(
    (promise: Promise<DataType>) => {
      dispatch({ type: 'pending' })

      promise.then(
        data => {
          dispatch({ data, type: 'resolved' })
        },
        error => {
          dispatch({ error, type: 'rejected' })
        },
      )
    },
    [dispatch],
  )

  const setData = React.useCallback(
    (data: DataType) => dispatch({ data, type: 'resolved' }),
    [dispatch],
  )

  const setError = React.useCallback(
    (error: string) => dispatch({ error, type: 'rejected' }),
    [dispatch],
  )

  return {
    data,
    error,
    run,
    setData,
    setError,
    status,
  }
}
