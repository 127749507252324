import { PhoneNumberField } from 'components/PhoneNumberField'
import { SelectField } from 'components/SelectField'
import { TextField } from 'components/TextField'
import { useFormikContext } from 'formik'
import { useAddressAutocomplete } from 'hooks/useAddressAutocomplete'
import { CountryCode } from 'libphonenumber-js'
import React from 'react'
import { Address, CompanyInfo, SelectOption } from 'types'

type Props = {
  headerClassName?: string
  inclEmail?: boolean
  inclPhone?: boolean
  stateOptions: SelectOption[]
}

export const CompanyInfoFields = ({
  headerClassName = 'mt-4 mb-4',
  stateOptions,
  inclPhone = false,
  inclEmail = false,
}: Props): JSX.Element => {
  const { setValues, values } = useFormikContext<FormWithCompanyValues>()

  const onAddressAutocomplete = React.useCallback(
    (newAddress: Address) => {
      void setValues({
        ...values,
        companyInfo: {
          ...values.companyInfo,
          address1: newAddress.address1,
          city: newAddress.city,
          postalCode: newAddress.postal_code,
          stateProvince: newAddress.state_province,
        } as CompanyInfo,
      })
    },
    [setValues, values],
  )
  const { address2Ref, setAddressInputElement } = useAddressAutocomplete(onAddressAutocomplete)

  return (
    <>
      <h4 className={headerClassName}>Company Information</h4>
      <TextField
        autoComplete="company_name"
        className="mb-3"
        label="Company Name"
        name="companyInfo.name"
      />
      {inclPhone && (
        <PhoneNumberField
          autocomplete="company_phone"
          className="mb-3"
          country={(values.companyInfo.country || 'US') as CountryCode}
          label="Phone"
          name="companyInfo.phone"
        />
      )}
      {inclEmail && (
        <TextField
          autoComplete="company_email"
          className="mb-3"
          label="Email"
          name="companyInfo.email"
        />
      )}
      <TextField
        autoComplete="company_address1"
        className="mb-3"
        label="Address 1"
        name="companyInfo.address1"
        ref={el => setAddressInputElement(el)}
      />
      <TextField
        autoComplete="company_address2"
        className="mb-3"
        label="Address 2"
        name="companyInfo.address2"
        ref={address2Ref}
      />
      <TextField
        autoComplete="company_city"
        className="mb-3"
        label="City"
        name="companyInfo.city"
      />
      <SelectField
        className="mb-3"
        label="State"
        name="companyInfo.stateProvince"
        options={stateOptions}
      />
      <TextField
        autoComplete="company_postal_code"
        className="mb-3"
        label="Zip Code"
        name="companyInfo.postalCode"
      />
    </>
  )
}

type FormWithCompanyValues = {
  companyInfo: CompanyInfo
}
