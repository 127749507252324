import { Formik } from 'formik'
import { Col, Form, Row } from 'react-bootstrap'
import * as Yup from 'yup'

import { DatePickerField } from 'components/DatePickerField'
import { SelectField } from 'components/SelectField'
import { TextareaField } from 'components/TextareaField'
import { useTimeOptions } from 'hooks/useTimeOptions'
import { RadioGroup } from 'components/RadioGroup'
import { DetailValue } from 'pages/CompanyProfile/components/DetailValue.tsx'
import { updateSchedulePickup } from 'lib/api/job/updateSchedulePickup'
import { GetJobResponse } from 'lib/api/job/get'
import { TextField } from 'components/TextField'

import styles from './SchedulePickupForm.module.scss'
import { REQUIRED_FIELD_ERROR_MESSAGE } from 'utils/commonUtils.ts'
import ReferenceNumbersValues from 'components/ReferenceNumbersValues'
import { useMemo } from 'react'

const LABEL_WIDTH = '200px'

const SchedulePickupSchema = Yup.object().shape({
  scheduled_pickup_date: Yup.string().required(REQUIRED_FIELD_ERROR_MESSAGE),
  scheduled_pickup_time: Yup.string().when('scheduled_pickup_time_type', {
    is: (scheduled_pickup_time_type?: string) => scheduled_pickup_time_type === 'specific_time',
    then: schema => schema.required(REQUIRED_FIELD_ERROR_MESSAGE),
  }),
  scheduled_pickup_time_end: Yup.string().when('scheduled_pickup_time_type', {
    is: (scheduled_pickup_time_type?: string) => scheduled_pickup_time_type === 'pickup_window',
    then: schema => schema.required(REQUIRED_FIELD_ERROR_MESSAGE),
  }),
  scheduled_pickup_time_start: Yup.string().when('scheduled_pickup_time_type', {
    is: (scheduled_pickup_time_type?: string) => scheduled_pickup_time_type === 'pickup_window',
    then: schema => schema.required(REQUIRED_FIELD_ERROR_MESSAGE),
  }),
  scheduled_pickup_time_type: Yup.string().required(REQUIRED_FIELD_ERROR_MESSAGE),
  tracking_number: Yup.string().required(REQUIRED_FIELD_ERROR_MESSAGE),
})

export type SchedulePickupFormProps = {
  formId: string
  job: GetJobResponse | null
  jobId: string
  onSubmitSuccess: () => void
  setSaving: (saving: boolean) => void
}

export const SchedulePickupForm = ({
  formId,
  job,
  jobId,
  onSubmitSuccess,
  setSaving,
}: SchedulePickupFormProps) => {
  const timeOptions = useTimeOptions()

  const requestedPickupDateValue = useMemo(() => {
    if (!job || !job.dates) return ''

    const date = job.dates.find(el => el.name === 'requested_service')
    if (!date || !date.date_range) return ''

    return date.date_range.join(' – ')
  }, [job])

  const handleSubmit = async (values: any) => {
    setSaving(true)

    try {
      let end = values.scheduled_pickup_time_end
      let start = values.scheduled_pickup_time_start
      if (values.scheduled_pickup_time_type === 'specific_time') {
        end = values.scheduled_pickup_time
        start = values.scheduled_pickup_time
      }

      await updateSchedulePickup({
        comment: values.comment,
        job_id: jobId,
        scheduled_pickup_date: values.scheduled_pickup_date,
        scheduled_pickup_time: {
          end_time: end,
          start_time: start,
        },
        tracking_number: values.tracking_number,
      })
      onSubmitSuccess()
    } catch {
      setSaving(false)
    }
  }

  return (
    <Formik
      initialValues={{
        comment: '',
        scheduled_pickup_date: '',
        scheduled_pickup_time: '',
        scheduled_pickup_time_end: '',
        scheduled_pickup_time_start: '',
        scheduled_pickup_time_type: '',
        tracking_number: '',
      }}
      onSubmit={handleSubmit}
      validationSchema={SchedulePickupSchema}
      enableReinitialize
    >
      {({ handleSubmit, values }) => {
        return (
          <Form className="w-100" noValidate id={formId} onSubmit={handleSubmit}>
            <DetailValue label="Order Number:" labelWidth={LABEL_WIDTH} value={jobId} />
            <ReferenceNumbersValues job={job} labelWidth={LABEL_WIDTH} />
            {!!requestedPickupDateValue && (
              <DetailValue
                label="Requested Pickup Date:"
                labelWidth={LABEL_WIDTH}
                value={requestedPickupDateValue}
              />
            )}

            <Row className="mb-3 mt-4">
              <Col>
                <DatePickerField
                  className={styles.DateInput}
                  name="scheduled_pickup_date"
                  label="Scheduled Pickup Date"
                  placeholderText="mm/dd/yyyy"
                  hideIcon
                />
              </Col>
            </Row>

            <Row className="mb-3">
              <Col>
                <RadioGroup
                  label="Schedule Pickup Time"
                  name="scheduled_pickup_time_type"
                  options={[
                    { label: 'Specific Time', value: 'specific_time' },
                    { label: 'Pickup Window', value: 'pickup_window' },
                  ]}
                />
              </Col>
            </Row>

            <Row className="mb-3">
              {values.scheduled_pickup_time_type === 'specific_time' && (
                <Col>
                  <SelectField
                    className={styles.TimeInput}
                    name="scheduled_pickup_time"
                    label="Time"
                    options={timeOptions}
                  />
                </Col>
              )}

              {values.scheduled_pickup_time_type === 'pickup_window' && (
                <>
                  <Col xs={6}>
                    <SelectField
                      name="scheduled_pickup_time_start"
                      label="From"
                      options={timeOptions}
                    />
                  </Col>
                  <Col xs={6}>
                    <SelectField
                      name="scheduled_pickup_time_end"
                      label="To"
                      options={timeOptions}
                    />
                  </Col>
                </>
              )}
            </Row>

            <Row className="mb-3">
              <Col>
                <TextField label="Tracking Number" name="tracking_number" />
              </Col>
            </Row>

            <Row className="mb-3">
              <Col>
                <TextareaField label="Comment" name="comment" />
              </Col>
            </Row>
          </Form>
        )
      }}
    </Formik>
  )
}
