import { lazy } from 'react'
import { Outlet } from 'react-router'
import { useAppContext } from 'contexts/ContextProvider'
import { AddAccountErrorActions } from 'types'
const UpdateAccountPage = lazy(() => import('pages/UpdateAccountPage'))
const AuthenticationCodePage = lazy(() => import('pages/AuthenticationCodePage'))

export const ForceAccountAction = () => {
  const { user } = useAppContext()

  if (user?.authAction?.name === AddAccountErrorActions.REDIRECT_UPDATE_ACCOUNT) {
    return <UpdateAccountPage />
  }

  if (user?.authAction?.name === AddAccountErrorActions.REDIRECT_REGISTRATION_VERIFICATION) {
    return <AuthenticationCodePage />
  }

  return <Outlet />
}
