import partition from 'lodash/partition'

import { GridConfig } from 'types'

type Params = {
  displayColumnNamesInNewOrder: string[]
  gridConfigs: GridConfig[]
}
type AssignColumnSequences = (params: Params) => { [key: number]: number }

export const assignColumnSequences: AssignColumnSequences = ({
  gridConfigs,
  displayColumnNamesInNewOrder,
}) => {
  const [dataOnlyColumns, displayColumns] = partition(gridConfigs, ({ dataOnly }) => dataOnly)
  const displayColumnsByName = displayColumns.reduce<{ [key: string]: GridConfig }>(
    (map, column) => {
      map[column.name] = column
      return map
    },
    {},
  )
  let allColumnsInNewOrder = displayColumnNamesInNewOrder.map(name => displayColumnsByName[name])

  dataOnlyColumns
    .sort((a, b) => a.sequence - b.sequence)
    .forEach(column => {
      allColumnsInNewOrder = [
        ...allColumnsInNewOrder.slice(0, column.sequence - 1),
        column,
        ...allColumnsInNewOrder.slice(column.sequence - 1),
      ]
    })

  return allColumnsInNewOrder.reduce<{ [key: number]: number }>((map, column, i) => {
    map[column.columnId] = i + 1
    return map
  }, {})
}
