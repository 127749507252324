import { api } from 'lib/api/base'
import { ENDPOINTS } from 'lib/api/endpoints'
import { BoolSortDirection } from 'types'
import { GridDataResponse, NormalizedGridDataResponse } from 'lib/api/types'
import { formatGridData } from 'lib/api/utils/formatGridData'

export type SortOrderParams = {
  brandId?: number
  columnId: number
  configId: number
  slaRuleSetId?: number
  sortDirection: BoolSortDirection
}

type UpdateSortOrder = (params: SortOrderParams) => Promise<NormalizedGridDataResponse>

export const updateSortOrder: UpdateSortOrder = async ({
  brandId,
  slaRuleSetId,
  configId,
  columnId,
  sortDirection,
}) => {
  const { data } = await api.post<GridDataResponse>(ENDPOINTS.gridConfigs, {
    action: 'update_sort_order',
    brand_id: brandId,
    column_id: columnId,
    config_id: configId,
    direction: sortDirection,
    set_id: slaRuleSetId,
  })

  return formatGridData(data)
}
