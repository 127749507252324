import { GridDataResponse, NormalizedGridDataResponse } from 'lib/api/types'
import { camelCaseKeys } from 'utils/camelCaseKeys'

export const formatGridData = ({
  data,
  page,
  records,
  rows,
  ...restData
}: GridDataResponse): NormalizedGridDataResponse => {
  return camelCaseKeys({
    ...restData,
    page: Number(page),
    records: Number(records),
    rows: data ?? rows,
  })
}
