import { DataTypeProvider } from '@devexpress/dx-react-grid'

import { ColorCell } from 'components/ColorCell'
import { ColumnName, DynamicObject } from 'types'
import { getDataOnlyValue } from 'utils/getDataOnlyValue'

export const slaColumns: DynamicObject = {
  [ColumnName.lpSla]: ColumnName.lpStatusText,
  [ColumnName.epSla]: ColumnName.epStatusText,
  [ColumnName.dspSla]: ColumnName.dspStatusText,
  [ColumnName.labelSla]: ColumnName.statusText,
  [ColumnName.sla]: ColumnName.statusText,
}
export const SLA_COLUMNS = [
  ColumnName.lpSla,
  ColumnName.epSla,
  ColumnName.dspSla,
  ColumnName.labelSla,
  ColumnName.sla,
]
export const SlaColorCellProvider = () => {
  const SlaColorCellFormatter = ({
    column: { name },
    row,
  }: DataTypeProvider.ValueFormatterProps): JSX.Element => {
    const statusColumnName = slaColumns[name as ColumnName] as ColumnName
    const statusText = String(getDataOnlyValue(row as DynamicObject, statusColumnName) || '')
    return <ColorCell colorCode={Number(row[name])} statusText={statusText} ariaHidden={false} />
  }
  return <DataTypeProvider formatterComponent={SlaColorCellFormatter} for={SLA_COLUMNS} />
}
