import { ChangeEvent } from 'react'
import { ErrorMessage, Field, FieldProps } from 'formik'
import Form from 'react-bootstrap/Form'
import styles from './CheckboxField.module.scss'
import classNames from 'classnames'

type Props = {
  className?: string
  disabled?: boolean
  label: string | JSX.Element
  name: string
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void
  required?: boolean
}

export const CheckboxField = ({
  className,
  disabled = false,
  label,
  name,
  onChange,
  required,
}: Props): JSX.Element => (
  <>
    <Field name={name}>
      {({ field }: FieldProps) => (
        <Form.Check
          {...field}
          checked={field.value}
          className={classNames(styles.CheckboxField, className)}
          disabled={disabled}
          id={name}
          label={label}
          name={field.name}
          onBlur={field.onBlur}
          onChange={(...args) => {
            if (typeof onChange === 'function') onChange(...args)
            field.onChange(...args)
          }}
          required={required}
          type="checkbox"
        />
      )}
    </Field>
    <ErrorMessage name={name}>
      {msg => {
        return <div className={styles.ErrorMessage}>{msg}</div>
      }}
    </ErrorMessage>
  </>
)
