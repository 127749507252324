import { FC } from 'react'
import styles from './SearchGlassIcon.module.scss'

type SearchGlassIconProps = {
  fill?: 'blue' | 'gray' | 'white'
}
export const SearchGlassIcon: FC<SearchGlassIconProps> = ({ fill = 'white' }) => {
  let fillColor: string
  switch (fill) {
    case 'blue':
      fillColor = '#0176ce'
      break

    case 'gray':
      fillColor = '#aaa9a9'
      break

    case 'white':
    default:
      fillColor = '#ffffff'
      break
  }

  return (
    <svg
      width="100%"
      viewBox="0 0 20 20"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      className={styles.SearchGlassIcon}
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="search-glass-path" transform="translate(-269.000000, -20.000000)" fill={fillColor}>
          <g transform="translate(15.000000, 11.000000)">
            <g transform="translate(264.000000, 19.000000) scale(-1, 1) translate(-264.000000, -19.000000) translate(254.000000, 9.000000)">
              <g>
                <path d="M15.0363146,8.36794092 C15.0192338,4.65259941 12.0052334,1.68043422 8.29254396,1.71425792 C4.6092538,1.74781158 1.6494562,4.72801074 1.66668238,8.41695491 C1.68399191,12.0720754 4.6844754,15.0381645 8.36594077,15.0391098 C12.0257115,15.0400548 15.0580273,12.0072635 15.0363146,8.36794092 M14.800733,13.6505824 C14.8919045,13.7332851 14.9744252,13.8017427 15.0498496,13.8773565 C16.599766,15.4320317 18.1489389,16.987382 19.6979767,18.5430024 C20.0076491,18.8539644 20.0803701,19.1983451 19.9106653,19.5378648 C19.6753359,20.0087637 19.0801188,20.1469616 18.6627167,19.8256026 C18.6006065,19.7778039 18.5451871,19.7208234 18.4894975,19.6652607 C16.9161292,18.0942475 15.3429638,16.5230318 13.7710148,14.9506009 C13.7278283,14.907393 13.6985642,14.85021 13.6615279,14.7976853 C11.6203394,16.3852391 9.35598678,16.9973063 6.8483298,16.5553703 C4.70961685,16.1784486 2.96444871,15.1026388 1.65763393,13.3632497 C-0.800415994,10.0913971 -0.527915106,5.39489807 2.49629056,2.39397261 C5.49427343,-0.580960592 10.3512368,-0.796595146 13.5528519,1.82187266 C15.3443155,3.28695891 16.3890374,5.17278233 16.6364644,7.47076885 C16.8834183,9.76362444 16.2467043,11.8158659 14.800733,13.6505824"></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
