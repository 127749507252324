import { ClientError } from 'lib/api/ClientError'
import { api } from 'lib/api/base'
import { ENDPOINTS } from 'lib/api/endpoints'
import { AddAccountErrorActions, CurrentUser } from 'types'
import { formatUserResponse, UserAuthResponse } from 'lib/api/user/utils/formatUserResponse'
import { isApiError, normalizeError } from 'utils/commonUtils'
import TagManager from '@sooro-io/react-gtm-module'
import { isAxiosError } from 'axios'
import { authorizationCheck } from 'lib/api/user'
import { removeGuestToken } from 'utils/guestToken'

export type LogInResponse = UserAuthResponse

interface LogInArgs {
  password: string
  username: string
}

export const logInConstants = {
  action: 'login',
}
export const logIn = async ({ password, username }: LogInArgs): Promise<CurrentUser | void> => {
  try {
    try {
      const { data } = await api.post<LogInResponse>(ENDPOINTS.user, {
        ...logInConstants,
        password,
        user_name: username,
      })

      TagManager.dataLayer({
        dataLayer: {
          event: 'UserSignedIn',
          user_name: username,
        },
      })

      // If user starts guest session and then Signs In without completing the
      // guest order - the guest token remains in the storage. We need to
      // remove guest token if user Signs In.
      removeGuestToken()

      return formatUserResponse(data)
    } catch (e) {
      const authAction = !!isAxiosError(e) && e?.response?.data?.auth_action

      // User provided correct credentials but needs to update their account information / verify phone
      if (
        authAction?.name === AddAccountErrorActions.REDIRECT_UPDATE_ACCOUNT ||
        authAction?.name === AddAccountErrorActions.REDIRECT_REGISTRATION_VERIFICATION
      ) {
        const token = authAction?.token
        return await authorizationCheck({ token })
      }

      throw e
    }
  } catch (e) {
    if (isApiError(e)) {
      throw new ClientError(normalizeError(e).message)
    } else {
      throw e
    }
  }
}
