import { FC } from 'react'

import { FormikDatePicker } from 'components/FormikDatePicker'
import styles from './DateInput.module.scss'

export type Props = { index: number }
export const DateInput: FC<Props> = ({ index }) => (
  <div className={styles.DatePickerWrapper}>
    <FormikDatePicker name={`filters[${index}].value`} />
  </div>
)
