import { FC } from 'react'
import { Dropdown } from 'react-bootstrap'

import styles from './SavedViewList.module.scss'
import { Grid } from 'types'
import { useModalState } from 'hooks/useModalState'
import { TransparentButton } from 'components/TransparentButton'
import { DeleteSavedViewConfirmation } from '../DeleteSavedViewConfirmation'
import { GridDispatch, ActionTypes } from '../state'

export type Props = {
  configId: number
  dispatch: GridDispatch
  savedViews: Grid[]
}

const defaultViewText = 'Default View'
export const SavedViewList: FC<Props> = ({ configId, savedViews, dispatch }) => {
  const { hide, open, state } = useModalState<{ grid: Grid | undefined }>({ grid: undefined })
  const currentSavedView = savedViews.find(
    ({ configId: savedViewConfigId }) => configId === savedViewConfigId,
  )

  const setActiveSavedView = (configId?: number) => {
    dispatch({ payload: configId, type: ActionTypes.SetConfigId })
  }

  return (
    <div className={styles.Container}>
      {savedViews.length > 0 ? (
        <>
          <p>Saved Views:</p>
          <Dropdown>
            <Dropdown.Toggle variant="light" className={styles.Dropdown} id="SavedViews">
              {currentSavedView ? currentSavedView.name : defaultViewText}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item>
                <TransparentButton
                  className={styles.SelectSavedViewButton}
                  onClick={() => setActiveSavedView()}
                >
                  {defaultViewText}
                </TransparentButton>
              </Dropdown.Item>
              {savedViews.map(grid => (
                <Dropdown.Item key={grid.configId}>
                  <TransparentButton
                    className={styles.SelectSavedViewButton}
                    onClick={() => setActiveSavedView(grid.configId)}
                  >
                    {grid.name}
                  </TransparentButton>
                  <TransparentButton
                    className={styles.DeleteSavedViewButton}
                    onClick={() => {
                      open({ grid })
                    }}
                  >
                    X
                  </TransparentButton>
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
            <DeleteSavedViewConfirmation
              hide={() => {
                hide({ grid: undefined })
              }}
              dispatch={dispatch}
              {...state}
            />
          </Dropdown>
        </>
      ) : (
        <p>No Saved Views</p>
      )}
    </div>
  )
}
