import {
  ApiCompany,
  BaseApiResult,
  BrandParameter,
  ColorKey,
  CompanyInfo,
  OFieldType,
  OFieldOption,
  CurrentUser,
  MenuConfig,
  OrderReferenceNumber,
  Paradigm,
  Program,
} from 'types'
import { filterMenuItems } from 'lib/api/user/utils/filterMenuItems'
import { camelCaseKeys } from 'utils/camelCaseKeys'
import { mapApiToUiCompany } from '../../getUser'

type OFieldValidationResponse = {
  error_message: string
  validation_type: string
  validation_value: string
}

type OFieldResponse = {
  column_id: number
  depends_on: string
  field_type_id: OFieldType
  id: number
  label: string
  name: string
  options?: OFieldOption[]
  sequence: number
  validations?: OFieldValidationResponse[]
}

type OFormSectionResponse = {
  border_style: string
  description: string
  fields: OFieldResponse[]
  id: number
  label_style: string
  name: string
  sequence: number
}

type OFormResponse = {
  description: string
  id: number
  name: string
  sections: OFormSectionResponse[]
}

type GridResponse = {
  config_id: number
  current?: boolean
  form?: OFormResponse
  name: string
}

export type MenuConfigResponse = {
  color: string
  config_id?: number
  description: string
  grid?: GridResponse[]
  icon: string
  menu?: MenuConfigResponse[]
  menu_id: number
  path: string
}

export type UserAuthResponse = BaseApiResult & {
  auth_action?: {
    name?: string
    token?: string
  }
  bill_company: ApiCompany
  brand_id: number
  brandingParameters: BrandParameter
  color_key?: ColorKey
  company: string
  culture_id: number
  default_destination_id: number
  default_menu_id: number
  default_path: string
  destination_routing: boolean
  email: string
  expired_message: string
  first_name: string
  is_verified: boolean
  last_name: string
  locale: string
  menu: MenuConfigResponse[]
  notification_interval: number
  paradigms: Paradigm[]
  parent_brand_id: number
  password_expired: boolean
  password_policy: string
  perspective_id: number
  programs: Program[]
  reference_numbers: OrderReferenceNumber[]
  token: string
  w9_url: string
}

type CamelCasedUserAuthResponse = Partial<BaseApiResult> & {
  bill_company?: ApiCompany // this key will be removed
  billingInfo: CompanyInfo
  brandId: number
  brandingParameters: BrandParameter
  colorKey?: ColorKey
  company: string
  cultureId: number
  defaultDestinationId: number
  defaultMenuId: number
  defaultPath: string
  destinationRouting: boolean
  email: string
  expiredMessage: string
  firstName: string
  isVerified: boolean
  lastName: string
  locale: string
  menu: MenuConfig[]
  notificationInterval?: number
  paradigms: Paradigm[]
  parentBrandId: number
  passwordExpired: boolean
  passwordPolicy: string
  perspectiveId: number
  poiUrl: string
  programs: Program[]
  referenceNumbers: OrderReferenceNumber[]
  templates: []
  token: string
  w9Url: string
}

type FormatUserResponse = (data: UserAuthResponse) => CurrentUser
export const formatUserResponse: FormatUserResponse = data => {
  const rawCamelCased = camelCaseKeys<UserAuthResponse, CamelCasedUserAuthResponse>(data, {
    deep: true,
    exclude: ['bill_company'],
  })

  delete rawCamelCased.bill_company
  delete rawCamelCased.notificationInterval
  delete rawCamelCased.result

  // Types correction.
  // paradigm_id should be number, payment_terms should be boolean
  const programs = data.programs?.map(program => ({
    ...program,
    paradigms: (program.paradigms || []).map(paradigm => {
      const paymentTerms = paradigm.payment_terms as string | boolean
      return {
        ...paradigm,
        paradigm_id: Number(paradigm.paradigm_id),
        payment_terms: typeof paymentTerms === 'string' ? paymentTerms === 'true' : paymentTerms,
      }
    }),
  }))

  // Create a map of grid config_id to path
  const gridConfigIdToPathMap: Record<number, string> = {}
  const processMenu = (menu: MenuConfigResponse) => {
    if (menu.config_id) gridConfigIdToPathMap[menu.config_id] = menu.path
    if (menu.menu) menu.menu.forEach(menu => processMenu(menu))
  }
  data.menu?.forEach(menu => processMenu(menu))

  return {
    ...rawCamelCased,
    billingInfo: mapApiToUiCompany(data.bill_company || {}),
    gridConfigIdToPathMap,
    loggedInAt: Date.now(),
    menu: filterMenuItems(data.menu || []),
    menuConfigIdCache: {},
    notificationIntervalMs: data.notification_interval * 1000,
    paradigms: data.paradigms,
    programs,
    tokenExpired: false,
  }
}
