import { SetStateAction, Suspense, useCallback, useEffect, useState } from 'react'
import { CurrentUser, SetUser } from 'types'
import { useRefresh } from 'hooks/useRefresh'
import { usePolling } from 'hooks/usePolling'
import { useForceUpdate } from 'hooks/useForceUpdate'
import { getLocalToken, removeLocalToken, setLocalToken } from 'utils/localToken'
import { removeGuestToken } from 'utils/guestToken'
import { authorizationCheck } from 'lib/api/user'
import { ErrorMessageContextProvider } from 'contexts/ErrorMessageContextProvider'
import { UnauthenticatedErrorMessageContextProvider } from 'contexts/UnauthorizedErrorMessageContextProvider'
import { ContextProvider } from 'contexts/ContextProvider'
import { StaticDataProvider } from 'contexts/StaticDataContext'
import { AppSettingsProvider } from 'contexts/AppSettingsContext'
import { ErrorBoundary } from 'components/ErrorBoundary'
import { Page } from 'components/Page'
import { LogInModal } from 'components/LogInModal'
import { Outlet } from 'react-router'
import { ErrorMessageDialogBox } from 'components/ErrorMessageDialogBox'
import { DialogBox } from 'components/DialogBox'
import { HtmlTitle } from 'components/HtmlTitle'

export const Providers = () => {
  const [user, setUser] = useState<CurrentUser | undefined>()
  const [initializing, setInitializing] = useState(true)
  const [pollingState, setPollingState] = useState({ alertCount: 0, version: '' })
  const [dialogVisible, setDialogVisible] = useState(false)
  const [refreshAlertTimestamp, refreshAlertCount] = useRefresh()

  const notificationInterval = !user || user?.tokenExpired ? undefined : user.notificationIntervalMs
  // TODO: Move usePolling (and anything else that uses token) under ContextProvider
  // ContextProvider attaches token to any API call. If user session expired,
  // axios api object remains in memory with expired token attached. As user signs
  // in again - ContextProvide will re-attach new token. However, since usePolling()
  // is called before ContextProvider, usePolling will issue first tick() call
  // with expired token leading to 401 error on the call.
  usePolling(notificationInterval, refreshAlertTimestamp, setPollingState)
  useForceUpdate(pollingState.version, () => setDialogVisible(true))

  const setSessionUser: SetUser = useCallback((user: SetStateAction<CurrentUser | undefined>) => {
    setUser(user)
    if (typeof user !== 'function') {
      if (user?.token) {
        setLocalToken(user.token)
      } else {
        removeLocalToken()
      }
      // Always remove guest token
      removeGuestToken()
    }
  }, [])

  useEffect(() => {
    const token = getLocalToken()
    if (token === null) {
      setInitializing(false)
      return
    }

    authorizationCheck({ token })
      .then(user => {
        setSessionUser(user)
      })
      .catch(() => {
        setSessionUser(undefined)
      })
      .finally(() => {
        setInitializing(false)
      })
  }, [setSessionUser])

  return (
    <ErrorMessageContextProvider reportErrors={!!user?.isVerified}>
      <UnauthenticatedErrorMessageContextProvider>
        <ContextProvider
          initializing={initializing}
          alertCount={pollingState.alertCount}
          refreshAlertCount={refreshAlertCount}
          setUser={setSessionUser}
          user={user}
        >
          <StaticDataProvider>
            <AppSettingsProvider brandingParameters={user?.brandingParameters}>
              <ErrorBoundary>
                <Suspense fallback={<Page />}>
                  <HtmlTitle />
                  <LogInModal />

                  <Outlet />
                </Suspense>
              </ErrorBoundary>

              <ErrorMessageDialogBox />
              <DialogBox
                buttonText="OK"
                hide={() => window.location.reload()}
                show={dialogVisible}
                title="Application Update"
              >
                <p>
                  A new version of this app is now available. Please press OK to refresh the
                  application.
                </p>
                <p>
                  If you are in the middle of making changes, please note that you will need to
                  re-enter your changes.
                </p>
              </DialogBox>
            </AppSettingsProvider>
          </StaticDataProvider>
        </ContextProvider>
      </UnauthenticatedErrorMessageContextProvider>
    </ErrorMessageContextProvider>
  )
}
