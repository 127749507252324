import { Field, FieldProps, Form, Formik } from 'formik'

import { SearchGlassIcon } from 'components/SearchGlassIcon'

import styles from './SearchForm.module.scss'

type FormValues = {
  searchTerm: string
}

export type Props = {
  onSubmit: (values: FormValues) => void
}

export const SearchForm = ({ onSubmit }: Props): JSX.Element => (
  <Formik initialValues={{ searchTerm: '' }} onSubmit={values => onSubmit(values)}>
    {() => (
      <Form className={styles.SearchForm}>
        <Field name="searchTerm">
          {({ field }: FieldProps) => (
            <input aria-label="Search orders" placeholder="Search" type="text" {...field} />
          )}
        </Field>

        <button aria-label="Run search" type="submit">
          <SearchGlassIcon fill="blue" />
        </button>
      </Form>
    )}
  </Formik>
)
