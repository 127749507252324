import { GridConfig, SortDirection, ColumnName, BaseApiResult, AdvancedSearchFilter } from 'types'
import { api } from 'lib/api/base'
import { ENDPOINTS } from 'lib/api/endpoints'
import { camelCaseKeys } from 'utils/camelCaseKeys'

export enum GridCustomization {
  'none' = 0,
  'column' = 1,
  'all' = 2,
}
export type GridConfigColumnResponse = {
  align: 'center' | 'left' | 'right'
  column_id: number
  data_only: number
  filterable: number
  heading: string
  heading_align: 'center' | 'left' | 'right'
  hidden: number
  highlight_column_id: number
  minimum_width: string
  name: string
  sequence: number
  sortable: number
  tooltip: number
  type_id: number
  width: string
}

type FiltersResponse = {
  column_id: number
  relation_id: number
  type_id: number
  value: string
}

export type GridConfigResponse = BaseApiResult & {
  columns: GridConfigColumnResponse[]
  customization: GridCustomization
  filter_column_id: number
  filter_field: string
  filters: FiltersResponse[]
  fixed_columns: number
  name: string
  per_page: string
  per_page_options: number[]
  sort_dir: SortDirection
  sort_field: ColumnName
}
interface GetGridConfigParams {
  configId: number
}

export type GridConfigData = {
  configs: GridConfig[]
  customization: GridCustomization
  filterColumnId: number
  filters: AdvancedSearchFilter[]
  fixedColumns: number
  name: string
  perPage: number
  perPageOptions: number[]
  sortDir: SortDirection
  sortField: ColumnName
}

type GetGridConfig = (args: GetGridConfigParams) => Promise<GridConfigData>
export const getGridConfig: GetGridConfig = async ({ configId }) => {
  const { data } = await api.get<GridConfigResponse>(ENDPOINTS.gridConfigs, {
    params: {
      action: 'get',
      config_id: configId,
    },
  })

  const configs = data.columns.map(
    ({
      align,
      column_id,
      data_only,
      filterable,
      heading,
      heading_align,
      hidden,
      highlight_column_id,
      minimum_width,
      name,
      sequence,
      sortable,
      tooltip,
      type_id,
      width,
    }: GridConfigColumnResponse): GridConfig => ({
      align,
      columnId: column_id,
      dataOnly: Boolean(Number(data_only)),
      filterable: Boolean(Number(filterable)),
      heading,
      headingAlignment: heading_align,
      hidden: Boolean(Number(hidden)),
      highlightColumnId: Number(highlight_column_id),
      minimumWidth: minimum_width,
      name: name || heading,
      sequence,
      sortable: Boolean(Number(sortable)),
      tooltip: Boolean(tooltip),
      typeId: type_id,
      width,
    }),
  )

  return {
    configs,
    customization: data.customization,
    filterColumnId: data.filter_column_id,
    filters: camelCaseKeys(data.filters),
    fixedColumns: data.fixed_columns,
    name: data.name,
    perPage: Number(data.per_page),
    perPageOptions: data.per_page_options,
    sortDir: data.sort_dir,
    sortField: data.sort_field,
  }
}
