import { DataTypeProvider } from '@devexpress/dx-react-grid'
import { generatePath, Link } from 'react-router'

import { ColumnName, DynamicObject, GridAction } from 'types'
import { EditIcon } from 'components/EditIcon'
import { getDataOnlyValue } from 'utils/getDataOnlyValue'
import { TransparentButton } from 'components/TransparentButton'
import { TrashIcon } from 'components/TrashIcon'
import { ROUTES } from 'Routes'

export interface Props {
  column: ColumnName
  handleDeleteQuoteClick: (row: DynamicObject) => () => void
}

export const ActionsProvider = ({ handleDeleteQuoteClick, column }: Props): JSX.Element => {
  const ActionFormatter = ({ row }: DataTypeProvider.ValueFormatterProps): JSX.Element => {
    const actionArray = String(row[column]).split(',')

    const quoteId = String(getDataOnlyValue(row as DynamicObject, ColumnName.quoteId))

    return (
      <span className="ms-2">
        {actionArray.map(action => {
          switch (action) {
            case GridAction.QuoteEdit:
              return (
                <Link
                  to={generatePath(ROUTES.editOrder, { orderId: quoteId })}
                  className="me-2"
                  key={action}
                  aria-label="edit link"
                >
                  <EditIcon />
                </Link>
              )
            case GridAction.QuoteCancel:
              return (
                <TransparentButton
                  onClick={handleDeleteQuoteClick(row as DynamicObject)}
                  className="me-2"
                  key={action}
                  aria-label="cancel button"
                >
                  <TrashIcon />
                </TransparentButton>
              )
          }
        })}
      </span>
    )
  }
  return <DataTypeProvider formatterComponent={ActionFormatter} for={[column]} />
}
