import { DataTypeProvider } from '@devexpress/dx-react-grid'

import { ColumnName, DynamicObject } from 'types'
import { TransparentButton } from 'components/TransparentButton'
import { EditIcon } from 'components/EditIcon'

export interface Props {
  column: ColumnName
  onClickFormatter: (row: DynamicObject) => () => void
}

export const EditButtonProvider = ({ onClickFormatter, column }: Props): JSX.Element => {
  const EditButtonFormatter = ({
    row,
  }: DataTypeProvider.ValueFormatterProps): JSX.Element | null => {
    const jobStatus = row[ColumnName.jobStatus]

    return jobStatus !== 'Delivered' ? (
      <TransparentButton className="w-100" onClick={onClickFormatter(row as DynamicObject)}>
        <EditIcon />
      </TransparentButton>
    ) : null
  }
  return <DataTypeProvider formatterComponent={EditButtonFormatter} for={[column]} />
}
