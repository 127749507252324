import { GridState, SavedViewsCache } from '..'
import { AdvancedSearchFilter } from 'types'
import { emptyFilters } from '../constants'
import { GridCustomization } from 'lib/api/gridConfig'

export type InitParams = {
  baseConfigId: number
  configId?: number
  filters?: AdvancedSearchFilter[]
  forceHideAdvancedSearch?: boolean
  hideAdvancedSearch?: boolean
  savedViewsCache?: SavedViewsCache
}

type Init = (params: InitParams) => GridState
export const init: Init = ({
  baseConfigId,
  savedViewsCache,
  hideAdvancedSearch,
  forceHideAdvancedSearch,
  filters,
}) => ({
  baseConfigId,
  configId: baseConfigId,
  currentPage: 0,
  customization: GridCustomization.none,
  filterColumnId: 0,
  filters: filters || emptyFilters,
  fixedColumns: 0,
  forceHideAdvancedSearch: forceHideAdvancedSearch || false,
  gridConfigs: [],
  gridDataRows: [],
  loading: 0,
  name: '',
  pageSize: 0,
  pageSizeOptions: [0],
  plmDownload: false,
  recordsCount: 0,
  refresh: {
    gridConfigLastUpdated: 0,
    lastUpdated: 0,
  },
  savedViewsCache: savedViewsCache || {},
  showAdvancedSearch:
    hideAdvancedSearch === undefined ? Boolean(filters && filters.length > 0) : !hideAdvancedSearch,
  sorting: [],
})
