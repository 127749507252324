import { useMemo, useRef } from 'react'
import classnames from 'classnames'
import { format } from 'date-fns'
import ReactDatePicker, { ReactDatePickerProps } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

import styles from './LocalizedDatePicker.module.scss'
import { CalendarIcon } from 'components/CalendarIcon'
import { TransparentButton } from 'components/TransparentButton'
import { useLocalDateFormat } from 'hooks/useLocalDateFormat'

interface IProps {
  hideIcon?: boolean
}

export const LocalizedDatePicker = (props: IProps & ReactDatePickerProps): JSX.Element => {
  const calendarRef = useRef<ReactDatePicker | null>(null)

  const dateFormat = useLocalDateFormat()
  const placeholder = useMemo(() => {
    const today = new Date()
    return `e.g. ${format(today, dateFormat)}`
  }, [dateFormat])

  return (
    <div className="d-flex align-items-center">
      <ReactDatePicker
        placeholderText={placeholder}
        {...props}
        className={classnames(styles.DatePicker, 'form-control', props.className)}
        wrapperClassName={classnames(styles.DatePickerWrapper)}
        dateFormat={dateFormat}
        ref={calendar => (calendarRef.current = calendar)}
      />
      {!props.hideIcon && (
        <TransparentButton
          className={styles.CalendarIcon}
          onClick={() => calendarRef.current?.setOpen(true)}
        >
          <CalendarIcon />
        </TransparentButton>
      )}
    </div>
  )
}
