import { FC } from 'react'
import { Button, Col, Row } from 'react-bootstrap'

import { SearchBar } from 'components/SearchBar'
import styles from './SearchAndFilter.module.scss'
import classNames from 'classnames'

interface Props {
  hideBasicSearch?: boolean
  onClear: () => void
  onToggleSearch: () => void
  query: string
}

export const SearchAndFilterView: FC<Props> = ({
  hideBasicSearch,
  onClear,
  onToggleSearch,
  query,
}) => (
  <Row className="bg-dark text-white py-3 w-100">
    <Col>
      <SearchBar collapsed={hideBasicSearch} onCollapsedClick={onToggleSearch} query={query} />
    </Col>

    <Col>
      <div className={classNames(styles.SearchActions, { [styles.Collapsed]: hideBasicSearch })}>
        <Button onClick={onToggleSearch} size="sm" variant="dark">
          Search in View
        </Button>
        <Button className={styles.Clear} onClick={onClear} size="sm" variant="dark">
          Clear
        </Button>
      </div>
    </Col>
  </Row>
)
