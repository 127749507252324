import { FC, ReactNode, useReducer, useEffect, useState } from 'react'

import { AdvancedSearchFilter } from 'types'
import { GridViewInner } from './GridViewInner'
import { init, ActionTypes } from './state'
import { listSavedViews } from 'lib/api/gridConfig'
import { reducer } from './state/reducer'
import { useLocalDateFormat } from 'hooks/useLocalDateFormat'
import { useLocationParam } from 'hooks/useLocationParam'
import { useMenuConfigIdCache } from 'hooks/useMenuConfigIdCache'
import { updateFilters } from 'components/GridView/utils/updateFilters'
import { Loader } from 'components/Loader'

export type GridViewProps = {
  baseConfigId: number
  brandId?: number
  cellFormatters?: ReactNode
  externalLastUpdated?: number
  hideAdvancedSearch?: boolean
  hideHeader?: boolean
  hideName?: boolean
  infoBarRecordsUnit?: string
  readonly?: boolean
  renderHeaderContent?: ReactNode
  showDispatchesHeader?: boolean
  slaRuleSetId?: number
  subtitle?: string
}

export const GridView: FC<GridViewProps> = ({ baseConfigId, hideAdvancedSearch, ...props }) => {
  const [updatingFilters, setUpdatingFilters] = useState(true)
  const dateFormat = useLocalDateFormat()
  const [menuConfigIdCache, setMenuConfigIdCache] = useMenuConfigIdCache()
  const [locationFilters] = useLocationParam<AdvancedSearchFilter[]>('filters')
  const [hideAdvancedSearchLocationParam] = useLocationParam<boolean>('hideAdvancedSearch')

  const [state, dispatch] = useReducer(
    reducer,
    {
      baseConfigId: menuConfigIdCache[baseConfigId] || baseConfigId,
      filters: locationFilters,
      forceHideAdvancedSearch: hideAdvancedSearchLocationParam,
      hideAdvancedSearch,
    },
    init,
  )

  const { baseConfigId: stateBaseConfigId, savedViewsCache, configId, loading } = state
  const savedViews = savedViewsCache[stateBaseConfigId]

  // Toggle advanced search visibility if prop was changed
  useEffect(() => {
    if (typeof hideAdvancedSearch !== 'boolean') return
    if (hideAdvancedSearch !== state.showAdvancedSearch) return

    dispatch({ type: ActionTypes.ToggleAdvancedSearch })
  }, [dispatch, hideAdvancedSearch, state.showAdvancedSearch])

  // Fetch saved views if they are not already loaded
  useEffect(() => {
    if (savedViews === undefined) {
      void listSavedViews({ configId: stateBaseConfigId }).then(grids => {
        dispatch({ payload: grids, type: ActionTypes.UpdateSavedViewsCache })
      })
    }
  }, [dispatch, savedViews, stateBaseConfigId])

  // Update filters if they were provided in location params
  useEffect(() => {
    if (!locationFilters) return setUpdatingFilters(false)

    void updateFilters(
      {
        brandId: props.brandId,
        configId,
        filters: locationFilters,
        slaRuleSetId: props.slaRuleSetId,
      },
      dispatch,
    ).finally(() => setUpdatingFilters(false))
  }, [locationFilters, props.brandId, configId, props.slaRuleSetId])

  return (
    <>
      {updatingFilters ? (
        <Loader />
      ) : (
        <GridViewInner
          {...props}
          {...state}
          dateFormat={dateFormat}
          dispatch={dispatch}
          isLoading={Boolean(loading)}
          savedViews={savedViews || []}
          setMenuConfigIdCache={setMenuConfigIdCache}
        />
      )}
    </>
  )
}
