import { FC } from 'react'

export const DownloadIcon: FC = () => (
  <svg
    width="13px"
    height="18px"
    viewBox="0 0 13 18"
    version="1.1"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-113.000000, -116.000000)" fill="#0176CE">
        <g transform="translate(113.000000, 116.000000)">
          <path d="M11.6502917,6.96149661 C12.1295479,7.46748138 12.5709225,7.9335398 13,8.38662426 C10.8593168,10.567088 8.68157723,12.785218 6.50713891,15 C4.33360844,12.7817025 2.15685926,10.5601406 0,8.35883485 C0.405638756,7.92542061 0.848581423,7.45208002 1.29746631,6.97246171 C2.69727077,8.39382272 4.07801063,9.79584833 5.50727858,11.2472587 L5.50727858,0 L7.47241885,0 L7.47241885,11.2047376 C8.87296609,9.78237214 10.2528806,8.38076505 11.6502917,6.96149661"></path>
          <polygon points="2 18 12 18 12 16 2 16"></polygon>
        </g>
      </g>
    </g>
  </svg>
)
