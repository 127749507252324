import React, { useState } from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { DataTypeProvider } from '@devexpress/dx-react-grid'

import styles from './TooltipFormatter.module.scss'
import { ColumnName, DynamicObject } from 'types'
import { getDataOnlyValue } from 'utils/getDataOnlyValue'

type Props = DataTypeProvider.ValueFormatterProps

export const TooltipFormatter = ({ column, row, value }: Props): JSX.Element => {
  const [title, setTitle] = useState('')
  const handleMouseEnter = (event: React.MouseEvent<HTMLDivElement>) => {
    setTitle((event.currentTarget as HTMLDivElement).innerText)
  }
  const onWheel = () => setTitle('')
  const cell = (
    <div className={styles.Container} onWheel={onWheel} onMouseEnter={handleMouseEnter}>
      {value}
    </div>
  )
  if (!title) {
    return cell
  }

  const jobId = getDataOnlyValue(row as DynamicObject, ColumnName.jobId)
  return (
    <OverlayTrigger
      overlay={
        <Tooltip
          id={`${column.name}-${String(jobId)}`}
          style={{ fontSize: '14px', position: 'absolute', top: 0 }}
        >
          {title}
        </Tooltip>
      }
    >
      {cell}
    </OverlayTrigger>
  )
}
