import { api } from 'lib/api/base'
import { BaseApiResult } from 'types'
import { ENDPOINTS } from 'lib/api/endpoints'

interface CancelJobProps {
  comments?: string
  complete?: number
  jobId: string
  page?: string
}
type CancelJob = (props: CancelJobProps) => Promise<BaseApiResult>

export const cancelJob: CancelJob = async ({ comments, jobId, page, complete }) => {
  const { data } = await api.post<BaseApiResult>(ENDPOINTS.job, {
    action: 'cancel',
    comments,
    complete,
    job_id: jobId,
    page,
  })

  return data
}
