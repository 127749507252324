import { api } from 'lib/api/base'
import { ENDPOINTS } from 'lib/api/endpoints'
import { GridDataResponse, NormalizedGridDataResponse } from 'lib/api/types'
import { formatGridData } from 'lib/api/utils/formatGridData'

export type UpdatePageNumberParams = {
  brandId?: number
  configId: number
  pageNumber: number
  slaRuleSetId?: number
}

type UpdatePageNumber = (params: UpdatePageNumberParams) => Promise<NormalizedGridDataResponse>

export const updatePageNumber: UpdatePageNumber = async ({
  brandId,
  configId,
  pageNumber,
  slaRuleSetId,
}) => {
  const { data } = await api.post<GridDataResponse>(ENDPOINTS.gridConfigs, {
    action: 'update_page_number',
    brand_id: brandId,
    config_id: configId,
    page_number: pageNumber,
    set_id: slaRuleSetId,
  })

  return formatGridData(data)
}
