import { DataTypeProvider } from '@devexpress/dx-react-grid'
import { generatePath, Link } from 'react-router'

import { ColumnName, DynamicObject } from 'types'
import { getDataOnlyValue } from 'utils/getDataOnlyValue'
import { ROUTES } from 'Routes'

export interface Props {
  idColumn?: ColumnName
  valueColumn: ColumnName
}

export const QuoteDetailLinkProvider = ({ valueColumn, idColumn }: Props): JSX.Element => {
  const LinkFormatter = ({ row }: DataTypeProvider.ValueFormatterProps): JSX.Element => {
    const column_value = String(getDataOnlyValue(row as DynamicObject, valueColumn))
    const item_id = idColumn
      ? String(getDataOnlyValue(row as DynamicObject, idColumn))
      : column_value
    return <Link to={generatePath(ROUTES.quoteDetails, { quoteId: item_id })}>{column_value}</Link>
  }

  return <DataTypeProvider formatterComponent={LinkFormatter} for={[valueColumn]} />
}
