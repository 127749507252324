export const HIDDEN_ID = 'HIDDEN'
export const HiddenMarker = {
  id: HIDDEN_ID,
  name: 'HIDDEN',
}
export const FROZEN_ID = 'FROZEN'
export const FrozenMarker = {
  id: FROZEN_ID,
  name: 'FROZEN',
}
