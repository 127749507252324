import { Formik } from 'formik'
import * as React from 'react'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'

import { Button } from 'components/Button'
import { Form } from 'components/Form'
import { CompanyInfo, SelectOption } from 'types'

import { BillingInfoFields } from 'components/FormFields/BillingInfoFields'
import { CompanyInfoFields } from 'components/FormFields/CompanyInfoFields'
import {
  CreateAccountFields,
  CreateAccountFormValues,
} from '../CreateAccountFields/CreateAccountFields'
import { CreateAccountSchema, CreateAccountSimpleSchema } from './schema'

export interface FormValues extends CreateAccountFormValues {
  billingInfo: CompanyInfo
  billingSameAsCompany: boolean
  companyInfo: CompanyInfo
}
export type CreateAccountFormProps = {
  initialValues: FormValues
  onSubmit: (values: FormValues) => Promise<void>
  simpleForm?: boolean
  stateOptions: SelectOption[]
}

export const CreateAccountForm = ({
  initialValues,
  onSubmit,
  simpleForm,
  stateOptions,
}: CreateAccountFormProps): JSX.Element => (
  <Formik
    initialValues={initialValues}
    onSubmit={async (values, formikBag) => {
      try {
        await onSubmit(values)
      } catch (error) {
        formikBag.setStatus((error as Error).message)
      }
    }}
    validationSchema={simpleForm ? CreateAccountSimpleSchema : CreateAccountSchema}
  >
    {({ handleSubmit, isSubmitting, status, values, setFieldValue }) => {
      // --- Start of fields sync section ---
      // The next 4 useEffect() is to sync Email/Phone and First/Last name for
      // company/billing info. These fields are not present in the UI but are
      // required by the API.
      // WARN: If any of these fields will be added to the form - remove
      // corresponsing sync from useEffect()

      // eslint-disable-next-line react-hooks/rules-of-hooks
      React.useEffect(() => {
        if (!simpleForm) {
          void setFieldValue('billingInfo.email', values.email, false)
          void setFieldValue('companyInfo.email', values.email, false)
        }
      }, [setFieldValue, values.email])

      // eslint-disable-next-line react-hooks/rules-of-hooks
      React.useEffect(() => {
        if (simpleForm) {
          void setFieldValue('billingInfo.name', values.companyName, false)
          void setFieldValue('companyInfo.name', values.companyName, false)
        }
      }, [setFieldValue, values.companyName])

      // eslint-disable-next-line react-hooks/rules-of-hooks
      React.useEffect(() => {
        if (!simpleForm) {
          void setFieldValue('companyInfo.firstName', values.firstName, false)
        } else {
          void setFieldValue('billingInfo.firstName', values.firstName, false)
        }
      }, [setFieldValue, values.firstName])

      // eslint-disable-next-line react-hooks/rules-of-hooks
      React.useEffect(() => {
        if (!simpleForm) {
          void setFieldValue('companyInfo.lastName', values.lastName, false)
        } else {
          void setFieldValue('billingInfo.lastName', values.lastName, false)
        }
      }, [setFieldValue, values.lastName])

      // eslint-disable-next-line react-hooks/rules-of-hooks
      React.useEffect(() => {
        if (!simpleForm) {
          void setFieldValue('companyInfo.phone', values.phone, false)
        } else {
          void setFieldValue('billingInfo.phone', values.phone, false)
        }
      }, [setFieldValue, values.phone])
      // --- End of sync section ---

      return (
        <Form onSubmit={handleSubmit}>
          <Container>
            <Row className="justify-content-center">
              <Col sm={8} xl={6}>
                <h2 className="mb-4">Create Account</h2>

                <h4 className="mb-4">Your Information</h4>

                <CreateAccountFields setFieldValue={setFieldValue} includePasswordSuggestions />

                {!simpleForm && (
                  <>
                    <CompanyInfoFields stateOptions={stateOptions} />
                    <BillingInfoFields stateOptions={stateOptions} />
                  </>
                )}

                {status && <div className="mb-3 text-center text-error">{status}</div>}

                {/* TODO - Add Captcha */}

                <div className="text-center mt-5">
                  <Button className="w-100" loading={isSubmitting} type="submit">
                    Create Account
                  </Button>
                </div>
              </Col>
            </Row>
          </Container>
        </Form>
      )
    }}
  </Formik>
)
