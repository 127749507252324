import * as React from 'react'
import classNames from 'classnames'
import { Field, FieldProps } from 'formik'

import styles from './FormikFileFieldV2.module.scss'
import Button from 'react-bootstrap/Button'
import { useRef } from 'react'

type Props = {
  accept?: string
  className?: string
  description?: string
  disabled?: boolean
  hideLabel?: boolean
  hideValidation?: boolean
  label: string
  name: string
}

export const FormikFileFieldV2 = React.forwardRef<HTMLInputElement, Props>(
  ({ accept, className, description, disabled, hideValidation, name }, ref) => {
    const labelRef = useRef<HTMLLabelElement>(null)

    const [buttonTitle, setButtonTitle] = React.useState('Upload File')

    const handleButtonClick = () => {
      if (labelRef && labelRef.current) labelRef.current.click()
    }

    return (
      <Field name={name}>
        {({ field, form, meta }: FieldProps) => {
          const onFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            if (!event.target.files?.length) return

            void form.setFieldValue(name, event.target.files?.[0])
            setButtonTitle('Select a Different File')
          }
          const invalid = meta.touched && meta.error && form.submitCount > 0

          return (
            <div className={classNames(styles.Wrapper, className)}>
              <input
                className={classNames(styles.Input, {
                  [styles.Invalid]: invalid && !hideValidation,
                })}
                disabled={disabled}
                value={field.value?.name || ''}
                readOnly
              />

              <Button
                className={classNames('version2', styles.UploadButton)}
                onClick={handleButtonClick}
              >
                {buttonTitle || 'Upload File'}
              </Button>

              {description && <div className={styles.FieldDescription}>{description}</div>}

              {invalid && !hideValidation && <div className={styles.ErrorText}>{meta.error}</div>}

              <label htmlFor={name} style={{ display: 'none' }} ref={labelRef}>
                <input
                  type="file"
                  style={{ display: 'none' }}
                  id={name}
                  ref={ref}
                  accept={accept}
                  {...field}
                  value={undefined}
                  onChange={onFileChange}
                  disabled={disabled}
                />
              </label>
            </div>
          )
        }}
      </Field>
    )
  },
)
