import { DataTypeProvider } from '@devexpress/dx-react-grid'
import { generatePath, Link } from 'react-router'

import { ColumnName, DynamicObject } from 'types'
import { getDataOnlyValue } from 'utils/getDataOnlyValue'
import { EditIcon } from 'components/EditIcon'
import { ROUTES } from 'Routes.ts'

export interface Props {
  editColumn: ColumnName
  quoteIdColumn: ColumnName
}

export const EditButtonLinkProvider = ({ editColumn, quoteIdColumn }: Props): JSX.Element => {
  const LinkFormatter = ({ row }: DataTypeProvider.ValueFormatterProps): JSX.Element => {
    const item_id = String(getDataOnlyValue(row as DynamicObject, quoteIdColumn))
    return (
      <Link to={generatePath(ROUTES.editOrder, { orderId: item_id })}>
        <EditIcon />
      </Link>
    )
  }

  return <DataTypeProvider formatterComponent={LinkFormatter} for={[editColumn]} />
}
