import { api } from 'lib/api/base'
import { BaseApiResult } from 'types'
import { ENDPOINTS } from 'lib/api/endpoints'

export type TRedirectInfo = {
  // Response will include either job_id or quote_id depending on redirect type
  details: {
    brand_name: string
    change_user_required: boolean
    job_id?: string
    lease_number: string
    quote_id?: string
    ra_user_name: string
    ra_user_type: string
    session_user_name: string
    session_user_type: string
  }
}

type TGetRedirectionResponse = TRedirectInfo & BaseApiResult

export const getRedirection = async (uniqueId: string): Promise<TRedirectInfo> => {
  const { data } = await api.get<TGetRedirectionResponse>(ENDPOINTS.user, {
    params: {
      action: 'get_redirection',
      unique_id: uniqueId,
    },
  })

  return data
}
