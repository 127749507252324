import { Dispatch } from 'react'

import { Action, ActionTypes } from 'components/GridView/state'
import * as gridConfigApi from 'lib/api/gridConfig'
import { UpdateFilterParams } from 'lib/api/gridConfig'

export const updateFilters = async (
  params: UpdateFilterParams,
  dispatch: Dispatch<Action>,
): Promise<void> => {
  dispatch({ payload: true, type: ActionTypes.SetLoading })

  try {
    const updatedGridData = await gridConfigApi.updateFilters(params)
    dispatch({ payload: params.filters, type: ActionTypes.SetFilters })
    dispatch({ payload: updatedGridData, type: ActionTypes.DigestGridData })
  } catch {
    /* Ignored */
  } finally {
    dispatch({ payload: false, type: ActionTypes.SetLoading })
  }
}
