import { FC } from 'react'

export const EditIcon: FC = () => (
  <svg
    height="16"
    viewBox="0 0 16 16"
    width="16"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <mask id="EditIcon-mask" fill="#fff">
      <path d="m0 0h16v16h-16z" fill="#fff" fillRule="evenodd" />
    </mask>
    <path
      d="m2.00328442 11.8885097c-.20256625.8997772-.40465874 1.7975265-.61236866 2.7200837.9273514-.2084677 1.82553272-.4103109 2.71816428-.6110049-.70387203-.7050317-1.3981335-1.4003294-2.10579562-2.1090788m11.38079858-6.7768209c.3839487-.37428189.7687095-.74944254 1.1496802-1.12088538-.8447141-.83474935-1.6945719-1.67443324-2.5283895-2.49850233-.3687884.36468319-.7509773.7425477-1.1274135 1.1147341.8399765.8394135 1.6861119 1.68511348 2.5061228 2.50465361m-8.21147939 8.1883037c2.44845947-2.4375303 4.90192729-4.88006273 7.34037009-7.30765632-.8394351-.83116673-1.6885484-1.67193216-2.52026795-2.49546049-2.43052427 2.42779638-4.87756246 4.87208631-7.31681746 7.30867021.83686324.8360337 1.68367544 1.6821393 2.49671532 2.4944466m-4.58389893 2.7000075c-.36567506-.0093283-.64898355-.3169601-.57764883-.6761679.13651057-.687051.2958293-1.3696406.44959826-2.0531765.17488513-.7771571.3479429-1.554855.53690547-2.3286323.03526128-.1448593.11458224-.2992498.21908017-.4039568 3.43056399-3.43633634 6.86640703-6.86746777 10.30252075-10.2983964.3222922-.32182701.6474269-.31837958.9695838.00331222 1.0870762 1.08512683 2.173814 2.17065924 3.260078 3.25666483.3353544.33527871.3346776.67434282-.0006768 1.00935114-3.4113428 3.40774303-6.82329484 6.81494531-10.2333518 10.22410781-.14002992.1398572-.29711521.2330728-.49013858.2767402-1.40713494.3180416-2.81359309.6389898-4.22052499.9579102-.07065792.0159527-.14361697.021766-.21542545.0322435"
      fill="#0176ce"
      fillRule="evenodd"
      mask="url(#EditIcon-mask)"
    />
  </svg>
)
