import * as yup from 'yup'

export const PalletsSchema = yup.object().shape({
  pallets: yup
    .array()
    .of(
      yup.object().shape({
        asset_id: yup
          .number()
          .required('You must select an asset type.')
          .min(0, 'You must select an asset type.'),
        height: yup.number().when('quantity', {
          is: (quantity?: number) => quantity && quantity > 0,
          then: height =>
            height
              .required('You must enter a height.')
              .min(1, 'Height must be greater than 0.')
              .max(96, 'Height must be less than or equal to 96.'),
        }),
        length: yup.number().when('quantity', {
          is: (quantity?: number) => quantity && quantity > 0,
          then: length =>
            length
              .required('You must enter a length.')
              .min(1, 'Length must be greater than 0.')
              .max(96, 'Length must be less than or equal to 96.'),
        }),
        quantity: yup.number<number>(),
        weight: yup.number().when('quantity', {
          is: (quantity?: number) => quantity && quantity > 0,
          then: weight =>
            weight
              .min(0, 'Weight must be at least 0.')
              .max(2500, 'Weight must be less than or equal to 2500.'),
        }),
        width: yup.number().when('quantity', {
          is: (quantity?: number) => quantity && quantity > 0,
          then: width =>
            width
              .required('You must enter a width.')
              .min(1, 'Width must be greater than 0.')
              .max(96, 'Width must be less than or equal to 96.'),
        }),
      }),
    )
    .test('at-least-one-pallet', 'At least one pallet must be completed.', pallets => {
      if (!pallets) return false
      // @ts-ignore
      return pallets.some(pallet => pallet?.quantity && Number(pallet.quantity) > 0)
    }),
})
