import { SearchableSelect } from './SearchableSelect'
import { NonSearchableSelect } from './NonSearchableSelect'

export type TSelectOption<ValueType> = {
  label: string
  value: ValueType
}

export type TSelectProps<ValueType> = {
  ariaLabel?: string
  className?: string
  disabled?: boolean
  error?: string
  floatingLabel?: boolean
  id?: string
  label?: string
  multiple?: boolean
  onBlur?: (e: React.FocusEvent) => void
  onChange: (value: ValueType) => void
  openAbove?: boolean
  options: TSelectOption<ValueType>[]
  searchable?: boolean
  value: ValueType
}

export const Select = <ValueType,>(props: TSelectProps<ValueType>): JSX.Element => (
  <div className={props.className}>
    {props.searchable ? <SearchableSelect {...props} /> : <NonSearchableSelect {...props} />}
  </div>
)
