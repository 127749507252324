import * as yup from 'yup'
import {
  getBillingInfoSchema,
  getCompanyInfoSchema,
  getPhoneSchema,
} from 'components/FormFields/schema'
import { BasePasswordValidation } from 'components/ChangePasswordForm'
import { TUserFormValues } from 'components/AdminUserModal'
import { validatePhone } from 'utils/validatePhone'
import { validateEmail } from 'utils/validateEmail'

export const CreateAccountSimpleSchema = yup
  .object({
    companyName: yup.string().required('Company name is required'),
    email: yup
      .string()
      .required('Email is required')
      .test('email', 'Please enter a valid email address', validateEmail),
    firstName: yup.string().required('First name is required'),
    lastName: yup.string().required('Last name is required'),
    phone: getPhoneSchema(),
  })
  .concat(BasePasswordValidation(false))

export const CreateAccountSchema = yup
  .object()
  .concat(CreateAccountSimpleSchema)
  .shape({
    billingInfo: getBillingInfoSchema(),
    companyInfo: getCompanyInfoSchema({ req_name: true }),
  })

export const EditCompanyInformationScheme = yup
  .object({
    email: yup
      .string()
      .required('Email is required.')
      .test('email', 'Please enter a valid email address.', validateEmail),
    firstName: yup.string().required('First name is required.'),
    lastName: yup.string().required('Last name is required.'),
    phone: yup
      .string()
      .required('Phone number is required')
      .test('test-phone', 'Invalid phone number', function () {
        const { country, phone } = this.parent as TUserFormValues
        return validatePhone({ country, phone })
      }),
  })
  .shape({
    billingInfo: getBillingInfoSchema(),
    companyInfo: getCompanyInfoSchema({ req_name: true }),
  })
