import { api } from 'lib/api/base'
import { ENDPOINTS } from 'lib/api/endpoints'
import { BaseGridConfigResponse, NormalizedGridConfigResponse } from '../types'

type Params = {
  configId: number
}

type DeleteSavedView = (params: Params) => Promise<NormalizedGridConfigResponse>

export const deleteSavedView: DeleteSavedView = async ({ configId }) => {
  const {
    data: { config_id },
  } = await api.delete<BaseGridConfigResponse>(ENDPOINTS.gridConfigs, {
    data: {
      action: 'delete',
      config_id: configId,
    },
  })

  return { configId: Number(config_id) }
}
