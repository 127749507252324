import { FC, useEffect, useState } from 'react'

import { ApprovePoModal } from './ApprovePoModal'
import { getJob } from 'lib/api/job/get'
import { OrderResponse } from 'types'
import { useLoggedInAppContext } from 'hooks/useLoggedInAppContext'

import * as helpers from './helpers'

interface Props {
  closeModal: () => void
  jobId: string
  refreshData: () => void
  show: boolean
}

export const ApprovePoModalContainer: FC<Props> = ({ closeModal, jobId, refreshData, show }) => {
  const {
    user: { referenceNumbers, token },
  } = useLoggedInAppContext()

  const [loadingOrder, setLoadingOrder] = useState(false)
  const [order, setOrder] = useState<OrderResponse | null>(null)

  // Fetch the order information
  useEffect(() => {
    if (!show) {
      return
    }

    let mounted = true
    const getOrder = async () => {
      setLoadingOrder(true)

      try {
        const data = await getJob(jobId, token)
        if (mounted) {
          setOrder(data)
        }
      } finally {
        if (mounted) {
          setLoadingOrder(false)
        }
      }
    }

    void getOrder()

    return () => {
      mounted = false
    }
  }, [jobId, show, token])

  const primaryContact = order?.pickup_location?.contacts
    ? order?.pickup_location?.contacts[0] || null
    : null

  return (
    <ApprovePoModal
      closeModal={closeModal}
      initialValues={{
        comment: '',
        pickup_info: {
          address1: order?.pickup_location?.address.address1 || '',
          address2: order?.pickup_location?.address.address2 || '',
          city: order?.pickup_location?.address.city || '',
          company: order?.pickup_location?.address.company || '',
          first_name: primaryContact?.first_name || '',
          last_name: primaryContact?.last_name || '',
          phone: primaryContact?.phone || '',
          postal_code: order?.pickup_location?.address.postal_code || '',
          state_province: order?.pickup_location?.address.state_province || '',
        },
        reference1: order?.reference1 || '',
        reference2: order?.reference2 || '',
        reference3: order?.reference3 || '',
      }}
      jobId={jobId}
      loading={loadingOrder}
      onApprove={async values => {
        await helpers.handleApproveJob(jobId, values, closeModal, refreshData)
      }}
      order={order}
      referenceNumbers={referenceNumbers}
      show={show}
    />
  )
}
