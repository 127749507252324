import * as React from 'react'

import { FilterType, getFilterTypes } from 'lib/api/setting'
import { Status, useAsync } from 'hooks/useAsync'

type ReturnType = {
  gridFilterTypes: FilterType[] | null
  status: Status
}

export const useGridFilterTypes = (): ReturnType => {
  const { data: gridFilterTypes, run, status } = useAsync<FilterType[]>()

  React.useEffect(() => {
    run(getFilterTypes())
  }, [run])

  return {
    gridFilterTypes,
    status,
  }
}
