import * as React from 'react'

import { getGridColumnTypes, NormalizedGridColumnType } from 'lib/api/setting'
import { Status, useAsync } from 'hooks/useAsync'

type ReturnType = {
  gridColumnTypes: NormalizedGridColumnType[] | null
  status: Status
}

export const useGridColumnTypes = (): ReturnType => {
  const { data: gridColumnTypes, run, status } = useAsync<NormalizedGridColumnType[]>()

  React.useEffect(() => {
    run(getGridColumnTypes())
  }, [run])

  return {
    gridColumnTypes,
    status,
  }
}
