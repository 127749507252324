import { FC, useState, useEffect } from 'react'
import { Modal, Button } from 'react-bootstrap'

import { Comment, CommentType } from 'types'
import { getComments } from 'lib/api/comments'
import { Loader } from 'components/Loader'
import styles from './MoreCommentsModal.module.scss'
import { generatePath, Link } from 'react-router'
import { ROUTES } from 'Routes'

type Props = {
  closeModal: () => void
  commentType: CommentType
  jobId: string
  show: boolean
}

export const MoreCommentsModal: FC<Props> = ({ closeModal, commentType, jobId, show }) => {
  const [comments, setComments] = useState<Comment[]>([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (!jobId) return

    setLoading(true)
    getComments({ commentType, jobId })
      .then(comments => setComments(comments))
      .catch(() => closeModal())
      .finally(() => setLoading(false))
  }, [closeModal, commentType, jobId])

  return (
    <Modal scrollable onHide={closeModal} show={show} dialogClassName={styles.Dialog}>
      <Modal.Header>
        <Modal.Title>Comment</Modal.Title>
      </Modal.Header>
      <Modal.Body className={styles.Body}>
        {loading ? (
          <Loader />
        ) : (
          <>
            {comments.map(({ commentId, text }) => (
              <pre className={styles.Comment} key={commentId}>
                {text}
              </pre>
            ))}
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button className={styles.CloseButton} onClick={closeModal}>
          Close
        </Button>
        <Link
          className={styles.ViewCommentsButton}
          to={generatePath(ROUTES.orderDetails, { jobId }) + '#comments'}
        >
          View Comments
        </Link>
      </Modal.Footer>
    </Modal>
  )
}
