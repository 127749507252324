import { PropsWithChildren } from 'react'
import { Button } from 'react-bootstrap'

import styles from './GridCommentTextDisplay.module.scss'

type Props = {
  count: number
  openMoreCommentsModal: () => void
}

export const GridCommentTextDisplay = ({
  children,
  count,
  openMoreCommentsModal,
}: PropsWithChildren<Props>): JSX.Element => {
  return (
    <div className="d-flex">
      <div className={styles.Text}>{children}</div>
      {count > 1 && (
        <Button className={styles.MoreCommentsButton} onClick={openMoreCommentsModal}>
          {'\u002b'} {count - 1}
        </Button>
      )}
    </div>
  )
}
