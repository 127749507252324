import { DataTypeProvider } from '@devexpress/dx-react-grid'

import { ColumnName, DynamicObject } from 'types'
import { getDataOnlyValue } from 'utils/getDataOnlyValue'
import { PortalLink } from 'components/PortalLink'
import { PdfIcon } from 'components/PdfIcon'

export const PDFPortalProvider = () => {
  const PDFPortalFormatter = ({ row }: DataTypeProvider.ValueFormatterProps): JSX.Element => {
    const jobId = String(getDataOnlyValue(row as DynamicObject, ColumnName.jobId))
    const trackingNumber = String(row[ColumnName.trackingNumber])
    return (
      <PortalLink
        rel="noreferrer noopener"
        relativeHref={`view_label.php?job_id=${jobId}&tracking_number=${trackingNumber}`}
        target="_blank"
      >
        <PdfIcon />
      </PortalLink>
    )
  }
  return <DataTypeProvider formatterComponent={PDFPortalFormatter} for={[ColumnName.viewLabel]} />
}
