import { api } from 'lib/api/base'
import { ENDPOINTS } from 'lib/api/endpoints'
import { BaseGridConfigResponse } from '../'

type Params = {
  configId: number
  fixedColumns: number
}

type UpdateFixedColumns = (params: Params) => Promise<number>

export const updateFixedColumns: UpdateFixedColumns = async ({ configId, fixedColumns }) => {
  const {
    data: { config_id },
  } = await api.post<BaseGridConfigResponse>(ENDPOINTS.gridConfigs, {
    action: 'update_fixed_columns',
    config_id: configId,
    fixed_columns: fixedColumns,
  })

  return Number(config_id)
}
