import { useEffect, useState } from 'react'

import { Modal } from 'components/Modal'

import { useLoggedInAppContext } from 'hooks/useLoggedInAppContext.ts'
import { getJob, GetJobResponse } from 'lib/api/job/get'
import { Loader } from 'components/Loader'
import { SchedulePickupForm } from 'forms/SchedulePickupForm'
import { ConfirmReceiptForm } from 'forms/ConfirmReceiptForm'
import { RecordDeliveryForm } from 'forms/RecordDeliveryForm'
import { RecordPickupForm } from 'forms/RecordPickupForm'
import { RecordPalletsForm } from 'forms/RecordPalletsForm'

export type UpdateJobInfoModalProps = {
  closeModal: () => void
  editAction: string
  jobId: string
  refreshData: () => void
  show: boolean
}

export const UpdateJobInfoModal = ({
  closeModal,
  editAction,
  refreshData,
  jobId,
  show,
}: UpdateJobInfoModalProps) => {
  const { user } = useLoggedInAppContext()
  const [saving, setSaving] = useState(false)
  const [loading, setLoading] = useState(true)
  const [job, setJob] = useState<GetJobResponse | null>(null)

  // Get Job record
  useEffect(() => {
    if (!show || !jobId) return

    void getJob(jobId, user.token)
      .then(data => setJob(data))
      .finally(() => setLoading(false))
  }, [jobId, user.token, show])

  const resetModal = () => {
    setSaving(false)
    setLoading(true)
    setJob(null)
    closeModal()
  }

  // Which form to show
  const action = getActionFormAndTitle(editAction)
  if (!action) return null

  return (
    <Modal
      formId={action.formId}
      hide={resetModal}
      show={show}
      saving={saving}
      submitButtonText="SAVE"
      title={action.title}
      size="lg"
    >
      {loading ? (
        <Loader />
      ) : (
        <action.Form
          formId={action.formId}
          jobId={jobId}
          onSubmitSuccess={() => {
            resetModal()
            refreshData()
          }}
          setSaving={setSaving}
          job={job}
        />
      )}
    </Modal>
  )
}

const getActionFormAndTitle = (editAction: string) => {
  switch (editAction) {
    case 'update_step_schedule_pickup':
      return {
        Form: SchedulePickupForm,
        formId: 'schedule-pickup-modal',
        title: 'Schedule Pickup',
      }
    case 'update_step_record_pickup':
      return {
        Form: RecordPickupForm,
        formId: 'record-pickup-modal',
        title: 'Record Pickup',
      }
    case 'update_step_record_pallets':
      return {
        Form: RecordPalletsForm,
        formId: 'record-pallets-modal',
        title: 'Record Pallets',
      }
    case 'update_step_ep_receive':
      return {
        Form: ConfirmReceiptForm,
        formId: 'confirm-receipt-modal',
        title: 'Confirm Receipt',
      }
    case 'update_step_deliver_to_ep':
      return {
        Form: RecordDeliveryForm,
        formId: 'record-delivery-modal',
        title: 'Record Delivery',
      }
  }
}
