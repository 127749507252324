import { TrashIcon } from 'components/TrashIcon'

import styles from './RemoveRowButton.module.scss'
import classNames from 'classnames'
import { useEffect, useRef } from 'react'

export type Props = {
  ariaLabel?: string
  className?: string
  index: number
  onClick: () => void
  shouldFocus?: boolean
  title?: string
}

export const RemoveRowButton = ({
  ariaLabel,
  className,
  index,
  onClick,
  shouldFocus = false,
  title,
}: Props): JSX.Element => {
  const ref = useRef<HTMLButtonElement>(null)

  useEffect(() => {
    if (shouldFocus && ref.current) {
      ref.current.focus()
    }
  }, [shouldFocus])

  return (
    <button
      aria-label={ariaLabel || `Remove pallet ${index + 1}`}
      className={classNames(styles.Button, className)}
      onClick={onClick}
      ref={ref}
      title={title || 'Remove pallet'}
      type="button"
    >
      <TrashIcon />
    </button>
  )
}
