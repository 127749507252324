import { FC } from 'react'
import { Link } from 'react-router'

import { AlertsCountBadge } from 'components/Header/components/AlertsCountBadge'
import styles from './BellIconWrapper.module.scss'
import { ROUTES } from 'Routes'

export const BellIconWrapper: FC = () => {
  return (
    <div className={styles.BellIconWrapper}>
      <Link to={ROUTES.dashboard} className={styles.BellIconButton}>
        <AlertsCountBadge />
      </Link>
    </div>
  )
}
