import { CountryCode } from 'libphonenumber-js'
import { CompanyAddress, CompanyContact, CompanyInfo } from 'types'
import { validatePhone } from 'utils/validatePhone'
import * as yup from 'yup'
import { validateEmail } from 'utils/validateEmail'

export const getPhoneSchema = (required = true, country: CountryCode = 'US'): yup.StringSchema => {
  type TFormWithCountryField = {
    country?: string | null
  }

  const rand = Math.round(1000 * Math.random())
  let schema = yup
    .string()
    .test(`test-phone-${rand}`, 'Invalid phone number', function (value?: string | null) {
      const { country: parentCountry } = this.parent as TFormWithCountryField
      const countryCode = parentCountry || country
      return validatePhone({ country: countryCode, phone: value || '' })
    })

  if (required) {
    schema = schema.required('Phone number is required')
  }

  return schema
}

export const getContactSchema = (
  opts?: TCompanyContactSchemaOpts,
): yup.ObjectSchema<yup.AnyObject> => {
  opts = getCompanyContactSchemaOpts(opts)

  let email = yup.string().test('email', 'Enter a valid email address', validateEmail)
  let firstName = yup.string()
  let lastName = yup.string()
  const cellPhone = getPhoneSchema(opts.req_cellPhone).nullable()
  const phone = getPhoneSchema(opts.req_phone)

  if (opts?.req_email) {
    email = email.required('Email is required')
  }
  if (opts?.req_firstName) {
    firstName = firstName.required('First name is required')
  }
  if (opts?.req_lastName) {
    lastName = lastName.required('Last name is required')
  }

  return yup.object({
    cellPhone,
    email,
    firstName,
    lastName,
    phone,
  })
}

export const getAddressSchema = (
  opts?: TCompanyAddressSchemaOpts,
): yup.ObjectSchema<yup.AnyObject> => {
  let address1 = yup.string()
  let address2 = yup.string().nullable()
  let city = yup.string()
  let postalCode = yup.string()
  let stateProvince = yup.string()
  let country = yup.string().nullable()

  if (opts?.req_address1) {
    address1 = address1.required('Address is required.')
  }
  if (opts?.req_address2) {
    address2 = address2.required('Address 2 is required.')
  }
  if (opts?.req_city) {
    city = city.required('City is required.')
  }
  if (opts?.req_postalCode) {
    postalCode = postalCode.required('Zip code is required.')
  }
  if (opts?.req_stateProvince) {
    stateProvince = stateProvince.required('State is required.')
  }
  if (opts?.req_country) {
    country = country.required('Country is required.')
  }

  return yup.object({
    address1,
    address2,
    city,
    country,
    postalCode,
    stateProvince,
  })
}

export const getCompanyInfoSchema = (
  opts?: TCompanyInfoSchemaOpts,
): yup.ObjectSchema<yup.AnyObject> => {
  opts = getCompanyInfoSchemaOpts(opts)

  let name = yup.string()
  if (opts.req_name) {
    name = name.required('Company name is required.')
  }

  return yup.object().concat(getAddressSchema(opts)).concat(getContactSchema(opts)).shape({ name })
}

export const getBillingInfoSchema = (
  opts?: TCompanyInfoSchemaOpts,
): yup.ObjectSchema<yup.AnyObject> => {
  return getCompanyInfoSchema(opts)
}

type TCompanyContactKeys = keyof CompanyContact
type TCompanyContactKeysOpts = `req_${TCompanyContactKeys}`
type TCompanyContactSchemaOpts = Partial<Record<TCompanyContactKeysOpts, boolean>>

type TCompanyAddressKeys = keyof CompanyAddress
type TCompanyAddressKeysOpts = `req_${TCompanyAddressKeys}`
type TCompanyAddressSchemaOpts = Partial<Record<TCompanyAddressKeysOpts, boolean>>

type TCompanyInfoKeys = keyof CompanyInfo
type TCompanyInfoRequiredOpts = `req_${TCompanyInfoKeys}`
type TCompanyInfoSchemaOpts = Partial<Record<TCompanyInfoRequiredOpts, boolean>>

const getCompanyContactSchemaOpts = (
  opts?: TCompanyContactSchemaOpts,
): TCompanyContactSchemaOpts => {
  return {
    req_cellPhone: false,
    req_email: true,
    req_firstName: true,
    req_lastName: true,
    req_phone: true,
    ...opts,
  }
}

const getCompanyAddressSchemaOpts = (
  opts?: TCompanyAddressSchemaOpts,
): TCompanyAddressSchemaOpts => {
  return {
    req_address1: true,
    req_address2: false,
    req_addressId: false,
    req_city: true,
    req_country: false,
    req_postalCode: true,
    req_stateProvince: true,
    ...opts,
  }
}

const getCompanyInfoSchemaOpts = (opts?: TCompanyInfoSchemaOpts): TCompanyInfoSchemaOpts => {
  return {
    req_name: false,
    ...getCompanyContactSchemaOpts(),
    ...getCompanyAddressSchemaOpts(),
    ...opts,
  }
}
