import { ReactNode } from 'react'
import { Navigate, useLocation } from 'react-router'

import { useAppContext } from 'contexts/ContextProvider'
import { ROUTES, UNAUTHORIZED_ROUTES } from 'Routes'

export const RequireAuth = ({ children }: { children: ReactNode }) => {
  const { user, initializing } = useAppContext()
  const { pathname } = useLocation()

  if (initializing) return null
  if (!user) {
    return (
      <Navigate
        to={{ pathname: UNAUTHORIZED_ROUTES.login }}
        state={{ referrer: pathname === ROUTES.signOut ? undefined : pathname }}
        replace
      />
    )
  }
  if (user.passwordExpired) {
    return (
      <Navigate
        to={{ pathname: UNAUTHORIZED_ROUTES.passwordExpired }}
        state={{ referrer: pathname }}
        replace
      />
    )
  }

  return children
}
