import { DataTypeProvider } from '@devexpress/dx-react-grid'

import { ColumnName } from 'types'
import { ModalFunc } from 'hooks/useModalState'
import { getDataOnlyValue } from 'utils/getDataOnlyValue'

export interface Props {
  column: ColumnName
  onClickFormatter: ModalFunc<{ editAction: string; jobId: string }>
}

export const JobStatusActionProvider = ({ onClickFormatter, column }: Props): JSX.Element => {
  const JobStatusActionFormatter = ({ row }: DataTypeProvider.ValueFormatterProps) => {
    const updateStepActionValue = getDataOnlyValue(row, ColumnName.updateStepAction) as string

    const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault()
      onClickFormatter({
        editAction: updateStepActionValue,
        jobId: row[ColumnName.jobId],
      })
    }

    // No actions
    if (!updateStepActionValue) return <div>{row[column]}</div>

    // Actions exist
    return (
      // eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
      <a onClick={handleClick} href="">
        {row[column]}
      </a>
    )
  }
  return <DataTypeProvider formatterComponent={JobStatusActionFormatter} for={[column]} />
}
