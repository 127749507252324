import * as React from 'react'

export const useMedia = (query: string): boolean => {
  const [matches, setMatches] = React.useState(window.matchMedia(query).matches)

  React.useEffect(() => {
    const mql = window.matchMedia(query)

    const onChange = () => {
      setMatches(mql.matches)
    }

    mql.addEventListener('change', onChange)
    setMatches(mql.matches)

    return () => mql.removeEventListener('change', onChange)
  }, [query])

  return matches
}
