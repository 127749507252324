import * as React from 'react'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import { FieldArray, Form, Formik } from 'formik'
import Row from 'react-bootstrap/Row'

import { AdvancedSearchFilter, GridConfig, SelectOption, TFormSubmit } from 'types'
import { Button } from 'components/Button'
import {
  GridColumnTypeMap,
  GridFilterTypeMap,
} from 'pages/DispatchesPage/components/MobileView/hooks/useAdvancedSearchSettings'

import { FilterRow } from './components/FilterRow'

type FilterAndValueTypesByColumn = {
  [columnId: number]: {
    filterTypeOptions: SelectOption[]
    valueTypeId: number
  }
}

type FormValues = {
  filters: AdvancedSearchFilter[]
}

type Props = {
  filterableColumns: GridConfig[]
  formId: string
  gridColumnTypeMap: GridColumnTypeMap
  gridFilterTypeMap: GridFilterTypeMap
  initialValues: FormValues
  onSubmit: TFormSubmit<FormValues, void>
}

export const DashboardAdvancedSearchForm = ({
  filterableColumns,
  formId,
  gridColumnTypeMap,
  gridFilterTypeMap,
  initialValues,
  onSubmit,
}: Props): JSX.Element => {
  const columnOptions = React.useMemo(() => {
    return filterableColumns.map<SelectOption>(column => ({
      label: column.heading,
      value: column.columnId,
    }))
  }, [filterableColumns])

  const filterAndValueTypesByColumn = React.useMemo(() => {
    return filterableColumns.reduce<FilterAndValueTypesByColumn>((map, column) => {
      const columnId = column.columnId
      const columnTypeId = column.typeId

      map[columnId] = {
        filterTypeOptions: gridColumnTypeMap[columnTypeId].filterTypes.map(filterTypeId => {
          const filterType = gridFilterTypeMap[filterTypeId]

          return {
            label: filterType.description,
            value: filterType.typeId,
          }
        }),
        valueTypeId: columnTypeId,
      }

      return map
    }, {})
  }, [filterableColumns, gridColumnTypeMap, gridFilterTypeMap])

  return (
    <Formik initialValues={initialValues} onSubmit={values => onSubmit(values)}>
      {({ setFieldValue, values }) => (
        <Form id={formId}>
          <FieldArray name="filters">
            {({ push, remove }) => (
              <Container>
                {values.filters.map((filter, index) => (
                  <FilterRow
                    columnOptions={columnOptions}
                    filterTypeOptions={
                      filterAndValueTypesByColumn[filter.columnId]?.filterTypeOptions ?? []
                    }
                    key={`filter_${index}`}
                    index={index}
                    onColumnIdChange={value => {
                      void setFieldValue(`filters[${index}].value`, '')
                      void setFieldValue(`filters[${index}].columnId`, value)
                    }}
                    onRemoveFilter={() => remove(index)}
                    valueTypeId={filterAndValueTypesByColumn[filter.columnId]?.valueTypeId ?? -1}
                  />
                ))}

                <Row className="justify-content-center pt-3">
                  <Col xs={8}>
                    <Button onClick={() => push({ ...initialValues.filters[0] })} size="sm">
                      Add Filter
                    </Button>
                  </Col>
                </Row>
              </Container>
            )}
          </FieldArray>
        </Form>
      )}
    </Formik>
  )
}
