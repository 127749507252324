import * as React from 'react'

import { Status, useAsync } from 'hooks/useAsync'
import { getGridData } from 'lib/api/grid'
import { NormalizedGridDataResponse } from 'lib/api/types'

type ReturnType = {
  gridData: NormalizedGridDataResponse | null
  gridDataStatus: Status
  refreshGridData: () => void
}

export const useGridData = (configId: number, externalLastUpdated?: number): ReturnType => {
  const { data: gridData, run, status: gridDataStatus } = useAsync<NormalizedGridDataResponse>()

  React.useEffect(() => {
    run(getGridData({ configId }))
  }, [configId, externalLastUpdated, run])

  const refreshGridData = React.useCallback(() => {
    run(getGridData({ configId }))
  }, [configId, run])

  return { gridData, gridDataStatus, refreshGridData }
}
