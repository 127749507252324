import { FC, useMemo } from 'react'

import styles from './JobCount.module.scss'

export type Props = {
  currentPage: number
  pageSize: number
  recordsCount: number
  recordsUnit: string
}
export const JobCount: FC<Props> = ({ currentPage, pageSize, recordsCount, recordsUnit }) => {
  const rangeMin = currentPage * pageSize + 1
  const rangeMax = Math.min(rangeMin + pageSize - 1, recordsCount)

  const jobCountMessage = useMemo(
    () =>
      recordsCount === 0
        ? `No ${recordsUnit.toLowerCase()} found`
        : `Showing ${recordsUnit} ${rangeMin}-${rangeMax} of ${recordsCount} found`,
    [rangeMax, rangeMin, recordsCount, recordsUnit],
  )
  return <strong className={styles.JobCount}>{jobCountMessage}</strong>
}
