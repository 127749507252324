import { createContext, ReactNode, useContext, useState } from 'react'

export type UnauthorizedErrorContext = {
  errorMessage: string | null
  setErrorMessage: (errorMessage: string | null) => void
}

export const UnauthorizedErrorMessageContext = createContext<UnauthorizedErrorContext | undefined>(
  undefined,
)

export const UnauthenticatedErrorMessageContextProvider = ({
  children,
  errorMessage,
  setErrorMessage,
}: {
  children: ReactNode
  errorMessage?: string | null
  setErrorMessage?: (errorMessage: string | null) => void
}) => {
  const [unAuthorizedErrorMessage, setUnauthorizedErrorMessage] = useState<string | null>(
    errorMessage ?? null,
  )

  const handleSetErrorMessage = (errorMessage: string | null) => {
    setUnauthorizedErrorMessage(errorMessage)
    setErrorMessage?.(errorMessage)
  }

  return (
    <UnauthorizedErrorMessageContext.Provider
      value={{
        errorMessage: unAuthorizedErrorMessage,
        setErrorMessage: handleSetErrorMessage,
      }}
    >
      {children}
    </UnauthorizedErrorMessageContext.Provider>
  )
}

export const useUnauthorizedErrorMessageContext = (): UnauthorizedErrorContext => {
  const errorContext = useContext(UnauthorizedErrorMessageContext)

  if (errorContext === undefined) {
    throw new Error(
      'useUnauthorizedErrorMessageContext must be used inside UnauthenticatedErrorMessageContextProvider',
    )
  }

  return errorContext
}
