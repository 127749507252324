import { FC, useState, useEffect } from 'react'

import { ConfirmationDialog } from 'components/ConfirmationDialog'
import { Grid } from 'types'
import { deleteSavedView as deleteSavedViewApi } from 'lib/api/gridConfig'
import { GridDispatch, ActionTypes } from '../state'

export type Props = {
  dispatch: GridDispatch
  grid: Grid | undefined
  hide: () => void
  show: boolean
}

export const DeleteSavedViewConfirmation: FC<Props> = ({ dispatch, grid, hide, show }) => {
  const [disabled, setDisabled] = useState(false)

  const deleteSavedView = async (grid: Pick<Grid, 'configId'>) => {
    await deleteSavedViewApi(grid)
    dispatch({ payload: grid.configId, type: ActionTypes.DeleteSavedView })
  }

  useEffect(() => {
    if (show) {
      setDisabled(false)
    }
  }, [show])

  return grid ? (
    <ConfirmationDialog
      onAccept={() => {
        setDisabled(true)
        void deleteSavedView(grid)
          .catch(() => {
            setDisabled(false)
          })
          .then(() => {
            hide()
          })
      }}
      onCancel={hide}
      title="Delete View"
      disabled={disabled}
      show={show}
    >
      {`Delete saved view "${grid.name}"?`}
    </ConfirmationDialog>
  ) : null
}
