import { api } from 'lib/api/base'
import { BaseApiResult, OrderResponse } from 'types'
import { ENDPOINTS } from 'lib/api/endpoints'

export type GetJobResponse = OrderResponse & BaseApiResult

const getJobConstants = {
  action: 'get',
}

export const getJob = async (jobId: string, token: string): Promise<GetJobResponse> => {
  const { data } = await api.get<GetJobResponse>(ENDPOINTS.job, {
    params: {
      ...getJobConstants,
      job_id: jobId,
      token,
    },
  })

  return data
}
